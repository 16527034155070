<template>
  <div v-resize="resizeMap">
    <wrapper-collapsible
      :filterNameText="name"
      :filterValueText="selectedAddrRadiusString"
      :canBeApplied="toBeSelectedCoordsString != ''"
      @on-open-clicked="loadYMap"
      @on-close-clicked="clearSelected"
      @on-apply-clicked="applySelected"
    >
      <MapAddressTextbox
        :selectedCoords="data.toBeSelectedCoords"
        @on-selected-addr="selectedTextboxAddr"
        @on-addr-changed="changeAddrText"
      />

      <transition name="fade">
        <div v-if="data.mapErrText != ''" class="err-my-location">
          {{ data.mapErrText }}
        </div>
      </transition>

      <div :id="mapUuid" :style="mapStyle"></div>

      <div v-if="withRadius" class="radius-slider">
        <label>{{
          t("filters.filter_location_map_select.radius") + " " + data.toBeRadius
        }}</label>
        <div v-if="sliderComponent">
          <component
            :is="sliderComponent"
            v-model="data.toBeRadius"
            color="#ffa93b"
            track-color="#FEFEFE"
            :height="10"
            :min="RADIUS_DEF.min"
            :max="RADIUS_DEF.max"
            :step="4"
            :aria-label="
              t('filters.filter_location_map_select.radius_x_km', {
                x: data.toBeRadius,
              })
            "
          />
        </div>
      </div>
    </wrapper-collapsible>
  </div>
</template>

<script>
import { reactive, ref, shallowRef } from "vue";
import { useI18n } from "vue-i18n";
import { v4 as uuidv4 } from "uuid";

import GenericFilterMixin from "../mixins/GenericFilterMixin";
import PropsLocationRadius from "../mixins/PropsLocationRadius";

import WrapperCollapsible from "./WrapperCollapsible.vue";
import MapAddressTextbox from "./MapAddressTextbox.vue";

import { newYmap, getZoomForKm } from "src/ymap.js";
import { getAddress as getAddressApi } from "api/geocoder.js";

import MapIcon from "assets/img/placemark.svg";

/** Стандартные значения радиуса, км */
const RADIUS_DEF = {
  max: 50,
  min: 2,
  default: 2,
};

export default {
  name: "FilterLocationMapSelect",
  components: {
    WrapperCollapsible,
    MapAddressTextbox,
  },
  props: {
    /** Название фильтра */
    name: {
      type: String,
      required: false,
      default() {
        const { t } = useI18n();
        return t("filters.filter_location_map_select.select_location");
      },
    },
    /** Разрешить выбирать радиус вокруг точки */
    withRadius: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    hint: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  mixins: [GenericFilterMixin, PropsLocationRadius],
  setup() {
    const { t } = useI18n();

    return {
      t,
      sliderComponent: shallowRef(null),
      RADIUS_DEF,
      mapUuid: "map_" + uuidv4(),
      data: reactive({
        toBeSelectedCoords: {
          lat: undefined,
          lon: undefined,
        },
        mapErrText: "",
        // радиус, км
        toBeRadius: RADIUS_DEF.default,
        addrText: "",
      }),
      map: {
        // Яндекс Карта
        mapObj: undefined,
        // метка на карте
        mark: undefined,
        // круг радиуса на карте
        radiusCircle: undefined,
      },
      // Ширина div'а карты
      mapWidth: ref(280),
      selectedAddrText: ref(""),
    };
  },
  async mounted() {
    if (!this.isSSR) {
      const slider = (await import("vue3-slider")).default;
      if (!slider) {
        console.error("Slider component was not loaded");
      }
      this.sliderComponent = slider;
    }

    if (
      this.defaultCoords.lat != undefined &&
      this.defaultCoords.lon != undefined
    ) {
      this.data.toBeSelectedCoords.lat = this.defaultCoords.lat;
      this.data.toBeSelectedCoords.lon = this.defaultCoords.lon;

      this.selectedCoords.lat = this.defaultCoords.lat;
      this.selectedCoords.lon = this.defaultCoords.lon;
    }

    if (this.defaultRadius != undefined) {
      this.data.toBeRadius = this.defaultRadius;
      this.selectedCoords.radius = this.defaultRadius;
    }

    if (this.hint == undefined || String(this.hint) != "") {
      await this.addrTextFromGeocoder();
    }
  },
  computed: {
    // Маркер, который изменится если хотя бы одна из координат изменится
    toBeSelectedCoordsString() {
      if (
        this.data.toBeSelectedCoords.lat == 0 ||
        this.data.toBeSelectedCoords.lat == undefined
      ) {
        return "";
      }

      if (
        this.data.toBeSelectedCoords.lon == 0 ||
        this.data.toBeSelectedCoords.lon == undefined
      ) {
        return "";
      }

      return (
        this.data.toBeSelectedCoords.lat +
        "_" +
        this.data.toBeSelectedCoords.lon
      );
    },
    selectedAddrRadiusString() {
      if (
        this.selectedCoords.lat == undefined ||
        this.selectedCoords.lat == 0
      ) {
        return "";
      }

      if (
        this.selectedCoords.lon == undefined ||
        this.selectedCoords.lon == 0
      ) {
        return "";
      }

      if (this.hint != undefined && String(this.hint) != "") {
        return this.hint;
      }

      if (
        this.withRadius &&
        this.selectedCoords.radius != 0 &&
        this.selectedCoords.radius != undefined
      ) {
        return this.t(
          "filters.filter_location_map_select.location_radius_selected",
          { r: this.selectedCoords.radius, addr: this.selectedAddrText }
        );
      }

      return this.t("filters.filter_location_map_select.location_selected", {
        addr: this.selectedAddrText,
      });
    },
    mapStyle() {
      return {
        width: this.mapWidth + "px",
        height: "400px",
        margin: "auto",
      };
    },
  },
  watch: {
    toBeSelectedCoordsString() {
      if (this.toBeSelectedCoordsString == "") {
        return;
      }
      this.updatePlacemark();
    },
    "data.toBeRadius"() {
      if (!this.withRadius) {
        return;
      }

      if (this.toBeSelectedCoordsString == "") {
        return;
      }

      this.updatePlacemark();

      if (this.map.mapObj != undefined) {
        this.map.mapObj
          .getInner()
          .setCenter(
            [
              this.data.toBeSelectedCoords.lat,
              this.data.toBeSelectedCoords.lon,
            ],
            getZoomForKm(this.data.toBeRadius)
          );
      }
    },
    mapWidth() {
      if (this.map.mapObj == undefined) {
        return;
      }

      this.map.mapObj.fitToViewport();
    },
    "data.mapErrText"() {
      if (this.data.mapErrText == "") {
        return;
      }

      setTimeout(() => {
        this.data.mapErrText = "";
      }, 5000);
    },
    defaultCoords: {
      handler() {
        this.selectedAddrText = this.hint == undefined ? "" : this.hint;
        this.selectedCoords.lat = this.defaultCoords.lat;
        this.selectedCoords.lon = this.defaultCoords.lon;
        this.selectedCoords.radius = this.defaultRadius;
      },
      deep: true,
    },
  },
  methods: {
    async loadYMap() {
      var mapCenter = [55.76, 37.64]; // Москва
      var zoom = 11;

      if (
        this.data.toBeSelectedCoords.lat != undefined &&
        this.data.toBeSelectedCoords.lon != undefined
      ) {
        mapCenter = [
          this.data.toBeSelectedCoords.lat,
          this.data.toBeSelectedCoords.lon,
        ];
        zoom = 14;
      }

      try {
        this.map.mapObj = await newYmap(this.$loadScript, this.mapUuid, {
          center: mapCenter,
          zoom,
          controls: ["zoomControl", "fullscreenControl"],
        });

        var c = this.data;
        this.map.mapObj.setOnClick((e) => {
          var coords = e.get("coords");
          c.toBeSelectedCoords.lat = coords[0];
          c.toBeSelectedCoords.lon = coords[1];
        });

        var buttonContent = '<i class="fas fa-location-arrow"></i>';

        this.map.mapObj.addButton(
          {
            content: buttonContent,
          },
          {
            selectOnClick: false,
            float: "left",
          },
          this.mapToCurrentLocation
        );

        this.updatePlacemark();
      } catch (e) {
        console.error("Error initialing map: ", e);
        this.mapErrText = this.t(
          "filters.filter_location_map_select.error_map_init"
        );
      }
    },
    resizeMap({ width }) {
      if (width >= 600) {
        this.mapWidth = 600;
        return;
      }
      if (width >= 550) {
        this.mapWidth = 550;
        return;
      }

      if (width >= 500) {
        this.mapWidth = 500;
        return;
      }
      if (width >= 450) {
        this.mapWidth = 450;
        return;
      }
      if (width >= 400) {
        this.mapWidth = 400;
        return;
      }
      if (width >= 350) {
        this.mapWidth = 350;
        return;
      }

      this.mapWidth = 280;
    },
    mapToCurrentLocation() {
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      var map = this.map.mapObj;
      var this_data = this.data;
      function success(pos) {
        var crd = pos.coords;

        this_data.mapErrText = "";
        map.getInner().setCenter(
          [crd.latitude, crd.longitude],
          // crd.accuracу в метрах
          getZoomForKm(crd.accuracy / 1000)
        );
      }

      var error = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`);

        switch (err.code) {
          case 1: //GeolocationPositionError.TIMEOUT
            this.data.mapErrText = this.t(
              "filters.filter_location_map_select.error_timeout"
            );
            break;
          case 2: //GeolocationPositionError.PERMISSION_DENIED
            this.data.mapErrText = this.t(
              "filters.filter_location_map_select.error_denied"
            );
            break;
          case 3: //GeolocationPositionError.POSITION_UNAVAILABLE
            this.data.mapErrText = this.t(
              "filters.filter_location_map_select.error_position_na"
            );
            break;
        }
      };

      navigator.geolocation.getCurrentPosition(success, error, options);
    },
    updatePlacemark() {
      if (this.map.mapObj == undefined) {
        return;
      }

      if (this.map.mark != undefined) {
        this.map.mapObj.removeGeoObj(this.map.mark);
      }

      if (this.map.radiusCircle != undefined) {
        this.map.mapObj.removeGeoObj(this.map.radiusCircle);
      }

      if (
        this.data.toBeSelectedCoords.lat == 0 ||
        this.data.toBeSelectedCoords.lon == 0 ||
        this.data.toBeSelectedCoords.lat == undefined ||
        this.data.toBeSelectedCoords.lon == undefined
      ) {
        return;
      }

      var coords = [
        this.data.toBeSelectedCoords.lat,
        this.data.toBeSelectedCoords.lon,
      ];
      this.map.mark = this.map.mapObj.addPlacemark(
        coords,
        {},
        {
          iconLayout: "default#image",
          iconImageHref: MapIcon,
          // Размеры метки.
          iconImageSize: [36, 45],
          // Смещение левого верхнего угла иконки относительно
          // её "ножки" (точки привязки).
          iconImageOffset: [-18, -55],
        }
      );
      if (this.withRadius) {
        if (this.data.toBeRadius != undefined && this.data.toBeRadius != 0) {
          this.map.radiusCircle = this.map.mapObj.addCircle(
            coords,
            // у нас радиус в км, карте нужен в метрах
            this.data.toBeRadius * 1000,
            {},
            {
              fillColor: "#414141",
              fillOpacity: 0.05,
              strokeColor: "#ffa93b",
              strokeOpacity: 1,
              strokeWidth: 2,
              interactivityModel: "default#transparent",
            }
          );
        }
      }
    },
    clearSelected() {
      this.data.addrText = "";
    },
    async applySelected() {
      this.selectedCoords.lat = this.data.toBeSelectedCoords.lat;
      this.selectedCoords.lon = this.data.toBeSelectedCoords.lon;

      if (this.withRadius) {
        if (this.data.toBeRadius > RADIUS_DEF.max) {
          this.selectedCoords.radius = RADIUS_DEF.max;
        } else if (this.data.toBeRadius < RADIUS_DEF.min) {
          this.selectedCoords.radius = RADIUS_DEF.min;
        } else {
          this.selectedCoords.radius = this.data.toBeRadius;
        }
      }

      this.selectedAddrText = this.data.addrText;

      this.clearSelected();
    },
    selectedTextboxAddr(lat, lon, addrText) {
      this.data.toBeSelectedCoords.lat = lat;
      this.data.toBeSelectedCoords.lon = lon;
      this.data.addrText = addrText;

      if (this.toBeSelectedCoordsString == "") {
        return;
      }

      this.map.mapObj
        .getInner()
        .setCenter([lat, lon], getZoomForKm(this.data.toBeRadius));
    },
    changeAddrText(text) {
      this.data.addrText = text;
    },
    async addrTextFromGeocoder() {
      if (this.selectedCoords == undefined) {
        return;
      }

      if (
        this.selectedCoords.lat == undefined ||
        this.selectedCoords.lon == undefined
      ) {
        return;
      }

      var geocoder = await getAddressApi(
        this.selectedCoords.lat,
        this.selectedCoords.lon,
        this.$i18n.locale
      );

      if (geocoder[0] != undefined) {
        if (geocoder[0].displayName != "") {
          this.selectedAddrText = geocoder[0].displayName;
        }
      }
    },
  },
};
</script>

<style scoped>
.radius-slider {
  margin: auto;
  padding: 10px;
}

.radius-slider label {
  text-align: start;
  display: block;
  font-size: 15px;
}

.err-my-location {
  margin: 10px 0;
  padding: 5px 0;
  color: white;
  background-color: red;
  transition: all 0.1s ease-in-out;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

// TODO: not use global style
<style>
.vue3-slider .handle {
  transform: scale(2) !important;
}
</style>
