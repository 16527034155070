import { grpc } from "@improbable-eng/grpc-web";

import {
  GetCategoriesRequest,
  GetCategoryRequest,
  GetParentRequest,
  CategoryId,
} from "./proto/categories_pb.js";
import { PublicCategoriesService } from "./proto/categories_pb_service.js";

import {
  CategoryStatRequest,
  CategoryId as PhotosCategoryId,
} from "./proto/photos_pb.js";
import { ApiService } from "./proto/photos_pb_service.js";

import { getMeta } from "./utils/meta.js";

/**
 * Получить дочерние категории для категории с id.
 * @param {string} id - id категории, для которой нужно получить дочерние
 * @returns {Object[]} Вернет list объектов с полями:
 *   - id - string
 *   - name - string
 *   - hasChildren - bool
 */
export function getChildren(id) {
  let request = new GetCategoriesRequest();

  var catId = new CategoryId();
  catId.setId(id);

  request.setParent(catId);

  return new Promise((resolve, reject) => {
    grpc.unary(PublicCategoriesService.GetCategories, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error PublicCategoriesService.GetCategories",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        var categories = [];
        for (var cat of obj.categoriesList) {
          categories.push({
            id: cat.id.id,
            name: cat.name,
            hasChildren: cat.hasChildren,
          });
        }

        return resolve(categories);
      },
    });
  });
}

/**
 * Получить родительские категории для категории с id.
 * @param {string} id - id категории, для которой нужно получить родительские категории
 * @returns {Object[]} Список категорий.
 * Самая большая категория будет сверху (Электроника -> Планшеты -> Чехлы и пленки).
 * Вернет list объектов с полями:
 *   - id
 *   - name
 *   - hasChildren
 */
export function getParent(id) {
  let request = new GetParentRequest();
  request.setCategoryId(id);

  return new Promise((resolve, reject) => {
    grpc.unary(PublicCategoriesService.GetParent, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error PublicCategoriesService.GetParent",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        var categories = [];

        for (var cat of obj.categoriesList) {
          categories.push({
            id: cat.id.id,
            name: cat.name,
          });
        }

        categories = categories.reverse();
        return resolve(categories);
      },
    });
  });
}

/**
 * Получить категорию по id
 * @param {string} id - id категории
 * @returns {Object} Категория с полями:
 *   - id
 *   - name
 *   - hasChildren
 */
export function getCategory(id) {
  let request = new GetCategoryRequest();
  request.setCategoryId(id);

  return new Promise((resolve, reject) => {
    grpc.unary(PublicCategoriesService.GetCategory, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error PublicCategoriesService.GetCategory",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        return resolve({
          id: obj.category.id.id,
          name: obj.category.name,
          hasChildren: obj.category.hasChildren,
        });
      },
    });
  });
}

export function getCategoryStats(categories) {
  let request = new CategoryStatRequest();

  let apiCategories = [];
  for (var c of categories) {
    let id = new PhotosCategoryId();
    id.setId(c);
    apiCategories.push(id);
  }

  request.setCategoriesList(apiCategories);

  return new Promise((resolve, reject) => {
    grpc.unary(ApiService.GetStatsByCategory, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error ApiService.GetStatsByCategory",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        let categories = {};

        for (var c of obj.categoryStatsList) {
          categories[c.id] = c.activated;
        }

        return resolve(categories);
      },
    });
  });
}
