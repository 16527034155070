/**
 * Выбрать не более props.maxSelected значений и сохранить их в selectedValues
 * Если selectedValues изменилось, emit 'on-value-selected'
 */

import { ref } from "vue";
import _ from "lodash";

export default {
  mounted() {
    this.mergeObjectSelectedValues();
  },
  data() {
    return {
      selectedValues: ref([]),
      skipSelectedValueWatch: false,
    };
  },
  props: {
    maxSelected: {
      type: Number,
      required: false,
      default() {
        return -1;
      },
    },
  },
  watch: {
    selectedValues: {
      handler(newVal, oldVal) {
        if (this.skipSelectedValueWatch) {
          return;
        }

        if (_.xor(oldVal, newVal).length == 0) {
          return;
        }

        this.$emit("on-value-selected", this.filterId, this.selectedValues);
      },
      deep: true,
    },
    objects() {
      this.mergeObjectSelectedValues();
    },
  },
  methods: {
    /** Добавить или удалить v из selectedValues */
    toggleValue(v) {
      var idx = this.selectedValues.indexOf(v);
      if (idx == -1) {
        if (
          this.selectedValues.length >= this.maxSelected &&
          this.maxSelected != -1
        ) {
          return;
        }

        this.selectedValues = [...this.selectedValues, v];
      } else {
        this.selectedValues.splice(idx, 1);
      }
    },
    /** Merge 'selectedValues' и 'objects' */
    mergeObjectSelectedValues() {
      var new_selected = this.objects
        .filter((obj) => obj.checked)
        .map((obj) => obj.value);

      if (new_selected.length == 0) {
        return;
      }

      var all_selected = this.selectedValues.concat(new_selected);
      var valid_selected = this.objects
        .filter((obj) => all_selected.includes(obj.value))
        .map((obj) => obj.value);

      this.skipSelectedValueWatch = true;
      this.selectedValues = valid_selected;
      this.skipSelectedValueWatch = false;
    },
  },
};
