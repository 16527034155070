// source: toponims.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.api.v1.toponims.Toponim', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.toponims.Toponim = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.toponims.Toponim, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.toponims.Toponim.displayName = 'proto.api.v1.toponims.Toponim';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.toponims.Toponim.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.toponims.Toponim.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.toponims.Toponim} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.toponims.Toponim.toObject = function(includeInstance, msg) {
  var f, obj = {
    country: jspb.Message.getFieldWithDefault(msg, 1, ""),
    countryIsoCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    region: jspb.Message.getFieldWithDefault(msg, 3, ""),
    regionFiasId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    regionWithType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    area: jspb.Message.getFieldWithDefault(msg, 6, ""),
    areaFiasId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    areaWithType: jspb.Message.getFieldWithDefault(msg, 8, ""),
    cityFiasId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    cityTypeFull: jspb.Message.getFieldWithDefault(msg, 10, ""),
    cityDistrictFiasId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    cityDistrict: jspb.Message.getFieldWithDefault(msg, 12, ""),
    settlementFiasId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    settlementWithType: jspb.Message.getFieldWithDefault(msg, 14, ""),
    streetFiasId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    street: jspb.Message.getFieldWithDefault(msg, 16, ""),
    houseFiasId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    house: jspb.Message.getFieldWithDefault(msg, 18, ""),
    block: jspb.Message.getFieldWithDefault(msg, 19, ""),
    flat: jspb.Message.getFieldWithDefault(msg, 20, ""),
    fiasId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    geoLat: jspb.Message.getFieldWithDefault(msg, 22, ""),
    geoLon: jspb.Message.getFieldWithDefault(msg, 23, ""),
    fiasLevel: jspb.Message.getFieldWithDefault(msg, 24, ""),
    humanName: jspb.Message.getFieldWithDefault(msg, 25, ""),
    metro: jspb.Message.getFieldWithDefault(msg, 26, ""),
    id: jspb.Message.getFieldWithDefault(msg, 27, ""),
    cityHumanName: jspb.Message.getFieldWithDefault(msg, 28, ""),
    radius: jspb.Message.getFieldWithDefault(msg, 29, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.toponims.Toponim}
 */
proto.api.v1.toponims.Toponim.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.toponims.Toponim;
  return proto.api.v1.toponims.Toponim.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.toponims.Toponim} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.toponims.Toponim}
 */
proto.api.v1.toponims.Toponim.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryIsoCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionFiasId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionWithType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setArea(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAreaFiasId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAreaWithType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityFiasId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityTypeFull(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityDistrictFiasId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityDistrict(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementFiasId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementWithType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreetFiasId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setHouseFiasId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setHouse(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlock(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlat(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setFiasId(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setGeoLat(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setGeoLon(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setFiasLevel(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setHumanName(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetro(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityHumanName(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRadius(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.toponims.Toponim.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.toponims.Toponim.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.toponims.Toponim} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.toponims.Toponim.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountryIsoCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRegionFiasId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRegionWithType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getArea();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAreaFiasId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAreaWithType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCityFiasId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCityTypeFull();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCityDistrictFiasId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCityDistrict();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSettlementFiasId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSettlementWithType();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getStreetFiasId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getHouseFiasId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getHouse();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getBlock();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getFlat();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getFiasId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getGeoLat();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getGeoLon();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getFiasLevel();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getHumanName();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getMetro();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getCityHumanName();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0) {
    writer.writeInt64(
      29,
      f
    );
  }
};


/**
 * optional string country = 1;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country_iso_code = 2;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getCountryIsoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setCountryIsoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string region = 3;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string region_fias_id = 4;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getRegionFiasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setRegionFiasId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string region_with_type = 5;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getRegionWithType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setRegionWithType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string area = 6;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getArea = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setArea = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string area_fias_id = 7;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getAreaFiasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setAreaFiasId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string area_with_type = 8;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getAreaWithType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setAreaWithType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string city_fias_id = 9;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getCityFiasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setCityFiasId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string city_type_full = 10;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getCityTypeFull = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setCityTypeFull = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string city_district_fias_id = 11;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getCityDistrictFiasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setCityDistrictFiasId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string city_district = 12;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getCityDistrict = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setCityDistrict = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string settlement_fias_id = 13;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getSettlementFiasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setSettlementFiasId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string settlement_with_type = 14;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getSettlementWithType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setSettlementWithType = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string street_fias_id = 15;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getStreetFiasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setStreetFiasId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string street = 16;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string house_fias_id = 17;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getHouseFiasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setHouseFiasId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string house = 18;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getHouse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setHouse = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string block = 19;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getBlock = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setBlock = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string flat = 20;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getFlat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setFlat = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string fias_id = 21;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getFiasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setFiasId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string geo_lat = 22;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getGeoLat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setGeoLat = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string geo_lon = 23;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getGeoLon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setGeoLon = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string fias_level = 24;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getFiasLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setFiasLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string human_name = 25;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getHumanName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setHumanName = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string metro = 26;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getMetro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setMetro = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string id = 27;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string city_human_name = 28;
 * @return {string}
 */
proto.api.v1.toponims.Toponim.prototype.getCityHumanName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setCityHumanName = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional int64 radius = 29;
 * @return {number}
 */
proto.api.v1.toponims.Toponim.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.toponims.Toponim} returns this
 */
proto.api.v1.toponims.Toponim.prototype.setRadius = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};


goog.object.extend(exports, proto.api.v1.toponims);
