import {
  encode as encodeMsgPack,
  decode as decodeMsgPack,
} from "@msgpack/msgpack";

import _ from "lodash";

/**
 * Представить объект как msgpack в base64
 * @param {Object} obj - объект, который нужно представить в base64
 * @returns {String} - строка в формате base64 или undefined (если объект пустой)
 */
export function encodeBase64(obj) {
  if (_.isEqual(obj, {})) {
    return undefined;
  }

  try {
    var encoded = encodeMsgPack(obj);

    var buffer = Buffer.from(
      encoded.buffer,
      encoded.byteOffset,
      encoded.byteLength
    );
    return buffer.toString("base64");
  } catch (e) {
    console.error("Error encoding", obj, e);
    return "";
  }
}

/**
 * Получить объект из msgpack в base64
 * @param {Sring} str - строка в формате base64
 * @returns {Object} - объект, который будет в base64
 */
export function decodeBase64(str) {
  if (str == "") {
    return {};
  }

  try {
    var new_buffer = Buffer.from(str, "base64");
    var obj = decodeMsgPack(new_buffer);
    return obj;
  } catch (e) {
    console.error(("Decode base 64 error ", e));
    return {};
  }
}
