import Home from "../views/Home.vue";
import SearchPage from "../views/SearchPage/SearchPage.vue";
import AdDetails from "../views/AdDetails.vue";

import { routes as profileRoutes } from "../modules/profile/router.js";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter(to) {
      if (
        (to.query.q != undefined && to.query.q != "") ||
        (to.query.category != undefined && to.query.category != "")
      ) {
        return { name: "SearchPage", params: to.params, query: to.query };
      }
    },
  },
  {
    path: "/search",
    name: "SearchPage",
    component: SearchPage,
  },
  {
    path: "/o/:id",
    name: "AdDetails",
    component: AdDetails,
  },
  {
    path: "/login",
    name: "LoginPage",
    component: () =>
      import(/* webpackChunkName: "LoginPage" */ "../views/LoginPage.vue"),
  },
  {
    path: "/profile",
    name: "UserProfile",
    component: () =>
      import(
        /* webpackChunkName: "userProfile" */ "../modules/profile/Profile.vue"
      ),
    children: profileRoutes,
    meta: { layout: "profile-layout", authRequired: true },
  },
  {
    path: "/u/:id",
    name: "UserDetails",
    component: () =>
      import(/* webpackChunkName: "userDetails" */ "../views/UserDetails.vue"),
  },
  {
    path: "/r/:pathMatch(.*)*",
    name: "GetAppRedirect",
    component: () =>
      import(/* webpackChunkName: "getApp" */ "../views/GetApp.vue"),
  },
  {
    path: "/get-app",
    name: "GetApp",
    component: () =>
      import(/* webpackChunkName: "getApp" */ "../views/GetApp.vue"),
  },
  {
    path: "/create-ad",
    name: "CreateAd",
    component: () =>
      import(/* webpackChunkName: "CreateAd" */ "../views/CreateAd.vue"),
  },
  {
    path: "/scammers-warning",
    name: "ScammersWarning",
    component: () =>
      import(
        /* webpackChunkName: "scammersWarning" */ "../views/ScammersWarning.vue"
      ),
  },
  {
    path: "/delete-account",
    name: "DeleteAccount",
    component: () =>
      import(
        /* webpackChunkName: "deleteAccount" */ "../views/DeleteAccount.vue"
      ),
  },
  {
    path: "/internal-error",
    name: "RouterError",
    component: () =>
      import(/* webpackChunkName: "routerError" */ "../views/RouterError.vue"),
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../views/NotFound.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "AnyUrl",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../views/NotFound.vue"),
  },
];

export default routes;
