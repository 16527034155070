const meta = { layout: "profile-layout" };

const routes = [
  {
    path: "",
    name: "Profile",
    redirect: { name: "ProfileDashboard" },
  },
  {
    path: "dashboard",
    name: "ProfileDashboard",
    component: () =>
      import(
        /* webpackChunkname: "ProfileDashboard" */ "./views/Dashboard.vue"
      ),
    meta,
  },
  {
    path: "settings",
    name: "ProfileSettings",
    component: () =>
      import(/* webpackChunkname: "ProfileSettings" */ "./views/Settings.vue"),
    meta,
  },
  {
    path: "ads",
    name: "ProfileAds",
    component: () =>
      import(/* webpackChunkname: "ProfileAds" */ "./views/ProfileAds.vue"),
  },
  {
    path: "change-password",
    name: "ProfileChangePassword",
    component: () =>
      import(
        /* webpackChunkname: "ChangePassword" */ "./views/ChangePassword.vue"
      ),
    meta,
  },
  {
    path: "messages",
    name: "ProfileMessages",
    component: () =>
      import(/* webpackChunkname: "Messages" */ "./views/Messages.vue"),
    meta,
  },
];

export { routes };
