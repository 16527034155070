<template>
  <div class="text-center user-details-list">
    <router-link
      :to="{ name: 'ProfileSettings' }"
      :title="$t('profile_sidebar.sidebar_buttons.settings')"
    >
      <i class="aficon-cog"></i>
      <span class="icon-caption">
        {{ $t("profile_sidebar.sidebar_buttons.settings") }}
      </span>
    </router-link>

    <ButtonMessages />

    <a
      :title="$t('profile_sidebar.sidebar_buttons.logout')"
      @click="logoutClicked"
    >
      <i class="aficon-logout"></i>
      <span class="icon-caption">
        {{ $t("profile_sidebar.sidebar_buttons.logout") }}
      </span>
    </a>
  </div>
</template>

<script>
import ButtonMessages from "./ButtonMessages.vue";

export default {
  name: "SidebarButtons",
  components: {
    ButtonMessages,
  },
  methods: {
    async logoutClicked(e) {
      e.preventDefault();

      await this.$store.dispatch("user/logout");
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style scoped>
.user-details-list a,
.user-details-list a:active,
.user-details-list a:focus {
  width: 50px;
  color: #92c4ff;
}
</style>
