<template>
  <div class="address-textbox">
    <TextboxSuggestions
      :placeholder="
        t('filters.filter_location_map_select.address_textbox_placeholder')
      "
      :label="t('filters.filter_location_map_select.address_input_label')"
      :suggestions="data.suggestedToponims"
      :defaultText="data.address"
      @on-search-clicked="selectToponim"
      @on-input-changed="changeAddress"
    />
  </div>
</template>

<script>
import { reactive } from "vue";
import { useI18n } from "vue-i18n";

import TextboxSuggestions from "modules/ui/TextboxSuggestions.vue";

import { getAllToponims as getAllToponimsApi } from "api/get_toponims.js";
import { getAddress as getAddressApi } from "api/geocoder.js";

export default {
  name: "MapAddressTextbox",
  props: {
    selectedCoords: {
      type: Object,
      required: false,
      default() {
        return {
          lat: undefined,
          lon: undefined,
        };
      },
    },
  },
  emits: [
    /** Пользователь выбрал адрес из подсказки.
     * Параметры:
     * 1. lat
     * 2. lon
     * 3. address
     */
    "on-selected-addr",
    /** Изменился текст адреса
     * Параметры:
     * 1. address - новый текст адреса
     */
    "on-addr-changed",
  ],
  components: {
    TextboxSuggestions,
  },
  setup(props) {
    const { t } = useI18n();

    return {
      t,
      data: reactive({
        address: "",
        suggestedToponims: [],
        // координаты, которые были выбраны пользователем из подсказки последний раз
        lastSelectedCoords: {
          lat: props.selectedCoords.lat,
          lon: props.selectedCoords.lon,
        },
        lastSelectedAddr: "",
      }),
    };
  },
  async mounted() {
    await this.addressFromGeocoder();
  },
  computed: {
    selectedCoordsString() {
      if (this.selectedCoords == undefined) {
        return "";
      }

      if (this.selectedCoords.lat == undefined) {
        return "";
      }

      if (this.selectedCoords.lon == undefined) {
        return "";
      }

      return this.selectedCoords.lat + "_" + this.selectedCoords.lon;
    },
  },
  watch: {
    async selectedCoordsString() {
      if (this.selectedCoordsString == "") {
        return;
      }

      if (this.coordsEq(this.selectedCoords, this.data.lastSelectedCoords)) {
        return;
      }

      await this.addressFromGeocoder();
    },
    // Watch address for changing and update suggestions accordingly
    async "data.address"() {
      if (this.data.address.length < 3) {
        return;
      }

      if (this.data.lastSelectedAddr == this.data.address) {
        return;
      }

      await this.getSuggestions();
    },
  },
  methods: {
    async getSuggestions() {
      if (this.data.address.length == 0) {
        this.data.suggestedToponims = [];
        return;
      }

      var toponims = await getAllToponimsApi(this.data.address);
      var locationToponims = [];

      for (var t of toponims) {
        if (
          t.geoLat != "" &&
          t.geoLat != undefined &&
          t.geoLon != "" &&
          t.geoLon != undefined
        ) {
          locationToponims.push({
            title: t.humanName,
            valueObj: t,
          });
        }
      }

      this.data.suggestedToponims = locationToponims;
    },
    selectToponim(name, toponim) {
      if (toponim == undefined) {
        return;
      }

      this.$emit(
        "on-selected-addr",
        toponim.geoLat,
        toponim.geoLon,
        toponim.humanName
      );
      this.data.lastSelectedCoords.lat = toponim.geoLat;
      this.data.lastSelectedCoords.lon = toponim.geoLon;
      this.data.address = toponim.humanName;
      this.$emit("on-addr-changed", this.data.address);
      this.data.lastSelectedAddr = toponim.humanName;
      this.data.suggestedToponims = [];
    },
    async addressFromGeocoder() {
      if (this.selectedCoords == undefined) {
        return;
      }

      if (
        this.selectedCoords.lat == undefined ||
        this.selectedCoords.lon == undefined
      ) {
        return;
      }

      var geocoder = await getAddressApi(
        this.selectedCoords.lat,
        this.selectedCoords.lon,
        this.$i18n.locale
      );

      if (geocoder[0] != undefined) {
        if (geocoder[0].displayName != "") {
          this.data.address = geocoder[0].displayName;
          this.$emit("on-addr-changed", this.data.address);
          this.data.lastSelectedAddr = geocoder[0].displayName;

          this.data.lastSelectedCoords.lat = this.selectedCoords.lat;
          this.data.lastSelectedCoords.lon = this.selectedCoords.lon;
        }
      }
    },
    /** Сравнить координаты до размера small buildings (5 цифр после запятой)
     *  Read more: https://en.wikipedia.org/wiki/Decimal_degrees#Precision
     * @param {Object} first - объект с полями { lat, lon }
     * @param {Object} second - объект с полями { lat, lon }
     * @returns {Boolean} - true если координаты одинаковые
     */
    coordsEq(first, second) {
      if (first == undefined) {
        return false;
      }

      if (second == undefined) {
        return false;
      }

      if (first.lat == undefined || first.lon == undefined) {
        return false;
      }

      if (second.lat == undefined || second.lon == undefined) {
        return false;
      }

      return (
        Number(first.lat).toFixed(5) == Number(second.lat).toFixed(5) &&
        Number(first.lon).toFixed(5) == Number(second.lon).toFixed(5)
      );
    },
    changeAddress(addr) {
      if (String(addr) != String(this.data.address)) {
        this.data.address = addr;
      }
    },
  },
};
</script>

<style scoped>
.address-textbox {
  margin: 10px 0;
  display: flex;
  background: white;
}
</style>
