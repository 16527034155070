<template>
  <header class="header-1 sticky-header">
    <div class="container">
      <div class="flex-wrap">
        <!-- <div class="show-on-414">
          <a
            href="javascript:void(0);"
            class="small-sidebar-open navigation-trigger"
            data-target=".navigation-wrap"
          >
            <i class="aficon-align-justify"></i>
            <span class="small-icon">Menu</span>
          </a>
        </div> -->
        <router-link to="/" class="logo" :title="t('the_header.to_main')">
          <img
            src="../../assets/OMOE.svg"
            width="844"
            height="224"
            aria-hidden="true"
          />
        </router-link>
        <div class="show-on-414">
          <!-- TODO: использовать ссылку для login -->
          <router-link
            :to="{ name: 'CreateAd' }"
            class="submit-btn-wrap submit-btn submit-redirect"
            :title="t('the_header.new_ad')"
          >
            <i class="aficon-add-ad"></i>
            <span>{{ t("the_header.new_ad") }}</span>
            <span class="small-icon" aria-hidden="true">{{
              t("the_header.new_ad")
            }}</span>
          </router-link>
        </div>

        <div class="special-nav flex-wrap">
          <router-link
            :to="{ name: 'SearchPage' }"
            class="account-btn small-sidebar-open search-trigger show-on-414"
            :tile="t('the_header.search')"
          >
            <i class="aficon-search"></i>
            <span class="small-icon">{{ t("the_header.search") }}</span>
          </router-link>

          <ModalLogin :openBtnClass="'account-btn header-user-icon'" />

          <router-link
            :to="{ name: 'GetApp' }"
            :title="t('the_header.get_app')"
            class="account-btn compare-open"
          >
            <i class="et-phone"></i>
            <span class="small-icon"> {{ t("the_header.get_app") }} </span>
          </router-link>

          <router-link
            :to="{ name: 'CreateAd' }"
            class="submit-btn-wrap submit-btn submit-redirect"
            :title="t('the_header.new_ad')"
          >
            <i class="aficon-add-ad"></i>
            <span>{{ t("the_header.new_ad") }}</span>
            <span class="small-icon" aria-hidden="true">{{
              t("the_header.new_ad")
            }}</span>
          </router-link>
          <a
            aria-hidden="true"
            href="javascript:void(0);"
            class="small-sidebar-open navigation-trigger"
          >
            <!-- <i class="aficon-align-justify"></i>
            <span class="small-icon">Menu</span> -->
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { useI18n } from "vue-i18n";

import ModalLogin from "../../components/ModalLogin.vue";

export default {
  name: "TheHeader",
  components: {
    ModalLogin,
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
};
</script>

<style scoped>
.header-1 > .container > .flex-wrap {
  height: 65px;
}

.logo {
  padding: 6px;
}
</style>
