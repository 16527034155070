<template>
  <wrapper-static :filterNameText="name">
    <div class="tabs-list">
      <button
        :key="obj.value"
        v-for="obj in objects"
        :class="tabClass(obj.value)"
        @click="toggleValue(obj.value)"
      >
        {{ obj.caption }}
      </button>
    </div>
  </wrapper-static>
</template>

<script>
import { ref } from "vue";

import GenericFilterMixin from "../mixins/GenericFilterMixin";

import WrapperStatic from "./WrapperStatic.vue";
import ValuesList from "../mixins/ValuesList.js";

export default {
  name: "FilterEmptySpace",
  components: {
    WrapperStatic,
  },
  mixins: [GenericFilterMixin, ValuesList],
  props: {
    /** Название фильтра */
    name: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
  },
  setup() {
    return {
      selectedValues: ref([]),
      skipSelectedValueWatch: false,
    };
  },
  methods: {
    tabClass(val) {
      return this.selectedValues.indexOf(val) == -1
        ? "tab outlined"
        : "tab filled";
    },
  },
};
</script>

<style scoped>
.tabs-list {
  width: 100%;
  max-height: 400px;
  overflow: auto;
}

.tab {
  padding: 10px;
  border-radius: 15px;
  margin: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.1s ease-in-out;
  min-height: 30px;
  border: solid;
  border-color: #1c83fc;
  border-width: 2px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.filled {
  background-color: #1c83fc;
  color: #fff;
}

.outlined {
  color: #1c83fc;
  background-color: #fff;
}
</style>
