<template>
  <wrapper-static :filterNameText="name">
    <div class="scrollable">
      <ul class="list-unstyled color-list">
        <li :key="o.value" v-for="o in objects">
          <button @click="toggleValue(o.value)">
            <div class="color-marker">
              <div
                :class="
                  selectedValues.indexOf(o.value) == -1
                    ? 'bubble-wrapper'
                    : 'bubble-wrapper selected'
                "
              >
                <span
                  class="color-bubble"
                  :style="{ backgroundColor: '#' + o.color.replace('#', '') }"
                ></span>
              </div>
              <label> {{ o.caption }} </label>
            </div>
          </button>
        </li>
      </ul>
    </div>
  </wrapper-static>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

import GenericFilterMixin from "../mixins/GenericFilterMixin";
import ValuesList from "../mixins/ValuesList.js";

import WrapperStatic from "./WrapperStatic.vue";

export default {
  name: "FilterColorSelect",
  components: {
    WrapperStatic,
  },
  props: {
    /** Название списка */
    name: {
      type: String,
      required: true,
    },

    /** Набор объектов, которые нужно отрисовать.
     *  Должны содержать поля:
     *  - value
     *  - caption
     *  - checked
     *  - color - цвет в формате hex
     */
    objects: {
      type: Array,
      required: true,
    },
  },
  mixins: [GenericFilterMixin, ValuesList],
  setup() {
    return {
      inputId: uuidv4(),
    };
  },
};
</script>

<style scoped>
button {
  border: none;
  background: none;
}

.scrollable {
  max-height: 300px;
  overflow: auto;
}

.color-list {
  display: grid;
  grid-template-columns: auto auto auto;
}

@media (max-width: 350px) {
  .color-list {
    grid-template-columns: auto auto;
  }
}

.color-marker {
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 5px;
}

.color-marker label {
  font-size: 10px;
  line-height: 15px;
  margin-top: 5px;
}

.bubble-wrapper {
  width: 35px;
  height: 35px;
  padding: 3px;
  margin: auto;
  border: none;
}

.color-bubble {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: block;
  margin: auto;
}

.selected {
  border: solid;
  border-color: #666;
  border-radius: 50%;
}
</style>
