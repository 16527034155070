<template>
  <div v-if="photos.length == 1" class="photos">
     <img
        class="photo-big" 
        :alt="t('photos_carousel.big_alt')"
        width="750" 
        height="450" 
        :src="photos[0].big"
      />
  </div>

  <div v-else class="photos p-10px">
    <carousel :mouseDrag="true" :touchDrag="true" >
        <slide v-for="p in photosIdx" :key="p.idx">
            <img
                class="photo-big" 
                :alt="t('photos_carousel.big_alt')"
                width="750" 
                height="450" 
                :src="p.data.big"
            />
        </slide>

        <template #addons>
        <navigation />
        <pagination />
        </template>
    </carousel>
  </div>
</template>


<script>
import { useI18n } from 'vue-i18n'

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
    name: 'PhotosCarousel',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    props: {
        /**
         * Список фотографий. 
         * Каждая фотография должна быть object с
         *  - uuid
         *  - big - url для просмотра большой фотографии
         *  - thumb - url для списка фотографий внизу
         */
        photos: {
            type: Array,
            default: ()=>[]
        }, 
    },
    setup() {
      const { t } = useI18n();
      return {
        t,
      }
    },
    computed: {
        photosIdx() {
            var photos_idx = [];

            for (var i = 0; i < this.photos.length; i++) {
                photos_idx.push(
                    {
                        idx: i+1,
                        data: this.photos[i]
                    }
                )
            }
            
            return photos_idx
        }
    }
}
</script>

<style scoped>
.photos {
    margin-bottom: 50px;
}

.p-10px {
    padding: 10px;
}

.photo-big {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 500px;
    background-size: cover;
}
</style>

<style>
.carousel__prev, .carousel__next {
    /* TODO: получать цвета из config ? */
    background-color: #1c83fc;
}

.carousel__pagination-button {
    background-color: #99c9ff;
}

.carousel__pagination-button--active {
    background-color: #1c83fc;
}
</style>
