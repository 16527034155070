<template>
  <teleport to="head">
    {{ `<script type="application/ld+json">` + JSON.stringify(meta.data) + `</script>` }}
  </teleport>
</template>

<script>
import { reactive } from "vue";
import { useI18n } from "vue-i18n";

export default {
  name: "RatingStructuredData",
  props: {
    itemName: String,
    itemDescription: String,
    imageThumb: String,
    imageSmall: String,
    imageBig: String,
    rating: Number,
    reviewCount: Number,
    adId: String,
    price: Number,
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      meta: reactive({ data: {} }),
    };
  },
  mounted() {
    this.updateData();
  },
  watch: {
    adId() {
      this.updateData();
    },
  },
  methods: {
    updateData() {
      var image = [];

      if (this.imageThumb) {
        image.push(this.imageThumb);
      }
      if (this.imageSmall) {
        image.push(this.imageSmall);
      }
      if (this.imageBig) {
        image.push(this.imageBig);
      }

      var aggregateRating = undefined;

      if (
        this.rating &&
        this.reviewCount &&
        Number(this.rating) != 0 &&
        Number(this.reviewCount) != 0
      ) {
        aggregateRating = {
          "@type": "AggregateRating",
          ratingValue: Number(this.rating).toFixed(1),
          reviewCount: Number(this.reviewCount),
        };
      }

      var offers = undefined;

      if (this.adId && this.price) {
        offers = {
          "@type": "Offer",
          // TODO: url из this.$router
          url: `https://omoe.ru/o/${this.adId}`,
          priceCurrency: this.t("$_code"),
          price: Number(this.price).toFixed(2),
          // TODO: Передавать https://schema.org/OfferItemCondition
          // itemCondition: "https://schema.org/UsedCondition",
        };
      }

      this.meta.data = {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: this.itemName ? this.itemName : "",
        image,
        description: this.itemDescription ? this.itemDescription : "",
        aggregateRating,
        offers,
      };
    },
  },
};
</script>
