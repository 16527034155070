<template>
  <div :class="isLoading ? 'is-loading' : ''">
    <ul class="list-unstyled taxonomy-filter category-filter" tabindex="0">
      <li :key="c.id" v-for="c in data.parentCategories">
        <div
          class="styled-radio"
          tabindex="0"
          @keydown.enter="selectedParent = c.id"
        >
          <input
            type="radio"
            :name="inputId"
            :value="c.id"
            :id="inputId + '_' + c.id"
            v-model="selectedParent"
            @click="selectedChild = ''"
          />
          <label :for="inputId + '_' + c.id">{{ c.name }}</label>
          <a v-if="c.hasChildren">
            <i
              :class="
                parentIsSelected(c.id)
                  ? 'aficon-angle-down'
                  : 'aficon-angle-left'
              "
            ></i>
          </a>
        </div>

        <transition name="subcategories-transition">
          <ul
            v-if="parentIsSelected(c.id) && data.childCategories.length != 0"
            class="list-unstyled"
          >
            <li :key="ch.id" v-for="ch in data.childCategories">
              <div
                class="styled-radio"
                tabindex="0"
                @keydown.enter="selectedChild = ch.id"
              >
                <input
                  type="radio"
                  :name="inputId"
                  :value="ch.id"
                  :id="inputId + '_' + ch.id"
                  v-model="selectedChild"
                />
                <label :for="inputId + '_' + ch.id">{{ ch.name }}</label>
              </div>
            </li>
          </ul>
        </transition>
      </li>
    </ul>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { v4 as uuidv4 } from "uuid";

import {
  getChildren as getChildrenApi,
  getParent as getParentApi,
} from "api/categories.js";

export default {
  name: "CategorySelector",
  props: {
    selectedCategoryId: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
  },
  emits: [
    /** Выбрали категорию.
     *  Параметры:
     *   1. id - id категории, которую выбрали
     */
    "on-category-selected",
  ],
  setup() {
    const data = reactive({
      // Должен иметь поля:
      //  - id
      //  - name
      //  - hasChildren
      parentCategories: [],
      childCategories: [],
    });

    return {
      data,
      selectedParent: ref(""),
      /** Не очищать дочерние категории, при смене родительской */
      selectedParentPersistChildren: false,
      selectedChild: ref(""),
      inputId: uuidv4(),
      isLoading: ref(false),
    };
  },
  async mounted() {
    this.isLoading = true;
    var parentCategories = await getChildrenApi("");
    this.data.parentCategories = parentCategories;
    this.isLoading = false;
    await this.selectPropSelectedCategoryId();
  },
  watch: {
    async selectedParent() {
      this.clearChild();
      this.$emit("on-category-selected", this.selectedParent);
      await this.loadChildCategories();
    },
    async selectedChild() {
      if (this.selectedChild != undefined && this.selectedChild != "") {
        this.$emit("on-category-selected", this.selectedChild);
      } else {
        this.$emit("on-category-selected", this.selectedParent);
      }
    },
    async selectedCategoryId() {
      this.selectPropSelectedCategoryId();
    },
  },
  methods: {
    async loadChildCategories() {
      if (this.selectedParent == "" || this.selectedParent == undefined) {
        return;
      }

      this.isLoading = true;

      var selected = this.data.parentCategories.find(
        (c) => c.id == this.selectedParent
      );

      if (!selected.hasChildren) {
        this.data.childCategories = [];
        this.isLoading = false;
        return;
      }

      var categories = await getChildrenApi(selected.id);
      this.data.childCategories = categories;
      this.isLoading = false;
    },
    clearChild() {
      if (this.selectedParentPersistChildren) {
        this.selectedParentPersistChildren = false;
        return;
      }

      this.selectedParentPersistChildren = false;
      this.selectedChild = "";
      this.data.childCategories = [];
    },
    parentIsSelected(id) {
      return id == this.selectedParent;
    },
    /** Выбрать родительскую и/или дочернюю категорию по id категории
     * Например: для id = Автомобили
     *   установить selectedParent = Транспорт
     *   установить selectedChild = Автомобили
     */
    async selectPropSelectedCategoryId() {
      this.isLoading = true;
      if (this.data.parentCategories.length == 0) {
        var parentCategories = await getChildrenApi("");
        this.data.parentCategories = parentCategories;
      }

      var parent = this.data.parentCategories.find(
        (c) => c.id == this.selectedCategoryId
      );

      if (parent != undefined) {
        this.selectedParent = parent.id;
        this.isLoading = false;
        return;
      }

      var potential_parents = await getParentApi(this.selectedCategoryId);

      if (
        potential_parents[0] == undefined ||
        potential_parents[0].id == undefined
      ) {
        this.isLoading = false;
        return;
      }

      this.selectedParentPersistChildren = true;
      this.selectedParent = potential_parents[0].id;

      if (
        potential_parents[1] == undefined ||
        potential_parents[1].id == undefined
      ) {
        this.isLoading = false;
        return;
      }

      this.selectedChild = potential_parents[1].id;
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.is-loading {
  pointer-events: none;
  opacity: 0.5;
}

.subcategories-transition-enter-active {
  transition: max-height 1s ease-out;
}

.subcategories-transition-enter-from {
  max-height: 0;
  overflow: hidden;
}

.subcategories-transition-enter-to {
  max-height: 1000px;
  overflow: hidden;
}
</style>
