export default {
  methods: {
    clickRef(id) {
      if (this.$refs[id] == undefined) {
        return;
      }

      this.$refs[id].click();
    },
  },
};
