<template>
  <SsrHead :meta="computedMeta" />

  <AdStructuredData
    v-if="!data.ad.isFinished"
    :itemName="data.ad.name"
    :itemDescription="data.ad.description"
    :rating="data.user.rating"
    :reviewCount="data.user.reviews"
    :price="data.ad.price"
    :adId="data.ad.uuid"
    :imageThumb="data.ad.photos[0] ? data.ad.photos[0].thumb : undefined"
    :imageBig="data.ad.photos[0] ? data.ad.photos[0].big : undefined"
  />

  <TitleAdWide
    :title="data.ad.name"
    :coords="data.ad.location"
    :is-finished="data.ad.isFinished"
    :categories="data.categories"
    :user-deleted="data.user.isDeleted"
    :similar-ads="data.similarAds"
  />

  <div class="container">
    <div class="row">
      <div class="col-sm-8">
        <div class="single-advert-tags" v-if="data.ad.photos.length != 0">
          <PhotosCarousel :photos="data.ad.photos" />
        </div>
        <div class="hide-price-big">
          <div class="single-price-wrap">
            <div class="white-block single-price">
              <i class="aficon-info-circle price-back-icon"></i>
              <div class="white-block-content">
                <h3
                  class="price"
                  role="heading"
                  :aria-label="t('ad_details.price_alt', { x: data.ad.price })"
                >
                  {{ Number(data.ad.price).toLocaleString() }}
                  <span class="price-symbol" aria-hidden="true">
                    {{ t("$") }}
                  </span>
                </h3>
                <div v-if="data.ad.oldPrice != 0" class="old-price">
                  {{ Number(data.ad.oldPrice).toLocaleString() }}
                  <span class="price-symbol">{{ t("$") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="data.ad.description" class="white-block">
          <h3 class="sr-only">{{ t("ad_details.ad_description") }}</h3>
          <div class="white-block-content" style="text-align: start;">
            <div class="post-content clearfix">
              <span style="white-space: pre-line;word-break: break-word;">
                {{ data.ad.description }}
              </span>
            </div>
          </div>
        </div>

        <div v-if="data.ad.addr" class="white-block">
          <h3 class="sr-only">{{ t("ad_details.ad_address") }}</h3>
          <div class="white-block-content" style="text-align: center;">
            <div
              class="address-box post-content clearfix"
              style="white-space: pre-line; word-break: break-word; "
            >
              <i class="fas fa-map-marker-alt"></i> {{ data.ad.addr }}
            </div>
          </div>
        </div>

        <div v-if="data.ad.specifications.length != 0" class="white-block">
          <div class="white-block-title">
            <h3>{{ t("ad_details.more_details") }}</h3>
          </div>
          <div class="white-block-content">
            <ul class="list-unstyled cf-advert-list list-inline">
              <li
                :key="s.key"
                v-for="s in data.ad.specifications"
                class="flex-wrap"
              >
                <span class="cf-label">{{ s.key }}</span>
                <span class="cf-value">{{ s.value }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="hide-print small-screen-last"></div>
      </div>
      <div class="col-sm-4">
        <div class="single-price-wrap">
          <div class="white-block single-price">
            <i class="aficon-info-circle price-back-icon"></i>
            <div class="white-block-content">
              <h3
                class="price"
                role="heading"
                :aria-label="t('ad_details.price_alt', { x: data.ad.price })"
              >
                {{ Number(data.ad.price).toLocaleString() }}
                <span class="price-symbol" aria-hidden="true">{{
                  t("$")
                }}</span>
              </h3>
              <div v-if="data.ad.oldPrice != 0" class="old-price">
                {{ Number(data.ad.oldPrice).toLocaleString() }}
                <span class="price-symbol">{{ t("$") }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="white-block contact-scroll-details">
          <div class="white-block-title">
            <h3>{{ t("ad_details.ad_owner") }}</h3>
          </div>
          <div class="white-block-content">
            <UserSmall :user="data.user" />
            <div v-if="!data.ad.isFinished">
              <PhoneNumber
                ref="phoneNumber"
                :phone="data.ad.phone"
                :canMessage="data.ad.useMessages"
              />
            </div>
          </div>
        </div>
        <!-- TODO: добавить избранное -->
        <!-- TODO: пожаловаться на объявление -->
        <div class="white-block hide-print">
          <div class="white-block-content">
            <ul
              class="list-unstyle list-inline single-advert-actions flex-wrap"
            >
              <!-- <li>
                      <a href="#" class="share-advert" data-toggle="modal" data-target="#share">
                        <i class="aficon-share-alt"></i>
                        <span>{{ t('ad_details.share') }}</span>
                      </a>
                    </li>
                    <li>
                      <a title="Favorite" href="" class="af-favs " data-toggle="modal" data-target="#login">
                        <i class="aficon-heart-o"></i>
                        <span>{{ t('ad_details.favorite') }}</span>
                      </a>
                    </li> -->
              <li>
                <ModalComplainDialog :adId="data.ad.uuid" />
              </li>
            </ul>
          </div>
        </div>
        <div v-if="data.similarAds.length != 0" class="white-block hide-print">
          <div class="white-block-title">
            <h3>{{ t("ad_details.similar_ads") }}</h3>
          </div>
          <div class="white-block-content">
            <ul class="list-unstyled random-author-ads">
              <li :key="ad.uuid" v-for="ad in data.similarAds">
                <AdSimilar :ad="ad" />
              </li>
            </ul>
          </div>
        </div>
        <BannerAppGetVertical />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, onServerPrefetch } from "vue";
import { useI18n } from "vue-i18n";
import { useMeta } from "vue-meta";
import { useRoute, useRouter } from "vue-router";

import {
  getAd as getAdApi,
  getSimilarAds as getSimilarAdsApi,
  addView as addViewApi,
} from "api/ad_details";
import {
  getUser as getUserApi,
  UserStatus as UserStatusApi,
} from "api/user_details";
import { getParent as getParentCategoriesApi } from "api/categories";

import TitleAdWide from "../components/TitleAdWide.vue";
import PhotosCarousel from "../components/PhotosCarousel.vue";
import AdSimilar from "../components/ad/AdSimilar.vue";
import UserSmall from "../components/UserSmall.vue";
import PhoneNumber from "../components/PhoneNumber.vue";
import BannerAppGetVertical from "../components/BannerAppGetVertical.vue";
import AdStructuredData from "../components/head/AdStructuredData.vue";
import ModalComplainDialog from "../components/ModalComplainDialog.vue";
import SsrHead from "../components/SsrHead.vue";

import DefaultAvatar from "../assets/img/default_avatar.png";

const LoadStatus = {
  Success: "Success",
  Redirect: "Redirect",
  Error: "Error",
  UserDeleted: "UserDeleted",
};

/**
 * Получить данные, нужные на этой странице
 * @param {string} ad_id - id объявления из параметров запроса
 * @returns {Object} Вернет объект с полями:
 * - status {LoadStatus}
 * - data - данные, нужные для LoadStatus
 */
async function loadData(ad_id, locale) {
  var data = {};
  try {
    data = await getAdApi(ad_id);
  } catch (e) {
    console.error("getAdApi error: ", e);
    return {
      status: LoadStatus.Redirect,
      data: { name: "NotFound" },
    };
  }

  var categories = [];
  try {
    categories = await getParentCategoriesApi(data.ad.categoryId, locale);
  } catch (e) {
    console.error("getParentCategoriesApi error:", e);
    return {
      status: LoadStatus.Error,
      data: e,
    };
  }

  var user = {};
  try {
    var user_data = await getUserApi(data.userId);
    if (user_data.status == UserStatusApi.Success) {
      user = user_data.user;
    } else {
      console.error({ status: "Invalid user", data: user_data });
      user = null;
    }
  } catch (e) {
    console.error("getUserApi error:", e);
    return {
      status: LoadStatus.Error,
      data: e,
    };
  }

  var similarAds = [];
  try {
    similarAds = await getSimilarAdsApi(ad_id);
  } catch (e) {
    console.error("getSimilarAdsApiError:", e);
    // TODO: показывать что ошибка только с similarAds
    return {
      status: LoadStatus.Error,
      data: e,
    };
  }

  return {
    status: LoadStatus.Success,
    data: {
      ad: data.ad,
      categories,
      similarAds,
      user,
    },
  };
}

export default {
  name: "AdDetails",
  components: {
    TitleAdWide,
    PhotosCarousel,
    AdSimilar,
    UserSmall,
    PhoneNumber,
    BannerAppGetVertical,
    AdStructuredData,
    ModalComplainDialog,
    SsrHead,
  },
  setup() {
    const { t } = useI18n();
    const DELETED_USER = {
      thumbUrl: DefaultAvatar,
      id: "",
      username: t("user_deleted"),
      rating: 0,
      reviews: 0,
      lastSeen: 0,
      isDeleted: true,
    };

    const data = reactive({
      ad: {
        uuid: "0",
        name: "Объявление OMOE - купить",
        description:
          "Описание объявления будет тут. \nОно может быть в несколько строк",
        price: 1234567,
        oldPrice: 0,
        phone: "",
        specifications: [],
        photos: [],
        location: {
          lat: 55.7522,
          lon: 37.6155,
        },
        isFinished: false,
        categoryId: "",
      },
      categories: [
        {
          id: "00000000-0000-0000-0000-000000000000",
          name: "Категория",
        },
      ],
      similarAds: [],
      user: {
        thumbUrl: DefaultAvatar,
        id: "00000000-0000-0000-0000-000000000000",
        username: t("default_username"),
        rating: 3.5,
        reviews: 0,
        lastSeen: 0,
        isDeleted: false,
      },
    });

    const computedMeta = computed(() => {
      var description = "";
      if (data.ad.description) {
        description =
          String(data.ad.description).length > 100
            ? String(data.ad.description).slice(0, 100) + "..."
            : String(data.ad.description);
      }

      var title = t("ad_details.default_title");
      var ogTitle = t("ad_details.default_title");
      if (data.ad.name) {
        title = data.ad.name;
        ogTitle = `${data.ad.name} — ${t("sitename")}`;
      }

      return {
        title,
        description,
        og: {
          url: typeof window !== "undefined" ? window.location.href : "",
          title: ogTitle,
          description,
          type: "website",
        },
      };
    });
    useMeta(computedMeta);

    // TODO: похожая логика дублируется в 3 местах
    onServerPrefetch(async () => {
      const route = useRoute();
      const router = useRouter();

      var res = await loadData(route.params.id, "rus");

      if (
        res.status == LoadStatus.Success ||
        res.status == LoadStatus.UserDeleted
      ) {
        data.ad = res.data.ad;
        data.categories = res.data.categories;
        data.similarAds = res.data.similarAds;
        if (res.data.user) {
          data.user = res.data.user;
        } else {
          data.user = DELETED_USER;
        }
      } else if (res.status == LoadStatus.Redirect) {
        router.push(res.data);
      } else if (res.status == LoadStatus.Error) {
        router.push({ name: "RouterError" });
      }
    });

    return {
      t,
      data,
      computedMeta,
      DELETED_USER,
    };
  },
  async beforeRouteEnter(to, _from, next) {
    // TODO: локализовать
    var res = await loadData(to.params.id, "rus");

    if (
      res.status == LoadStatus.Success ||
      res.status == LoadStatus.UserDeleted
    ) {
      next((vm) =>
        vm.setData({
          ad: res.data.ad,
          categories: res.data.categories,
          similarAds: res.data.similarAds,
          user: res.data.user,
        })
      );
      return;
    } else if (res.status == LoadStatus.Redirect) {
      next(res.data);
      return;
    } else if (res.status == LoadStatus.Error) {
      console.error(res);
      next({ name: "RouterError" });
      return;
    }
  },
  async beforeRouteUpdate(to) {
    // TODO: локализовать с помощью this.$i18n.locale
    var res = await loadData(to.params.id, "rus");

    if (
      res.status == LoadStatus.Success ||
      res.status == LoadStatus.UserDeleted
    ) {
      this.setData({
        ad: res.data.ad,
        categories: res.data.categories,
        similarAds: res.data.similarAds,
        user: res.data.user,
      });
    } else if (res.status == LoadStatus.Redirect) {
      return res.data;
    } else if (res.status == LoadStatus.Error) {
      console.error(res);
      return { name: "RouterError" };
    }
  },
  methods: {
    setData({ ad, categories, similarAds, user }) {
      this.data.ad = ad;
      this.data.categories = categories;
      this.data.similarAds = similarAds;

      if (user) {
        this.data.user = user;
      } else {
        this.data.user = this.DELETED_USER;
      }

      if (this.$refs.phoneNumber) {
        this.$refs.phoneNumber.closeAll();
      }
      this.addView();
    },
    async addView() {
      if (this.isSSR) {
        return;
      }

      try {
        await addViewApi(this.data.ad.uuid);
      } catch (e) {
        console.error("View error: ", e);
      }
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: 18px;
}

.price-back-icon {
  position: absolute;
  right: 0px;
  top: -30px;
  font-size: 150px;
  opacity: 0.1;
  transform: rotate(-45deg);
}

.cf-advert-list li {
  width: calc(100% - 15px);
  margin-right: 15px;
}

.old-price {
  color: #fff;
  font-size: 18px;
  text-decoration: line-through;
  text-decoration-thickness: 3px;
  margin-top: 7px;
}

.address-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.address-box i {
  font-size: 30px;
  margin: 0 10px;
}
</style>
