import _ from "lodash";
import { grpc } from "@improbable-eng/grpc-web";

import { protoFromObject } from "./utils/protoFromObject.js";
import { PublicAdService } from "./proto/ad_pb_service.js";
import {
  SearchAdsRequest,
  CountSearchResultsRequest,
  GetSearchHintsRequest,
} from "./proto/ad_pb";
import { Toponim } from "./proto/toponims_pb.js";
import { getMeta } from "./utils/meta.js";

/**
 * Получить объявления, принадлежащие пользователю с user id.
 *
 * @param {String} category_id Id категории, в которой нужно получить объявления. Необязательно.
 * @param {number} size Количество объявлений, которое нужно получить
 * @return {Object} Вернет объект с полями:
 *   - adsList - набор объявлений
 *     - uuid
 *     - name
 *     - price
 *     - mainPhoto - обложка объявления
 *       - thumb
 *       - small
 *       - big
 *   - cursor - курсор для получения следующих объявлений.
 *              Если равен '', следующих объявлений нет
 *   - selectedFilters - выбранные фильтры поиска
 *   - selectedToponim - выбранный топоним
 */
export function searchAds(filters, toponim) {
  var request = objectToSearchRequest(filters, toponim);

  return new Promise((resolve, reject) => {
    grpc.unary(PublicAdService.SearchAds, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error PublicAdService.SearchAds",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        var result_ads = [];
        for (var ad of obj.adsList) {
          var thumb = "";
          var small = "";
          var big = "";

          if (ad.mainPhoto != undefined) {
            if (ad.mainPhoto.thumb != undefined) {
              thumb = ad.mainPhoto.thumb;
            }
            if (ad.mainPhoto.small != undefined) {
              small = ad.mainPhoto.small;
            }
            if (ad.mainPhoto.big != undefined) {
              big = ad.mainPhoto.big;
            }
          }

          result_ads.push({
            uuid: ad.adId,
            name: ad.name,
            price: ad.price,
            mainPhoto: {
              thumb,
              small,
              big,
            },
          });
        }

        return resolve({
          adsList: result_ads,
          cursor: obj.cursor,
          selectedFilters: _.fromPairs(obj.filtersMap),
          selectedToponim: obj.toponim,
        });
      },
    });
  });
}

/**
 * Получить количество объявлений для поискового запроса
 * @param {Object} filters - фильтры поиска
 * @param {Object} toponim - топоним, для которого нужно получить запрос
 * @returns {Number} - количество результатов в поиск
 */
export function getSearchResultsCount(filters, toponim) {
  var request = new CountSearchResultsRequest();
  var searchReq = objectToSearchRequest(filters, toponim);
  request.setSearchRequest(searchReq);

  return new Promise((resolve, reject) => {
    grpc.unary(PublicAdService.CountSearchResults, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error PublicAdService.CountSearchResults",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        return resolve(obj.totalResults);
      },
    });
  });
}

/**
 * Получить подсказки поиска для текста запроса
 * @param {String} query - текст поискового запроса
 * @param {Number} limit - максимальное количество результатов. По умолчанию 5.
 * @returns {Array} - список объектов с полями:
 *  - icon - icon запроса
 *  - query - текст запроса (например: "ford")
 *  - hint - подсказка под текстом (например: "в категории Автомобили")
 *  - request - поисковый запрос, который нужно отправить для подсказки.
 *    Поля:
 *    -
 */
export function getSearchHints(query, limit, lang) {
  if (limit == undefined) {
    limit = 5;
  }

  if (lang == undefined) {
    lang = "rus";
  }

  var request = new GetSearchHintsRequest();
  request.setQuery(query);
  request.setLimit(limit);

  return new Promise((resolve, reject) => {
    grpc.unary(PublicAdService.GetSearchHints, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error PublicAdService.GetSearchHints",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        var hints = [];

        for (var hint of obj.hintsList) {
          hints.push({
            query: hint.query,
            hint: hint.hint,
            icon: hint.icon,
            request: {
              query: hint.request.query,
              filters: _.fromPairs(hint.request.filtersMap),
              toponim: hint.request.toponim,
            },
          });
        }

        return resolve(hints);
      },
    });
  });
}

/**
 * Получить фильтры API для объекта JS.
 * Пример:
 * Из
 * {
 *   category: "123-456",
 *   filters: {
 *     "8438": "894932"
 *   }
 * }
 * Будет получено
 * {
 *   "8438": "894932",
 *   "category_id": "123-456"
 * }
 * Нужно, чтобы не хардкодить специальные фильтры ("category_id", "sort_by") как строки
 * @param {String} {category - id категории. может быть undefined
 * @param {Object} filters} - uuid фильтров поиска
 * @returns {any}
 */
export function getFiltersForParams({ category }) {
  var filters = {};

  if (category != undefined) {
    filters["category_id"] = String(category);
  }

  return filters;
}

/**
 * Создать SearchAdsRequest из объекта
 * @param {Object} filters - фильтры поиска
 * @param {Object} toponim - объект топонима
 * @returns {Object} - вернет SearchAdsRequest
 */
function objectToSearchRequest(filters, toponim) {
  let request = new SearchAdsRequest();

  if (filters.size != undefined) {
    request.setLimit(filters.size);
  }

  if (filters.cursor != undefined && String(filters.cursor) != "") {
    request.setCursor(filters.cursor);
  }

  if (filters.text != undefined && String(filters.text) != "") {
    request.setQuery(filters.text);
  }

  if (toponim != undefined && !_.isEqual(toponim, {})) {
    var protoToponim = new protoFromObject(Toponim, toponim);
    request.setToponim(protoToponim);
  }

  if (filters.filters != undefined) {
    var filtersMap = request.getFiltersMap();

    for (var key of Object.keys(filters.filters)) {
      filtersMap.set(String(key), String(filters.filters[key]));
    }
  }

  return request;
}
