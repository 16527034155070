import formurlencoded from "form-urlencoded";

export function toUrlEncoded(ty, value) {
  switch (ty) {
    case "String": {
      if (value.value != undefined && typeof value.value == "string") {
        return String(value.value);
      }
      return String(value);
    }
    case "Number": {
      if (value.value != undefined) {
        return String(value.value);
      }
      return String(value);
    }
    case "Boolean":
      return Boolean(value).toString();
    case "Array": {
      var toEnc = {};
      for (var v of value) {
        toEnc[v] = "";
      }

      return formurlencoded(toEnc);
    }
    case "Object": {
      return formurlencoded(value);
    }
    default: {
      return String(value);
    }
  }
}
