<template>
  <router-link
    v-if="userLoggedIn"
    :to="{ name: 'UserProfile' }"
    :title="t('modal_login.profile')"
    :class="openBtnClass"
  >
    <i class="aficon-user"></i>
    <span class="small-icon">{{ t("modal_login.profile") }}</span>
  </router-link>

<!-- TODO: возможно сломается, лучше как-то  -->
  <modal-generic
    v-else
    :openWithLink="true"
    :openBtnClass="openBtnClass"
    :openBtnAriaLabel="t('modal_login.login')"
    :initialFocus="
      () => this.$refs.login.phoneInputRef
    "
  >
    <template v-slot:open-btn>
      <i class="aficon-login"></i>
      <span class="small-icon"> {{ t("modal_login.login") }} </span>
    </template>

    <template v-slot:title>
      <h1>{{ t("modal_login.login") }}</h1>
    </template>

    <template v-slot:content>
      <Login ref="login" />
    </template>
  </modal-generic>
</template>

<script>
import { useI18n } from "vue-i18n";
import { mapGetters } from "vuex";

import ModalGeneric from "./ModalGeneric.vue";
import Login from "./Login.vue";

export default {
  name: "ModalLogin",
  components: {
    ModalGeneric,
    Login,
  },
  props: {
    openBtnClass: {
      type: String,
      required: false,
      defualt: "",
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  computed: {
    ...mapGetters({ userLoggedIn: "user/loggedIn" }),
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
}

h1 {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 20px;
}
</style>
