// source: filters.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var toponims_pb = require('./toponims_pb.js');
goog.object.extend(proto, toponims_pb);
goog.exportSymbol('proto.api.v1.filters.Filter', null, global);
goog.exportSymbol('proto.api.v1.filters.FilterId', null, global);
goog.exportSymbol('proto.api.v1.filters.FilterObject', null, global);
goog.exportSymbol('proto.api.v1.filters.GetFiltersRequest', null, global);
goog.exportSymbol('proto.api.v1.filters.GetFiltersResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.filters.FilterId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.filters.FilterId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.filters.FilterId.displayName = 'proto.api.v1.filters.FilterId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.filters.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.filters.Filter.repeatedFields_, null);
};
goog.inherits(proto.api.v1.filters.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.filters.Filter.displayName = 'proto.api.v1.filters.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.filters.FilterObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.filters.FilterObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.filters.FilterObject.displayName = 'proto.api.v1.filters.FilterObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.filters.GetFiltersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.filters.GetFiltersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.filters.GetFiltersRequest.displayName = 'proto.api.v1.filters.GetFiltersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.filters.GetFiltersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.filters.GetFiltersResponse.repeatedFields_, null);
};
goog.inherits(proto.api.v1.filters.GetFiltersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.filters.GetFiltersResponse.displayName = 'proto.api.v1.filters.GetFiltersResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.filters.FilterId.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.filters.FilterId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.filters.FilterId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.filters.FilterId.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.filters.FilterId}
 */
proto.api.v1.filters.FilterId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.filters.FilterId;
  return proto.api.v1.filters.FilterId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.filters.FilterId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.filters.FilterId}
 */
proto.api.v1.filters.FilterId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.filters.FilterId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.filters.FilterId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.filters.FilterId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.filters.FilterId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.v1.filters.FilterId.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.filters.FilterId} returns this
 */
proto.api.v1.filters.FilterId.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.filters.Filter.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.filters.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.filters.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.filters.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.filters.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    filterType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    position: jspb.Message.getFieldWithDefault(msg, 2, 0),
    caption: jspb.Message.getFieldWithDefault(msg, 3, ""),
    objectsList: jspb.Message.toObjectList(msg.getObjectsList(),
    proto.api.v1.filters.FilterObject.toObject, includeInstance),
    value: jspb.Message.getFieldWithDefault(msg, 5, ""),
    maxSelected: jspb.Message.getFieldWithDefault(msg, 6, 0),
    minValue: jspb.Message.getFieldWithDefault(msg, 7, 0),
    maxValue: jspb.Message.getFieldWithDefault(msg, 8, 0),
    postfix: jspb.Message.getFieldWithDefault(msg, 9, ""),
    hint: jspb.Message.getFieldWithDefault(msg, 10, ""),
    id: (f = msg.getId()) && proto.api.v1.filters.FilterId.toObject(includeInstance, f),
    required: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    lang: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.filters.Filter}
 */
proto.api.v1.filters.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.filters.Filter;
  return proto.api.v1.filters.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.filters.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.filters.Filter}
 */
proto.api.v1.filters.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFilterType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPosition(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 4:
      var value = new proto.api.v1.filters.FilterObject;
      reader.readMessage(value,proto.api.v1.filters.FilterObject.deserializeBinaryFromReader);
      msg.addObjects(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxSelected(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinValue(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxValue(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostfix(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setHint(value);
      break;
    case 11:
      var value = new proto.api.v1.filters.FilterId;
      reader.readMessage(value,proto.api.v1.filters.FilterId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLang(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.filters.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.filters.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.filters.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.filters.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilterType();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.api.v1.filters.FilterObject.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMaxSelected();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getMinValue();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getMaxValue();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getPostfix();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getHint();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.api.v1.filters.FilterId.serializeBinaryToWriter
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getLang();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional int64 filter_type = 1;
 * @return {number}
 */
proto.api.v1.filters.Filter.prototype.getFilterType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.filters.Filter} returns this
 */
proto.api.v1.filters.Filter.prototype.setFilterType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 position = 2;
 * @return {number}
 */
proto.api.v1.filters.Filter.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.filters.Filter} returns this
 */
proto.api.v1.filters.Filter.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string caption = 3;
 * @return {string}
 */
proto.api.v1.filters.Filter.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.filters.Filter} returns this
 */
proto.api.v1.filters.Filter.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated FilterObject objects = 4;
 * @return {!Array<!proto.api.v1.filters.FilterObject>}
 */
proto.api.v1.filters.Filter.prototype.getObjectsList = function() {
  return /** @type{!Array<!proto.api.v1.filters.FilterObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.filters.FilterObject, 4));
};


/**
 * @param {!Array<!proto.api.v1.filters.FilterObject>} value
 * @return {!proto.api.v1.filters.Filter} returns this
*/
proto.api.v1.filters.Filter.prototype.setObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.api.v1.filters.FilterObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.filters.FilterObject}
 */
proto.api.v1.filters.Filter.prototype.addObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.api.v1.filters.FilterObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.filters.Filter} returns this
 */
proto.api.v1.filters.Filter.prototype.clearObjectsList = function() {
  return this.setObjectsList([]);
};


/**
 * optional string value = 5;
 * @return {string}
 */
proto.api.v1.filters.Filter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.filters.Filter} returns this
 */
proto.api.v1.filters.Filter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 max_selected = 6;
 * @return {number}
 */
proto.api.v1.filters.Filter.prototype.getMaxSelected = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.filters.Filter} returns this
 */
proto.api.v1.filters.Filter.prototype.setMaxSelected = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 min_value = 7;
 * @return {number}
 */
proto.api.v1.filters.Filter.prototype.getMinValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.filters.Filter} returns this
 */
proto.api.v1.filters.Filter.prototype.setMinValue = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 max_value = 8;
 * @return {number}
 */
proto.api.v1.filters.Filter.prototype.getMaxValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.filters.Filter} returns this
 */
proto.api.v1.filters.Filter.prototype.setMaxValue = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string postfix = 9;
 * @return {string}
 */
proto.api.v1.filters.Filter.prototype.getPostfix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.filters.Filter} returns this
 */
proto.api.v1.filters.Filter.prototype.setPostfix = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string hint = 10;
 * @return {string}
 */
proto.api.v1.filters.Filter.prototype.getHint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.filters.Filter} returns this
 */
proto.api.v1.filters.Filter.prototype.setHint = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional FilterId id = 11;
 * @return {?proto.api.v1.filters.FilterId}
 */
proto.api.v1.filters.Filter.prototype.getId = function() {
  return /** @type{?proto.api.v1.filters.FilterId} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.filters.FilterId, 11));
};


/**
 * @param {?proto.api.v1.filters.FilterId|undefined} value
 * @return {!proto.api.v1.filters.Filter} returns this
*/
proto.api.v1.filters.Filter.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.filters.Filter} returns this
 */
proto.api.v1.filters.Filter.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.filters.Filter.prototype.hasId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool required = 12;
 * @return {boolean}
 */
proto.api.v1.filters.Filter.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.filters.Filter} returns this
 */
proto.api.v1.filters.Filter.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string lang = 16;
 * @return {string}
 */
proto.api.v1.filters.Filter.prototype.getLang = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.filters.Filter} returns this
 */
proto.api.v1.filters.Filter.prototype.setLang = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.filters.FilterObject.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.filters.FilterObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.filters.FilterObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.filters.FilterObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, ""),
    color: jspb.Message.getFieldWithDefault(msg, 3, ""),
    checked: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.filters.FilterObject}
 */
proto.api.v1.filters.FilterObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.filters.FilterObject;
  return proto.api.v1.filters.FilterObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.filters.FilterObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.filters.FilterObject}
 */
proto.api.v1.filters.FilterObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChecked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.filters.FilterObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.filters.FilterObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.filters.FilterObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.filters.FilterObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChecked();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.api.v1.filters.FilterObject.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.filters.FilterObject} returns this
 */
proto.api.v1.filters.FilterObject.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.api.v1.filters.FilterObject.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.filters.FilterObject} returns this
 */
proto.api.v1.filters.FilterObject.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string color = 3;
 * @return {string}
 */
proto.api.v1.filters.FilterObject.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.filters.FilterObject} returns this
 */
proto.api.v1.filters.FilterObject.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool checked = 4;
 * @return {boolean}
 */
proto.api.v1.filters.FilterObject.prototype.getChecked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.filters.FilterObject} returns this
 */
proto.api.v1.filters.FilterObject.prototype.setChecked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.filters.GetFiltersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.filters.GetFiltersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.filters.GetFiltersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.filters.GetFiltersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lang: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filtersMap: (f = msg.getFiltersMap()) ? f.toObject(includeInstance, undefined) : [],
    toponim: (f = msg.getToponim()) && toponims_pb.Toponim.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.filters.GetFiltersRequest}
 */
proto.api.v1.filters.GetFiltersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.filters.GetFiltersRequest;
  return proto.api.v1.filters.GetFiltersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.filters.GetFiltersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.filters.GetFiltersRequest}
 */
proto.api.v1.filters.GetFiltersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLang(value);
      break;
    case 2:
      var value = msg.getFiltersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 3:
      var value = new toponims_pb.Toponim;
      reader.readMessage(value,toponims_pb.Toponim.deserializeBinaryFromReader);
      msg.setToponim(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.filters.GetFiltersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.filters.GetFiltersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.filters.GetFiltersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.filters.GetFiltersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLang();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFiltersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getToponim();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      toponims_pb.Toponim.serializeBinaryToWriter
    );
  }
};


/**
 * optional string lang = 1;
 * @return {string}
 */
proto.api.v1.filters.GetFiltersRequest.prototype.getLang = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.filters.GetFiltersRequest} returns this
 */
proto.api.v1.filters.GetFiltersRequest.prototype.setLang = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> filters = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.api.v1.filters.GetFiltersRequest.prototype.getFiltersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.api.v1.filters.GetFiltersRequest} returns this
 */
proto.api.v1.filters.GetFiltersRequest.prototype.clearFiltersMap = function() {
  this.getFiltersMap().clear();
  return this;};


/**
 * optional api.v1.toponims.Toponim toponim = 3;
 * @return {?proto.api.v1.toponims.Toponim}
 */
proto.api.v1.filters.GetFiltersRequest.prototype.getToponim = function() {
  return /** @type{?proto.api.v1.toponims.Toponim} */ (
    jspb.Message.getWrapperField(this, toponims_pb.Toponim, 3));
};


/**
 * @param {?proto.api.v1.toponims.Toponim|undefined} value
 * @return {!proto.api.v1.filters.GetFiltersRequest} returns this
*/
proto.api.v1.filters.GetFiltersRequest.prototype.setToponim = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.filters.GetFiltersRequest} returns this
 */
proto.api.v1.filters.GetFiltersRequest.prototype.clearToponim = function() {
  return this.setToponim(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.filters.GetFiltersRequest.prototype.hasToponim = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.filters.GetFiltersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.filters.GetFiltersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.filters.GetFiltersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.filters.GetFiltersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.filters.GetFiltersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.api.v1.filters.Filter.toObject, includeInstance),
    toponim: (f = msg.getToponim()) && toponims_pb.Toponim.toObject(includeInstance, f),
    selectedFiltersMap: (f = msg.getSelectedFiltersMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.filters.GetFiltersResponse}
 */
proto.api.v1.filters.GetFiltersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.filters.GetFiltersResponse;
  return proto.api.v1.filters.GetFiltersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.filters.GetFiltersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.filters.GetFiltersResponse}
 */
proto.api.v1.filters.GetFiltersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.filters.Filter;
      reader.readMessage(value,proto.api.v1.filters.Filter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 2:
      var value = new toponims_pb.Toponim;
      reader.readMessage(value,toponims_pb.Toponim.deserializeBinaryFromReader);
      msg.setToponim(value);
      break;
    case 3:
      var value = msg.getSelectedFiltersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.filters.GetFiltersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.filters.GetFiltersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.filters.GetFiltersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.filters.GetFiltersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.filters.Filter.serializeBinaryToWriter
    );
  }
  f = message.getToponim();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      toponims_pb.Toponim.serializeBinaryToWriter
    );
  }
  f = message.getSelectedFiltersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * repeated Filter filters = 1;
 * @return {!Array<!proto.api.v1.filters.Filter>}
 */
proto.api.v1.filters.GetFiltersResponse.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.api.v1.filters.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.filters.Filter, 1));
};


/**
 * @param {!Array<!proto.api.v1.filters.Filter>} value
 * @return {!proto.api.v1.filters.GetFiltersResponse} returns this
*/
proto.api.v1.filters.GetFiltersResponse.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.filters.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.filters.Filter}
 */
proto.api.v1.filters.GetFiltersResponse.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.filters.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.filters.GetFiltersResponse} returns this
 */
proto.api.v1.filters.GetFiltersResponse.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};


/**
 * optional api.v1.toponims.Toponim toponim = 2;
 * @return {?proto.api.v1.toponims.Toponim}
 */
proto.api.v1.filters.GetFiltersResponse.prototype.getToponim = function() {
  return /** @type{?proto.api.v1.toponims.Toponim} */ (
    jspb.Message.getWrapperField(this, toponims_pb.Toponim, 2));
};


/**
 * @param {?proto.api.v1.toponims.Toponim|undefined} value
 * @return {!proto.api.v1.filters.GetFiltersResponse} returns this
*/
proto.api.v1.filters.GetFiltersResponse.prototype.setToponim = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.filters.GetFiltersResponse} returns this
 */
proto.api.v1.filters.GetFiltersResponse.prototype.clearToponim = function() {
  return this.setToponim(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.filters.GetFiltersResponse.prototype.hasToponim = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * map<string, string> selected_filters = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.api.v1.filters.GetFiltersResponse.prototype.getSelectedFiltersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.api.v1.filters.GetFiltersResponse} returns this
 */
proto.api.v1.filters.GetFiltersResponse.prototype.clearSelectedFiltersMap = function() {
  this.getSelectedFiltersMap().clear();
  return this;};


goog.object.extend(exports, proto.api.v1.filters);
