<template>
  <wrapper-collapsible
    :filterNameText="name"
    :filterValueText="selectedText"
    :canBeApplied="showApplyBtn"
    @on-close-clicked="clearSelected"
    @on-apply-clicked="applySelected"
  >
    <div class="scrollable">
      <ul class="list-unstyled">
        <li :key="o.value" v-for="o in objects">
          <div
            class="styled-checkbox"
            tabindex="0"
            @keydown.enter="clickRef(String(inputId + '_' + o.value))"
          >
            <input
              type="checkbox"
              :name="inputId"
              :value="o.value"
              :id="inputId + '_' + o.value"
              :ref="String(inputId + '_' + o.value)"
              v-model="toBeSelected"
              :disabled="
                maxSelected == undefined
                  ? false
                  : toBeSelected.length >= maxSelected &&
                    toBeSelected.indexOf(o.value) === -1
              "
            />
            <label :for="inputId + '_' + o.value">{{ o.caption }} </label>
          </div>
        </li>
      </ul>
    </div>
  </wrapper-collapsible>
</template>

<script>
// TODO: dry с FilterCheckboxMultiInline
import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";

import GenericFilterMixin from "../mixins/GenericFilterMixin";
import ClickRefMixin from "../mixins/ClickRefMixin";

import WrapperCollapsible from "./WrapperCollapsible.vue";

export default {
  name: "FilterCheckboxMultiCollapsible",
  components: {
    WrapperCollapsible,
  },
  props: {
    /** Название списка */
    name: {
      type: String,
      required: true,
    },
    /** Набор объектов, которые нужно отрисовать.
     * Должны содержать поля:
     * - value
     * - caption
     * - checked
     */
    objects: {
      type: Array,
      required: true,
    },
    /** Максимальное количество выбранных элементов */
    maxSelected: {
      type: Number,
      required: false,
    },
  },
  mixins: [GenericFilterMixin, ClickRefMixin],
  setup() {
    return {
      inputId: uuidv4(),
      selectedValues: ref([]),
      toBeSelected: ref([]),
    };
  },
  mounted() {
    this.mergeObjectSelectedAndSelectedValues();
  },
  watch: {
    objects() {
      this.mergeObjectSelectedAndSelectedValues();
    },
    selectedValues() {
      this.toBeSelected = this.selectedValues;
    },
  },
  computed: {
    selectedText() {
      var selected = this.selectedValues;
      var selected_captions = this.objects
        .filter((obj) => selected.includes(obj.value))
        .map((obj) => obj.caption)
        .join(", ");

      return selected_captions;
    },
    showApplyBtn() {
      return (
        this.toBeSelected != undefined &&
        this.toBeSelected != null &&
        this.toBeSelected.length != 0
      );
    },
  },
  methods: {
    mergeObjectSelectedAndSelectedValues() {
      var new_selected = this.objects
        .filter((obj) => obj.checked)
        .map((obj) => obj.value);
      var all_selected = this.selectedValues.concat(new_selected);
      var valid_selected = this.objects
        .filter((obj) => all_selected.includes(obj.value))
        .map((obj) => obj.value);

      this.selectedValues = valid_selected;
    },
    applySelected() {
      this.selectedValues = this.toBeSelected;
      this.$emit("on-value-selected", this.filterId, this.selectedValues);
    },
    clearSelected() {
      this.toBeSelected = this.selectedValues;
    },
  },
};
</script>

<style scoped>
.scrollable {
  max-height: 300px;
  overflow: auto;
}
</style>
