// package: api.v1.categories
// file: categories.proto

var categories_pb = require("./categories_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var PublicCategoriesService = (function () {
  function PublicCategoriesService() {}
  PublicCategoriesService.serviceName = "api.v1.categories.PublicCategoriesService";
  return PublicCategoriesService;
}());

PublicCategoriesService.GetCategories = {
  methodName: "GetCategories",
  service: PublicCategoriesService,
  requestStream: false,
  responseStream: false,
  requestType: categories_pb.GetCategoriesRequest,
  responseType: categories_pb.GetCategoriesResponse
};

PublicCategoriesService.GetCategory = {
  methodName: "GetCategory",
  service: PublicCategoriesService,
  requestStream: false,
  responseStream: false,
  requestType: categories_pb.GetCategoryRequest,
  responseType: categories_pb.GetCategoryResponse
};

PublicCategoriesService.GetParent = {
  methodName: "GetParent",
  service: PublicCategoriesService,
  requestStream: false,
  responseStream: false,
  requestType: categories_pb.GetParentRequest,
  responseType: categories_pb.GetParentResponse
};

exports.PublicCategoriesService = PublicCategoriesService;

function PublicCategoriesServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

PublicCategoriesServiceClient.prototype.getCategories = function getCategories(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicCategoriesService.GetCategories, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicCategoriesServiceClient.prototype.getCategory = function getCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicCategoriesService.GetCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicCategoriesServiceClient.prototype.getParent = function getParent(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicCategoriesService.GetParent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.PublicCategoriesServiceClient = PublicCategoriesServiceClient;

