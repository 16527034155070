<template>
  <div style="display: flex;padding: 10px;">
    <i class="fas fa-mobile" style="font-size: 35px;margin: auto 15px;"></i>
    <div style="margin: auto;text-align: start;">
      <div style="font-size: 15px;">
        {{ $t("banner_app_download.no_account") }}
      </div>
      <!-- href, так как не закрывал modal -->
      <a
        href="/get-app"
        style="color: #1c83fc;font-size: 10px;text-decoration: underline;"
      >
        {{ $t("banner_app_download.register_in_app") }}
      </a>
    </div>
  </div>
</template>
