import { createI18n } from "vue-i18n";
import rus from "./locales/rus.json";

// Locale должна быть в формате [ISO 639-3], для лучшей совместимости с API
//
// [ISO 639-3]: https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes

const i18n = createI18n({
  locale: "rus",
  fallbackLocale: "rus",
  messages: {
    rus: rus,
  },
});

export default i18n;
