<template>
  <wrapper-static
    :filterNameText="name"
    :filterValueText="selectedText"
    :canBeApplied="false"
    :canBeCleared="showClearBtn"
    @on-clear-selected="clearSelected"
  >
    <ul class="list-unstyled">
      <li :key="o.value" v-for="o in objects">
        <div
          class="styled-checkbox"
          tabindex="0"
          @keydown.enter="selectedValue = o.value"
        >
          <input
            type="radio"
            :name="inputId"
            :value="o.value"
            :id="inputId + '_' + o.value"
            v-model="selectedValue"
          />
          <label :for="inputId + '_' + o.value">{{ o.caption }}</label>
        </div>
      </li>
    </ul>
  </wrapper-static>
</template>

<script>
import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";

import GenericFilterMixin from "../mixins/GenericFilterMixin";

import WrapperStatic from "./WrapperStatic.vue";

export default {
  name: "FilterCheckboxSingleInline",
  components: {
    WrapperStatic,
  },
  props: {
    /** Название списка */
    name: {
      type: String,
      required: true,
    },
    /** Набор объектов, которые нужно отрисовать.
     * Должны содержать поля:
     * - value
     * - caption
     * - checked
     */
    objects: {
      type: Array,
      required: true,
    },
  },
  mixins: [GenericFilterMixin],
  setup() {
    return {
      inputId: uuidv4(),
      selectedValue: ref(""),
      skipSelectedValueWatch: false,
    };
  },
  mounted() {
    this.updateSelectedValue();
  },
  watch: {
    objects() {
      this.updateSelectedValue();
    },
    selectedValue(newVal, oldVal) {
      if (this.skipSelectedValueWatch) {
        return;
      }

      if (String(newVal) == String(oldVal)) {
        return;
      }

      this.$emit("on-value-selected", this.filterId, this.selectedValue);
    },
  },
  computed: {
    selectedText() {
      var selected = this.selectedValue;
      var object = this.objects.find((obj) => obj.value == selected);

      if (object != undefined && object != null) {
        return object.caption;
      }

      return "";
    },
    showClearBtn() {
      return (
        this.selectedValue != null &&
        this.selectedValue != undefined &&
        this.selectedValue != ""
      );
    },
  },
  methods: {
    updateSelectedValue() {
      var selected_obj = this.objects.find((obj) => obj.checked);

      if (selected_obj == undefined) {
        return;
      }

      this.skipSelectedValueWatch = true;
      this.selectedValue = selected_obj.value;
      this.skipSelectedValueWatch = false;
    },
    clearSelected() {
      this.selectedValue = "";
    },
  },
};
</script>

<style scoped></style>
