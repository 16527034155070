<template>
  <p v-if="stations.length == 0">
    {{ t("filters.filter_metro_select.no_stations") }}
  </p>

  <ul class="stations">
    <li
      :key="st.idx"
      v-for="st in stations"
      tabindex="0"
      @keydown.enter="clickRef(String(inputId + '_' + st.idx))"
    >
      <div class="label styled-checkbox">
        <input
          type="checkbox"
          :name="inputId"
          :value="st.idx"
          :id="inputId + '_' + st.idx"
          :ref="String(inputId + '_' + st.idx)"
          v-model="selectedValues"
        />

        <label :for="inputId + '_' + st.idx">
          <span
            :key="color"
            v-for="color in st.colors"
            :style="{ backgroundColor: '#' + color.replace('#', '') }"
            class="color"
            aria-hidden="true"
          ></span>
          <span class="name">{{ st.name }}</span>
        </label>
      </div>
    </li>
  </ul>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { useI18n } from "vue-i18n";

import ClickRefMixin from "../mixins/ClickRefMixin";

export default {
  name: "MetroStations",
  props: {
    /** Список всех станций
     *    - idx - индекс в списке
     *    - stationIds - String - id станции
     *    - lineIds - String[] - id веток, на которой расположена станция
     *    - name - String - название станции
     *    - colors - String[] - цвета веток, на которых расположена станция
     */
    stations: Array,
    /** Список id выбранных станции метро. */
    selected: Array,
  },
  mixins: [ClickRefMixin],
  emits: [
    /** Была выбрана станция метро
     * Параметры:
     *  1. {Array[String]} id выбранных станций метро. Вернет все станции каждый раз.
     */
    "selected-station",
  ],
  setup() {
    const { t } = useI18n();

    return {
      t,
      inputId: uuidv4(),
    };
  },
  computed: {
    selectedValues: {
      get() {
        var stations_idx = [
          ...new Set(
            this.selected.map((id) => {
              var obj = this.stations.find((st) => st.stationIds.includes(id));

              if (obj == undefined) {
                return undefined;
              }
              return obj.idx;
            })
          ),
        ];

        return stations_idx;
      },
      set(ids) {
        var selected_ids = this.stations
          .filter((st) => ids.includes(st.idx))
          .map((s) => s.stationIds)
          .flat();

        this.$emit("selected-station", selected_ids);
      },
    },
  },
};
</script>

<style scoped>
.stations {
  display: block;
}

.stations li {
  display: inline-flex;
  width: 100%;
}

.stations li label {
  color: black;
}

.stations .color {
  width: 15px;
  height: 15px;
  display: block;
  background-color: red;
  border-radius: 50%;
  margin-right: 1px;
  margin-left: 1px;
  margin-top: auto;
  margin-bottom: auto;
}

.stations .name {
  margin-left: 5px;
  margin-right: 5px;
}

.stations .label {
  display: flex;
}
</style>
