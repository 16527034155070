<template>
  <div class="seller-details flex-wrap flex-start-h">
    <router-link
      v-if="user.id"
      :to="{ name: 'UserDetails', params: { id: user.id } }"
      class="avatar-wrap"
      tabindex="-1"
    >
      <div
        class="avatar avatar-70 photo"
        :style="{ backgroundImage: 'url(' + user.thumbUrl + ')' }"
      ></div>
    </router-link>
    <div v-else class="avatar-wrap" tabindex="-1">
      <div
        :style="{ backgroundImage: 'url(' + DefaultAvatar + ')' }"
        class="avatar avatar-70 photo"
      ></div>
    </div>
    <div class="seller-name">
      <h5>
        <router-link
          v-if="user.id"
          :to="{ name: 'UserDetails', params: { id: user.id } }"
          :aria-label="t('user_small.to_user_page', { name: user.username })"
        >
          {{
            user.username.length == 0 ? t("default_username") : user.username
          }}
        </router-link>
        <div v-else>
          {{ t("user_deleted") }}
        </div>
      </h5>
      <div class="user-rating">
        <RatingStar :rating="user.rating" />
      </div>
      <div
        :class="[
          'online-status',
          'flex-wrap',
          'flex-start-h',
          userSeenToday ? '' : 'offline',
        ]"
      >
        <span></span>
        {{
          userSeenToday
            ? t("user_small.user_was_today")
            : t("user_small.user_was_not_today")
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

import RatingStar from "./RatingStar.vue";

import DefaultAvatar from "../assets/img/default_avatar.png";

export default {
  name: "UserSmall",
  components: {
    RatingStar,
  },
  props: {
    /** Пользователь, которого нужно отрисовать */
    user: {
      type: Object,
      default() {
        const { t } = useI18n();

        return {
          thumbUrl: DefaultAvatar,
          id: "00000000-0000-0000-0000-000000000000",
          username: t("default_username"),
          rating: 3.5,
          lastSeen: 0,
        };
      },
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      DefaultAvatar,
    };
  },
  computed: {
    userSeenToday() {
      // 24 hours in seconds
      var day = 86400;
      var now = Math.floor(Date.now() / 1000);
      var days_ago = Math.floor((now - this.user.lastSeen) / day);
      return days_ago < 1;
    },
  },
};
</script>

<style scoped>
.seller-name {
  text-align: start;
}

.avatar-wrap {
  border-radius: 50%;
  overflow: clip;
  display: block;
  width: 50px;
  height: 50px;
}

.avatar {
  height: 50px;
  width: 50px;
  background-position: center;
  background-size: cover;
}
</style>
