/** API для получения объявлений пользователя */

import { grpc } from "@improbable-eng/grpc-web";

import {
  GetUserRequest,
  DeletePhoneRequest,
  PhoneId,
  EditUserRequest,
  EditUserResponse,
  DeleteUserRequest,
  LogoutRequest,
  ChangePasswordRequest,
  ChangePasswordResponse,
} from "../proto/user_pb.js";

import store from "store";
import { UserService } from "../proto/user_pb_service.js";
import { getMeta } from "../utils/meta.js";

import DefaultAvatar from "assets/img/default_avatar.png";

const axios = require("axios").default;

/**
 * Получить пользователя, для которого выполнен вход.
 * @returns {Object} Пользователь, для которого выполнен вход с auth token'ом
 */
export async function getUserSelf() {
  let request = new GetUserRequest();

  return new Promise((resolve, reject) => {
    grpc.unary(UserService.GetUser, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error UserService.GetUser",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        var avatar = {
          original: DefaultAvatar,
          thumb: DefaultAvatar,
        };

        if (obj.user.userData.avatar) {
          if (obj.user.userData.avatar.original) {
            avatar.original = obj.user.userData.avatar.original;
          }

          if (obj.user.userData.avatar.thumb) {
            avatar.thumb = obj.user.userData.avatar.thumb;
          }
        }

        return resolve({
          userId: obj.user.userData.id,
          rating: obj.user.userData.rating
            ? obj.user.userData.rating.rating
            : 0,
          activeAds: obj.user.userData.activeAds,
          finishedAds: obj.user.userData.finishedAds,
          reviews: obj.user.userData.reviews,
          username: obj.user.userData.username,
          phones: obj.user.phonesList,
          avatar,
        });
      },
    });
  });
}

/**
 * Отвязать номер телефона с id от текущего пользователя
 * @param {String} id - id телефона, который нужно отвязать
 */
export async function deletePhone(id) {
  let request = new DeletePhoneRequest();
  var phoneId = new PhoneId();
  phoneId.setId(id);
  request.setPhoneId(phoneId);

  return new Promise((resolve, reject) => {
    grpc.unary(UserService.DeletePhone, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error UserService.DeletePhone",
            status,
            statusMessage,
            message,
          });
        }

        return resolve();
      },
    });
  });
}

/**
 * Изменить имя пользователя на username
 * @param {String} username - новое имя пользователя
 * @returns {Object} Вернет объект с полями:
 *  - status - статус изменения (имя пользователя может
 *             быть слишком длинное или короткое)
 */
export async function changeUsername(username) {
  if (!username) {
    return { status: "TOO_SHORT" };
  }

  let request = new EditUserRequest();
  request.setUsername(username);

  return new Promise((resolve, reject) => {
    grpc.unary(UserService.EditUser, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error UserService.EditUser",
            status,
            statusMessage,
            message,
          });
        }

        var r = message.toObject();

        if (r.status == EditUserResponse.Status.SUCCESS) {
          return resolve({ status: "SUCCESS" });
        }

        if (r.status == EditUserResponse.Status.USERNAME_TOO_LONG) {
          return resolve({ status: "TOO_LONG" });
        }

        if (r.status == EditUserResponse.Status.USERNAME_TOO_SHORT) {
          return resolve({ status: "TOO_SHORT" });
        }

        return resolve({ status: "UNKNOWN" });
      },
    });
  });
}

/**
 * Удалить текущего пользователя
 * @param {String} reason - причина удаления аккаунта
 */
export async function deleteAccount(reason) {
  let request = new DeleteUserRequest();
  if (reason) {
    request.setReason(reason);
  }

  return new Promise((resolve, reject) => {
    grpc.unary(UserService.DeleteUser, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error UserService.DeleteUser",
            status,
            statusMessage,
            message,
          });
        }

        return resolve();
      },
    });
  });
}

/**
 * Загрузить аватар на сервер из файла
 * @param {Object} {fileInput} - [File], который нужно загрузить на сервер
 * @returns {Object} Вернет объект с полями:
 *  - avatar
 *  - error
 *
 * [File]: https://developer.mozilla.org/en-US/docs/Web/API/File
 */
export async function uploadAvatar({ fileInput }) {
  var api_key = "";
  if (store.state.user.userId && store.state.user.authToken) {
    api_key = store.state.user.userId + ":" + store.state.user.authToken;
  } else {
    return {
      error: "NO_USER_ID_OR_AUTH_TOKEN",
    };
  }

  var headers = {
    "X-API-KEY": api_key,
    "X-Extension": fileInput.name,
    "Content-Type": fileInput.type,
  };

  var buffer = await fileInput.arrayBuffer();

  var response = await axios({
    method: "post",
    url: process.env.VUE_APP_AVATAR_UPLOAD_URI,
    data: buffer,
    headers,
  });

  switch (response.status) {
    // 201 — все хорошо — файл закачан и в ответ придут уменьшенные варианты в json
    case 201: {
      return {
        avatar: {
          original: response.data.original,
          thumb: response.data.thumb,
        },
      };
    }
    // 413 — Request Entity Too Large — если размер файла больше чем положено (пока 50 мб)
    case 413: {
      return {
        error: "TOO_LARGE",
      };
    }
    // 502 — Bad gateway — сервер upload не запущен (просьба написать admin@omoe.ru)
    case 502: {
      return {
        error: "BAD_GATEWAY",
      };
    }
    // 507 — если пользователь загрузил лимит своих фотографий и больше нельзя. Например не более 15 фотографий.
    case 507: {
      return {
        error: "LIMIT_EXCEEDED",
      };
    }
    // 404 — если заголовок API-KEY не передан, или он не корректен, или он не найден в базе
    case 404:
    default: {
      return {
        error: "UNKNOWN",
      };
    }
  }
}

/**
 * Выйти из аккаунта на сервере
 * @returns {Object} Вернет объект с полем status
 */
export async function logout() {
  var request = new LogoutRequest();

  return new Promise((resolve, reject) => {
    grpc.unary(UserService.Logout, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status == grpc.Code.Unauthenticated) {
          return resolve({
            status: "UNAUTHENTICATED",
          });
        }

        if (status != grpc.Code.OK) {
          return reject({
            name: "error UserService.Logout",
            status,
            statusMessage,
            message,
          });
        }

        return resolve({
          status: "OK",
        });
      },
    });
  });
}

/**
 * Изменить пароль пользователя
 * @param {String} {oldPassword - старый пароль
 * @param {String} newPassword} - новый пароль
 * @returns {Object} Вернет объект с полями;
 *  - authToken
 *  - status
 */
export async function changePassword({ oldPassword, newPassword }) {
  var request = new ChangePasswordRequest();

  request.setOldPassword(oldPassword);
  request.setNewPassword(newPassword);

  return new Promise((resolve, reject) => {
    grpc.unary(UserService.ChangePassword, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error UserService.ChangePassword",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        switch (obj.status) {
          case ChangePasswordResponse.Status.SUCCESS: {
            return resolve({
              authToken: obj.authToken,
              status: "SUCCESS",
            });
          }
          case ChangePasswordResponse.Status.INVALID_PASSWORD: {
            return resolve({
              authToken: null,
              status: "INVALID_PASSWORD",
            });
          }
          case ChangePasswordResponse.Status.PASSWORD_TOO_LONG: {
            return resolve({
              authToken: null,
              status: "TOO_LONG",
            });
          }
          case ChangePasswordResponse.Status.PASSWORD_TOO_SHORT: {
            return resolve({
              authToken: null,
              status: "TOO_SHORT",
            });
          }
          default: {
            return resolve({
              authToken: obj.authToken,
              status: "UNKNOWN",
            });
          }
        }
      },
    });
  });
}
