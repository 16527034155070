import {
  watch,
  ref,
  reactive,
  onMounted,
  computed,
  onServerPrefetch,
} from "vue";
import { useRouter, useRoute } from "vue-router";

import {
  searchAds as searchAdsApi,
  getFiltersForParams as getFiltersForParamsApi,
} from "api/search.js";

import { encodeBase64, decodeBase64 } from "./base64.js";

/**
 * Создать запрос с категорией
 * @param {String} category Категория, для которой нужно создать фильтр
 * @returns {String} Фильтр, который можно использовать с get параметром 'f'
 */
export function createCategoryFilters(category) {
  var filters = getFiltersForParamsApi({
    category,
  });

  return encodeBase64(filters);
}

export function setupSearch() {
  const router = useRouter();
  const route = useRoute();

  const data = reactive({
    ads: [],
    cursor: "",
  });

  const searchQuery = reactive({
    text: "",
    filters: {},
    toponim: {},
  });

  const resultsIsLoading = ref(false);

  /** Фильтры были изменены в component выбора фильтров */
  function applyFilters(filters, toponim) {
    searchQuery.filters = filters;
    searchQuery.toponim = toponim;
    updateSearchQueryUrl();
  }

  async function loadAds({ more }) {
    resultsIsLoading.value = true;

    var ads = await searchAdsApi(
      {
        cursor: data.cursor,
        text: searchQuery.text,
        filters: searchQuery.filters,
        size: 24,
      },
      searchQuery.toponim
    );

    if (more) {
      data.ads = data.ads.concat(ads.adsList);
    } else {
      data.ads = ads.adsList;
    }
    data.cursor = ads.cursor;

    resultsIsLoading.value = false;
  }

  async function updateSearchQueryUrl() {
    router.push({
      query: {
        q: searchQuery.text,
        f: encodeBase64(searchQuery.filters),
        t: encodeBase64(searchQuery.toponim),
      },
    });
  }

  async function getSearchQueryFromUrl() {
    var text = route.query.q;

    var filters = {};
    try {
      if (route.query.f != undefined) {
        filters = decodeBase64(route.query.f);
      }
    } catch (e) {
      console.error("Error parsing filters", e);
    }

    var toponim = {};
    try {
      if (route.query.t != undefined) {
        toponim = decodeBase64(route.query.t);
      }
    } catch (e) {
      console.error("Error parsing toponim", e);
    }

    searchQuery.text = text;
    searchQuery.filters = filters;
    searchQuery.toponim = toponim;

    await loadAds({ more: false });
  }

  function updateSearchText(new_text, selected) {
    if (new_text == "" || new_text == null) {
      searchQuery.text = undefined;
    } else {
      searchQuery.text = new_text;
    }

    if (selected != undefined) {
      if (selected.query != undefined && selected.query != new_text) {
        searchQuery.text = selected.query;
      }

      if (selected.filters != undefined) {
        searchQuery.filters = selected.filters;
      }

      if (selected.toponim != undefined) {
        searchQuery.toponim = selected.toponim;
      }
    }
    updateSearchQueryUrl();
  }

  onMounted(() => {
    getSearchQueryFromUrl();
  });

  const adsLoaderStyle = computed(() => {
    if (resultsIsLoading.value) {
      return {
        visibility: "visible",
      };
    }

    return {
      visibility: "hidden",
    };
  });

  const adsListStyle = computed(() => {
    if (resultsIsLoading.value) {
      return {
        "pointer-events": "none",
        opacity: "0.4",
      };
    }

    return {};
  });

  watch(
    () => route.query,
    () => {
      data.cursor = "";
      resultsIsLoading.value = false;
      getSearchQueryFromUrl();
    }
  );

  onServerPrefetch(async () => {
    await getSearchQueryFromUrl();
    resultsIsLoading.value = false;
  });

  return {
    searchQuery,
    resultsIsLoading,
    data,

    adsLoaderStyle,
    adsListStyle,

    applyFilters,
    getSearchQueryFromUrl,
    loadAds,
    updateSearchText,
  };
}
