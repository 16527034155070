<template>
  <div v-if="successfullyLoggedIn">
    <i class="fas fa-check"></i> {{ t("modal_login.login_successfull") }}
  </div>
  <div v-else class="modal-body">
    <div v-if="errorMessage" role="alert">
      <p class="error">
        <i class="fas fa-exclamation-circle"></i> {{ errorMessage }}
      </p>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="form-group has-feedback">
          <!-- TODO: не hardcode номер страны -->
          <InputPhoneNumber
            ref="phoneInputComponent"
            countryCode="+7"
            @on-phone-changed="(n) => (phoneNumber = n)"
          />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group has-feedback relative-wrap">
          <InputPassword
            @on-password-changed="(p) => (password = p)"
            @keydown-enter="loginClicked"
          />
        </div>
      </div>
    </div>

    <button class="submit-ajax-form af-button" @click="loginClicked">
      {{ t("modal_login.login") }}
    </button>

    <div class="or-divider" style="height: 10px;"></div>

    <div class="text-center">
      <BannerAppDownload />
    </div>

    <!-- TODO: добавить регистрацию и восстановление пароля -->
    <!-- <div class="text-center">
      <a href="#">
        {{ t("modal_login.forgot_password") }}
      </a>
    </div>

    <div class="or-divider">
      <p>{{ t("modal_login.or") }}</p>
    </div>

    <div class="text-center">
      <a href="#">
        {{ t("modal_login.register") }}
      </a>
    </div> -->
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import InputPhoneNumber from "./InputPhoneNumber.vue";
import InputPassword from "modules/ui/InputPassword.vue";
import BannerAppDownload from "./BannerAppDownload.vue";

export default {
  name: "Login",
  components: {
    InputPhoneNumber,
    InputPassword,
    BannerAppDownload,
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
      phoneNumber: ref(undefined),
      password: ref(undefined),
      phoneInputRef: ref(null),

      errorMessage: ref(undefined),
      successfullyLoggedIn: ref(false),
    };
  },
  mounted() {
    this.phoneInputRef = this.$refs.phoneInputComponent.$refs.phoneInput;
  },
  methods: {
    hasErrors() {
      if (!this.phoneNumber) {
        this.errorMessage = this.t("modal_login.errors.phone_num_empty");
        return true;
      }

      if (!this.password) {
        this.errorMessage = this.t("modal_login.errors.password_empty");
        return true;
      }

      this.errorMessage = undefined;
      return false;
    },
    async loginClicked() {
      if (this.hasErrors()) {
        return;
      }

      var res = await this.$store.dispatch("user/loginUser", {
        phoneNumber: "+7" + this.phoneNumber,
        password: this.password,
      });

      switch (res.status) {
        case "OK": {
          this.errorMessage = undefined;
          this.successfullyLoggedIn = true;
          this.$router.push({ name: "UserProfile" });
          break;
        }
        case "BLOCKED": {
          this.errorMessage = this.t("modal_login.errors.blocked");
          break;
        }
        case "DELETED": {
          this.errorMessage = this.t("modal_login.errors.deleted");
          break;
        }
        case "INVALID_CREDENTIALS": {
          this.errorMessage = this.t("modal_login.errors.invalid_credentials");
          break;
        }
        case "UNKNOWN":
        default: {
          this.errorMessage = this.t("modal_login.errors.unknown");
          break;
        }
      }
    },
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
}

.af-button {
  margin-bottom: 15px;
}

.or-divider p {
  background: #fff;
  padding: 0px 10px;
  color: #646c75;
  position: relative;
  z-index: 1;
  font-size: 12px;
  display: inline-block;
  margin: 0;
}

.error {
  color: red;
  text-align: start;
}
</style>
