<template>
  <div class="loader-wrapper">
    <span class="loader"></span>
    <p class="sr-only">{{ t("loading") }}</p>
  </div>
</template>

<script>
/**
 * На основе https://vineethtrv.github.io/loader/
 */

import { useI18n } from "vue-i18n";

export default {
  name: "GridLoader",
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>

<style scoped>
.loader-wrapper {
  margin: 10px;
}

.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #cee4ff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-bottom-color: #ffa93b;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
