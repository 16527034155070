/**
 * Примитивы, использующиеся во всех api handler'ах
 */

/**
 * @param {number} ts - или:
 *  - unix timestamp (**в секундах**), дату для которого нужно отрисовать
 *  - дата в формате, который может прочитать Date
 * @return {Date} - созданная дата
 */
export function tsToDate(ts) {
  if (!isNaN(Number(ts))) {
    var timestamp_date = Number(ts) * 1000; // в браузере timestamp в миллисекундах
    // TODO: брать язык из настроек локализации
    return new Date(timestamp_date);
  }

  if (
    String(ts)
      .toLowerCase()
      .endsWith("utc")
  ) {
    var utc_date = String(ts)
      .toLowerCase()
      .replaceAll("utc", "")
      .trim();
    return new Date(utc_date);
  }

  return new Date(ts);
}
