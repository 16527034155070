/*eslint no-undef: 0*/

/**
 * Загрузить скрипт Яндекс.Карты и вернуть ссылку на объект
 * @param {Object} loadScript - Глобальный vue load script. Получить: this.$loadScript
 * @param {String} div_id - id div, в которой нужно показать карту
 * @param {String} params - параметры Яндекс Карты.
 *   Необходимые поля:
 *   {
 *     center: [55.76, 37.64], // Центр карты
 *     zoom: 10 // Коэф. масштабирования
 *   }
 * @returns {Object} - объект Яндекс Карты
 */
async function newYmap(loadScript, div_id, params) {
  let ymapUrl = "https://api-maps.yandex.ru/2.1/?lang=ru_RU";

  var yKey = process.env.VUE_APP_YMAP_KEY;
  if (yKey != "" && yKey != undefined) {
    ymapUrl = ymapUrl + "&apikey=" + yKey;
  }

  await loadScript(ymapUrl);

  var myMap;

  await ymaps.ready();

  myMap = new ymaps.Map(div_id, params, {});

  myMap.behaviors.disable(["ruler", "routeEditor"]);

  return new YMap(myMap);

  // TODO: handle errors
}

/** Обертка вокруг Яндекс Карты.
 * Нужна для методов, которым нужен объект ymap и
 * для convenience wrapper'ов
 */
class YMap {
  constructor(map) {
    this.map = map;
  }

  /** Получить внутреннюю карты */
  getInner() {
    return this.map;
  }

  /**
   * Добавить метку на карту
   * @param {Array} coords - Набор коодинат [lat, lon].
   *  Например: [55.7525442,37.6203418] (Москва, Красная Площадь)
   * @param {Object} content - параметры контента. Необязательно.
   *   Пример:
   *   {
   *       hintContent: 'Собственный значок метки',
   *       balloonContent: 'Это красивая метка'
   *   }
   * @param {Object} params - параметры метки. Необязательно.
   *   Пример:
   *   {
   *       preset: 'islands#dotIcon',
   *       iconColor: '#FF0000',
   *       draggable: "false"
   *   }s
   * @returns {Object} - метка, которая была добавлена
   */
  addPlacemark(coords, content, params) {
    if (content == undefined || content == null) {
      content = {};
    }

    if (params == undefined || params == null) {
      params = {};
    }

    var mark = new ymaps.Placemark(coords, content, params);

    this.map.geoObjects.add(mark);

    return mark;
  }

  /** Добавить круг на карту.
   *  Пример: https://yandex.ru/dev/maps/jsbox/2.1/circle
   *
   * @param {Array} coords - координаты центра круга.
   * @param {Number} radius - радиус круга в метрах.
   * @param {Object} content - параметры контента. Необязательно.
   * Пример:
   * {
   *      // Описываем свойства круга.
   *      // Содержимое балуна.
   *      balloonContent: "Радиус круга - 10 км",
   *      // Содержимое хинта.
   *      hintContent: "Подвинь меня"
   * }
   * @param {Object} params - опции круга. Необязательно.
   * Пример:
   * {
   *      // Задаем опции круга.
   *      // Включаем возможность перетаскивания круга.
   *      draggable: true,
   *      // Цвет заливки.
   *      // Последний байт (77) определяет прозрачность.
   *      // Прозрачность заливки также можно задать используя опцию "fillOpacity".
   *      fillColor: "#DB709377",
   *      // Цвет обводки.
   *      strokeColor: "#990066",
   *      // Прозрачность обводки.
   *      strokeOpacity: 0.8,
   *      // Ширина обводки в пикселях.
   *      strokeWidth: 5
   *  }
   * @returns {Object} - Ссылка на круг.
   */
  addCircle(coords, radius, content, params) {
    if (content == undefined || content == null) {
      content = {};
    }

    if (params == undefined || params == null) {
      params = {};
    }

    var circle = new ymaps.Circle([coords, radius], content, params);

    this.map.geoObjects.add(circle);

    return circle;
  }

  /**
   * Удалить объект (метка, круг, ...) на карте
   * @param {any} obj - объект карты, который нужно удалить
   */
  removeGeoObj(obj) {
    if (obj == undefined) {
      return;
    }

    this.map.geoObjects.remove(obj);
  }

  /**
   * Установить событие при клике на карте.
   * map.setOnClick((e) => {
   *      var coords = e.get('coords');
   *      alert(coords.join(', '));
   *  })
   * @param {Function} trigger - функция, которую нужно вызвать
   */
  setOnClick(trigger) {
    this.map.events.add("click", trigger);
  }

  /**
   * Добавить кнопку на карту
   * Пример: https://yandex.ru/dev/maps/jsbox/2.1/button
   *
   * @param {Object} data - параметры кнопки или строка - содержимое кнопки в виде HTML.
   *  Подробнее: https://yandex.ru/dev/maps/archive/doc/jsapi/2.0/ref/reference/control.Button.html#control.Button__param-params.data
   * @param {Object} options - опции кнопки
   *   Подробнее: https://yandex.ru/dev/maps/archive/doc/jsapi/2.0/ref/reference/control.Button.html#control.Button__param-options
   * @param {Function} onClick - событие, которое нужно вызвать при нажатии на кнопку
   * @returns {Object} - добавленная кнопка
   */
  addButton(data, options, onClick) {
    var button = new ymaps.control.Button({
      data,
      options,
    });

    button.events.add("click", onClick);

    this.map.controls.add(button);

    return button;
  }

  /** Синхронизировать размер карты с размером ее родительского контейнера */
  fitToViewport() {
    this.map.container.fitToViewport();
  }
}

/**
 * Получить zoom Яндекс карты для расстояния в километрах.
 * @param {Number} dist - расстояние в км
 * @returns {Number} - zoom, на котором будет видно это расстояние
 */
function getZoomForKm(dist) {
  //  1 ---->   > 5000 км
  //  2 ---->   5000 - 2100
  //  3 ---->   2100 - 1000
  //  4 ---->   1000 - 500
  //  5 ---->   500 - 250
  //  6 ---->   250 - 110
  //  7 ---->   110 - 65
  //  8 ---->   65 - 30
  //  9 ---->   30 - 15
  // 10 ---->   15 - 7
  // 11 ----->  7 - 5
  // 12 ----->  5 - 2
  // 13 ----->  2 - 1
  // 14 ----->  < 1 км

  if (dist >= 5000) {
    return 1;
  }
  if (dist >= 2100) {
    return 2;
  }
  if (dist >= 1000) {
    return 3;
  }
  if (dist >= 500) {
    return 4;
  }
  if (dist >= 250) {
    return 5;
  }
  if (dist >= 110) {
    return 6;
  }
  if (dist >= 65) {
    return 7;
  }
  if (dist >= 30) {
    return 8;
  }
  if (dist >= 15) {
    return 9;
  }
  if (dist >= 7) {
    return 10;
  }
  if (dist >= 5) {
    return 11;
  }
  if (dist >= 2) {
    return 12;
  }
  if (dist >= 1) {
    return 13;
  }

  return 14;
}

export { YMap, newYmap, getZoomForKm };
