import emptyImg from "assets/img/empty-image.svg";

export default {
  methods: {
    thumbnail(photo) {
      return photo.thumb.length == 0 ? emptyImg : photo.thumb;
    },
    imageSetArray(photo) {
      var set = [];

      if (photo.small != "" && photo.small != undefined) {
        set.push("url(" + photo.small + ") 2x");
      }

      if (photo.big != "" && photo.big != undefined) {
        set.push("url(" + photo.big + ") 3x");
      }

      return set;
    },
    thumbnailStyle(photo) {
      var backgroundImage = ["url(" + this.thumbnail(photo) + ")"];

      var imageSet = this.imageSetArray(photo);
      if (imageSet.length != 0) {
        backgroundImage.push("image-set(" + imageSet.join(",") + ")");
        backgroundImage.push("-webkit-image-set(" + imageSet.join(",") + ")");
      }

      var backgroundPosition = undefined;
      if (photo.thumb.length == 0) {
        backgroundPosition = "left";
      }

      return {
        backgroundImage,
        backgroundPosition,
      };
    },
  },
};
