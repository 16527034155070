import _ from "lodash";
import { grpc } from "@improbable-eng/grpc-web";

import { GetFiltersRequest } from "./proto/filters_pb.js";
import { Toponim } from "./proto/toponims_pb.js";
import { PublicFiltersService } from "./proto/filters_pb_service.js";
import { protoFromObject } from "./utils/protoFromObject.js";
import { getMeta } from "./utils/meta.js";

export function getFilters(filters, toponim, fromFilter) {
  let request = new GetFiltersRequest();

  var filtersMap = request.getFiltersMap();

  for (var key of Object.keys(filters)) {
    filtersMap.set(key, filters[key]);
  }

  if (toponim != undefined && !_.isEqual(toponim, {})) {
    var protoToponim = new protoFromObject(Toponim, toponim);
    request.setToponim(protoToponim);
  }

  return new Promise((resolve, reject) => {
    grpc.unary(PublicFiltersService.GetFilters, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error PublicFiltersService.GetFilters",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        var parsedFilters = [];

        for (var f of obj.filtersList) {
          var fObj = fromFilter(f, obj.toponim);
          if (fObj != undefined) {
            parsedFilters.push(fObj);
          }
        }

        var selectedFilters = {};
        for (var kv of obj.selectedFiltersMap) {
          if (kv.length != 2) {
            continue;
          }

          selectedFilters[kv[0]] = kv[1];
        }

        return resolve({
          filters: parsedFilters,
          selectedFilters,
          selectedToponim: obj.toponim,
        });
      },
    });
  });
}
