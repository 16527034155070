<template>
  <modal-generic
    :openBtnAriaLabel="t('ad_details.report')"
    @on-closed="clearSelected"
  >
    <template v-slot:open-btn>
      <i class="aficon-flag"></i>
      <span>{{ t("ad_details.report") }}</span>
    </template>
    <template v-slot:title>
      <h1 v-if="errors.sendingRequest">
        <i class="fas fa-bug"></i>
        {{ t("modal_complain_dialog.request_error_title") }}
      </h1>
      <h1 v-else-if="sentSuccessfully">
        <i class="far fa-check-circle"></i>
        {{ t("modal_complain_dialog.sent_title") }}
      </h1>
      <h1 v-else>
        {{ t("modal_complain_dialog.leave_a_complaint") }}
      </h1>
    </template>
    <template v-slot:content>
      <div v-if="errors.sendingRequest">
        <p>{{ t("modal_complain_dialog.request_error_body") }}</p>
      </div>
      <div v-else-if="sentSuccessfully">
        <p>{{ t("modal_complain_dialog.sent_body") }}</p>
      </div>
      <div v-else>
        <div>
          <h2
            :class="this.errors.noReason ? 'h2-error' : ''"
            :id="inputId + '_selectreason_h2'"
          >
            {{ t("modal_complain_dialog.reason") }}
          </h2>

          <p v-if="this.errors.noReason" class="error" role="alert">
            <i class="fas fa-exclamation-circle"></i>
            {{ t("modal_complain_dialog.select_a_reason") }}
          </p>

          <ul
            class="list-unstyled reasons-list"
            :aria-labelledby="inputId + '_selectreason_h2'"
          >
            <li :key="reason.id" v-for="(reason, index) in reasonsList">
              <div
                class="styled-radio"
                tabindex="0"
                @keydown.enter="setSelectedId(reason.id)"
                role="button"
                :ref="
                  (el) => {
                    if (index == 0) {
                      this.selectReasonFirstElementRef = el;
                    }
                  }
                "
              >
                <input
                  type="radio"
                  :name="inputId"
                  :id="inputId + '_' + reason.id"
                  :value="reason.id"
                  v-model="selectedReasonId"
                />
                <label :for="inputId + '_' + reason.id">
                  {{ reason.name }}
                </label>
              </div>
              <transition name="slide">
                <ul
                  class="list-unstyled subreasons"
                  v-if="selectedSubreasons(reason.id)"
                  :aria-labelledby="inputId + '_' + reason.id"
                >
                  <li :key="sr.id" v-for="sr in selectedSubreasons(reason.id)">
                    <div
                      class="styled-radio"
                      tabindex="0"
                      @keydown.enter="setSelectedId(sr.id)"
                      role="button"
                    >
                      <input
                        type="radio"
                        :name="inputId"
                        :id="inputId + '_' + sr.id"
                        :value="sr.id"
                        v-model="selectedReasonId"
                      />
                      <label :for="inputId + '_' + sr.id">
                        {{ sr.name }}
                      </label>
                    </div>
                  </li>
                </ul>
              </transition>
            </li>
          </ul>
        </div>

        <div>
          <h2 :id="inputId + '_reasontextarea_h2'">
            {{ t("modal_complain_dialog.leave_a_comment") }}
          </h2>
          <textarea
            v-model="reasonText"
            :aria-labelledby="inputId + '_reasontextarea_h2'"
          ></textarea>
        </div>

        <button class="af-button noselect" @click="sendComplaintClicked">
          {{ t("modal_complain_dialog.submit") }}
        </button>
      </div>
    </template>
  </modal-generic>
</template>

<script>
import { ref, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { v4 as uuidv4 } from "uuid";

import ModalGeneric from "../components/ModalGeneric.vue";

import { leaveComplaint as leaveComplaintApi } from "api/complain.js";

export default {
  name: "ModalComplainDialog",
  props: {
    adId: {
      type: String,
      required: true,
    },
  },
  components: {
    ModalGeneric,
  },
  setup() {
    const { t } = useI18n();

    var reasonsList = [
      {
        id: "0",
        name: t("modal_complain_dialog.reasons_list.1_ITEM_SOLDED"),
        code: 1,
      },
      {
        id: "1",
        name: t("modal_complain_dialog.reasons_list.33_WRONG_PRICE"),
        code: 33,
      },
      {
        id: "2",
        name: t("modal_complain_dialog.reasons_list.27_WRONG_DESCRIPTION"),
        code: 27,
      },
      {
        id: "3",
        name: t("modal_complain_dialog.reasons_list.32_WRONG_ADDRESS"),
        code: 32,
      },
      {
        id: "4",
        name: t("modal_complain_dialog.reasons_list.40_UNABLE_TO_CALL"),
        code: 40,
      },
      {
        id: "5",
        name: t("modal_complain_dialog.reasons_list.21_VIOLATE_AD"),
        code: 21,
        subreasons: [
          {
            id: "5_1",
            name: t("modal_complain_dialog.reasons_list.22_VIOLATE_PHOTO"),
            code: 22,
          },
          {
            id: "5_2",
            name: t(
              "modal_complain_dialog.reasons_list.23_VIOLATE_PHONE_OR_LINKS"
            ),
            code: 23,
          },
          {
            id: "5_3",
            name: t("modal_complain_dialog.reasons_list.24_VIOLATE_MULTIPOST"),
            code: 24,
          },
          {
            id: "5_4",
            name: t("modal_complain_dialog.reasons_list.25_VIOLATE_REGULATOR"),
            code: 25,
          },
          {
            id: "5_5",
            name: t("modal_complain_dialog.reasons_list.26_INCORRECT_CATEGORY"),
            code: 26,
          },
          {
            id: "5_6",
            name: t("modal_complain_dialog.reasons_list.27_WRONG_DESCRIPTION"),
            code: 27,
          },
        ],
      },
      {
        id: "6",
        name: t("modal_complain_dialog.reasons_list.13_SCAM_CRIME"),
        code: 13,
        subreasons: [
          {
            id: "6_1",
            name: t("modal_complain_dialog.reasons_list.12_SCAM_MONEY"),
            code: 12,
          },
          {
            id: "6_2",
            name: t("modal_complain_dialog.reasons_list.11_SCAM_DELIVERY"),
            code: 11,
          },
        ],
      },
    ];

    return {
      t,
      inputId: "complain_" + uuidv4(),
      reasonsList,
      selectedReasonId: ref(""),
      reasonText: ref(""),
      selectReasonFirstElementRef: ref(undefined),
      errors: reactive({
        // Ошибка не указана причина
        noReason: false,
        // Ошибка отправки запроса
        sendingRequest: false,
      }),
      sentSuccessfully: ref(false),
    };
  },
  methods: {
    selectedSubreasons(id) {
      if (id == "" || this.selectedReasonId == "") {
        return undefined;
      }
      var parentId = id.split("_")[0];
      var selectedParent = String(this.selectedReasonId).split("_")[0];

      if (selectedParent != parentId) {
        return undefined;
      }

      var selected = this.reasonsList.find((r) => r.id == parentId);
      if (selected == undefined) {
        return undefined;
      }

      return selected.subreasons;
    },
    setSelectedId(id) {
      this.selectedReasonId = id;
    },
    clearSelected() {
      setTimeout(() => {
        this.selectedReasonId = "";
        this.reasonText = "";
        this.sentSuccessfully = false;

        for (var key of Object.keys(this.errors)) {
          this.errors[key] = false;
        }
      }, 200);
    },
    async sendComplaintClicked() {
      if (this.checkErrors()) {
        return;
      }

      var parentId = this.selectedReasonId.split("_")[0];
      var code = this.reasonsList.find((r) => r.id == parentId);
      if (this.selectedReasonId.includes("_")) {
        code = code.subreasons.find((r) => r.id == this.selectedReasonId);
      }
      code = code.code;

      try {
        await leaveComplaintApi({
          ad_id: this.adId,
          code,
          text: this.reasonText,
        });
        this.sentSuccessfully = true;
      } catch (e) {
        console.error("Error sending complain request:", e);
        this.errors.sendingRequest = true;
        return;
      }
    },
    /** Проверить есть ли ошибки в форме.
     * @returns {Boolean} - true, если есть ошибки
     */
    checkErrors() {
      var noErrors = false;

      if (this.selectedReasonId == "") {
        noErrors = true;

        if (this.errors.noReason) {
          this.errors.noReason = false;
          setTimeout(() => {
            this.errors.noReason = true;
          }, 100);
        } else {
          this.errors.noReason = true;
        }
        if (this.selectReasonFirstElementRef) {
          this.selectReasonFirstElementRef.focus();
        }
      }

      return noErrors;
    },
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}

p {
  margin-bottom: 10px;
}

h1 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 0;
}

h2 {
  font-size: 23px;
}

.reasons-list {
  width: 500px;
}

@media (max-width: 680px) {
  .reasons-list {
    width: 300px;
  }
}

@media (max-width: 500px) {
  .reasons-list {
    width: 200px;
  }
}

.subreasons {
  transition: all 0.3s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 0;
}

.slide-enter-to,
.slide-leave-from {
  max-height: 800px;
  overflow: visible;
}

.error {
  color: red;
  margin-left: 5px;
}

.h2-error {
  margin-bottom: 5px;
}
</style>
