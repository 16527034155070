<template>
  <TheHeader />

  <main>
    <the-error-handler :showErr="showErr">
      <slot></slot>
    </the-error-handler>
  </main>

  <TheFooter />
</template>

<script>
import { ref } from "vue";
import { useMeta } from "vue-meta";

import TheHeader from "./TheHeader.vue";
import TheFooter from "./TheFooter.vue";
import TheErrorHandler from "../../components/TheErrorHandler.vue";

export default {
  name: "DefaultLayout",
  components: {
    TheHeader,
    TheFooter,
    TheErrorHandler,
  },
  setup() {
    useMeta({
      bodyAttrs: {
        class:
          "page-template page-template-page-tpl_home page-template-page-tpl_home-php page page-id-662 kingcomposer kc-css-system",
      },
    });

    return {
      showErr: ref(false),
    };
  },
  errorCaptured(err, instance, info) {
    console.error("Error: ", err, instance, info);
    if (process.env.NODE_ENV != "development") {
      this.showErr = true;
    }
  },
};
</script>
