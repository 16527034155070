import { createStore } from "vuex";

import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";

import { user } from "./modules/user.js";

const debug = process.env.NODE_ENV !== "production";

const store = createStore({
  modules: {
    user,
  },
  strict: debug,
  plugins: [
    createPersistedState({
      key: "omoe-vue",
      paths: ["user.authToken", "user.userId"],
      storage: {
        getItem: (key) => {
          return Cookies.get(key);
        },
        setItem: (key, value) => {
          return Cookies.set(key, value, {
            secure: debug ? false : true,
            expires: 365,
            sameSite: "strict",
          });
        },
        removeItem: (key) => {
          return Cookies.remove(key);
        },
      },
      fetchBeforeUse: true,
      overwrite: true,
    }),
  ],
});

export default store;
