import { grpc } from "@improbable-eng/grpc-web";

import { AdRequest, AdsRequest } from "./proto/photos_pb.js";
import { ApiService } from "./proto/photos_pb_service.js";
import { PublicAdService } from "./proto/ad_pb_service.js";
import { AddWebviewRequest } from "./proto/ad_pb.js";
import { getMeta } from "./utils/meta.js";
import { tsToDate } from "./utils/common.js";

const base64 = require("base-64");
var utf8 = require("utf8");

/** Состояние объявления */
export const AdState = {
  DELETED: 121,
  SOLD: 115,
  ACTIVE: 100,
  FINALIZED: 120,
  WAITING: 20,
  PENDING: 10, // the ad is pending moderation
  DECLINED: 110, // the ad was declined
  BANNED: 130, // the ad was banned
  FROD: 140, // the ad was blocked due to suspicions of fraud
};

/**
 * @param {string!} message
 * @returns string | null
 */
function decodeAddr(message) {
  let addr = message.replaceAll("\n", "");

  const regex = /caption=[a-zA-Z0-9+/=]*[&]?/;
  let addrs = regex.exec(addr);

  if (!addrs || !addrs[0]) {
    return null;
  }

  let base64Str = addrs[0].split("caption=")[1]; // could be undefined!, check if it is

  if (!base64Str) {
    return null;
  }

  base64Str = base64Str.split("&")[0]; // trim the last '&' (if any). COULD BE UNDEFINED!

  if (!base64Str) {
    return null;
  }

  let bytes = base64.decode(base64Str);
  let text = utf8.decode(bytes);

  if (text.length == 0) {
    return null;
  }

  return text;
}

/**
 * Получить данные объявления по uuid.
 *
 * @param {String} id Uuid объявления, которое нужно получить
 * @return {Object[]} Вернет структуру с полями:
 *  - ad
 *    - uuid - string
 *    - name - string
 *    - description - string
 *    - state - number
 *    - price - number
 *    - oldPrice - number
 *    - categoryId string
 *    - specifications - Object[] - набор объектов c полями { key, value }
 *    - photos - Object[] - набор объектов c полями { uuid, thumb, big }
 *    - location - Object[] - объект, содержащий поля lat и lon. Оба будут 0 если нет координат
 *    - isFinished - Boolean - имеет ли объявление статус завершенного
 *    - phone - String - phone
 *    - useMessages - String - можно ли написать в сообщении
 *    - addr - String - адрес, указанный в объявлении. Может быть пустой
 *  - userId
 */
export function getAd(id) {
  let request = new AdRequest();
  request.setAdId(id);

  return new Promise((resolve, reject) => {
    grpc.unary(ApiService.GetAd, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error ApiService.GetAd",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        let addr = decodeAddr(obj.address);

        if (!addr) {
          addr = "";
        }

        if (
          obj.state != AdState.DELETED &&
          obj.state != AdState.SOLD &&
          obj.state != AdState.ACTIVE &&
          obj.state != AdState.FINALIZED
        ) {
          return reject("Not found " + id);
        }

        var photos = [];
        for (var photo of obj.photosList) {
          photos.push({
            uuid: photo.uuid,
            thumb: photo.thumb,
            big: photo.big,
          });
        }

        var location = {
          lat: 0,
          lon: 0,
        };

        if (obj.location != undefined) {
          if (
            obj.location.latitude != undefined &&
            obj.location.longitude != undefined
          ) {
            location = {
              lat: obj.location.latitude,
              lon: obj.location.longitude,
            };
          }
        }

        var isFinished = Number(obj.state) != 100;

        const connect_via = obj.parametersList.find(
          (v) => v.key === "connect_via"
        );

        let phone = "";
        let useMessages = false;
        if (connect_via) {
          if (
            String(connect_via.value) === "phone,messages" ||
            String(connect_via.value) === "phone_only"
          ) {
            phone = obj.phone;
          }

          if (
            String(connect_via.value) === "phone,messages" ||
            String(connect_via.value) === "message_only"
          ) {
            useMessages = true;
          }
        }

        return resolve({
          ad: {
            uuid: obj.id,
            name: obj.name,
            state: obj.state,
            description: obj.description,
            price: obj.price,
            oldPrice: obj.oldPrice,
            categoryId: obj.categoryId,
            phone,
            useMessages,
            // содержит набор объектов в формате { key, value }
            specifications: obj.humanParametersList,
            photos,
            location,
            isFinished,
            addr,
          },
          userId: obj.user.userId,
        });
      },
    });
  });
}

/**
 * Получить объявления, похожие на объявление с id.
 *
 * @param {String} id Uuid объявления, которое нужно получить
 * @return {Object[]} Вернет набор объявлений с полями:
 *   - uuid
 *   - name
 *   - price
 *   - mainPhoto
 *     - thumb
 *     - small
 */
export function getSimilarAds(id) {
  let request = new AdsRequest();
  request.setAdId(id);
  request.setPerPage(5);

  return new Promise((resolve, reject) => {
    grpc.unary(ApiService.GetSimilarTinyAds, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error ApiService.GetSimilarTinyAds",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        var similar_ads = [];
        for (var ad of obj.adsList) {
          var thumb = "";
          var small = "";

          if (ad.mainPhoto != undefined) {
            if (ad.mainPhoto.thumb != undefined) {
              thumb = ad.mainPhoto.thumb;
            }
            if (ad.mainPhoto.small != undefined) {
              small = ad.mainPhoto.small;
            }
          }

          similar_ads.push({
            uuid: ad.adId,
            name: ad.name,
            price: ad.price,
            mainPhoto: {
              thumb,
              small,
            },
          });
        }

        return resolve(similar_ads);
      },
    });
  });
}

/**
 * Получить объявления, принадлежащие пользователю с user id.
 *
 * @param {String} user_id Id пользователя, объявления которого нужно получить
 * @param {String} ad_type Тип объявления, который нужно получить.
 *   Может быть:
 *     - active
 *     - sold
 * @param {number} size Количество объявлений, которое нужно получить
 * @return {Object[]} Вернет набор объявлений с полями:
 *   - uuid
 *   - name
 *   - price
 *   - mainPhoto - обложка объявления
 *     - thumb
 *     - small
 *     - big
 */
export function getUserAds({ user_id, ad_type, size, cursor }) {
  ad_type = typeof ad_type !== "undefined" ? ad_type : "active";
  size = typeof size !== "undefined" ? size : 12;

  let request = new AdsRequest();
  request.setUserId(user_id);
  request.setPerPage(size);
  if (cursor) {
    request.setCursor(cursor);
  }

  var filter = "active";
  if (ad_type == "sold") {
    filter = "sold|finalized|deleted";
  }
  request.setFilter(filter);

  return new Promise((resolve, reject) => {
    grpc.unary(ApiService.GetAds, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error ApiService.GetAds",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        var user_ads = [];
        for (var ad of obj.adsList) {
          var thumb = "";
          var small = "";
          var big = "";

          if (ad.photosList != undefined && ad.photosList.length >= 1) {
            if (ad.photosList[0].thumb != undefined) {
              thumb = ad.photosList[0].thumb;
            }
            if (ad.photosList[0].small != undefined) {
              small = ad.photosList[0].small;
            }
            if (ad.photosList[0].big != undefined) {
              big = ad.photosList[0].big;
            }
          }

          var state = Object.keys(AdState).find(
            (key) => AdState[key] == ad.state
          );
          var date = ad.createdAt ? tsToDate(ad.createdAt.date) : undefined;

          user_ads.push({
            uuid: ad.id,
            name: ad.name,
            price: ad.price,
            mainPhoto: {
              thumb,
              small,
              big,
            },
            date,
            state,
          });
        }

        return resolve({
          ads: user_ads,
          cursor: obj.cursor,
        });
      },
    });
  });
}

/**
 * Add a view to the ad
 * @param {string} ad_id - id of the ad that was viewed
 * @returns {any} - returns 0
 */
export function addView(ad_id) {
  let request = new AddWebviewRequest();
  request.setAdId(ad_id);

  return new Promise((resolve, reject) => {
    grpc.unary(PublicAdService.AddWebview, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error AdService.AddWebview",
            status,
            statusMessage,
            message,
          });
        }

        return resolve(0);
      },
    });
  });
}
