<template>
  <div class="password-wrap">
    <label :for="inputId" class="bold">
      {{ label }}
    </label>

    <input
      :type="pwdIsVisible ? 'text' : 'password'"
      :id="inputId"
      ref="inputField"
      class="form-control"
      v-model="passwordText"
      @keydown.enter="$emit('keydown-enter')"
    />

    <a
      :title="t('input_password.view_password')"
      class="toggle-password"
      @click="pwdToggleVisible"
    >
      <i :class="pwdIsVisible ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
    </a>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { v4 as uuidv4 } from "uuid";
import { useToggle } from "@vueuse/core";

export default {
  name: "InputPassword",
  props: {
    /** Метка поля ввода */
    label: {
      type: String,
      required: false,
      default() {
        const { t } = useI18n();
        return t("input_password.password");
      },
    },
  },
  emits: [
    /** Изменился текст в поле пароль.
     * Параметры:
     *   1. новый пароль
     */
    "on-password-changed",

    /** Была нажата кнопка enter в поле ввода пароля */
    "keydown-enter",
  ],
  setup() {
    const { t } = useI18n();
    const [pwdIsVisible, pwdToggleVisible] = useToggle();

    return {
      t,
      inputId: "pwd_" + uuidv4(),
      pwdIsVisible,
      pwdToggleVisible,
      passwordText: ref(""),
      /**
       * Ref на поле ввода.
       * Может использоваться родителем.
       */
      inputField: ref(null),
    };
  },
  watch: {
    passwordText(new_v) {
      this.$emit("on-password-changed", new_v);
    },
  },
};
</script>

<style scoped>
.password-wrap {
  display: block;
  position: relative;
}
</style>
