<template>
  <ul
    :v-if="categories && categories.length != 0"
    class="list-unstyled list-inline breadcrumbs"
  >
    <li :key="cat.id" v-for="cat in categories" tabIndex="-1">
      <router-link
        :to="{
          name: 'SearchPage',
          query: { f: createCategoryFilters(cat.id) },
        }"
        tabindex="0"
      >
        {{ cat.name }}
      </router-link>
    </li>
  </ul>
</template>

<script>
import { createCategoryFilters } from "../views/SearchPage/search.js";

export default {
  name: "BreadcrumbsCategories",
  props: {
    /** Список категорий, который нужно показать
     * Должен быть Array, содержащий Object.
     * У каждого Object должны быть поля:
     *  - id - id категории
     *  - name - название категории
     */
    categories: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  setup() {
    return {
      createCategoryFilters,
    };
  },
};
</script>

<style scoped>
.list-inline {
  margin-left: 0px;
}

.breadcrumbs li:after {
  content: ">";
  margin: 0px 5px;
}

.breadcrumbs li:last-child::after {
  content: "";
  margin: 0px;
}
</style>
