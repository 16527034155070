<template>
  <router-link
    :to="{ name: 'ProfileMessages' }"
    :title="$t('profile_sidebar.sidebar_buttons.messages')"
  >
    <i class="aficon-messages"></i>
    <div v-if="unreadCount && unreadCount > 0" class="messages-unread-count">
      <span class="unread-badge"> {{ unreadCount }} </span>
    </div>
    <span class="icon-caption">{{
      $t("profile_sidebar.sidebar_buttons.messages")
    }}</span>
  </router-link>
</template>

<script>
import { ref } from "vue";

import { getNewMessagesCount as getNewMessagesCountApi } from "api/profile/messages.js";

/** Интервал, раз в который будет обновляться счетчик прочитанных сообщений */
const UNREAD_COUNT_FETCH_INTVL_MS = 30000;

export default {
  name: "ButtonMessages",
  setup() {
    return {
      unreadCount: ref(0),
      fetchTimeout: null,
    };
  },
  async mounted() {
    await this.fetchUnreadCount();
  },
  beforeUnmount() {
    if (this.fetchTimeout) {
      clearTimeout(this.fetchTimeout);
    }
  },
  methods: {
    async fetchUnreadCount() {
      var newCount = await getNewMessagesCountApi();
      this.unreadCount = newCount;
      this.fetchTimeout = setTimeout(
        this.fetchUnreadCount,
        UNREAD_COUNT_FETCH_INTVL_MS
      );
    },
  },
};
</script>
