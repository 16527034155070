<template>
  <a
    v-if="openWithLink"
    tabindex="0"
    :class="openBtnClass"
    :title="openBtnAriaLabel ? openBtnAriaLabel : ''"
    @click="showModalClicked"
  >
    <slot name="open-btn"></slot>
  </a>
  <button
    v-else
    :class="openBtnClass"
    :aria-label="openBtnAriaLabel ? openBtnAriaLabel : ''"
    @click="showModalClicked"
  >
    <slot name="open-btn"></slot>
  </button>

  <teleport to="#modals">
    <transition name="fade">
      <div
        class="modal-dialog-overlay"
        v-show="showModal"
        @click="hideModalClicked"
      ></div>
    </transition>

    <transition name="fade">
      <div
        v-show="showModal"
        class="modal-dialog"
        role="dialog"
        tabindex="0"
        @keydown.esc="hideModalClicked"
      >
        <focus-trap
          v-model:active="focusTrapped"
          :initial-focus="
            () => (initialFocus() ? initialFocus() : $refs.titleDiv)
          "
        >
          <div>
            <div class="modal-content">
              <div tabindex="0" ref="titleDiv">
                <slot name="title"></slot>
              </div>

              <slot name="content"></slot>
            </div>

            <button
              class="close-btn"
              @click="hideModalClicked"
              :title="t('modal_generic.close')"
            >
              <i class="aficon-times"></i>
            </button>
          </div>
        </focus-trap>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { ref } from "vue";
import { FocusTrap } from "focus-trap-vue";
import { useI18n } from "vue-i18n";

export default {
  /**
   * Окно modal с контентом из slot и кнопкой закрыть, задерживающее фокус внутри себя
   */
  name: "ModalGeneric",
  props: {
    /**
     * Id div, к которому прикреплено приложение.
     * По умолчанию: #app
     */
    appMount: {
      type: String,
      required: false,
      default: "app",
    },
    /** Aria label для кнопки открытия */
    openBtnAriaLabel: {
      type: String,
      required: false,
      default: undefined,
    },
    /**
     * Нужно ли открывать диалог с помощью ссылки.
     * По умолчанию диалог открывается кнопкой
     */
    openWithLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    /** Класс, который нужно применить к кнопке открыть */
    openBtnClass: {
      type: String,
      required: false,
      default: "",
    },
    initialFocus: {
      type: Function,
      required: false,
      default: () => undefined,
    },
  },
  emits: [
    /** Modal была закрыта (кнопкой закрыть, esc или кликом на фон).
     * Параметров нет. */
    "on-closed",
  ],
  components: {
    FocusTrap,
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      showModal: ref(false),
      focusTrapped: ref(false),
    };
  },
  methods: {
    showModalClicked() {
      this.showModal = true;
      setTimeout(() => {
        this.focusTrapped = true;
      }, 100);

      // TODO: динамическое название id с приложением?
      document.getElementById("app").setAttribute("aria-hidden", "true");
    },
    /** Скрыть modal. Может быть вызвана родителем */
    hideModalClicked() {
      this.focusTrapped = false;

      setTimeout(() => {
        this.showModal = false;
        this.$emit("on-closed");
      }, 100);

      // TODO: динамическое название id с приложением?
      document.getElementById("app").removeAttribute("aria-hidden");
    },
  },
};
</script>

<style scoped>
button {
  border: none;
  background: none;
}

.modal-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  display: block;
  max-width: 85vw;
  max-height: 80vh;
  width: max-content;
  margin: 0;
  background-color: #fff;
  padding: 20px;
  overflow: hidden;
}

.modal-content {
  overflow: auto;
  max-height: 75vh;
  overscroll-behavior: contain;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: white;
  font-size: 20px;
  padding: 0;
  margin: 10px;
  color: #939393;
}

.close-btn:hover {
  color: #000;
}

.close-btn i {
  margin: 0;
  background: white;
  border-radius: 100%;
  padding: 0;
  width: 24px;
  height: 24px;
  font-size: 20px;
}

.fade-enter-active {
  transition: opacity 0.4s;
}

.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 100;
}
</style>
