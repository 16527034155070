import { grpc } from "@improbable-eng/grpc-web";

import { GetSubwaysRequest } from "./proto/photos_pb.js";
import { ApiService } from "./proto/photos_pb_service.js";
import { getMeta } from "./utils/meta.js";

/**
 * Получить список станций метро
 * @param {String} city_id - fias id города, для которого нужно получить список линий
 * @param {String} lang - язык, на котором нужно получить список линий
 * @returns {Object} - вернет объект с полями:
 *  - stations - Object[] - список всех станций всех линий,
 *  отсортированный по name по алфавиту:
 *    - idx - индекс в списке
 *    - stationIds - String - id станции
 *    - lineIds - String[] - id веток, на которой расположена станция
 *    - name - String - название станции
 *    - colors - String[] - цвета веток, на которых расположена станция
 *  - lines - Object[] - список линий со станциями
 *   - lineId - id линии
 *   - name - название линии
 *   - color - цвет линии
 *   - stations - Object[] - список станций
 *     - stationId - id станции
 *     - name - название станции
 */
export async function getStationsLinesLists(city_id) {
  var lines = await getLinesFromApi(city_id);

  var allStations = [];
  var stationsIdx = 0;
  var allLines = [];

  for (var line of lines) {
    var lineStations = [];
    for (var station of line.stationsList) {
      {
        var existing = allStations.find((s) => s.name == station.name);
        if (existing == undefined) {
          allStations.push({
            idx: stationsIdx,
            stationIds: [station.id],
            lineIds: [line.id],
            name: station.name,
            colors: [line.color],
          });
          stationsIdx += 1;
        } else {
          existing.lineIds.push(line.id);
          existing.stationIds.push(station.id);
          existing.colors.push(line.color);
        }
      }
      lineStations.push({
        name: station.name,
        stationId: station.id,
      });
    }
    allLines.push({
      lineId: line.id,
      name: line.name,
      color: line.color,
      stations: lineStations,
    });
  }

  allStations.sort((a, b) => a.name.localeCompare(b.name));

  return {
    stations: allStations,
    lines: allLines,
  };
}

/**
 * Получить список линий метро из API
 * @param {String} city_id - fias id города, для которого нужно получить список линий
 * @param {String} lang - язык, на котором нужно получить список линий
 * @returns {Object[]} - вернет список `Line.lines` (из photos.proto)
 */
async function getLinesFromApi(city_id) {
  let request = new GetSubwaysRequest();
  request.setCityId(city_id);

  return new Promise((resolve, reject) => {
    grpc.unary(ApiService.GetSubways, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error ApiService.GetSubways",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        return resolve(obj.linesList);
      },
    });
  });
}
