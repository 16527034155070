const axios = require("axios").default;

/**
 * Получить предложения для выбранной геолокации
 * @param {Number} lat - широта
 * @param {Number} lon - долгота
 * @param {String} lang - язык, на котором нужно вернуть результаты
 * @returns {Object[]} - список результатов, не более 5
 */
export async function getAddress(lat, lon, lang) {
  lang = geocoderLocale(lang);

  var params = new URLSearchParams({
    format: "json",
    results: 5,
    apikey: process.env.VUE_APP_GEOCODER_KEY,
    // у геокодера почему-то формат lon, затем lat
    geocode: lon + "," + lat,
    lang,
  }).toString();

  var resp = await axios({
    method: "get",
    url: process.env.VUE_APP_GEOCODER_URL + "/?" + params,
  });

  if (resp.status != 200) {
    console.error("Geocoder response: " + "resp.status");
  }

  var places = [];

  for (var obj of resp.data.response.GeoObjectCollection.featureMember) {
    var [pos_lon, pos_lat] = obj.GeoObject.Point.pos.split(" ");
    places.push({
      name: obj.GeoObject.name,
      displayName: obj.GeoObject.display_name,
      precision: obj.GeoObject.metaDataProperty.GeocoderMetaData.precision,
      kind: obj.GeoObject.metaDataProperty.GeocoderMetaData.kind,
      pos: { lat: pos_lat, lon: pos_lon },
    });
  }

  return places;
}

function geocoderLocale(lang) {
  // Список локализаций, похожих на используемые в геокодере
  // https://stackoverflow.com/questions/3191664/list-of-all-locales-and-their-short-codes#3191729

  if (lang == "rus") {
    return "ru_RU";
  }

  if (lang == "eng") {
    return "en_US";
  }

  if (lang == "uzb") {
    return "uz";
  }

  // Язык по умолчанию
  return "ru_RU";
}
