<template>
  <wrapper-static
    :filterNameText="name"
    :canBeApplied="false"
    :canBeCleared="false"
  >
    <ul class="list-unstyled">
      <li :key="o.value" v-for="o in objects">
        <div
          class="styled-checkbox"
          tabindex="0"
          role="checkbox"
          :aria-checked="selectedValues.indexOf(o.value) != -1"
          @keydown.enter="clickRef(String(inputId + '_' + o.value))"
        >
          <input
            type="checkbox"
            :name="inputId"
            :value="o.value"
            :id="inputId + '_' + o.value"
            :ref="String(inputId + '_' + o.value)"
            v-model="selectedValues"
          />
          <label :for="inputId + '_' + o.value">
            {{ o.caption }}
          </label>
        </div>
      </li>
    </ul>
  </wrapper-static>
</template>

<script>
import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";

import GenericFilterMixin from "../mixins/GenericFilterMixin";
import ClickRefMixin from "../mixins/ClickRefMixin";

import WrapperStatic from "./WrapperStatic.vue";

// TODO: merge default selected

export default {
  name: "FilterCheckboxList",
  components: {
    WrapperStatic,
  },
  props: {
    /** Набор объектов, которые нужно отрисовать.
     * Должны содержать поля:
     * - value
     * - caption
     * - checked
     */
    objects: {
      type: Array,
      required: true,
    },
  },
  mixins: [GenericFilterMixin, ClickRefMixin],
  setup() {
    return {
      inputId: uuidv4(),
      selectedValues: ref([]),
    };
  },
  mounted() {
    this.mergeObjectSelectedAndSelectedValues();
  },
  watch: {
    objects() {
      this.mergeObjectSelectedAndSelectedValues();
    },
    selectedValues(newVal, oldVal) {
      if (this.skipSelectedValuesWatch) {
        return;
      }

      if (newVal.toString() == oldVal.toString()) {
        return;
      }
      this.$emit("on-value-selected", this.filterId, this.selectedValues);
    },
  },
  computed: {},
  methods: {
    mergeObjectSelectedAndSelectedValues() {
      var new_selected = this.objects
        .filter((obj) => obj.checked)
        .map((obj) => obj.value);

      if (new_selected.length == 0) {
        return;
      }

      var all_selected = this.selectedValues.concat(new_selected);
      var valid_selected = this.objects
        .filter((obj) => all_selected.includes(obj.value))
        .map((obj) => obj.value);

      this.skipSelectedValuesWatch = true;
      this.selectedValues = valid_selected;
      this.skipSelectedValuesWatch = false;
    },
  },
};
</script>

<style scoped>
.styled-checkbox {
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
}
</style>
