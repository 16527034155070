<template>
  <div class="search-with-map flex-wrap flex-start-v">
    <div class="search-map-form">
      <FiltersSearch
        :alreadySelectedFilters="search.searchQuery.filters"
        :alreadySelectedToponim="search.searchQuery.toponim"
        @on-filters-applied="search.applyFilters"
      />
    </div>

    <div class="search-results search-map-results">
      <div :style="search.adsLoaderStyle.value" class="loader">
        <TheLoader />
      </div>

      <div :style="search.adsListStyle.value">
        <div class="search-map-results-content">
          <div class="ajax-search">
            <SearchBar
              :queryText="search.searchQuery.text"
              @confirmed-input="search.updateSearchText"
            />
          </div>
        </div>

        <GridAds
          :ads="search.data.ads"
          :hasMore="search.data.cursor.length != 0"
          @has-more-clicked="search.loadAds({ more: true })"
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Страница поиска объявления. Возможные параметры:
 * q - текстовый поисковый запрос
 * category - id категории, в которой нужно выполнить поиск
 * f - объект с фильтрами в base64 messagepack.
 * t - объект с топонимом в base64 messagepack.
 */
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useMeta } from "vue-meta";

import FiltersSearch from "../../components/FiltersSearch.vue";
import GridAds from "../../components/GridAds.vue";
import SearchBar from "../../components/SearchBar.vue";
import TheLoader from "modules/ui/TheLoader.vue";

import { setupSearch } from "./search.js";

export default {
  name: "SearchPage",
  components: {
    FiltersSearch,
    GridAds,
    SearchBar,
    TheLoader,
  },
  setup() {
    const { t } = useI18n();

    const search = setupSearch();

    const computedMeta = computed(() => {
      if (search.searchQuery.text) {
        return {
          title: t("search_page.title_search_query", {
            q: search.searchQuery.text,
          }),
        };
      }

      return {
        title: t("search_page.title"),
      };
    });
    useMeta(computedMeta);

    return {
      t,
      search,
    };
  },
};
</script>

<style scoped>
/* Фильтры слева, нет кнопки показать фильтры */
@media (min-width: 740px) {
  .search-results {
    width: calc(100% - 380px);
    margin-left: auto;
    margin-right: auto;
  }
  .search-map-form {
    max-width: 300px;
    margin-top: 15px;
    margin-right: 10px;
    margin-left: 10px;
  }
}

/* Если max-width 740px показывается кнопка открытия фильтров. */
@media (max-width: 740px) {
  .search-results {
    width: 100%;
  }
  .search-map-form {
    width: 100%;
  }
}

.loader {
  top: 300px;
  position: sticky;
  z-index: 999;
  height: 0;
  transform: translateY(-80px);
}
</style>
