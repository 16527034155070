<template>
  <div>
    <button
      v-if="noSpacePhone"
      class="reveal-phone flex-wrap flex-start-h"
      @click="phoneToggle()"
    >
      <i class="aficon-phone" aria-hidden="true"></i>
      <span class="flex-right">
        <span class="description">
          {{ t("ad_details.show_phone") }}
          <em class="reveal-phone-number" v-if="phoneActive">
            <a
              class="phone-link"
              :href="'tel:+7' + noSpacePhone"
              @click="(ev) => ev.stopPropagation()"
            >
              {{ phoneNumber }}
            </a>
          </em>
        </span>
      </span>
    </button>

    <RouterLink
      v-if="canMessage"
      :to="{ name: 'GetApp' }"
      class="reveal-phone flex-wrap flex-start-h"
      style="background: #ffa93b;"
    >
      <i class="aficon-messages message-icon" aria-hidden="true"></i>
      <span class="flex-right">
        <span class="description">
          {{ t("ad_details.message") }}
        </span>
      </span>
    </RouterLink>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useToggle } from "@vueuse/core";

const [phoneActive, phoneToggle] = useToggle();

export default {
  name: "PhoneNumber",
  props: {
    /** Номер телефона, который нужно отобразить */
    phone: {
      type: String,
      default: "",
      required: false,
    },
    canMessage: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      phoneActive,
      phoneToggle,
    };
  },
  computed: {
    noSpacePhone() {
      if (!this.phone) {
        return "";
      }

      var phone = this.phone.trim().replaceAll(" ", "");

      if (phone.startsWith("+7")) {
        phone = phone.slice(2);
      } else if (phone.startsWith("7")) {
        phone = phone.slice(1);
      }

      if (this.phone.length != 10) {
        return this.phone;
      }

      return phone;
    },
    phoneNumber() {
      var phone = this.noSpacePhone;

      if (!this.noSpacePhone) {
        return "";
      }

      // телефон вида 123 456 78 90 (без пробелов)

      return (
        "+7 " +
        phone.slice(0, 3) +
        " " +
        phone.slice(3, 6) +
        " " +
        phone.slice(6, 8) +
        " " +
        phone.slice(8, 10)
      );
    },
  },
  methods: {
    closeAll() {
      this.phoneActive = false;
    },
  },
};
</script>

<style scoped>
.reveal-phone {
  border: none;
}

.reveal-phone-number {
  margin-top: 10px;
}

.phone-link {
  color: white;
}

.message-icon {
  transform: rotate(30deg);
  font-size: 80px;
  right: -12px;
  top: -15px;
}
</style>
