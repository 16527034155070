<template>
    <div class="white-block hide-print">
        <div class="white-block-title">
        <h5> {{ t('banner_app_get_vertical.title') }} </h5>
        </div>
        <div class="white-block-content">
            <p> {{ t('banner_app_get_vertical.description') }} </p>
            <div style="margin:-12px;margin-bottom:0;" class="mb-3">
                <a href='https://play.google.com/store/apps/details?id=com.omoe&utm_source=omoe.ru%20website&utm_campaign=bannerappgetvertical&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img alt='Доступно в Google Play' src='../assets/store-badges/google.png' style="width: 185px;"/>
                </a>
            </div>
            <div>
                <a href="https://apps.apple.com/ru/app/omoe-%D0%BE%D0%B1%D1%8A%D1%8F%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F/id1549513530?itsct=apps_box&amp;itscg=30200" 
                   style="display: inline-block; overflow: hidden; border-radius: 13px; width: 100%; margin: auto; padding: 0;">
                    <img src="../assets/store-badges/apple.svg" alt="Загрузите в App Store" style="border-radius: 13px; width: 165px;">
                </a>
            </div>

            <div style="margin-top: 12px;">
                <p style="font-size: 8px;line-height: 12px;">
                    <!-- TODO: локализовать -->
                    Google Play и логотип Google Play являются товарными знаками корпорации Google LLC.
                    App Store, iPhone и iPad являются товарными знаками Apple Inc.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
    name: 'BannerAppGetVertical',
    setup() {
      const { t } = useI18n();
      return {
        t,
      }
    },
}
</script>

<style scoped>

</style>