<template>
  <div>
    <label :for="inputId" class="bold">
      {{ t("input_phone_number.phone_number") }}
    </label>
    <div>
      <div class="prefix-wrap">
        <span class="prefix">{{ countryCode }}</span>
        <input
          :id="inputId"
          type="tel"
          class="form-control"
          v-model="inputText"
          ref="phoneInput"
        />
      </div>
      <div v-if="phoneInvalid" class="error" role="alert">
        <i class="fas fa-exclamation-circle"></i>
        {{ t("input_phone_number.invalid_phone") }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "InputPhoneNumber",
  props: {
    countryCode: {
      type: String,
      required: false,
      default: "",
    },
    phoneInvalid: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    /** Изменилась строка номера телефона. Не вызывает если нет параметров.
     * Параметры:
     *   1. new_val {String} - новый номер телефона
     */
    "on-phone-changed",
  ],
  setup() {
    const { t } = useI18n();
    return {
      t,
      inputId: "phonenum_" + uuidv4(),
      inputText: ref(""),
    };
  },
  watch: {
    inputText(new_v, old_v) {
      // Удалить все пробелы и буквы
      var noSpaces = String(new_v).replaceAll(/\D/g, "");
      var oldNoSpaces = String(old_v).replaceAll(/\D/g, "");

      if (noSpaces == oldNoSpaces) {
        return;
      }

      this.$emit("on-phone-changed", noSpaces);

      var selectionStart = this.$refs.phoneInput.selectionStart;
      var inputText = noSpaces.slice(0, 3);

      if (noSpaces.slice(3, 6).length != 0) {
        inputText += " " + noSpaces.slice(3, 6);
      }
      if (noSpaces.slice(6, 8).length != 0) {
        inputText += " " + noSpaces.slice(6, 8);
      }
      if (noSpaces.slice(8, 10).length != 0) {
        inputText += " " + noSpaces.slice(8, 10);
      }
      if (noSpaces.slice(10).length != 0) {
        inputText += " " + noSpaces.slice(10);
      }

      if (selectionStart == new_v.length) {
        selectionStart = inputText.length;
      }

      this.inputText = inputText;
      setTimeout(() => {
        this.$refs.phoneInput.setSelectionRange(selectionStart, selectionStart);
      }, 10);
    },
  },
};
</script>

<style scoped>
input {
  width: 100%;
}

.prefix-wrap {
  display: flex;
  height: 35px;
}

.prefix-wrap input {
  font-size: 13px;
}

.prefix {
  font-size: 13px;
  user-select: none;
  color: #8a929e;
  width: auto;
  max-width: 20%;
  margin: auto;
  margin-right: 5px;
  /* Компенсация border-bottom у input */
  padding-bottom: 2px;
}

.error {
  color: red;
}
</style>
