import { grpc } from "@improbable-eng/grpc-web";

import { GetUserInfoRequest, GetUserInfoResponse } from "./proto/user_pb.js";
import { PublicUserService } from "./proto/user_pb_service.js";
import { ApiService } from "./proto/photos_pb_service.js";

import { getMeta } from "./utils/meta.js";
import { TinyUser } from "./proto/photos_pb.js";

import { tsToDate } from "./utils/common.js";

import DefaultAvatar from "../assets/img/default_avatar.png";

/** Статус получения пользователя */
export const UserStatus = {
  Success: "Success",
  NotFound: "NotFound",
  Error: "Error",
};

/**
 * Получить данные пользователя по id.
 *
 * @param {String} id Id пользователя, данные которого нужно получить
 * @return {Object} Вернет ответ с полями:
 *  - status - UserStatus
 *  - user - данные пользователя
 *    - thumbUrl - String
 *    - id - String
 *    - username - String
 *    - rating - number
 *    - reviews - number
 *    - lastSeen - number
 */
export function getUser(id) {
  let request = new GetUserInfoRequest();
  request.setUserId(id);

  return new Promise((resolve, reject) => {
    grpc.unary(PublicUserService.GetUserInfo, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (
          status == grpc.Code.NotFound ||
          status == grpc.Code.InvalidArgument
        ) {
          return resolve({
            status: UserStatus.NotFound,
          });
        }

        if (status != grpc.Code.OK) {
          return reject({
            name: "error PublicUserService.GetUserInfo",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        if (obj.status == GetUserInfoResponse.Status.NOT_FOUND) {
          return resolve({
            status: UserStatus.NotFound,
          });
        } else if (obj.status == GetUserInfoResponse.Status.INVALID_ARGUMENT) {
          return resolve({
            status: UserStatus.NotFound,
          });
        } else if (obj.status != GetUserInfoResponse.Status.SUCCESS) {
          return resolve({
            status: UserStatus.Error,
          });
        }

        var thumbUrl = DefaultAvatar;
        if (obj.info.avatar != undefined) {
          if (obj.info.avatar.thumb != undefined) {
            thumbUrl = obj.info.avatar.thumb;
          }
        }

        var rating = 0;
        var reviews = 0;
        if (obj.info.rating != undefined && obj.info.reviews != undefined) {
          rating = new Number(obj.info.rating.rating);
          reviews = new Number(obj.info.reviews);
          if (isNaN(rating)) {
            console.error("rating is NaN");
            rating = 0;
          }

          if (isNaN(reviews)) {
            console.error("reviews is NaN");
            reviews = 0;
          }
        }

        var username = "";
        if (obj.info.username != undefined) {
          username = obj.info.username;
        }

        return resolve({
          status: UserStatus.Success,
          user: {
            thumbUrl,
            id: obj.info.id,
            username,
            rating,
            reviews,
            lastSeen: obj.info.lastSeen.date,
          },
        });
      },
    });
  });
}

/**
 * Получить отзывы о пользователе по id.
 *
 * @param {String} id Id пользователя, отзывы которого нужно вернуть
 * @return {Object[]} Вернет список отзывов с полями:
 *  - id
 *  - text
 *  - adUuid
 *  - response
 *    - text
 *    - date - Date - дата когда был получен ответ
 *  - date - Date - дата когда был написан отзыв
 *  - username
 *  - rating
 */
export function getUserReviews(id) {
  let request = new TinyUser();
  request.setUserId(id);

  return new Promise((resolve, reject) => {
    grpc.unary(ApiService.GetUserReviews, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error ApiService.GetUserReviews",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        var reviewsList = [];

        for (var r of obj.reviewsList) {
          var response = {
            text: "",
            date: "",
          };

          if (r.feedback != undefined) {
            response = {
              text: r.feedback.text,
              date: tsToDate(r.feedback.createdAt),
            };
          }

          reviewsList.push({
            id: r.id,
            text: r.text,
            adUuid: r.adId,
            response,
            date: tsToDate(r.createdAt),
            username: r.username,
            rating: r.stars,
          });
        }

        return resolve(reviewsList);
      },
    });
  });
}
