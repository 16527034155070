import FilterCategorySelect from "./components/FilterCategorySelect.vue";
import FilterCheckboxSingleInline from "./components/FilterCheckboxSingleInline.vue";
import FilterCheckboxSingleCollapsible from "./components/FilterCheckboxSingleCollapsible.vue";
import FilterCheckboxMultiInline from "./components/FilterCheckboxMultiInline.vue";
import FilterCheckboxMultiCollapsible from "./components/FilterCheckboxMultiCollapsible.vue";
import FilterCheckboxList from "./components/FilterCheckboxList.vue";
import FilterColorSelect from "./components/FilterColorSelect.vue";
import FilterRadiobuttons from "./components/FilterRadiobuttons.vue";
import FilterEmptySpace from "./components/FilterEmptySpace.vue";
import FilterTextboxNumber from "./components/FilterTextboxNumber.vue";
import FilterMetroSelect from "./components/FilterMetroSelect.vue";
import FilterTabs from "./components/FilterTabs.vue";
import FilterToponimSelect from "./components/FilterToponimSelect.vue";
import FilterLocationMapSelect from "./components/FilterLocationMapSelect.vue";

/** Номер типа фильтра из API - component, отвечающий за отрисовку */
export const FilterType = {
  1: FilterCheckboxSingleCollapsible, // Single select drop-down list (in a separate window, one item at most can be selected)
  2: FilterCheckboxMultiCollapsible, // Multi select drop-down list (in a separate window, max_selected items at most can be selected).
  3: FilterToponimSelect, // Select a city/region toponim. Toponims list should be fetched separately.
  4: FilterMetroSelect, // Select a metro station. The metro list should be fetched separately.
  5: FilterColorSelect, // Select colors. Color list will be in filter.objects.
  6: FilterEmptySpace, // Empty space.
  7: FilterEmptySpace, // Empty space with a text. The text will be contained in the caption.
  8: FilterLocationMapSelect, // Map for choosing location and search radius.
  9: FilterCheckboxSingleInline, // Single select list box (on this page, one item at most can be selected).
  10: FilterCheckboxMultiInline, // Multi select list box (on this page, max_selected items at most can be selected).
  11: FilterCheckboxList, // A set of check boxes.
  12: FilterTextboxNumber, // Textbox for entering numeric values. Input value must be validated to be in the range from
  // element.min_value (inclusive) to element.max_value (inclusive).
  // Element.postfix should be used if provided.
  13: FilterTabs, // Tab filter - select one value from several tabs that can be scrolled horizontally.
  // Values are passed in objects.
  14: FilterRadiobuttons, // Radiobutton. The set of values is passed in objects.
  15: FilterLocationMapSelect, // Address select.
  24: FilterCategorySelect, // Category select. Categories list should be fetched separately.
};

export function fromFilter(f, toponim) {
  var idx = f.id.id;

  var component = FilterType[f.filterType];

  if (component == undefined) {
    return undefined;
  }

  var props = getFilterSpecificProps(f.filterType);

  props["filterId"] = f.id.id;

  if (f.caption != undefined && f.caption != "") {
    props["name"] = f.caption;
  }

  if (f.value != undefined && f.value != "") {
    props["defaultValue"] = f.value;
  }

  if (f.maxSelected != undefined) {
    var maxSelected = Number(f.maxSelected);
    if (!isNaN(maxSelected) && maxSelected != 0) {
      props["maxSelected"] = maxSelected;
    }
  }

  if (f.minValue != undefined) {
    props["minValue"] = f.minValue;
  }

  if (f.maxValue != undefined && f.maxValue != 0) {
    props["maxValue"] = f.maxValue;
  }

  if (f.objectsList != undefined && f.objectsList != []) {
    props["objects"] = f.objectsList;
  }

  if (toponim != undefined) {
    props["toponim"] = toponim;
  }

  if (f.hint != undefined && f.hint != "") {
    props["hint"] = f.hint;
  }

  if (f.postfix != undefined && f.postfix != "") {
    props["postfix"] = f.postfix;
  }

  return {
    idx,
    component,
    props,
  };
}

function getFilterSpecificProps(f_type) {
  switch (f_type) {
    // Map for choosing location and search radius.
    case 8:
      return {
        withRadius: true,
      };
    // Address select
    case 15:
      return {
        withRadius: false,
      };
    default:
      return {};
  }
}
