/**
 * Parse `defaultValue` prop в `defaultCoords` и `defaultRadius`.
 * Должен использоваться после GenericFilterMixin.
 */

import { ref, reactive } from "vue";

// TODO: тестировать
/**
 * Создать point, radius из geoUri
 * @param {String} uri - geo uri.
 *      Например: "point=geo:37,-122;u=10&radius=10"
 * @returns {Object} - объект с полями point и radius.
 * Для uri из примера выше может быть:
 * ```
 * {
 *   point: { lat: 37, lon: -122 }
 *   radius: 10
 * }
 * ```
 */
function parseGeoUri(uri) {
  if (uri == undefined) {
    return {
      coords: { lat: undefined, lon: undefined },
      radius: undefined,
    };
  }

  if (uri.length == 0) {
    return {
      coords: { lat: undefined, lon: undefined },
      radius: undefined,
    };
  }

  uri = decodeURIComponent(uri);

  var entries = [];

  for (var e of uri.split("&")) {
    var name = e.split("=")[0];
    var value = e.replace(name + "=", "");
    entries.push({ name, value });
  }

  var coords = { lat: undefined, lon: undefined };

  var point = entries.find((e) => e["name"] == "point");
  if (point != undefined) {
    var coordsStr = point.value.replace("geo:", "").replace(/;u(.*)/, "");
    var coordsArr = coordsStr.split(",");

    if (coordsArr.length == 2) {
      var lat = Number(coordsArr[0]);
      var lon = Number(coordsArr[1]);

      if (!isNaN(lat) && !isNaN(lon)) {
        coords = {
          lat,
          lon,
        };
      }
    }
  }

  var radius = undefined;
  var radiusObj = entries.find((e) => e["name"] == "radius");
  if (radiusObj != undefined) {
    var radiusNum = Number(radiusObj.value);
    if (!isNaN(radiusNum)) {
      radius = radiusNum;
    }
  }

  return {
    coords,
    radius,
  };
}

function toGeoUri(lat, lon, radius) {
  // "point=geo:37.786971,-122.399677;u=10&radius=10"
  if (lat == undefined || lon == undefined) {
    return "";
  }

  var point = "point=" + encodeURIComponent("geo:" + lat + "," + lon + ";u=0");

  if (radius == undefined) {
    radius = 0;
  }
  var radiusStr = "&radius=" + encodeURIComponent(radius);

  return point + radiusStr;
}

export default {
  props: {
    defaultValue: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  mounted() {
    var { coords, radius } = parseGeoUri(this.defaultValue);

    this.defaultCoords.lat = coords.lat;
    this.defaultCoords.lon = coords.lon;
    this.defaultRadius = radius;
  },
  data() {
    return {
      defaultCoords: reactive({
        lat: undefined,
        lon: undefined,
      }),
      defaultRadius: ref(undefined),
      selectedCoords: reactive({
        lat: undefined,
        lon: undefined,
        // радиус в км
        radius: undefined,
      }),
    };
  },
  watch: {
    defaultValue() {
      var { coords, radius } = parseGeoUri(this.defaultValue);
      
      this.defaultCoords.lat = coords.lat;
      this.defaultCoords.lon = coords.lon;
      this.defaultRadius = radius;
    },
    selectedCoords: {
      handler(new_coords) {
        this.$emit(
          "on-value-selected",
          this.filterId,
          toGeoUri(new_coords.lat, new_coords.lon, new_coords.radius)
        );
      },
      deep: true,
    },
  },
};
