var _ = require("lodash");
import { grpc } from "@improbable-eng/grpc-web";

import { ToponimRequest } from "./proto/photos_pb.js";
import { ApiService } from "./proto/photos_pb_service.js";
import { getMeta } from "./utils/meta.js";

// Пустой топоним, который почему-то возвращает API
const EMPTY_TOPONIM = {
  country: "Россия",
  countryIsoCode: "RU",
  region: "",
  regionFiasId: "",
  regionWithType: "",
  area: "",
  areaFiasId: "",
  areaWithType: "",
  cityFiasId: "",
  cityTypeFull: "",
  cityDistrictFiasId: "",
  cityDistrict: "",
  settlementFiasId: "",
  settlementWithType: "",
  streetFiasId: "",
  street: "",
  houseFiasId: "",
  house: "",
  block: "",
  flat: "",
  fiasId: "",
  geoLat: "",
  geoLon: "",
  fiasLevel: "",
  humanName: "",
  metro: "",
  id: "",
  cityHumanName: "",
  radius: 0,
};

/**
 * Получить список топонимов городов и областей для запроса
 * @param {string} query - поисковый запрос, для которого нужно вернуть топонимы
 * @returns {Object[]} - топонимы, как в proto файле
 */
export function getCitiesToponims(query) {
  let request = new ToponimRequest();

  request.setName(query);
  // получить только от региона до города
  request.setMinFiasLevel(1);
  request.setMaxFiasLevel(5);

  return new Promise((resolve, reject) => {
    grpc.unary(ApiService.GetToponims, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error ApiService.GetToponims",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        obj = cleanToponimObj(obj);

        return resolve(obj.toponimsList);
      },
    });
  });
}

/**
 * Получить список топонимов для запроса
 * @param {string} query - поисковый запрос, для которого нужно вернуть топонимы
 * @returns {Object[]} - топонимы, как в proto файле
 */
export function getAllToponims(query) {
  let request = new ToponimRequest();

  request.setName(query);

  return new Promise((resolve) => {
    grpc.unary(ApiService.GetToponims, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          var e = new Error("error ApiService.GetToponims");
          e.statusMessage = statusMessage;
          e.message = message;
          throw e;
        }

        var obj = message.toObject();

        obj = cleanToponimObj(obj);

        resolve(obj.toponimsList);
      },
    });
  });
}

function cleanToponimObj(obj) {
  var empty_idx = obj.toponimsList.findIndex((t) =>
    _.isEqual(t, EMPTY_TOPONIM)
  );
  if (empty_idx != -1) {
    obj.toponimsList.splice(empty_idx, 1);
  }

  return obj;
}
