<template>
  <div :class="sidebarClass">
    <div
      ref="sidebarWrapper"
      class="scroll-wrapper sidebar"
      @keydown.esc="closeSidebar"
    >
      <div ref="sidebarContents" @click="sidebarClicked">
        <focus-trap
          v-model:active="holdFocusComputed"
          :initial-focus="() => this.$refs.sidebarFocusRef"
        >
          <div class="scroll-content scroll-scrolly_visible">
            <div class="user-details text-center">
              <div
                aria-hidden="true"
                :style="{ backgroundImage: 'url(' + userAvatarUrl + ')' }"
                class="avatar photo"
                width="80"
                height="80"
              />
              <div class="author-details">
                <h1
                  class="username"
                  :aria-label="
                    t('profile_sidebar.username_alt', {
                      username: displayUsername,
                    })
                  "
                >
                  {{ displayUsername }}
                </h1>

                <router-link
                  v-if="$store.state.user.userId"
                  :to="{
                    name: 'UserDetails',
                    params: { id: $store.state.user.userId },
                  }"
                  custom
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    :active="isActive"
                    :href="href"
                    @click="navigate"
                    :title="t('profile_sidebar.view_profile')"
                    ref="sidebarFocusRef"
                    >{{ t("profile_sidebar.view_profile") }}</a
                  >
                </router-link>
              </div>

              <SidebarButtons />
            </div>

            <h2 class="author-sidebar-title" id="sidebar-main-section-title">
              {{ t("profile_sidebar.main_section.title") }}
            </h2>
            <ul
              class="list-unstyled author-sidebar-list"
              aria-labelledby="sidebar-main-section-title"
            >
              <router-link
                :to="{ name: 'ProfileDashboard' }"
                custom
                v-slot="{ isActive, href, navigate }"
              >
                <li :class="isActive ? 'active' : ''">
                  <a :href="href" @click="navigate">
                    <i class="aficon-tachometer-alt"></i>
                    <span>
                      {{ t("profile_sidebar.main_section.dashboard") }}
                    </span>
                  </a>
                </li>
              </router-link>
            </ul>

            <h2 class="author-sidebar-title" id="sidebar-ads-section-title">
              {{ t("profile_sidebar.profile.title") }}
            </h2>
            <ul
              class="list-unstyled author-sidebar-list"
              aria-labelledby="sidebar-ads-section-title"
            >
              <router-link
                :to="{ name: 'ProfileAds' }"
                custom
                v-slot="{ isActive, href, navigate }"
              >
                <li :class="isActive ? 'active' : ''">
                  <a :href="href" @click="navigate">
                    <i class="aficon-clone"></i>
                    <span>
                      {{ t("profile_sidebar.profile.your_ads") }}
                    </span>
                  </a>
                </li>
              </router-link>

              <router-link
                :to="{ name: 'ProfileChangePassword' }"
                custom
                v-slot="{ isActive, href, navigate }"
              >
                <li :class="isActive ? 'active' : ''">
                  <a :href="href" @click="navigate">
                    <i class="aficon-lock-alt"></i>
                    <span>
                      {{ t("profile_sidebar.profile.change_password") }}
                    </span>
                  </a>
                </li>
              </router-link>
            </ul>

            <!-- 
            <li class="">
              <a
                href="http://127.0.0.1:8080/author/oiufih8034/?screen=favorites"
              >
                <i class="aficon-heart-o"></i>
                <span>Favorite Ads</span>
              </a>
            </li>
            <li class="">
              <a
                href="http://127.0.0.1:8080/author/oiufih8034/?screen=auctions"
              >
                <i class="aficon-stopwatch"></i>
                <span>Auctions</span>
              </a>
            </li>
            <li class="author-sidebar-title">
              feedback
            </li>
            <li class="">
              <a href="http://127.0.0.1:8080/author/oiufih8034/?screen=reviews">
                <i class="aficon-star-o"></i>
                <span>Reviews</span>
              </a>
            </li>

            <li class="author-sidebar-title">
              transactions
            </li>
            <li class="">
              <a href="http://127.0.0.1:8080/author/oiufih8034/?screen=acc_pay">
                <i class="aficon-plus-circle"></i>
                <span>Packages</span>
              </a>
            </li>

            <li class="">
              <a
                href="http://127.0.0.1:8080/author/oiufih8034/?screen=invoices"
              >
                <i class="aficon-list-alt"></i>
                <span>Invoices</span>
              </a>
            </li> -->
          </div>
        </focus-trap>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useScreen } from "vue-screen";
import { FocusTrap } from "focus-trap-vue";
import { useI18n } from "vue-i18n";
import { mapGetters } from "vuex";

import SidebarButtons from "./components/SidebarButtons.vue";

export default {
  name: "ProfileSidebar",
  components: { FocusTrap, SidebarButtons },
  props: {
    sidebarVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    /** Была нажата кнопка закрыть.
     * Нужно установить sidebarClosed = true.
     * Параметров нет.
     */
    "close-clicked",
  ],
  setup() {
    const sidebarWrapper = ref(null);
    const screen = useScreen();
    const { t } = useI18n();

    return {
      t,
      sidebarWrapper,
      // always use `setIsVisible` to set isVisible
      // so inert property is updated!
      isVisible: ref(false),
      screen,
    };
  },
  async mounted() {
    if (!this.isSSR) {
      await import("wicg-inert");
    }

    this.setIsVisible(this.sidebarVisible);
    onClickOutside(this.sidebarWrapper, () => this.closeSidebar());
  },
  watch: {
    sidebarVisible() {
      this.setIsVisible(this.sidebarVisible);
    },
    async "screen.width"() {
      if (this.sidebarAlwaysOn) {
        this.setIsVisible(false);
      } else {
        this.setIsVisible(false);
      }
    },
  },
  computed: {
    ...mapGetters({ userAvatarUrl: "user/avatarThumb" }),
    sidebarClass() {
      return this.isVisible ? "author-sidebar open" : "author-sidebar";
    },
    sidebarAlwaysOn() {
      return this.screen.width > 1024;
    },
    holdFocusComputed: {
      get() {
        return this.isVisible && !this.sidebarAlwaysOn;
      },
      set() {},
    },
    displayUsername() {
      return this.$store.state.user.username
        ? this.$store.state.user.username
        : this.t("profile_sidebar.default_username");
    },
  },
  methods: {
    closeSidebar() {
      if (this.sidebarAlwaysOn) {
        return;
      }

      if (!this.isVisible) {
        return;
      }

      this.$emit("close-clicked");
    },
    sidebarClicked(ev) {
      if (!this.holdFocusComputed) {
        return;
      }

      switch (ev.target.tagName) {
        case "A":
        case "BUTTON": {
          this.setIsVisible(false);
          break;
        }
        case "SPAN":
        case "I": {
          if (
            ev.target.className.includes("aficon-") ||
            ev.target.classList.contains("icon-caption") ||
            ev.target.classList.contains("unread-badge") ||
            ev.target.parentElement.tagName == "A"
          ) {
            this.setIsVisible(false);
          }
          break;
        }
      }
    },
    async setIsVisible(visible) {
      if (this.sidebarAlwaysOn) {
        await this.$refs.sidebarContents.removeAttribute("inert");
      } else {
        if (visible) {
          await this.$refs.sidebarContents.removeAttribute("inert");
        } else {
          await this.$refs.sidebarContents.setAttribute("inert", "");
        }
      }

      if (!visible) {
        this.$emit("close-clicked");
      }

      this.isVisible = visible;
    },
  },
};
</script>

<style scoped>
.scroll-content {
  max-height: 100vh;
}

.sidebar {
  position: relative;
}

.avatar {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 80px;
  height: 80px;
  margin: 0 auto 15px auto;
  border-radius: 50%;
}

.username {
  color: #fff;
  margin-top: 0px;
  font-size: 16px;
  margin-bottom: -2px;
}

.author-sidebar-title {
  margin-bottom: 20px;
}
</style>
