<template>
  <ul class="lines">
    <p v-if="lines.length == 0">
      {{ t("filters.filter_metro_select.no_lines") }}
    </p>

    <li
      :key="l.lineId"
      v-for="l in lines"
      class="line"
      :style="{ borderColor: '#' + l.color.replace('#', '') }"
    >
      <span
        class="line-border-start"
        :style="{ backgroundColor: '#' + l.color.replace('#', '') }"
        aria-hidden="true"
      ></span>
      <label
        class="line-name"
        :aria-label="t('filters.filter_metro_select.line', { name: l.name })"
        >{{ l.name }}</label
      >
      <ul class="stations-list">
        <li
          :key="st.stationId"
          v-for="st in l.stations"
          tabindex="0"
          role="checkbox"
          :aria-checked="selectedValues.indexOf(st.stationId) != -1"
          :aria-label="st.name"
          @keydown.enter="clickRef(String(inputId + '_' + st.stationId))"
        >
          <div class="label styled-checkbox">
            <input
              type="checkbox"
              :name="inputId"
              :value="st.stationId"
              :id="inputId + '_' + st.stationId"
              :ref="String(inputId + '_' + st.stationId)"
              v-model="selectedValues"
            />

            <label :for="inputId + '_' + st.stationId"> {{ st.name }} </label>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { useI18n } from "vue-i18n";

import ClickRefMixin from "../mixins/ClickRefMixin";

export default {
  name: "MetroLines",
  props: {
    /** Список всех линий
     *  - lines - Object[] - список линий со станциями
     *   - lineId - id линии
     *   - name - название линии
     *   - color - цвет линии
     *   - stations - Object[] - список станций
     *     - stationId - id станции
     *     - name - название станции
     */
    lines: Array,
    /**
     * Выбранные станции метро.
     * Если станция метро была выбрана, $emit('selected-station')
     */
    selected: Array,
  },
  mixins: [ClickRefMixin],
  emits: [
    /**
     * Была выбрана станция метро
     * Параметры:
     *  1. {Array[String]} id выбранных станций метро. Вернет все станции каждый раз.
     */
    "selected-station",
  ],
  setup() {
    const { t } = useI18n();

    return {
      t,
      inputId: uuidv4(),
    };
  },
  computed: {
    selectedValues: {
      get() {
        return this.selected;
      },
      set(v) {
        this.$emit("selected-station", v);
      },
    },
  },
};
</script>

<style scoped>
li {
  list-style: none;
}

.lines {
  width: 100%;
  margin: 0;
  padding: 5px;
  text-align: start;
}

.line {
  padding-left: 6px;
  border-left: 6px solid;
  position: relative;
  margin-top: 10px;
  margin-bottom: 20px;
}

.line-border-start {
  width: 16px;
  height: 16px;
  display: block;
  left: -11px;
  top: -2px;
  position: absolute;
  border-radius: 50%;
}

.line-name {
  color: black;
  font-size: 17px;
  margin-left: 6px;
  position: relative;
  top: -5px;
}

.stations-list {
  margin: 0;
  padding: 0;
  padding-left: 7px;
  margin-top: -5px;
}
</style>
