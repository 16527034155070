// package: api.v1.photos
// file: photos.proto

var photos_pb = require("./photos_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ApiService = (function () {
  function ApiService() {}
  ApiService.serviceName = "api.v1.photos.ApiService";
  return ApiService;
}());

ApiService.SetPhotoPosition = {
  methodName: "SetPhotoPosition",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.PositionRequest,
  responseType: photos_pb.PositionResponse
};

ApiService.DeletePhoto = {
  methodName: "DeletePhoto",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.PhotoRequest,
  responseType: photos_pb.DeleteResponse
};

ApiService.DeletePhotos = {
  methodName: "DeletePhotos",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.PhotoRequest,
  responseType: photos_pb.DeleteResponse
};

ApiService.GetUserPhotos = {
  methodName: "GetUserPhotos",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.PhotoRequest,
  responseType: photos_pb.Photos
};

ApiService.GetUserDialogs = {
  methodName: "GetUserDialogs",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.UserDialogRequest,
  responseType: photos_pb.Dialogs
};

ApiService.GetDialog = {
  methodName: "GetDialog",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.DialogRequest,
  responseType: photos_pb.Dialog
};

ApiService.SetViewedDialog = {
  methodName: "SetViewedDialog",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.DialogRequest,
  responseType: photos_pb.Dialog
};

ApiService.WriteMessage = {
  methodName: "WriteMessage",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.MessageRequest,
  responseType: photos_pb.Dialog
};

ApiService.RemoveMessage = {
  methodName: "RemoveMessage",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.RemoveMessageRequest,
  responseType: photos_pb.RemoveMessageResponse
};

ApiService.RemoveParticipation = {
  methodName: "RemoveParticipation",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.RemoveParticipationRequest,
  responseType: photos_pb.RemoveParticipationResponse
};

ApiService.AddToFavouritesList = {
  methodName: "AddToFavouritesList",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.AdsRequest,
  responseType: photos_pb.AdId
};

ApiService.GetFavouritesList = {
  methodName: "GetFavouritesList",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.AdsRequest,
  responseType: photos_pb.TinyAdsResponse
};

ApiService.GetFavouritesListCompact = {
  methodName: "GetFavouritesListCompact",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.AdsRequest,
  responseType: photos_pb.CompactFavouritesResponse
};

ApiService.RemoveFromFavouritesList = {
  methodName: "RemoveFromFavouritesList",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.AdsRequest,
  responseType: photos_pb.AdId
};

ApiService.DropFavouritesList = {
  methodName: "DropFavouritesList",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.AdsRequest,
  responseType: photos_pb.AdIds
};

ApiService.GetViewList = {
  methodName: "GetViewList",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.ViewRequest,
  responseType: photos_pb.AdIds
};

ApiService.AddView = {
  methodName: "AddView",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.ViewRequest,
  responseType: photos_pb.AdId
};

ApiService.DropViewed = {
  methodName: "DropViewed",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.ViewRequest,
  responseType: photos_pb.AdIds
};

ApiService.GetAdStat = {
  methodName: "GetAdStat",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.StatRequest,
  responseType: photos_pb.StatResponse
};

ApiService.GetBlackList = {
  methodName: "GetBlackList",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.BlackListRequest,
  responseType: photos_pb.TinyUsers
};

ApiService.AddToBlackList = {
  methodName: "AddToBlackList",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.BlackListRequest,
  responseType: photos_pb.TinyUsers
};

ApiService.RemoveFromBlackList = {
  methodName: "RemoveFromBlackList",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.BlackListRequest,
  responseType: photos_pb.TinyUsers
};

ApiService.GetToponims = {
  methodName: "GetToponims",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.ToponimRequest,
  responseType: photos_pb.Toponims
};

ApiService.GetSubways = {
  methodName: "GetSubways",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.GetSubwaysRequest,
  responseType: photos_pb.Lines
};

ApiService.AssignToponimToSubject = {
  methodName: "AssignToponimToSubject",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.AssignLocationRequest,
  responseType: photos_pb.AssignResponse
};

ApiService.GetAds = {
  methodName: "GetAds",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.AdsRequest,
  responseType: photos_pb.AdsResponse
};

ApiService.SetAdState = {
  methodName: "SetAdState",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.SetStateRequest,
  responseType: photos_pb.Ad
};

ApiService.GetUserReviews = {
  methodName: "GetUserReviews",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.TinyUser,
  responseType: photos_pb.Reviews
};

ApiService.GetReview = {
  methodName: "GetReview",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.ReviewRequest,
  responseType: photos_pb.Review
};

ApiService.SaveReview = {
  methodName: "SaveReview",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.Review,
  responseType: photos_pb.Review
};

ApiService.SaveFeedbackToReview = {
  methodName: "SaveFeedbackToReview",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.Feedback,
  responseType: photos_pb.Review
};

ApiService.HideReview = {
  methodName: "HideReview",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.ReviewRequest,
  responseType: photos_pb.Review
};

ApiService.HideFeedback = {
  methodName: "HideFeedback",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.ReviewRequest,
  responseType: photos_pb.Review
};

ApiService.DeleteAvatar = {
  methodName: "DeleteAvatar",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.TinyUser,
  responseType: photos_pb.TinyUser
};

ApiService.AddComplain = {
  methodName: "AddComplain",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.ComplainRequest,
  responseType: photos_pb.Ad
};

ApiService.GetSimilarAds = {
  methodName: "GetSimilarAds",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.AdsRequest,
  responseType: photos_pb.AdsResponse
};

ApiService.GetSimilarTinyAds = {
  methodName: "GetSimilarTinyAds",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.AdsRequest,
  responseType: photos_pb.TinyAdsResponse
};

ApiService.GetPage = {
  methodName: "GetPage",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.PageRequest,
  responseType: photos_pb.Page
};

ApiService.EditAd = {
  methodName: "EditAd",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.Draft,
  responseType: photos_pb.Draft
};

ApiService.CreateAd = {
  methodName: "CreateAd",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.Draft,
  responseType: photos_pb.Draft
};

ApiService.GetAd = {
  methodName: "GetAd",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.AdRequest,
  responseType: photos_pb.Ad
};

ApiService.GetLanguages = {
  methodName: "GetLanguages",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.LanguageRequest,
  responseType: photos_pb.Languages
};

ApiService.GetCitizenships = {
  methodName: "GetCitizenships",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.CitizenshipRequest,
  responseType: photos_pb.Citizenships
};

ApiService.GetCountries = {
  methodName: "GetCountries",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.CountryRequest,
  responseType: photos_pb.Countries
};

ApiService.GetNewMessagesCount = {
  methodName: "GetNewMessagesCount",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.MessagesCountRequest,
  responseType: photos_pb.MessagesCountResponse
};

ApiService.GetSubjectToponim = {
  methodName: "GetSubjectToponim",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.SubjectToponimRequest,
  responseType: photos_pb.Toponim
};

ApiService.GenerateRecoveryToken = {
  methodName: "GenerateRecoveryToken",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.RecoveryRequest,
  responseType: photos_pb.RecoveryAnswer
};

ApiService.LoginWithRecoveryToken = {
  methodName: "LoginWithRecoveryToken",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.RecoveryToken,
  responseType: photos_pb.LoginResult
};

ApiService.GetSettings = {
  methodName: "GetSettings",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.SettingRequest,
  responseType: photos_pb.Settings
};

ApiService.GetMetrics = {
  methodName: "GetMetrics",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.MetricRequest,
  responseType: photos_pb.Metrics
};

ApiService.GetStatsByCategory = {
  methodName: "GetStatsByCategory",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: photos_pb.CategoryStatRequest,
  responseType: photos_pb.CategoryStatResponse
};

exports.ApiService = ApiService;

function ApiServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ApiServiceClient.prototype.setPhotoPosition = function setPhotoPosition(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.SetPhotoPosition, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.deletePhoto = function deletePhoto(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.DeletePhoto, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.deletePhotos = function deletePhotos(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.DeletePhotos, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getUserPhotos = function getUserPhotos(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetUserPhotos, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getUserDialogs = function getUserDialogs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetUserDialogs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getDialog = function getDialog(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetDialog, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.setViewedDialog = function setViewedDialog(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.SetViewedDialog, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.writeMessage = function writeMessage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.WriteMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.removeMessage = function removeMessage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.RemoveMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.removeParticipation = function removeParticipation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.RemoveParticipation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.addToFavouritesList = function addToFavouritesList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.AddToFavouritesList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getFavouritesList = function getFavouritesList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetFavouritesList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getFavouritesListCompact = function getFavouritesListCompact(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetFavouritesListCompact, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.removeFromFavouritesList = function removeFromFavouritesList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.RemoveFromFavouritesList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.dropFavouritesList = function dropFavouritesList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.DropFavouritesList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getViewList = function getViewList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetViewList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.addView = function addView(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.AddView, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.dropViewed = function dropViewed(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.DropViewed, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getAdStat = function getAdStat(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetAdStat, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getBlackList = function getBlackList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetBlackList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.addToBlackList = function addToBlackList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.AddToBlackList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.removeFromBlackList = function removeFromBlackList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.RemoveFromBlackList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getToponims = function getToponims(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetToponims, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getSubways = function getSubways(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetSubways, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.assignToponimToSubject = function assignToponimToSubject(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.AssignToponimToSubject, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getAds = function getAds(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetAds, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.setAdState = function setAdState(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.SetAdState, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getUserReviews = function getUserReviews(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetUserReviews, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getReview = function getReview(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetReview, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.saveReview = function saveReview(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.SaveReview, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.saveFeedbackToReview = function saveFeedbackToReview(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.SaveFeedbackToReview, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.hideReview = function hideReview(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.HideReview, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.hideFeedback = function hideFeedback(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.HideFeedback, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.deleteAvatar = function deleteAvatar(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.DeleteAvatar, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.addComplain = function addComplain(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.AddComplain, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getSimilarAds = function getSimilarAds(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetSimilarAds, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getSimilarTinyAds = function getSimilarTinyAds(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetSimilarTinyAds, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getPage = function getPage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetPage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.editAd = function editAd(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.EditAd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.createAd = function createAd(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.CreateAd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getAd = function getAd(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetAd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getLanguages = function getLanguages(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetLanguages, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getCitizenships = function getCitizenships(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetCitizenships, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getCountries = function getCountries(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetCountries, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getNewMessagesCount = function getNewMessagesCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetNewMessagesCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getSubjectToponim = function getSubjectToponim(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetSubjectToponim, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.generateRecoveryToken = function generateRecoveryToken(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GenerateRecoveryToken, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.loginWithRecoveryToken = function loginWithRecoveryToken(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.LoginWithRecoveryToken, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getSettings = function getSettings(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetSettings, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getMetrics = function getMetrics(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetMetrics, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.getStatsByCategory = function getStatsByCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetStatsByCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ApiServiceClient = ApiServiceClient;

