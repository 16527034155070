// package: api.v1.ad
// file: ad.proto

var ad_pb = require("./ad_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var PublicAdService = (function () {
  function PublicAdService() {}
  PublicAdService.serviceName = "api.v1.ad.PublicAdService";
  return PublicAdService;
}());

PublicAdService.SearchAds = {
  methodName: "SearchAds",
  service: PublicAdService,
  requestStream: false,
  responseStream: false,
  requestType: ad_pb.SearchAdsRequest,
  responseType: ad_pb.SearchAdsResponse
};

PublicAdService.CountSearchResults = {
  methodName: "CountSearchResults",
  service: PublicAdService,
  requestStream: false,
  responseStream: false,
  requestType: ad_pb.CountSearchResultsRequest,
  responseType: ad_pb.CountSearchResultsResponse
};

PublicAdService.GetSearchHints = {
  methodName: "GetSearchHints",
  service: PublicAdService,
  requestStream: false,
  responseStream: false,
  requestType: ad_pb.GetSearchHintsRequest,
  responseType: ad_pb.GetSearchHintsResponse
};

PublicAdService.GetRecommendedAds = {
  methodName: "GetRecommendedAds",
  service: PublicAdService,
  requestStream: false,
  responseStream: false,
  requestType: ad_pb.GetRecommendedAdsRequest,
  responseType: ad_pb.GetRecommendedAdsResponse
};

PublicAdService.AddView = {
  methodName: "AddView",
  service: PublicAdService,
  requestStream: false,
  responseStream: false,
  requestType: ad_pb.ViewRequest,
  responseType: ad_pb.ViewResponse
};

PublicAdService.AddWebview = {
  methodName: "AddWebview",
  service: PublicAdService,
  requestStream: false,
  responseStream: false,
  requestType: ad_pb.AddWebviewRequest,
  responseType: ad_pb.AddWebviewResponse
};

PublicAdService.GetViewList = {
  methodName: "GetViewList",
  service: PublicAdService,
  requestStream: false,
  responseStream: false,
  requestType: ad_pb.ViewRequest,
  responseType: ad_pb.ViewResponse
};

PublicAdService.DropViewed = {
  methodName: "DropViewed",
  service: PublicAdService,
  requestStream: false,
  responseStream: false,
  requestType: ad_pb.ViewRequest,
  responseType: ad_pb.ViewResponse
};

PublicAdService.AddToFavouritesList = {
  methodName: "AddToFavouritesList",
  service: PublicAdService,
  requestStream: false,
  responseStream: false,
  requestType: ad_pb.FavouriteRequest,
  responseType: ad_pb.FavouriteResponse
};

PublicAdService.GetFavouritesList = {
  methodName: "GetFavouritesList",
  service: PublicAdService,
  requestStream: false,
  responseStream: false,
  requestType: ad_pb.FavouriteRequest,
  responseType: ad_pb.FavouriteResponse
};

PublicAdService.RemoveFromFavouritesList = {
  methodName: "RemoveFromFavouritesList",
  service: PublicAdService,
  requestStream: false,
  responseStream: false,
  requestType: ad_pb.FavouriteRequest,
  responseType: ad_pb.FavouriteResponse
};

PublicAdService.DropFavouritesList = {
  methodName: "DropFavouritesList",
  service: PublicAdService,
  requestStream: false,
  responseStream: false,
  requestType: ad_pb.FavouriteRequest,
  responseType: ad_pb.FavouriteResponse
};

PublicAdService.GetSavedSearches = {
  methodName: "GetSavedSearches",
  service: PublicAdService,
  requestStream: false,
  responseStream: false,
  requestType: ad_pb.GetSavedSearchesRequest,
  responseType: ad_pb.GetSavedSearchesResponse
};

PublicAdService.UpdateSavedSearch = {
  methodName: "UpdateSavedSearch",
  service: PublicAdService,
  requestStream: false,
  responseStream: false,
  requestType: ad_pb.UpdateSavedSearchRequest,
  responseType: ad_pb.UpdateSavedSearchResponse
};

PublicAdService.DeleteSavedSearch = {
  methodName: "DeleteSavedSearch",
  service: PublicAdService,
  requestStream: false,
  responseStream: false,
  requestType: ad_pb.DeleteSavedSearchRequest,
  responseType: ad_pb.DeleteSavedSearchResponse
};

PublicAdService.GetLastSearchQueries = {
  methodName: "GetLastSearchQueries",
  service: PublicAdService,
  requestStream: false,
  responseStream: false,
  requestType: ad_pb.GetLastSearchQueriesRequest,
  responseType: ad_pb.GetLastSearchQueriesResponse
};

exports.PublicAdService = PublicAdService;

function PublicAdServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

PublicAdServiceClient.prototype.searchAds = function searchAds(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicAdService.SearchAds, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicAdServiceClient.prototype.countSearchResults = function countSearchResults(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicAdService.CountSearchResults, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicAdServiceClient.prototype.getSearchHints = function getSearchHints(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicAdService.GetSearchHints, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicAdServiceClient.prototype.getRecommendedAds = function getRecommendedAds(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicAdService.GetRecommendedAds, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicAdServiceClient.prototype.addView = function addView(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicAdService.AddView, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicAdServiceClient.prototype.addWebview = function addWebview(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicAdService.AddWebview, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicAdServiceClient.prototype.getViewList = function getViewList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicAdService.GetViewList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicAdServiceClient.prototype.dropViewed = function dropViewed(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicAdService.DropViewed, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicAdServiceClient.prototype.addToFavouritesList = function addToFavouritesList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicAdService.AddToFavouritesList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicAdServiceClient.prototype.getFavouritesList = function getFavouritesList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicAdService.GetFavouritesList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicAdServiceClient.prototype.removeFromFavouritesList = function removeFromFavouritesList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicAdService.RemoveFromFavouritesList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicAdServiceClient.prototype.dropFavouritesList = function dropFavouritesList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicAdService.DropFavouritesList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicAdServiceClient.prototype.getSavedSearches = function getSavedSearches(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicAdService.GetSavedSearches, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicAdServiceClient.prototype.updateSavedSearch = function updateSavedSearch(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicAdService.UpdateSavedSearch, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicAdServiceClient.prototype.deleteSavedSearch = function deleteSavedSearch(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicAdService.DeleteSavedSearch, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicAdServiceClient.prototype.getLastSearchQueries = function getLastSearchQueries(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicAdService.GetLastSearchQueries, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.PublicAdServiceClient = PublicAdServiceClient;

