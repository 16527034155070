<template>
  <wrapper-collapsible
    :filterNameText="name"
    :filterValueText="selectedCategoryName"
    :canBeApplied="canApply"
    @on-apply-clicked="applyUnappliedCategory"
    @on-close-clicked="clearUnapplied"
  >
    <CategorySelector
      @on-category-selected="changeUnappliedCategory"
      :selectedCategoryId="selectedCategoryId"
    />
  </wrapper-collapsible>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import GenericFilterMixin from "../mixins/GenericFilterMixin";

import WrapperCollapsible from "./WrapperCollapsible.vue";
import CategorySelector from "./CategorySelector.vue";

import { getCategory as getCategoryApi } from "api/categories.js";

export default {
  name: "FilterCategorySelect",
  components: {
    CategorySelector,
    WrapperCollapsible,
  },
  props: {
    name: {
      type: String,
      required: false,
      default() {
        const { t } = useI18n();
        return t("filters.category_select.category");
      },
    },
  },
  mixins: [GenericFilterMixin],
  setup() {
    const { t } = useI18n();

    return {
      t,
      // Название выбранной категории
      selectedCategoryName: ref(""),
      // Id новой выбранной категории
      selectedCategoryId: ref(undefined),
      // Id категории, которую выбрали, но пока не нажали применить
      unappliedCategoryId: ref(undefined),
    };
  },
  async mounted() {
    await this.updateDefaultValue();
    await this.updateSelectedCategoryName();
  },
  watch: {
    async defaultValue() {
      await this.updateDefaultValue();
    },
    async selectedCategoryId() {
      await this.updateSelectedCategoryName();
    },
  },
  computed: {
    canApply() {
      return (
        this.unappliedCategoryId != undefined &&
        this.unappliedCategoryId != null
      );
    },
  },
  methods: {
    async updateSelectedCategoryName() {
      if (
        this.selectedCategoryId == "" ||
        this.selectedCategoryId == undefined ||
        this.selectedCategoryId == null
      ) {
        this.selectedCategoryName = "";
        return;
      }

      try {
        var c = await getCategoryApi(this.selectedCategoryId);
        this.selectedCategoryName = c.name;
      } catch (e) {
        console.error("Error getting categories:", e);

        this.selectedCategoryName = this.t(
          "filters.filter_category_select.category_selected"
        );
      }
    },
    changeUnappliedCategory(id) {
      this.unappliedCategoryId = id;
    },
    applyUnappliedCategory() {
      this.selectedCategoryId = this.unappliedCategoryId;
      this.$emit("on-value-selected", this.filterId, this.selectedCategoryId);
    },
    clearUnapplied() {
      this.unappliedCategoryId = undefined;
    },
    async updateDefaultValue() {
      this.selectedCategoryId = this.defaultValue;
      await this.updateSelectedCategoryName();
    },
  },
};
</script>

<style scoped></style>
