<template>
  <div class="kc-row-container  kc-container">
    <div class="kc-wrap-columns">
      <div class="kc-elm kc-css-257109 kc_col-sm-2 kc_column kc_col-sm-2">
        <div class="kc-col-container"></div>
      </div>
      <div class="kc-elm kc-css-260347 kc_col-sm-8 kc_column kc_col-sm-8">
        <div class="kc-col-container">
          <div class="af-title text-center">
            <h1 class="af-heading">
              <span style="color: #fff">{{ t("index.title") }}</span>
            </h1>
            <p>
              <span style="color: #fff"> {{ t("index.description") }} </span>
            </p>
          </div>
          <div
            class="kc-elm kc-css-505080"
            style="height: 35px; clear: both; width:100%;"
          ></div>
          <SearchBar
            @confirmed-input="
              (t, obj) => {
                $emit('start-search', t, obj);
              }
            "
          />
          <div
            class="kc-elm kc-css-804029"
            style="height: 25px; clear: both; width:100%;"
          ></div>
        </div>
      </div>
      <div class="kc-elm kc-css-47509 kc_col-sm-2 kc_column kc_col-sm-2">
        <div class="kc-col-container"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

import SearchBar from "./SearchBar.vue";

export default {
  name: "SearchBig",
  components: {
    SearchBar,
  },
  emits: [
    /** Пользователь нажал кнопку "Найти" или ввод в строке поиска.
     *  Параметры:
     *   1. текст поиска
     *   2. поисковый запрос. может быть undefined.
     */
    "start-search",
  ],
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
};
</script>

<style scoped></style>
