<template>
  <wrapper-static :filterNameText="name">
    <div>
      <input
        v-model="inputValue"
        type="text"
        :name="inputId"
        :placeholder="hint"
        :class="inputValid ? '' : 'invalid-value'"
        @blur="inputBlurred"
      />
      <div class="postfix-wrap">
        <span v-if="showPostfix" class="postfix"> {{ postfix }} </span>
      </div>
    </div>
  </wrapper-static>
</template>

<script>
import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import { useI18n } from "vue-i18n";

import GenericFilterMixin from "../mixins/GenericFilterMixin";

import WrapperStatic from "./WrapperStatic.vue";

export default {
  name: "FilterTextBoxNumber",
  components: {
    WrapperStatic,
  },
  props: {
    /** Название фильтра */
    name: {
      type: String,
      required: true,
    },
    /** Подсказка для фильтра */
    hint: {
      type: String,
      required: false,
      default: "",
    },
    /** Минимальное значение, которое можно ввести */
    minValue: {
      type: Number,
      required: false,
    },
    /** Максимальное значение, которое можно ввести */
    maxValue: {
      type: Number,
      required: false,
    },
    postfix: {
      type: String,
      required: false,
      default: undefined,
    },
    defaultValue: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  mixins: [GenericFilterMixin],
  setup(props) {
    const { t } = useI18n();

    return {
      t,
      inputId: uuidv4(),
      inputValue: ref(
        props.defaultValue == undefined ? "" : props.defaultValue
      ),
    };
  },
  watch: {
    inputValue(new_v, old_v) {
      // TODO: разрешить вводить '-', чтобы ввести отрицательное число
      if (
        this.inputValue == "" ||
        this.inputValue == null ||
        this.inputValue == undefined
      ) {
        return;
      }

      if (String(new_v) == String(old_v)) {
        return;
      }

      var n = new Number(new_v);
      var old_n = old_v == "" ? NaN : new Number(old_v);

      if (isNaN(n)) {
        this.inputValue = isNaN(old_n) ? "" : old_n;
        return;
      }

      if (n > this.maxValue) {
        this.inputValue = isNaN(old_n) ? "" : old_n;
        return;
      }

      if (n < this.minValue) {
        if (this.minValue >= 1) {
          // incorrect input, but the user has to see first x digits entered
          if (n <= 0) {
            this.inputValue = isNaN(old_n) || old_n == 0 ? "" : old_n;
          } else {
            this.inputValue = n;
          }
          return;
        }

        this.inputValue = isNaN(old_n) ? "" : old_n;
        return;
      }

      this.inputValue = n;
    },
    defaultValue() {
      this.inputValue = this.defaultValue;
    },
  },
  computed: {
    inputValid() {
      var n = new Number(this.inputValue);
      if (isNaN(n)) {
        return false;
      }

      if (n > this.maxValue || n < this.minValue) {
        return false;
      }

      return true;
    },
    showPostfix() {
      return (
        this.inputValue != "" &&
        this.inputValue != undefined &&
        this.postfix != undefined &&
        this.postfix != ""
      );
    },
  },
  methods: {
    inputBlurred() {
      if (!this.inputValid) {
        this.inputValue = "";
        return;
      }
      this.$emit("on-value-selected", this.filterId, this.inputValue);
    },
  },
};
</script>

<style scoped>
input {
  width: 100%;
  padding: 5px 7px;
}

.postfix-wrap {
  position: relative;
}

.postfix {
  position: absolute;
  right: 0.5em;
  bottom: 5px;
  user-select: none;
  color: #8a929e;
}

.invalid-value {
  color: red;
}
</style>
