/**
 * Любой фильтр, который может отрисовать приложение.
 * Содержит все основные props.
 *
 * Если фильтр использует какие-либо props из списка ниже, их лучше всего объявить еще раз в component фильтра.
 * Например, если фильтр использует `name`:
 * ```
 * export default {
 *   name: 'FilterUsingName',
 *   props: {
 *     name: {
 *       type: String,
 *       required: true
 *     }
 *   }
 * }
 * ```
 */

// TODO: поддерживать required в фильтрах
export default {
  props: {
    /** Id фильтра, который будет указан если выбрано значение */
    filterId: {
      type: String,
      required: true,
    },

    /** Название фильтра */
    name: {
      type: String,
      required: false,
      default: undefined,
    },

    /**
     * Значение, выбранное по умолчанию или до создания фильтра.
     *
     * __Если не установлено всегда должно быть `undefined`.__
     */
    defaultValue: {
      type: String,
      required: false,
      default: undefined,
    },

    /**
     * Максимальное количество выбранных элементов.
     *
     * __Если не установлено всегда должно быть `undefined`.__
     */
    maxSelected: {
      type: Number,
      required: false,
      default: undefined,
    },

    minValue: {
      type: Number,
      required: false,
      default: undefined,
    },

    maxValue: {
      type: Number,
      required: false,
      default: undefined,
    },

    /** Набор объектов, которые нужно отрисовать.
     * Должны содержать поля:
     * - value {String} - значение, которому равен объект (2)
     * - caption {String} - текст объекта для пользователя (2 чашки)
     * - checked {Boolean} - выбран объект или нет (для checkbox'ов)
     * - color {String} - цвет объекта
     */
    objects: {
      type: Array,
      required: false,
      default: [],
    },

    /**
     * Топоним, для которого отрисовываются фильтры.
     * Топоним должен иметь поля:
     * - cityId
     */
    toponim: {
      type: Object,
      required: false,
      default: undefined,
    },

    /** Подсказка фильтра */
    hint: {
      type: String,
      required: false,
      default: undefined,
    },

    /** Постфикс после значения фильтра */
    postfix: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  emits: [
    /** Было выбрано новое значение фильтра.
     * Параметры:
     * 1. id - String - id фильтра
     * 2. value - String - новое выбранное значение
     */
    "on-value-selected",

    /** Было выбрано новое значение топонима.
     * Только фильтры, которые выбирают топоним будут emit это событие.
     * Параметры:
     * 1. id - String - id фильтра
     * 2. value - Object - выбранный топоним
     */
    "on-toponim-selected",
  ],
};
