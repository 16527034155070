/** Функциональность, связанная с входом/регистрацией/изменением пароля */

import { grpc } from "@improbable-eng/grpc-web";

import { LoginRequest, LoginResponse } from "./proto/user_pb.js";
import { CreateUserService } from "./proto/user_pb_service.js";
import { getMeta } from "./utils/meta.js";

export const LoginStatus = LoginResponse.Status;

/**
 * Получить auth token для пользователя с login и password
 * @param {any} {login - номер телефона или email, для которого нужно получить пароль
 * @param {any} password} - пароль
 * @returns {Object} Вернет объект с полями:
 *   - authToken {String}
 *   - status {Number} - число из `LoginStatus`
 */
export function login({ login, password }) {
  if (login == undefined) {
    console.error("api.login called with login = ", login);
    return {
      authToken: undefined,
      status: LoginStatus.INVALID_CREDENTIALS,
    };
  }

  if (password == undefined) {
    console.error("api.login called with password = undefined");
    return {
      authToken: undefined,
      status: LoginStatus.INVALID_CREDENTIALS,
    };
  }

  let request = new LoginRequest();
  request.setLogin(login);
  request.setPassword(password);

  return new Promise((resolve, reject) => {
    grpc.unary(CreateUserService.Login, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error CreateUserService.Login",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        if (obj.status == LoginResponse.Status.OK && !obj.authToken) {
          console.error("api.login auth token undefined, response returned ok");
          return resolve({
            authToken: undefined,
            status: LoginStatus.INVALID_CREDENTIALS,
          });
        }
        return resolve({
          authToken: obj.authToken ? obj.authToken.token : undefined,
          status: obj.status,
        });
      },
    });
  });
}
