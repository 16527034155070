<template>
  <div class="search-bar">
    <div class="header-search flex-wrap">
      <div class="keyword-wrap">
        <TextboxSuggestions
          ref="textboxSugg"
          :label="t('search_bar.aria_label')"
          inputType="search"
          :placeholder="t('search_bar.placeholder')"
          :defaultText="query.q"
          :suggestions="hints"
          @on-search-clicked="onSearchClicked"
          @on-input-changed="
            (q) => {
              query.q = q;
              updateHints();
            }
          "
          @on-input-focused="updateHints()"
        />
      </div>
      <button
        class="af-button noselect search-button"
        @click="$emit('confirmed-input', query.q, undefined)"
      >
        {{ t("search_bar.btn_text") }}
      </button>
    </div>

    <div v-if="categories.length != 0" class="kc-elm kc-css-37751">
      <div class="kc-row-container kc-container categories-wrapper">
        <BreadcrumbsCategories
          :categories="categories"
          class="categories-list"
        />
        <a class="clear-categories-btn" @click="$emit('clear-categories')">
          <i class="aficon-times-circle"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { mapGetters } from "vuex";

import BreadcrumbsCategories from "./BreadcrumbsCategories.vue";
import TextboxSuggestions from "modules/ui/TextboxSuggestions.vue";

import { getSearchHints as getSearchHintsApi } from "api/search.js";

export default {
  name: "SearchBar",
  components: {
    BreadcrumbsCategories,
    TextboxSuggestions,
  },
  props: {
    queryText: {
      type: String,
      required: false,
      default() {
        "";
      },
    },
    // TODO: не используется, подумать нужно ли удалить
    /** Список категорий, который нужно показать
     * Должен быть Array, содержащий Object.
     */
    categories: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  emits: [
    /**
     * Пользователь нажал кнопку "Найти" или ввод в строке поиска.
     * Параметры:
     *   1. текст поиска
     *   2. поисковый запрос. Может быть undefined
     */
    "confirmed-input",
    /**
     * Пользователь нажал кнопку очистить выбор категории
     */
    "clear-categories",
  ],
  setup() {
    const { t } = useI18n();
    const query = reactive({ q: "" });

    return {
      t,
      query,
      hints: ref([]),
    };
  },
  mounted() {
    this.query.q = this.queryText;
  },
  watch: {
    queryText() {
      this.query.q = this.queryText;
      this.$refs.textboxSugg.hideHints();
    },
  },
  computed: {
    ...mapGetters({ userLoggedIn: "user/loggedIn" }),
  },
  methods: {
    clearSearchBox() {
      this.query.q = "";
    },
    async updateHints() {
      if (
        !this.userLoggedIn &&
        (this.query.q == undefined || this.query.q == "")
      ) {
        this.hints = [];
        return;
      }

      var hints = await getSearchHintsApi(this.query.q, 5, this.$i18n.locale);
      var hintObjs = [];

      for (var hint of hints) {
        hintObjs.push({
          title: hint.query,
          description: hint.hint,
          iconUrl: hint.icon,
          valueObj: hint.request,
        });
      }

      this.hints = hintObjs;
    },
    onSearchClicked(text, obj) {
      this.query.q = text;
      this.$emit("confirmed-input", text, obj);
    },
  },
};
</script>

<style scoped>
.search-bar {
  margin-top: 10px;
  margin-bottom: 20px;
  /* TODO: почему-то не видит высоту окна поиска */
  min-height: 75px;
}

.kc-css-37751 {
  padding: 5px;
}

.header-search {
  margin-top: 0;
  background: none;
  padding: 0;
  box-shadow: none;
  border-radius: unset;
}

@media (max-width: 414px) {
  .header-search {
    flex-flow: row !important;
  }
}

.header-search .keyword-wrap::after {
  content: "" !important;
}

.keyword-wrap {
  display: flex;
  text-align: start;
  margin: 0 5px;
}

.kc-search {
  border-radius: 0;
  box-shadow: none;
  overflow: hidden;
  background: transparent;
  padding: 0px;
  margin-top: 0px;
}

.kc-search input {
  border: none;
  width: 100%;
  margin: 0;
  padding: 5px;
  padding-left: 12px;
}

.keyword-wrap:hover > .clear-btn {
  visibility: visible;
}

.clear-btn {
  z-index: 999;
  position: relative;
  margin: auto;
  margin-right: 15px;
  color: #666666;
  visibility: hidden;
}

.clear-btn:hover {
  color: #1c83fc;
}

.search-button {
  padding: 8px 24px !important;
}

.categories-wrapper {
  display: flex;
  text-align: start;
}

.categories-list {
  color: black;
}

.clear-categories-btn {
  margin-left: 5px;
  visibility: hidden;
}

.categories-wrapper:hover > .clear-categories-btn {
  visibility: visible;
}
</style>
