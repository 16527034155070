import buildApp from "./app";

import VueYandexMetrika from "vue3-yandex-metrika";

(async () => {
  const { app, router } = await buildApp({ isSSR: false });

  app.use(VueYandexMetrika, {
    id: process.env.VUE_APP_YANDEX_METRIKA_ID,
    env: process.env.NODE_ENV,
    router,
  });

  router.isReady().then(() => {
    app.mount("#app", false);

    // TODO: not do this for vue3-progress
    let progress = document.getElementsByClassName("vue3-progress");
    if (progress && progress[0]) {
      progress[0].className = "vue3-progress-custom";
    }
  });
})();
