import store from "store";

export function getMeta() {
  if (store.state.user.authToken) {
    return {
      Authorization: "Bearer " + String(store.state.user.authToken),
      "Content-Language": "rus",
    };
  }


  // TODO: брать язык из l18n
  return {
    Authorization: "Bearer " + process.env.VUE_APP_PUBLIC_AUTH_TOKEN,
    "Content-Language": "rus",
  };
}
