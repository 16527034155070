<template>
  <wrapper-static
    :filterNameText="name"
    :filterValueText="selectedText"
    :canBeApplied="false"
    :canBeCleared="showClearBtn"
    @on-clear-selected="clearSelected"
  >
    <ul class="list-unstyled">
      <li :key="o.value" v-for="o in objects">
        <div
          class="styled-checkbox"
          tabindex="0"
          @keydown.enter="clickRef(String(inputId + '_' + o.value))"
        >
          <input
            type="checkbox"
            :name="inputId"
            :value="o.value"
            :id="inputId + '_' + o.value"
            :ref="String(inputId + '_' + o.value)"
            v-model="selectedValues"
            :disabled="
              maxSelected == undefined
                ? false
                : selectedValues.length >= maxSelected &&
                  selectedValues.indexOf(o.value) === -1
            "
          />
          <label :for="inputId + '_' + o.value">{{ o.caption }} </label>
        </div>
      </li>
    </ul>
  </wrapper-static>
</template>

<script>
import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";

import GenericFilterMixin from "../mixins/GenericFilterMixin";
import ClickRefMixin from "../mixins/ClickRefMixin";

import WrapperStatic from "./WrapperStatic.vue";

export default {
  name: "FilterCheckboxMultiInline",
  components: {
    WrapperStatic,
  },
  props: {
    /** Набор объектов, которые нужно отрисовать.
     * Должны содержать поля:
     * - value
     * - caption
     * - checked
     */
    objects: {
      type: Array,
      required: true,
    },
    /** Название списка */
    name: {
      type: String,
      required: true,
    },
    /** Максимальное количество выбранных элементов */
    maxSelected: {
      type: Number,
      required: false,
    },
  },
  mixins: [GenericFilterMixin, ClickRefMixin],
  setup() {
    return {
      inputId: uuidv4(),
      selectedValues: ref([]),
      skipSelectedValueWatch: false,
    };
  },
  mounted() {
    this.mergeObjectSelectedAndSelectedValues();
  },
  watch: {
    objects() {
      this.mergeObjectSelectedAndSelectedValues();
    },
    selectedValues(oldVal, newVal) {
      if (this.skipSelectedValueWatch) {
        return;
      }

      if (String(oldVal) == String(newVal)) {
        return;
      }

      this.$emit("on-value-selected", this.filterId, this.selectedValues);
    },
  },
  computed: {
    selectedText() {
      var selected = this.selectedValues;
      var selected_captions = this.objects
        .filter((obj) => selected.includes(obj.value))
        .map((obj) => obj.caption)
        .join(", ");

      return selected_captions;
    },
    showClearBtn() {
      return (
        this.selectedValues != undefined &&
        this.selectedValues != null &&
        this.selectedValues.length != 0
      );
    },
  },
  methods: {
    clearSelected() {
      this.selectedValues = [];
    },
    mergeObjectSelectedAndSelectedValues() {
      var new_selected = this.objects
        .filter((obj) => obj.checked)
        .map((obj) => obj.value);
      var all_selected = this.selectedValues.concat(new_selected);
      var valid_selected = this.objects
        .filter((obj) => all_selected.includes(obj.value))
        .map((obj) => obj.value);

      this.skipSelectedValueWatch = true;
      this.selectedValues = valid_selected;
      this.skipSelectedValueWatch = false;
    },
  },
};
</script>

<style scoped></style>
