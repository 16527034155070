<template>
  <transition name="label-slide">
    <div v-if="!isOpen">
      <button
        @click="openClicked"
        class="button-open"
        :aria-label="openBtnAria"
      >
        <div>
          <div :style="nameTextStyle">{{ filterNameText }}</div>
          <div v-if="filterValueText.length != 0" class="value-text">
            {{ filterValueText }}
          </div>
        </div>
        <div class="arrow-open">
          <i class="sl-arrow-right"></i>
        </div>
      </button>
    </div>
  </transition>

  <transition name="label-slide">
    <div v-if="isOpen">
      <div class="filter-wrapper">
        <button
          @click="closeClicked"
          class="button-close"
          :aria-label="t('filters.wrapper_collapsible.close')"
        >
          <i class="sl-close"></i>
        </button>

        <label class="labels-div">
          <div :style="nameTextStyle">{{ filterNameText }}</div>
          <div v-if="filterValueText.length != 0" class="value-text">
            {{ filterValueText }}
          </div>
        </label>

        <button
          @click="applyClicked"
          :style="canBeApplied ? '' : 'visibility: hidden;'"
          class="button-apply"
          :aria-label="t('filters.wrapper_collapsible.apply')"
        >
          <i class="sl-check"></i>
        </button>
      </div>

      <slot></slot>
    </div>
  </transition>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

export default {
  name: "WrapperCollapsible",
  props: {
    /** Заголовок названия фильтра */
    filterNameText: {
      type: String,
      required: false,
      default() {
        const { t } = useI18n();

        return t("filters.button_open.open");
      },
    },
    /** Текст значения, установленного в фильтре */
    filterValueText: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
    /** Нужно ли показывать кнопку применить */
    canBeApplied: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  emits: [
    /** Фильтр был открыт из свернутого состояния */
    "on-open-clicked",

    /** Была нажата кнопка закрыть. Не передает параметров. */
    "on-close-clicked",

    /** Была нажата кнопка применить. Не передает параметров. */
    "on-apply-clicked",
  ],
  setup() {
    const { t } = useI18n();

    return {
      t,
      isOpen: ref(false),
    };
  },
  computed: {
    nameTextStyle() {
      if (this.filterValueText.length == 0) {
        return {
          fontSize: "15px",
        };
      }

      return {
        fontSize: "12px",
        color: "#616161",
      };
    },
    openBtnAria() {
      if (this.filterValueText != undefined) {
        if (this.filterValueText.length != 0) {
          return this.t("filters.wrapper_collapsible.open_selected", {
            name: this.filterNameText,
            value: this.filterValueText,
          });
        }
      }
      return this.t("filters.wrapper_collapsible.open", {
        name: this.filterNameText,
      });
    },
  },
  methods: {
    setOpen(open) {
      this.isOpen = open;
    },
    openClicked() {
      this.setOpen(true);
      this.$emit("on-open-clicked");
    },
    closeClicked() {
      this.setOpen(false);
      this.$emit("on-close-clicked");
    },
    applyClicked() {
      this.setOpen(false);
      this.$emit("on-apply-clicked");
    },
  },
};
</script>

<style scoped>
button {
  font-size: 15px;
  transition: all 0.2s ease;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

label {
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.filter-wrapper {
  border-bottom: solid;
  border-color: #000;
  margin-bottom: 10px;
}

.arrow-open {
  float: right;
  position: relative;
  height: 0;
  bottom: 25px;
}

.button-open {
  width: 100%;
  color: #000;
  background-color: #fff;
  border-width: 2px;
  border-style: solid;
  border-color: #d2d2d2;
  text-align: start;
  display: block;
}

.button-close {
  width: auto;
  float: left;
  border-style: none;
  background: none;
}

.labels-div {
  width: calc(100% - 100px);
}

.button-apply {
  width: auto;
  float: right;
  border-style: none;
  background: none;
  color: #4eac00;
}

.value-text {
  font-size: 15px;
  color: black;
  margin-right: 13px;
}

.label-slide-enter-active {
  transition: all 0.2s ease;
}

.label-slide-enter-from {
  transform: translateY(50px);
}
</style>
