<template>
  <wrapper-collapsible
    :filterNameText="name"
    :filterValueText="selectedText"
    :canBeApplied="canApply"
    @on-apply-clicked="applyPending"
    @on-close-clicked="clearPending"
  >
    <div
      class="buttons-wrapper"
      @keydown.left="showStations = true"
      @keydown.right="showStations = false"
    >
      <button
        :class="showStations ? 'btn-active' : ''"
        @click="showStations = true"
      >
        {{ t("filters.filter_metro_select.show_stations") }}
      </button>

      <button
        :class="showStations ? '' : 'btn-active'"
        @click="showStations = false"
      >
        {{ t("filters.filter_metro_select.show_lines") }}
      </button>

      <span class="slider" :style="sliderStyle"></span>
    </div>

    <div
      class="overflow"
      @keydown.left="showStations = true"
      @keydown.right="showStations = false"
    >
      <MetroStations
        v-if="showStations"
        :stations="data.stations"
        :selected="pendingStations"
        @selected-station="selectStation"
      />

      <MetroLines
        v-else
        :lines="data.lines"
        :selected="pendingStations"
        @selected-station="selectStation"
      />
    </div>
  </wrapper-collapsible>
</template>

<script>
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";

import GenericFilterMixin from "../mixins/GenericFilterMixin";

import WrapperCollapsible from "./WrapperCollapsible.vue";
import MetroStations from "./MetroStations.vue";
import MetroLines from "./MetroLines.vue";

import { getStationsLinesLists as getStationsLinesListsApi } from "api/metro_stations.js";

export default {
  name: "FilterMetroSelect",
  components: {
    WrapperCollapsible,
    MetroStations,
    MetroLines,
  },
  mixins: [GenericFilterMixin],
  props: {
    /** Название списка */
    name: {
      type: String,
      required: false,
      default() {
        const { t } = useI18n();
        return t("filters.filter_metro_select.default_name");
      },
    },

    /** Топоним с fias id города, для которого нужно получить категории */
    toponim: {
      type: Object,
      required: true,
      default() {
        return {
          cityFiasId: undefined,
        };
      },
    },

    defaultValue: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      showStations: ref(true),
      data: reactive({
        stations: [],
        lines: [],
      }),
      selectedStations: ref([]),
      pendingStations: ref([]),
    };
  },
  async mounted() {
    await this.updateStationsList();
    this.applyDefaultValue();
    // TODO: decode stations list from defaultValue prop
  },
  watch: {
    async "toponim.cityFiasId"() {
      await this.updateStationsList();
      this.selectedStations = [];
      this.pendingStations = [];
    },
  },
  computed: {
    sliderStyle() {
      if (this.showStations) {
        return {
          left: "0",
        };
      } else {
        return {
          left: "50%",
        };
      }
    },
    selectedText() {
      if (this.selectedStations.length == 0 || this.data.stations.length == 0) {
        return "";
      }

      const STATIONS_TO_DISP = 4;
      var two_selected = this.selectedStations
        .slice(0, STATIONS_TO_DISP)
        .map((id, index) => {
          var st = this.data.stations.find((st) => st.stationIds.includes(id));

          if (st == undefined) {
            return this.$t("filters.filter_metro_select.station") + " " + index;
          }

          return st.name;
        });

      var selected_nonduplicate = [...new Set(two_selected)];

      if (selected_nonduplicate.length == 0) {
        return "";
      }

      if (this.selectedStations.length > STATIONS_TO_DISP) {
        var not_shown = this.selectedStations.length - STATIONS_TO_DISP;
        return (
          selected_nonduplicate.join(", ") +
          " " +
          this.t("filters.filter_metro_select.and_x_more_stations", {
            x: not_shown,
          })
        );
      }

      return selected_nonduplicate.join(", ");
    },
    canApply() {
      return this.pendingStations.length > 0;
    },
  },
  methods: {
    async updateStationsList() {
      var res = await getStationsLinesListsApi(this.$props.toponim.cityFiasId);
      this.data.stations = res.stations;
      this.data.lines = res.lines;
      // нужно, так как MetroStations опирается на индекс
      // станции, чтобы выбрать несколько станций. Индексы поменяются
      this.pendingStations = [];
    },
    applyDefaultValue() {
      if (!this.defaultValue) {
        return;
      }

      var stations = this.defaultValue
        .split("&")
        .map((v) => v.replaceAll("=", ""));

      this.selectedStations = stations;
    },
    selectStation(s) {
      this.pendingStations = s;
    },
    applyPending() {
      this.selectedStations = this.pendingStations;
      this.$emit("on-value-selected", this.filterId, this.selectedStations);
    },
    clearPending() {
      this.pendingStations = this.selectedStations;
    },
  },
};
</script>

<style scoped>
.buttons-wrapper {
  width: 100%;
  padding-bottom: 10px;
}

.buttons-wrapper button {
  width: 50%;
  text-align: center;
  background-color: white;
  padding: 5px;
  border: none;
  transition: all 0.3s ease;
  color: #000;
}

.btn-active {
  color: #1c83fc !important;
}

.slider {
  content: "";
  display: block;
  bottom: 0;
  left: 0;
  height: 2px;
  margin-top: -2px;
  width: 50%;
  background-color: #1c83fc;
  transition: left 0.3s ease;
  position: relative;
}

.overflow {
  max-height: 400px;
  overflow: auto;
}
</style>
