// source: photos.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.api.v1.photos.Ad', null, global);
goog.exportSymbol('proto.api.v1.photos.AdId', null, global);
goog.exportSymbol('proto.api.v1.photos.AdIds', null, global);
goog.exportSymbol('proto.api.v1.photos.AdRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.Ads', null, global);
goog.exportSymbol('proto.api.v1.photos.AdsRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.AdsResponse', null, global);
goog.exportSymbol('proto.api.v1.photos.AssignLocationRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.AssignResponse', null, global);
goog.exportSymbol('proto.api.v1.photos.BlackListRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.CategoryId', null, global);
goog.exportSymbol('proto.api.v1.photos.CategoryStat', null, global);
goog.exportSymbol('proto.api.v1.photos.CategoryStatRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.CategoryStatResponse', null, global);
goog.exportSymbol('proto.api.v1.photos.Citizenship', null, global);
goog.exportSymbol('proto.api.v1.photos.CitizenshipRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.Citizenships', null, global);
goog.exportSymbol('proto.api.v1.photos.City', null, global);
goog.exportSymbol('proto.api.v1.photos.CompactFavouritesResponse', null, global);
goog.exportSymbol('proto.api.v1.photos.ComplainRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.Countries', null, global);
goog.exportSymbol('proto.api.v1.photos.Country', null, global);
goog.exportSymbol('proto.api.v1.photos.CountryRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.Date', null, global);
goog.exportSymbol('proto.api.v1.photos.DeleteResponse', null, global);
goog.exportSymbol('proto.api.v1.photos.Dialog', null, global);
goog.exportSymbol('proto.api.v1.photos.DialogRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.Dialogs', null, global);
goog.exportSymbol('proto.api.v1.photos.Draft', null, global);
goog.exportSymbol('proto.api.v1.photos.Element', null, global);
goog.exportSymbol('proto.api.v1.photos.Elements', null, global);
goog.exportSymbol('proto.api.v1.photos.FavouriteRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.Feedback', null, global);
goog.exportSymbol('proto.api.v1.photos.GetSubwaysRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.Language', null, global);
goog.exportSymbol('proto.api.v1.photos.LanguageRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.Languages', null, global);
goog.exportSymbol('proto.api.v1.photos.LatLng', null, global);
goog.exportSymbol('proto.api.v1.photos.Line', null, global);
goog.exportSymbol('proto.api.v1.photos.Lines', null, global);
goog.exportSymbol('proto.api.v1.photos.Location', null, global);
goog.exportSymbol('proto.api.v1.photos.LoginResult', null, global);
goog.exportSymbol('proto.api.v1.photos.Message', null, global);
goog.exportSymbol('proto.api.v1.photos.MessageRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.Messages', null, global);
goog.exportSymbol('proto.api.v1.photos.MessagesCountRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.MessagesCountResponse', null, global);
goog.exportSymbol('proto.api.v1.photos.Metric', null, global);
goog.exportSymbol('proto.api.v1.photos.MetricRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.Metrics', null, global);
goog.exportSymbol('proto.api.v1.photos.Page', null, global);
goog.exportSymbol('proto.api.v1.photos.PageRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.Param', null, global);
goog.exportSymbol('proto.api.v1.photos.Params', null, global);
goog.exportSymbol('proto.api.v1.photos.Photo', null, global);
goog.exportSymbol('proto.api.v1.photos.PhotoRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.Photos', null, global);
goog.exportSymbol('proto.api.v1.photos.PositionRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.PositionResponse', null, global);
goog.exportSymbol('proto.api.v1.photos.PromoFeatures', null, global);
goog.exportSymbol('proto.api.v1.photos.RecoveryAnswer', null, global);
goog.exportSymbol('proto.api.v1.photos.RecoveryRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.RecoveryToken', null, global);
goog.exportSymbol('proto.api.v1.photos.RemoveMessageRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.RemoveMessageResponse', null, global);
goog.exportSymbol('proto.api.v1.photos.RemoveParticipationRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.RemoveParticipationResponse', null, global);
goog.exportSymbol('proto.api.v1.photos.Review', null, global);
goog.exportSymbol('proto.api.v1.photos.ReviewRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.Reviews', null, global);
goog.exportSymbol('proto.api.v1.photos.SearchRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.SetStateRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.Setting', null, global);
goog.exportSymbol('proto.api.v1.photos.SettingRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.Settings', null, global);
goog.exportSymbol('proto.api.v1.photos.StatDetail', null, global);
goog.exportSymbol('proto.api.v1.photos.StatDetails', null, global);
goog.exportSymbol('proto.api.v1.photos.StatRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.StatResponse', null, global);
goog.exportSymbol('proto.api.v1.photos.Station', null, global);
goog.exportSymbol('proto.api.v1.photos.SubjectToponimRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.TinyAd', null, global);
goog.exportSymbol('proto.api.v1.photos.TinyAdsResponse', null, global);
goog.exportSymbol('proto.api.v1.photos.TinyUser', null, global);
goog.exportSymbol('proto.api.v1.photos.TinyUsers', null, global);
goog.exportSymbol('proto.api.v1.photos.Toponim', null, global);
goog.exportSymbol('proto.api.v1.photos.ToponimRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.Toponims', null, global);
goog.exportSymbol('proto.api.v1.photos.UserDialogRequest', null, global);
goog.exportSymbol('proto.api.v1.photos.Value', null, global);
goog.exportSymbol('proto.api.v1.photos.Values', null, global);
goog.exportSymbol('proto.api.v1.photos.ViewRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.CategoryId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.CategoryId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.CategoryId.displayName = 'proto.api.v1.photos.CategoryId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.CategoryStatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.CategoryStatRequest.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.CategoryStatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.CategoryStatRequest.displayName = 'proto.api.v1.photos.CategoryStatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.CategoryStatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.CategoryStatResponse.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.CategoryStatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.CategoryStatResponse.displayName = 'proto.api.v1.photos.CategoryStatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.CategoryStat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.CategoryStat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.CategoryStat.displayName = 'proto.api.v1.photos.CategoryStat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.MetricRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.MetricRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.MetricRequest.displayName = 'proto.api.v1.photos.MetricRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Metrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Metrics.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Metrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Metrics.displayName = 'proto.api.v1.photos.Metrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Metric = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.Metric, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Metric.displayName = 'proto.api.v1.photos.Metric';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.SettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.SettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.SettingRequest.displayName = 'proto.api.v1.photos.SettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Settings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Settings.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Settings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Settings.displayName = 'proto.api.v1.photos.Settings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Setting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.Setting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Setting.displayName = 'proto.api.v1.photos.Setting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.CompactFavouritesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.CompactFavouritesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.CompactFavouritesResponse.displayName = 'proto.api.v1.photos.CompactFavouritesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.StatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.StatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.StatRequest.displayName = 'proto.api.v1.photos.StatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.StatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.StatResponse.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.StatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.StatResponse.displayName = 'proto.api.v1.photos.StatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.StatDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.StatDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.StatDetail.displayName = 'proto.api.v1.photos.StatDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.StatDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.StatDetails.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.StatDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.StatDetails.displayName = 'proto.api.v1.photos.StatDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.PageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.PageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.PageRequest.displayName = 'proto.api.v1.photos.PageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Page = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.Page, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Page.displayName = 'proto.api.v1.photos.Page';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.LoginResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.LoginResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.LoginResult.displayName = 'proto.api.v1.photos.LoginResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.RecoveryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.RecoveryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.RecoveryRequest.displayName = 'proto.api.v1.photos.RecoveryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.RecoveryAnswer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.RecoveryAnswer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.RecoveryAnswer.displayName = 'proto.api.v1.photos.RecoveryAnswer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.RecoveryToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.RecoveryToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.RecoveryToken.displayName = 'proto.api.v1.photos.RecoveryToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.MessagesCountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.MessagesCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.MessagesCountRequest.displayName = 'proto.api.v1.photos.MessagesCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.MessagesCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.MessagesCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.MessagesCountResponse.displayName = 'proto.api.v1.photos.MessagesCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.ComplainRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.ComplainRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.ComplainRequest.displayName = 'proto.api.v1.photos.ComplainRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.ReviewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.ReviewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.ReviewRequest.displayName = 'proto.api.v1.photos.ReviewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Feedback = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.Feedback, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Feedback.displayName = 'proto.api.v1.photos.Feedback';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Reviews = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Reviews.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Reviews, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Reviews.displayName = 'proto.api.v1.photos.Reviews';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Review = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.Review, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Review.displayName = 'proto.api.v1.photos.Review';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.SetStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.SetStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.SetStateRequest.displayName = 'proto.api.v1.photos.SetStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Date = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.Date, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Date.displayName = 'proto.api.v1.photos.Date';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.PromoFeatures = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.PromoFeatures, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.PromoFeatures.displayName = 'proto.api.v1.photos.PromoFeatures';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.TinyAd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.TinyAd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.TinyAd.displayName = 'proto.api.v1.photos.TinyAd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.TinyAdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.TinyAdsResponse.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.TinyAdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.TinyAdsResponse.displayName = 'proto.api.v1.photos.TinyAdsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.AdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.AdsResponse.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.AdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.AdsResponse.displayName = 'proto.api.v1.photos.AdsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.SubjectToponimRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.SubjectToponimRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.SubjectToponimRequest.displayName = 'proto.api.v1.photos.SubjectToponimRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.AssignResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.AssignResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.AssignResponse.displayName = 'proto.api.v1.photos.AssignResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.AssignLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.AssignLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.AssignLocationRequest.displayName = 'proto.api.v1.photos.AssignLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.GetSubwaysRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.GetSubwaysRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.GetSubwaysRequest.displayName = 'proto.api.v1.photos.GetSubwaysRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Lines = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Lines.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Lines, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Lines.displayName = 'proto.api.v1.photos.Lines';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Toponims = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Toponims.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Toponims, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Toponims.displayName = 'proto.api.v1.photos.Toponims';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.ToponimRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.ToponimRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.ToponimRequest.displayName = 'proto.api.v1.photos.ToponimRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Toponim = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.Toponim, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Toponim.displayName = 'proto.api.v1.photos.Toponim';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Location.displayName = 'proto.api.v1.photos.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.City = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.City, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.City.displayName = 'proto.api.v1.photos.City';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Line = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Line.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Line, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Line.displayName = 'proto.api.v1.photos.Line';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Station = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.Station, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Station.displayName = 'proto.api.v1.photos.Station';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.FavouriteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.FavouriteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.FavouriteRequest.displayName = 'proto.api.v1.photos.FavouriteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.ViewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.ViewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.ViewRequest.displayName = 'proto.api.v1.photos.ViewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.AdId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.AdId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.AdId.displayName = 'proto.api.v1.photos.AdId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.AdIds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.AdIds.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.AdIds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.AdIds.displayName = 'proto.api.v1.photos.AdIds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.BlackListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.BlackListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.BlackListRequest.displayName = 'proto.api.v1.photos.BlackListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.TinyUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.TinyUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.TinyUser.displayName = 'proto.api.v1.photos.TinyUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.TinyUsers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.TinyUsers.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.TinyUsers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.TinyUsers.displayName = 'proto.api.v1.photos.TinyUsers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.RemoveParticipationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.RemoveParticipationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.RemoveParticipationRequest.displayName = 'proto.api.v1.photos.RemoveParticipationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.RemoveParticipationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.RemoveParticipationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.RemoveParticipationResponse.displayName = 'proto.api.v1.photos.RemoveParticipationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.RemoveMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.RemoveMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.RemoveMessageRequest.displayName = 'proto.api.v1.photos.RemoveMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.RemoveMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.RemoveMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.RemoveMessageResponse.displayName = 'proto.api.v1.photos.RemoveMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.MessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.MessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.MessageRequest.displayName = 'proto.api.v1.photos.MessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Dialogs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Dialogs.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Dialogs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Dialogs.displayName = 'proto.api.v1.photos.Dialogs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.UserDialogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.UserDialogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.UserDialogRequest.displayName = 'proto.api.v1.photos.UserDialogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.DialogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.DialogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.DialogRequest.displayName = 'proto.api.v1.photos.DialogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Dialog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Dialog.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Dialog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Dialog.displayName = 'proto.api.v1.photos.Dialog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Messages = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Messages.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Messages, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Messages.displayName = 'proto.api.v1.photos.Messages';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Message = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.Message, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Message.displayName = 'proto.api.v1.photos.Message';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.LatLng = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.LatLng, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.LatLng.displayName = 'proto.api.v1.photos.LatLng';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Ads = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Ads.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Ads, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Ads.displayName = 'proto.api.v1.photos.Ads';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Ad = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Ad.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Ad, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Ad.displayName = 'proto.api.v1.photos.Ad';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.AdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.AdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.AdRequest.displayName = 'proto.api.v1.photos.AdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.AdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.AdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.AdsRequest.displayName = 'proto.api.v1.photos.AdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Element = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Element.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Element, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Element.displayName = 'proto.api.v1.photos.Element';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Value.displayName = 'proto.api.v1.photos.Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Values = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Values.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Values, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Values.displayName = 'proto.api.v1.photos.Values';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Draft = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Draft.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Draft, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Draft.displayName = 'proto.api.v1.photos.Draft';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Param = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.Param, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Param.displayName = 'proto.api.v1.photos.Param';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Params = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Params.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Params, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Params.displayName = 'proto.api.v1.photos.Params';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Elements = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Elements.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Elements, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Elements.displayName = 'proto.api.v1.photos.Elements';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.CitizenshipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.CitizenshipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.CitizenshipRequest.displayName = 'proto.api.v1.photos.CitizenshipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Citizenship = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.Citizenship, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Citizenship.displayName = 'proto.api.v1.photos.Citizenship';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Citizenships = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Citizenships.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Citizenships, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Citizenships.displayName = 'proto.api.v1.photos.Citizenships';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.LanguageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.LanguageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.LanguageRequest.displayName = 'proto.api.v1.photos.LanguageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.CountryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.CountryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.CountryRequest.displayName = 'proto.api.v1.photos.CountryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Countries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Countries.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Countries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Countries.displayName = 'proto.api.v1.photos.Countries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Country = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.Country, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Country.displayName = 'proto.api.v1.photos.Country';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Languages = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Languages.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Languages, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Languages.displayName = 'proto.api.v1.photos.Languages';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Language = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.Language, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Language.displayName = 'proto.api.v1.photos.Language';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.PositionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.PositionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.PositionRequest.displayName = 'proto.api.v1.photos.PositionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.PositionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.PositionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.PositionResponse.displayName = 'proto.api.v1.photos.PositionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.DeleteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.DeleteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.DeleteResponse.displayName = 'proto.api.v1.photos.DeleteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.PhotoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.PhotoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.PhotoRequest.displayName = 'proto.api.v1.photos.PhotoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Photo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.Photo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Photo.displayName = 'proto.api.v1.photos.Photo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.Photos = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.photos.Photos.repeatedFields_, null);
};
goog.inherits(proto.api.v1.photos.Photos, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.Photos.displayName = 'proto.api.v1.photos.Photos';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.photos.SearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.photos.SearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.photos.SearchRequest.displayName = 'proto.api.v1.photos.SearchRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.CategoryId.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.CategoryId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.CategoryId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.CategoryId.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.CategoryId}
 */
proto.api.v1.photos.CategoryId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.CategoryId;
  return proto.api.v1.photos.CategoryId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.CategoryId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.CategoryId}
 */
proto.api.v1.photos.CategoryId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.CategoryId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.CategoryId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.CategoryId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.CategoryId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.v1.photos.CategoryId.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.CategoryId} returns this
 */
proto.api.v1.photos.CategoryId.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.CategoryStatRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.CategoryStatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.CategoryStatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.CategoryStatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.CategoryStatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    proto.api.v1.photos.CategoryId.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.CategoryStatRequest}
 */
proto.api.v1.photos.CategoryStatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.CategoryStatRequest;
  return proto.api.v1.photos.CategoryStatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.CategoryStatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.CategoryStatRequest}
 */
proto.api.v1.photos.CategoryStatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.CategoryId;
      reader.readMessage(value,proto.api.v1.photos.CategoryId.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.CategoryStatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.CategoryStatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.CategoryStatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.CategoryStatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.CategoryId.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CategoryId categories = 1;
 * @return {!Array<!proto.api.v1.photos.CategoryId>}
 */
proto.api.v1.photos.CategoryStatRequest.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.api.v1.photos.CategoryId>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.CategoryId, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.CategoryId>} value
 * @return {!proto.api.v1.photos.CategoryStatRequest} returns this
*/
proto.api.v1.photos.CategoryStatRequest.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.CategoryId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.CategoryId}
 */
proto.api.v1.photos.CategoryStatRequest.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.CategoryId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.CategoryStatRequest} returns this
 */
proto.api.v1.photos.CategoryStatRequest.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.CategoryStatResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.CategoryStatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.CategoryStatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.CategoryStatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.CategoryStatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoryStatsList: jspb.Message.toObjectList(msg.getCategoryStatsList(),
    proto.api.v1.photos.CategoryStat.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.CategoryStatResponse}
 */
proto.api.v1.photos.CategoryStatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.CategoryStatResponse;
  return proto.api.v1.photos.CategoryStatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.CategoryStatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.CategoryStatResponse}
 */
proto.api.v1.photos.CategoryStatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.CategoryStat;
      reader.readMessage(value,proto.api.v1.photos.CategoryStat.deserializeBinaryFromReader);
      msg.addCategoryStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.CategoryStatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.CategoryStatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.CategoryStatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.CategoryStatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoryStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.CategoryStat.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CategoryStat category_stats = 1;
 * @return {!Array<!proto.api.v1.photos.CategoryStat>}
 */
proto.api.v1.photos.CategoryStatResponse.prototype.getCategoryStatsList = function() {
  return /** @type{!Array<!proto.api.v1.photos.CategoryStat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.CategoryStat, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.CategoryStat>} value
 * @return {!proto.api.v1.photos.CategoryStatResponse} returns this
*/
proto.api.v1.photos.CategoryStatResponse.prototype.setCategoryStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.CategoryStat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.CategoryStat}
 */
proto.api.v1.photos.CategoryStatResponse.prototype.addCategoryStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.CategoryStat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.CategoryStatResponse} returns this
 */
proto.api.v1.photos.CategoryStatResponse.prototype.clearCategoryStatsList = function() {
  return this.setCategoryStatsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.CategoryStat.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.CategoryStat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.CategoryStat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.CategoryStat.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    activated: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.CategoryStat}
 */
proto.api.v1.photos.CategoryStat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.CategoryStat;
  return proto.api.v1.photos.CategoryStat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.CategoryStat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.CategoryStat}
 */
proto.api.v1.photos.CategoryStat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActivated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.CategoryStat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.CategoryStat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.CategoryStat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.CategoryStat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getActivated();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.v1.photos.CategoryStat.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.CategoryStat} returns this
 */
proto.api.v1.photos.CategoryStat.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.api.v1.photos.CategoryStat.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.CategoryStat} returns this
 */
proto.api.v1.photos.CategoryStat.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 activated = 3;
 * @return {number}
 */
proto.api.v1.photos.CategoryStat.prototype.getActivated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.CategoryStat} returns this
 */
proto.api.v1.photos.CategoryStat.prototype.setActivated = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.MetricRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.MetricRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.MetricRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.MetricRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accessKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    key: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.MetricRequest}
 */
proto.api.v1.photos.MetricRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.MetricRequest;
  return proto.api.v1.photos.MetricRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.MetricRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.MetricRequest}
 */
proto.api.v1.photos.MetricRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.MetricRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.MetricRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.MetricRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.MetricRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccessKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string version = 1;
 * @return {string}
 */
proto.api.v1.photos.MetricRequest.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.MetricRequest} returns this
 */
proto.api.v1.photos.MetricRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string access_key = 2;
 * @return {string}
 */
proto.api.v1.photos.MetricRequest.prototype.getAccessKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.MetricRequest} returns this
 */
proto.api.v1.photos.MetricRequest.prototype.setAccessKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string key = 3;
 * @return {string}
 */
proto.api.v1.photos.MetricRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.MetricRequest} returns this
 */
proto.api.v1.photos.MetricRequest.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Metrics.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Metrics.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Metrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Metrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Metrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    metricsList: jspb.Message.toObjectList(msg.getMetricsList(),
    proto.api.v1.photos.Metric.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Metrics}
 */
proto.api.v1.photos.Metrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Metrics;
  return proto.api.v1.photos.Metrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Metrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Metrics}
 */
proto.api.v1.photos.Metrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.Metric;
      reader.readMessage(value,proto.api.v1.photos.Metric.deserializeBinaryFromReader);
      msg.addMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Metrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Metrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Metrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Metrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetricsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.Metric.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Metric metrics = 1;
 * @return {!Array<!proto.api.v1.photos.Metric>}
 */
proto.api.v1.photos.Metrics.prototype.getMetricsList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Metric>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Metric, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.Metric>} value
 * @return {!proto.api.v1.photos.Metrics} returns this
*/
proto.api.v1.photos.Metrics.prototype.setMetricsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.Metric=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Metric}
 */
proto.api.v1.photos.Metrics.prototype.addMetrics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.Metric, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Metrics} returns this
 */
proto.api.v1.photos.Metrics.prototype.clearMetricsList = function() {
  return this.setMetricsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Metric.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Metric.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Metric} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Metric.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Metric}
 */
proto.api.v1.photos.Metric.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Metric;
  return proto.api.v1.photos.Metric.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Metric} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Metric}
 */
proto.api.v1.photos.Metric.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Metric.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Metric.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Metric} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Metric.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.api.v1.photos.Metric.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Metric} returns this
 */
proto.api.v1.photos.Metric.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.api.v1.photos.Metric.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Metric} returns this
 */
proto.api.v1.photos.Metric.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.api.v1.photos.Metric.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Metric} returns this
 */
proto.api.v1.photos.Metric.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.SettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.SettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.SettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.SettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientVersion: jspb.Message.getFieldWithDefault(msg, 1, ""),
    apiVersion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.SettingRequest}
 */
proto.api.v1.photos.SettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.SettingRequest;
  return proto.api.v1.photos.SettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.SettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.SettingRequest}
 */
proto.api.v1.photos.SettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.SettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.SettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.SettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.SettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApiVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string client_version = 1;
 * @return {string}
 */
proto.api.v1.photos.SettingRequest.prototype.getClientVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.SettingRequest} returns this
 */
proto.api.v1.photos.SettingRequest.prototype.setClientVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string api_version = 2;
 * @return {string}
 */
proto.api.v1.photos.SettingRequest.prototype.getApiVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.SettingRequest} returns this
 */
proto.api.v1.photos.SettingRequest.prototype.setApiVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Settings.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Settings.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Settings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Settings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Settings.toObject = function(includeInstance, msg) {
  var f, obj = {
    settingsList: jspb.Message.toObjectList(msg.getSettingsList(),
    proto.api.v1.photos.Setting.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Settings}
 */
proto.api.v1.photos.Settings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Settings;
  return proto.api.v1.photos.Settings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Settings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Settings}
 */
proto.api.v1.photos.Settings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.Setting;
      reader.readMessage(value,proto.api.v1.photos.Setting.deserializeBinaryFromReader);
      msg.addSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Settings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Settings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Settings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Settings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.Setting.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Setting settings = 1;
 * @return {!Array<!proto.api.v1.photos.Setting>}
 */
proto.api.v1.photos.Settings.prototype.getSettingsList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Setting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Setting, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.Setting>} value
 * @return {!proto.api.v1.photos.Settings} returns this
*/
proto.api.v1.photos.Settings.prototype.setSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.Setting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Setting}
 */
proto.api.v1.photos.Settings.prototype.addSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.Setting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Settings} returns this
 */
proto.api.v1.photos.Settings.prototype.clearSettingsList = function() {
  return this.setSettingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Setting.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Setting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Setting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Setting.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    valueType: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Setting}
 */
proto.api.v1.photos.Setting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Setting;
  return proto.api.v1.photos.Setting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Setting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Setting}
 */
proto.api.v1.photos.Setting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Setting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Setting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Setting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Setting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValueType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.api.v1.photos.Setting.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Setting} returns this
 */
proto.api.v1.photos.Setting.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.api.v1.photos.Setting.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Setting} returns this
 */
proto.api.v1.photos.Setting.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value_type = 3;
 * @return {string}
 */
proto.api.v1.photos.Setting.prototype.getValueType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Setting} returns this
 */
proto.api.v1.photos.Setting.prototype.setValueType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.CompactFavouritesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.CompactFavouritesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.CompactFavouritesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.CompactFavouritesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    favouritesId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.CompactFavouritesResponse}
 */
proto.api.v1.photos.CompactFavouritesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.CompactFavouritesResponse;
  return proto.api.v1.photos.CompactFavouritesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.CompactFavouritesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.CompactFavouritesResponse}
 */
proto.api.v1.photos.CompactFavouritesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFavouritesId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.CompactFavouritesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.CompactFavouritesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.CompactFavouritesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.CompactFavouritesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFavouritesId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string favourites_id = 1;
 * @return {string}
 */
proto.api.v1.photos.CompactFavouritesResponse.prototype.getFavouritesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.CompactFavouritesResponse} returns this
 */
proto.api.v1.photos.CompactFavouritesResponse.prototype.setFavouritesId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.StatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.StatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.StatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.StatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    adId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tillDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    filter: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.StatRequest}
 */
proto.api.v1.photos.StatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.StatRequest;
  return proto.api.v1.photos.StatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.StatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.StatRequest}
 */
proto.api.v1.photos.StatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTillDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.StatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.StatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.StatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.StatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTillDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFilter();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string ad_id = 1;
 * @return {string}
 */
proto.api.v1.photos.StatRequest.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.StatRequest} returns this
 */
proto.api.v1.photos.StatRequest.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string from_date = 2;
 * @return {string}
 */
proto.api.v1.photos.StatRequest.prototype.getFromDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.StatRequest} returns this
 */
proto.api.v1.photos.StatRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string till_date = 3;
 * @return {string}
 */
proto.api.v1.photos.StatRequest.prototype.getTillDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.StatRequest} returns this
 */
proto.api.v1.photos.StatRequest.prototype.setTillDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string filter = 4;
 * @return {string}
 */
proto.api.v1.photos.StatRequest.prototype.getFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.StatRequest} returns this
 */
proto.api.v1.photos.StatRequest.prototype.setFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.StatResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.StatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.StatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.StatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.StatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalViews: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalContactQuantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalFavouritesQuantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalPayedQuantity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    detailsList: jspb.Message.toObjectList(msg.getDetailsList(),
    proto.api.v1.photos.StatDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.StatResponse}
 */
proto.api.v1.photos.StatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.StatResponse;
  return proto.api.v1.photos.StatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.StatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.StatResponse}
 */
proto.api.v1.photos.StatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalViews(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalContactQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalFavouritesQuantity(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalPayedQuantity(value);
      break;
    case 5:
      var value = new proto.api.v1.photos.StatDetails;
      reader.readMessage(value,proto.api.v1.photos.StatDetails.deserializeBinaryFromReader);
      msg.addDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.StatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.StatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.StatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.StatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalViews();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTotalContactQuantity();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTotalFavouritesQuantity();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTotalPayedQuantity();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.api.v1.photos.StatDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total_views = 1;
 * @return {number}
 */
proto.api.v1.photos.StatResponse.prototype.getTotalViews = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.StatResponse} returns this
 */
proto.api.v1.photos.StatResponse.prototype.setTotalViews = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 total_contact_quantity = 2;
 * @return {number}
 */
proto.api.v1.photos.StatResponse.prototype.getTotalContactQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.StatResponse} returns this
 */
proto.api.v1.photos.StatResponse.prototype.setTotalContactQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_favourites_quantity = 3;
 * @return {number}
 */
proto.api.v1.photos.StatResponse.prototype.getTotalFavouritesQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.StatResponse} returns this
 */
proto.api.v1.photos.StatResponse.prototype.setTotalFavouritesQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 total_payed_quantity = 4;
 * @return {number}
 */
proto.api.v1.photos.StatResponse.prototype.getTotalPayedQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.StatResponse} returns this
 */
proto.api.v1.photos.StatResponse.prototype.setTotalPayedQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated StatDetails details = 5;
 * @return {!Array<!proto.api.v1.photos.StatDetails>}
 */
proto.api.v1.photos.StatResponse.prototype.getDetailsList = function() {
  return /** @type{!Array<!proto.api.v1.photos.StatDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.StatDetails, 5));
};


/**
 * @param {!Array<!proto.api.v1.photos.StatDetails>} value
 * @return {!proto.api.v1.photos.StatResponse} returns this
*/
proto.api.v1.photos.StatResponse.prototype.setDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.api.v1.photos.StatDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.StatDetails}
 */
proto.api.v1.photos.StatResponse.prototype.addDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.api.v1.photos.StatDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.StatResponse} returns this
 */
proto.api.v1.photos.StatResponse.prototype.clearDetailsList = function() {
  return this.setDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.StatDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.StatDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.StatDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.StatDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateAt: (f = msg.getDateAt()) && proto.api.v1.photos.Date.toObject(includeInstance, f),
    views: jspb.Message.getFieldWithDefault(msg, 2, 0),
    contactQuantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    favouritesQuantity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    payedQuantity: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.StatDetail}
 */
proto.api.v1.photos.StatDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.StatDetail;
  return proto.api.v1.photos.StatDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.StatDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.StatDetail}
 */
proto.api.v1.photos.StatDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.Date;
      reader.readMessage(value,proto.api.v1.photos.Date.deserializeBinaryFromReader);
      msg.setDateAt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setViews(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContactQuantity(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFavouritesQuantity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPayedQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.StatDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.StatDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.StatDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.StatDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.v1.photos.Date.serializeBinaryToWriter
    );
  }
  f = message.getViews();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getContactQuantity();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getFavouritesQuantity();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPayedQuantity();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional Date date_at = 1;
 * @return {?proto.api.v1.photos.Date}
 */
proto.api.v1.photos.StatDetail.prototype.getDateAt = function() {
  return /** @type{?proto.api.v1.photos.Date} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.photos.Date, 1));
};


/**
 * @param {?proto.api.v1.photos.Date|undefined} value
 * @return {!proto.api.v1.photos.StatDetail} returns this
*/
proto.api.v1.photos.StatDetail.prototype.setDateAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.photos.StatDetail} returns this
 */
proto.api.v1.photos.StatDetail.prototype.clearDateAt = function() {
  return this.setDateAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.photos.StatDetail.prototype.hasDateAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 views = 2;
 * @return {number}
 */
proto.api.v1.photos.StatDetail.prototype.getViews = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.StatDetail} returns this
 */
proto.api.v1.photos.StatDetail.prototype.setViews = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 contact_quantity = 3;
 * @return {number}
 */
proto.api.v1.photos.StatDetail.prototype.getContactQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.StatDetail} returns this
 */
proto.api.v1.photos.StatDetail.prototype.setContactQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 favourites_quantity = 4;
 * @return {number}
 */
proto.api.v1.photos.StatDetail.prototype.getFavouritesQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.StatDetail} returns this
 */
proto.api.v1.photos.StatDetail.prototype.setFavouritesQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 payed_quantity = 5;
 * @return {number}
 */
proto.api.v1.photos.StatDetail.prototype.getPayedQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.StatDetail} returns this
 */
proto.api.v1.photos.StatDetail.prototype.setPayedQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.StatDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.StatDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.StatDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.StatDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.StatDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    detailsList: jspb.Message.toObjectList(msg.getDetailsList(),
    proto.api.v1.photos.StatDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.StatDetails}
 */
proto.api.v1.photos.StatDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.StatDetails;
  return proto.api.v1.photos.StatDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.StatDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.StatDetails}
 */
proto.api.v1.photos.StatDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.StatDetail;
      reader.readMessage(value,proto.api.v1.photos.StatDetail.deserializeBinaryFromReader);
      msg.addDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.StatDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.StatDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.StatDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.StatDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.StatDetail.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StatDetail details = 1;
 * @return {!Array<!proto.api.v1.photos.StatDetail>}
 */
proto.api.v1.photos.StatDetails.prototype.getDetailsList = function() {
  return /** @type{!Array<!proto.api.v1.photos.StatDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.StatDetail, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.StatDetail>} value
 * @return {!proto.api.v1.photos.StatDetails} returns this
*/
proto.api.v1.photos.StatDetails.prototype.setDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.StatDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.StatDetail}
 */
proto.api.v1.photos.StatDetails.prototype.addDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.StatDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.StatDetails} returns this
 */
proto.api.v1.photos.StatDetails.prototype.clearDetailsList = function() {
  return this.setDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.PageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.PageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.PageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.PageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    permalink: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.PageRequest}
 */
proto.api.v1.photos.PageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.PageRequest;
  return proto.api.v1.photos.PageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.PageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.PageRequest}
 */
proto.api.v1.photos.PageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPermalink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.PageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.PageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.PageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.PageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermalink();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string permalink = 1;
 * @return {string}
 */
proto.api.v1.photos.PageRequest.prototype.getPermalink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.PageRequest} returns this
 */
proto.api.v1.photos.PageRequest.prototype.setPermalink = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Page.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Page.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Page} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Page.toObject = function(includeInstance, msg) {
  var f, obj = {
    permalink: jspb.Message.getFieldWithDefault(msg, 1, ""),
    body: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Page}
 */
proto.api.v1.photos.Page.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Page;
  return proto.api.v1.photos.Page.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Page} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Page}
 */
proto.api.v1.photos.Page.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPermalink(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Page.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Page.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Page} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Page.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermalink();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string permalink = 1;
 * @return {string}
 */
proto.api.v1.photos.Page.prototype.getPermalink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Page} returns this
 */
proto.api.v1.photos.Page.prototype.setPermalink = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string body = 2;
 * @return {string}
 */
proto.api.v1.photos.Page.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Page} returns this
 */
proto.api.v1.photos.Page.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.LoginResult.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.LoginResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.LoginResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.LoginResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.LoginResult}
 */
proto.api.v1.photos.LoginResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.LoginResult;
  return proto.api.v1.photos.LoginResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.LoginResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.LoginResult}
 */
proto.api.v1.photos.LoginResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.LoginResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.LoginResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.LoginResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.LoginResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.api.v1.photos.LoginResult.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.LoginResult} returns this
 */
proto.api.v1.photos.LoginResult.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 status = 2;
 * @return {number}
 */
proto.api.v1.photos.LoginResult.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.LoginResult} returns this
 */
proto.api.v1.photos.LoginResult.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.RecoveryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.RecoveryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.RecoveryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.RecoveryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    phone: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.RecoveryRequest}
 */
proto.api.v1.photos.RecoveryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.RecoveryRequest;
  return proto.api.v1.photos.RecoveryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.RecoveryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.RecoveryRequest}
 */
proto.api.v1.photos.RecoveryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.RecoveryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.RecoveryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.RecoveryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.RecoveryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string phone = 1;
 * @return {string}
 */
proto.api.v1.photos.RecoveryRequest.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.RecoveryRequest} returns this
 */
proto.api.v1.photos.RecoveryRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.api.v1.photos.RecoveryRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.RecoveryRequest} returns this
 */
proto.api.v1.photos.RecoveryRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.RecoveryAnswer.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.RecoveryAnswer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.RecoveryAnswer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.RecoveryAnswer.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.RecoveryAnswer}
 */
proto.api.v1.photos.RecoveryAnswer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.RecoveryAnswer;
  return proto.api.v1.photos.RecoveryAnswer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.RecoveryAnswer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.RecoveryAnswer}
 */
proto.api.v1.photos.RecoveryAnswer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.RecoveryAnswer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.RecoveryAnswer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.RecoveryAnswer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.RecoveryAnswer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 code = 1;
 * @return {number}
 */
proto.api.v1.photos.RecoveryAnswer.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.RecoveryAnswer} returns this
 */
proto.api.v1.photos.RecoveryAnswer.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.RecoveryToken.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.RecoveryToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.RecoveryToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.RecoveryToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    smsToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    emailToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.RecoveryToken}
 */
proto.api.v1.photos.RecoveryToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.RecoveryToken;
  return proto.api.v1.photos.RecoveryToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.RecoveryToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.RecoveryToken}
 */
proto.api.v1.photos.RecoveryToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmsToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.RecoveryToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.RecoveryToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.RecoveryToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.RecoveryToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmsToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmailToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string sms_token = 1;
 * @return {string}
 */
proto.api.v1.photos.RecoveryToken.prototype.getSmsToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.RecoveryToken} returns this
 */
proto.api.v1.photos.RecoveryToken.prototype.setSmsToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email_token = 2;
 * @return {string}
 */
proto.api.v1.photos.RecoveryToken.prototype.getEmailToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.RecoveryToken} returns this
 */
proto.api.v1.photos.RecoveryToken.prototype.setEmailToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone = 3;
 * @return {string}
 */
proto.api.v1.photos.RecoveryToken.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.RecoveryToken} returns this
 */
proto.api.v1.photos.RecoveryToken.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.api.v1.photos.RecoveryToken.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.RecoveryToken} returns this
 */
proto.api.v1.photos.RecoveryToken.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.MessagesCountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.MessagesCountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.MessagesCountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.MessagesCountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.MessagesCountRequest}
 */
proto.api.v1.photos.MessagesCountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.MessagesCountRequest;
  return proto.api.v1.photos.MessagesCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.MessagesCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.MessagesCountRequest}
 */
proto.api.v1.photos.MessagesCountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.MessagesCountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.MessagesCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.MessagesCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.MessagesCountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.api.v1.photos.MessagesCountRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.MessagesCountRequest} returns this
 */
proto.api.v1.photos.MessagesCountRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.MessagesCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.MessagesCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.MessagesCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.MessagesCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    counter: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.MessagesCountResponse}
 */
proto.api.v1.photos.MessagesCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.MessagesCountResponse;
  return proto.api.v1.photos.MessagesCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.MessagesCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.MessagesCountResponse}
 */
proto.api.v1.photos.MessagesCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCounter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.MessagesCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.MessagesCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.MessagesCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.MessagesCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCounter();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 counter = 1;
 * @return {number}
 */
proto.api.v1.photos.MessagesCountResponse.prototype.getCounter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.MessagesCountResponse} returns this
 */
proto.api.v1.photos.MessagesCountResponse.prototype.setCounter = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.ComplainRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.ComplainRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.ComplainRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.ComplainRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    adId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, 0),
    feelCode: jspb.Message.getFieldWithDefault(msg, 4, 0),
    text: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.ComplainRequest}
 */
proto.api.v1.photos.ComplainRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.ComplainRequest;
  return proto.api.v1.photos.ComplainRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.ComplainRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.ComplainRequest}
 */
proto.api.v1.photos.ComplainRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFeelCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.ComplainRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.ComplainRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.ComplainRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.ComplainRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getFeelCode();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string ad_id = 1;
 * @return {string}
 */
proto.api.v1.photos.ComplainRequest.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.ComplainRequest} returns this
 */
proto.api.v1.photos.ComplainRequest.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.api.v1.photos.ComplainRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.ComplainRequest} returns this
 */
proto.api.v1.photos.ComplainRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 code = 3;
 * @return {number}
 */
proto.api.v1.photos.ComplainRequest.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.ComplainRequest} returns this
 */
proto.api.v1.photos.ComplainRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 feel_code = 4;
 * @return {number}
 */
proto.api.v1.photos.ComplainRequest.prototype.getFeelCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.ComplainRequest} returns this
 */
proto.api.v1.photos.ComplainRequest.prototype.setFeelCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string text = 5;
 * @return {string}
 */
proto.api.v1.photos.ComplainRequest.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.ComplainRequest} returns this
 */
proto.api.v1.photos.ComplainRequest.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.ReviewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.ReviewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.ReviewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.ReviewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.ReviewRequest}
 */
proto.api.v1.photos.ReviewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.ReviewRequest;
  return proto.api.v1.photos.ReviewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.ReviewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.ReviewRequest}
 */
proto.api.v1.photos.ReviewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.ReviewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.ReviewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.ReviewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.ReviewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string review_id = 1;
 * @return {string}
 */
proto.api.v1.photos.ReviewRequest.prototype.getReviewId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.ReviewRequest} returns this
 */
proto.api.v1.photos.ReviewRequest.prototype.setReviewId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ad_id = 2;
 * @return {string}
 */
proto.api.v1.photos.ReviewRequest.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.ReviewRequest} returns this
 */
proto.api.v1.photos.ReviewRequest.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.api.v1.photos.ReviewRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.ReviewRequest} returns this
 */
proto.api.v1.photos.ReviewRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Feedback.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Feedback.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Feedback} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Feedback.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reviewId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    text: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 4, ""),
    state: jspb.Message.getFieldWithDefault(msg, 5, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Feedback}
 */
proto.api.v1.photos.Feedback.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Feedback;
  return proto.api.v1.photos.Feedback.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Feedback} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Feedback}
 */
proto.api.v1.photos.Feedback.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Feedback.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Feedback.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Feedback} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Feedback.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReviewId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.v1.photos.Feedback.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Feedback} returns this
 */
proto.api.v1.photos.Feedback.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string review_id = 2;
 * @return {string}
 */
proto.api.v1.photos.Feedback.prototype.getReviewId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Feedback} returns this
 */
proto.api.v1.photos.Feedback.prototype.setReviewId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string text = 3;
 * @return {string}
 */
proto.api.v1.photos.Feedback.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Feedback} returns this
 */
proto.api.v1.photos.Feedback.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string created_at = 4;
 * @return {string}
 */
proto.api.v1.photos.Feedback.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Feedback} returns this
 */
proto.api.v1.photos.Feedback.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 state = 5;
 * @return {number}
 */
proto.api.v1.photos.Feedback.prototype.getState = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.Feedback} returns this
 */
proto.api.v1.photos.Feedback.prototype.setState = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string user_id = 6;
 * @return {string}
 */
proto.api.v1.photos.Feedback.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Feedback} returns this
 */
proto.api.v1.photos.Feedback.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Reviews.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Reviews.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Reviews.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Reviews} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Reviews.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewsList: jspb.Message.toObjectList(msg.getReviewsList(),
    proto.api.v1.photos.Review.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Reviews}
 */
proto.api.v1.photos.Reviews.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Reviews;
  return proto.api.v1.photos.Reviews.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Reviews} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Reviews}
 */
proto.api.v1.photos.Reviews.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.Review;
      reader.readMessage(value,proto.api.v1.photos.Review.deserializeBinaryFromReader);
      msg.addReviews(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Reviews.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Reviews.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Reviews} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Reviews.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.Review.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Review reviews = 1;
 * @return {!Array<!proto.api.v1.photos.Review>}
 */
proto.api.v1.photos.Reviews.prototype.getReviewsList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Review>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Review, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.Review>} value
 * @return {!proto.api.v1.photos.Reviews} returns this
*/
proto.api.v1.photos.Reviews.prototype.setReviewsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.Review=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Review}
 */
proto.api.v1.photos.Reviews.prototype.addReviews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.Review, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Reviews} returns this
 */
proto.api.v1.photos.Reviews.prototype.clearReviewsList = function() {
  return this.setReviewsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Review.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Review.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Review} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Review.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    reporterId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    text: jspb.Message.getFieldWithDefault(msg, 5, ""),
    stars: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dealState: jspb.Message.getFieldWithDefault(msg, 7, 0),
    state: jspb.Message.getFieldWithDefault(msg, 8, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 9, ""),
    feedback: (f = msg.getFeedback()) && proto.api.v1.photos.Feedback.toObject(includeInstance, f),
    thumbUrl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    username: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Review}
 */
proto.api.v1.photos.Review.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Review;
  return proto.api.v1.photos.Review.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Review} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Review}
 */
proto.api.v1.photos.Review.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReporterId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStars(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDealState(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setState(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = new proto.api.v1.photos.Feedback;
      reader.readMessage(value,proto.api.v1.photos.Feedback.deserializeBinaryFromReader);
      msg.setFeedback(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbUrl(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Review.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Review.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Review} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Review.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReporterId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStars();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getDealState();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getState();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFeedback();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.api.v1.photos.Feedback.serializeBinaryToWriter
    );
  }
  f = message.getThumbUrl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.v1.photos.Review.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Review} returns this
 */
proto.api.v1.photos.Review.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ad_id = 2;
 * @return {string}
 */
proto.api.v1.photos.Review.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Review} returns this
 */
proto.api.v1.photos.Review.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.api.v1.photos.Review.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Review} returns this
 */
proto.api.v1.photos.Review.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string reporter_id = 4;
 * @return {string}
 */
proto.api.v1.photos.Review.prototype.getReporterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Review} returns this
 */
proto.api.v1.photos.Review.prototype.setReporterId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string text = 5;
 * @return {string}
 */
proto.api.v1.photos.Review.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Review} returns this
 */
proto.api.v1.photos.Review.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 stars = 6;
 * @return {number}
 */
proto.api.v1.photos.Review.prototype.getStars = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.Review} returns this
 */
proto.api.v1.photos.Review.prototype.setStars = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 deal_state = 7;
 * @return {number}
 */
proto.api.v1.photos.Review.prototype.getDealState = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.Review} returns this
 */
proto.api.v1.photos.Review.prototype.setDealState = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 state = 8;
 * @return {number}
 */
proto.api.v1.photos.Review.prototype.getState = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.Review} returns this
 */
proto.api.v1.photos.Review.prototype.setState = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string created_at = 9;
 * @return {string}
 */
proto.api.v1.photos.Review.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Review} returns this
 */
proto.api.v1.photos.Review.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional Feedback feedback = 10;
 * @return {?proto.api.v1.photos.Feedback}
 */
proto.api.v1.photos.Review.prototype.getFeedback = function() {
  return /** @type{?proto.api.v1.photos.Feedback} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.photos.Feedback, 10));
};


/**
 * @param {?proto.api.v1.photos.Feedback|undefined} value
 * @return {!proto.api.v1.photos.Review} returns this
*/
proto.api.v1.photos.Review.prototype.setFeedback = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.photos.Review} returns this
 */
proto.api.v1.photos.Review.prototype.clearFeedback = function() {
  return this.setFeedback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.photos.Review.prototype.hasFeedback = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string thumb_url = 11;
 * @return {string}
 */
proto.api.v1.photos.Review.prototype.getThumbUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Review} returns this
 */
proto.api.v1.photos.Review.prototype.setThumbUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string username = 12;
 * @return {string}
 */
proto.api.v1.photos.Review.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Review} returns this
 */
proto.api.v1.photos.Review.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.SetStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.SetStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.SetStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.SetStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    adId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.SetStateRequest}
 */
proto.api.v1.photos.SetStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.SetStateRequest;
  return proto.api.v1.photos.SetStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.SetStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.SetStateRequest}
 */
proto.api.v1.photos.SetStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilter(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.SetStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.SetStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.SetStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.SetStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string ad_id = 1;
 * @return {string}
 */
proto.api.v1.photos.SetStateRequest.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.SetStateRequest} returns this
 */
proto.api.v1.photos.SetStateRequest.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string filter = 2;
 * @return {string}
 */
proto.api.v1.photos.SetStateRequest.prototype.getFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.SetStateRequest} returns this
 */
proto.api.v1.photos.SetStateRequest.prototype.setFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 state = 3;
 * @return {number}
 */
proto.api.v1.photos.SetStateRequest.prototype.getState = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.SetStateRequest} returns this
 */
proto.api.v1.photos.SetStateRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Date.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Date.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Date} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Date.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Date}
 */
proto.api.v1.photos.Date.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Date;
  return proto.api.v1.photos.Date.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Date} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Date}
 */
proto.api.v1.photos.Date.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Date.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Date.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Date} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Date.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 date = 1;
 * @return {number}
 */
proto.api.v1.photos.Date.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.Date} returns this
 */
proto.api.v1.photos.Date.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.PromoFeatures.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.PromoFeatures.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.PromoFeatures} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.PromoFeatures.toObject = function(includeInstance, msg) {
  var f, obj = {
    highlight: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.PromoFeatures}
 */
proto.api.v1.photos.PromoFeatures.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.PromoFeatures;
  return proto.api.v1.photos.PromoFeatures.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.PromoFeatures} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.PromoFeatures}
 */
proto.api.v1.photos.PromoFeatures.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHighlight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.PromoFeatures.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.PromoFeatures.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.PromoFeatures} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.PromoFeatures.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHighlight();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool highlight = 1;
 * @return {boolean}
 */
proto.api.v1.photos.PromoFeatures.prototype.getHighlight = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.photos.PromoFeatures} returns this
 */
proto.api.v1.photos.PromoFeatures.prototype.setHighlight = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.TinyAd.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.TinyAd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.TinyAd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.TinyAd.toObject = function(includeInstance, msg) {
  var f, obj = {
    adId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    price: jspb.Message.getFieldWithDefault(msg, 4, 0),
    oldPrice: jspb.Message.getFieldWithDefault(msg, 5, 0),
    mainPhoto: (f = msg.getMainPhoto()) && proto.api.v1.photos.Photo.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && proto.api.v1.photos.Date.toObject(includeInstance, f),
    favourite: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    promoFeatures: (f = msg.getPromoFeatures()) && proto.api.v1.photos.PromoFeatures.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && proto.api.v1.photos.Location.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.TinyAd}
 */
proto.api.v1.photos.TinyAd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.TinyAd;
  return proto.api.v1.photos.TinyAd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.TinyAd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.TinyAd}
 */
proto.api.v1.photos.TinyAd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOldPrice(value);
      break;
    case 6:
      var value = new proto.api.v1.photos.Photo;
      reader.readMessage(value,proto.api.v1.photos.Photo.deserializeBinaryFromReader);
      msg.setMainPhoto(value);
      break;
    case 7:
      var value = new proto.api.v1.photos.Date;
      reader.readMessage(value,proto.api.v1.photos.Date.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavourite(value);
      break;
    case 16:
      var value = new proto.api.v1.photos.PromoFeatures;
      reader.readMessage(value,proto.api.v1.photos.PromoFeatures.deserializeBinaryFromReader);
      msg.setPromoFeatures(value);
      break;
    case 17:
      var value = new proto.api.v1.photos.Location;
      reader.readMessage(value,proto.api.v1.photos.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.TinyAd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.TinyAd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.TinyAd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.TinyAd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getOldPrice();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getMainPhoto();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.api.v1.photos.Photo.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.api.v1.photos.Date.serializeBinaryToWriter
    );
  }
  f = message.getFavourite();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPromoFeatures();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.api.v1.photos.PromoFeatures.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.api.v1.photos.Location.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ad_id = 1;
 * @return {string}
 */
proto.api.v1.photos.TinyAd.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.TinyAd} returns this
 */
proto.api.v1.photos.TinyAd.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.api.v1.photos.TinyAd.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.TinyAd} returns this
 */
proto.api.v1.photos.TinyAd.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.api.v1.photos.TinyAd.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.TinyAd} returns this
 */
proto.api.v1.photos.TinyAd.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 price = 4;
 * @return {number}
 */
proto.api.v1.photos.TinyAd.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.TinyAd} returns this
 */
proto.api.v1.photos.TinyAd.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 old_price = 5;
 * @return {number}
 */
proto.api.v1.photos.TinyAd.prototype.getOldPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.TinyAd} returns this
 */
proto.api.v1.photos.TinyAd.prototype.setOldPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional Photo main_photo = 6;
 * @return {?proto.api.v1.photos.Photo}
 */
proto.api.v1.photos.TinyAd.prototype.getMainPhoto = function() {
  return /** @type{?proto.api.v1.photos.Photo} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.photos.Photo, 6));
};


/**
 * @param {?proto.api.v1.photos.Photo|undefined} value
 * @return {!proto.api.v1.photos.TinyAd} returns this
*/
proto.api.v1.photos.TinyAd.prototype.setMainPhoto = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.photos.TinyAd} returns this
 */
proto.api.v1.photos.TinyAd.prototype.clearMainPhoto = function() {
  return this.setMainPhoto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.photos.TinyAd.prototype.hasMainPhoto = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Date created_at = 7;
 * @return {?proto.api.v1.photos.Date}
 */
proto.api.v1.photos.TinyAd.prototype.getCreatedAt = function() {
  return /** @type{?proto.api.v1.photos.Date} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.photos.Date, 7));
};


/**
 * @param {?proto.api.v1.photos.Date|undefined} value
 * @return {!proto.api.v1.photos.TinyAd} returns this
*/
proto.api.v1.photos.TinyAd.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.photos.TinyAd} returns this
 */
proto.api.v1.photos.TinyAd.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.photos.TinyAd.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool favourite = 8;
 * @return {boolean}
 */
proto.api.v1.photos.TinyAd.prototype.getFavourite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.photos.TinyAd} returns this
 */
proto.api.v1.photos.TinyAd.prototype.setFavourite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional PromoFeatures promo_features = 16;
 * @return {?proto.api.v1.photos.PromoFeatures}
 */
proto.api.v1.photos.TinyAd.prototype.getPromoFeatures = function() {
  return /** @type{?proto.api.v1.photos.PromoFeatures} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.photos.PromoFeatures, 16));
};


/**
 * @param {?proto.api.v1.photos.PromoFeatures|undefined} value
 * @return {!proto.api.v1.photos.TinyAd} returns this
*/
proto.api.v1.photos.TinyAd.prototype.setPromoFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.photos.TinyAd} returns this
 */
proto.api.v1.photos.TinyAd.prototype.clearPromoFeatures = function() {
  return this.setPromoFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.photos.TinyAd.prototype.hasPromoFeatures = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Location location = 17;
 * @return {?proto.api.v1.photos.Location}
 */
proto.api.v1.photos.TinyAd.prototype.getLocation = function() {
  return /** @type{?proto.api.v1.photos.Location} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.photos.Location, 17));
};


/**
 * @param {?proto.api.v1.photos.Location|undefined} value
 * @return {!proto.api.v1.photos.TinyAd} returns this
*/
proto.api.v1.photos.TinyAd.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.photos.TinyAd} returns this
 */
proto.api.v1.photos.TinyAd.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.photos.TinyAd.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 17) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.TinyAdsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.TinyAdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.TinyAdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.TinyAdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.TinyAdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cursor: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adsList: jspb.Message.toObjectList(msg.getAdsList(),
    proto.api.v1.photos.TinyAd.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.TinyAdsResponse}
 */
proto.api.v1.photos.TinyAdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.TinyAdsResponse;
  return proto.api.v1.photos.TinyAdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.TinyAdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.TinyAdsResponse}
 */
proto.api.v1.photos.TinyAdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    case 2:
      var value = new proto.api.v1.photos.TinyAd;
      reader.readMessage(value,proto.api.v1.photos.TinyAd.deserializeBinaryFromReader);
      msg.addAds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.TinyAdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.TinyAdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.TinyAdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.TinyAdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.v1.photos.TinyAd.serializeBinaryToWriter
    );
  }
};


/**
 * optional string cursor = 1;
 * @return {string}
 */
proto.api.v1.photos.TinyAdsResponse.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.TinyAdsResponse} returns this
 */
proto.api.v1.photos.TinyAdsResponse.prototype.setCursor = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated TinyAd ads = 2;
 * @return {!Array<!proto.api.v1.photos.TinyAd>}
 */
proto.api.v1.photos.TinyAdsResponse.prototype.getAdsList = function() {
  return /** @type{!Array<!proto.api.v1.photos.TinyAd>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.TinyAd, 2));
};


/**
 * @param {!Array<!proto.api.v1.photos.TinyAd>} value
 * @return {!proto.api.v1.photos.TinyAdsResponse} returns this
*/
proto.api.v1.photos.TinyAdsResponse.prototype.setAdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.v1.photos.TinyAd=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.TinyAd}
 */
proto.api.v1.photos.TinyAdsResponse.prototype.addAds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.v1.photos.TinyAd, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.TinyAdsResponse} returns this
 */
proto.api.v1.photos.TinyAdsResponse.prototype.clearAdsList = function() {
  return this.setAdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.AdsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.AdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.AdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.AdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.AdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cursor: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adsList: jspb.Message.toObjectList(msg.getAdsList(),
    proto.api.v1.photos.Ad.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.AdsResponse}
 */
proto.api.v1.photos.AdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.AdsResponse;
  return proto.api.v1.photos.AdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.AdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.AdsResponse}
 */
proto.api.v1.photos.AdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    case 2:
      var value = new proto.api.v1.photos.Ad;
      reader.readMessage(value,proto.api.v1.photos.Ad.deserializeBinaryFromReader);
      msg.addAds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.AdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.AdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.AdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.AdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.v1.photos.Ad.serializeBinaryToWriter
    );
  }
};


/**
 * optional string cursor = 1;
 * @return {string}
 */
proto.api.v1.photos.AdsResponse.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.AdsResponse} returns this
 */
proto.api.v1.photos.AdsResponse.prototype.setCursor = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Ad ads = 2;
 * @return {!Array<!proto.api.v1.photos.Ad>}
 */
proto.api.v1.photos.AdsResponse.prototype.getAdsList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Ad>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Ad, 2));
};


/**
 * @param {!Array<!proto.api.v1.photos.Ad>} value
 * @return {!proto.api.v1.photos.AdsResponse} returns this
*/
proto.api.v1.photos.AdsResponse.prototype.setAdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.v1.photos.Ad=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Ad}
 */
proto.api.v1.photos.AdsResponse.prototype.addAds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.v1.photos.Ad, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.AdsResponse} returns this
 */
proto.api.v1.photos.AdsResponse.prototype.clearAdsList = function() {
  return this.setAdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.SubjectToponimRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.SubjectToponimRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.SubjectToponimRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.SubjectToponimRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    savedSearchId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.SubjectToponimRequest}
 */
proto.api.v1.photos.SubjectToponimRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.SubjectToponimRequest;
  return proto.api.v1.photos.SubjectToponimRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.SubjectToponimRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.SubjectToponimRequest}
 */
proto.api.v1.photos.SubjectToponimRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSavedSearchId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.SubjectToponimRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.SubjectToponimRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.SubjectToponimRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.SubjectToponimRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSavedSearchId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.api.v1.photos.SubjectToponimRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.SubjectToponimRequest} returns this
 */
proto.api.v1.photos.SubjectToponimRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ad_id = 2;
 * @return {string}
 */
proto.api.v1.photos.SubjectToponimRequest.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.SubjectToponimRequest} returns this
 */
proto.api.v1.photos.SubjectToponimRequest.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string saved_search_id = 3;
 * @return {string}
 */
proto.api.v1.photos.SubjectToponimRequest.prototype.getSavedSearchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.SubjectToponimRequest} returns this
 */
proto.api.v1.photos.SubjectToponimRequest.prototype.setSavedSearchId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.AssignResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.AssignResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.AssignResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.AssignResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.AssignResponse}
 */
proto.api.v1.photos.AssignResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.AssignResponse;
  return proto.api.v1.photos.AssignResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.AssignResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.AssignResponse}
 */
proto.api.v1.photos.AssignResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.AssignResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.AssignResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.AssignResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.AssignResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.api.v1.photos.AssignResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.photos.AssignResponse} returns this
 */
proto.api.v1.photos.AssignResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.AssignLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.AssignLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.AssignLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.AssignLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    toponim: (f = msg.getToponim()) && proto.api.v1.photos.Toponim.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    savedSearchId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.AssignLocationRequest}
 */
proto.api.v1.photos.AssignLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.AssignLocationRequest;
  return proto.api.v1.photos.AssignLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.AssignLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.AssignLocationRequest}
 */
proto.api.v1.photos.AssignLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.Toponim;
      reader.readMessage(value,proto.api.v1.photos.Toponim.deserializeBinaryFromReader);
      msg.setToponim(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSavedSearchId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.AssignLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.AssignLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.AssignLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.AssignLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToponim();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.v1.photos.Toponim.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSavedSearchId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional Toponim toponim = 1;
 * @return {?proto.api.v1.photos.Toponim}
 */
proto.api.v1.photos.AssignLocationRequest.prototype.getToponim = function() {
  return /** @type{?proto.api.v1.photos.Toponim} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.photos.Toponim, 1));
};


/**
 * @param {?proto.api.v1.photos.Toponim|undefined} value
 * @return {!proto.api.v1.photos.AssignLocationRequest} returns this
*/
proto.api.v1.photos.AssignLocationRequest.prototype.setToponim = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.photos.AssignLocationRequest} returns this
 */
proto.api.v1.photos.AssignLocationRequest.prototype.clearToponim = function() {
  return this.setToponim(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.photos.AssignLocationRequest.prototype.hasToponim = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.api.v1.photos.AssignLocationRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.AssignLocationRequest} returns this
 */
proto.api.v1.photos.AssignLocationRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ad_id = 3;
 * @return {string}
 */
proto.api.v1.photos.AssignLocationRequest.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.AssignLocationRequest} returns this
 */
proto.api.v1.photos.AssignLocationRequest.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string saved_search_id = 4;
 * @return {string}
 */
proto.api.v1.photos.AssignLocationRequest.prototype.getSavedSearchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.AssignLocationRequest} returns this
 */
proto.api.v1.photos.AssignLocationRequest.prototype.setSavedSearchId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.GetSubwaysRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.GetSubwaysRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.GetSubwaysRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.GetSubwaysRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cityId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.GetSubwaysRequest}
 */
proto.api.v1.photos.GetSubwaysRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.GetSubwaysRequest;
  return proto.api.v1.photos.GetSubwaysRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.GetSubwaysRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.GetSubwaysRequest}
 */
proto.api.v1.photos.GetSubwaysRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.GetSubwaysRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.GetSubwaysRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.GetSubwaysRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.GetSubwaysRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string city_id = 1;
 * @return {string}
 */
proto.api.v1.photos.GetSubwaysRequest.prototype.getCityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.GetSubwaysRequest} returns this
 */
proto.api.v1.photos.GetSubwaysRequest.prototype.setCityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Lines.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Lines.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Lines.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Lines} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Lines.toObject = function(includeInstance, msg) {
  var f, obj = {
    linesList: jspb.Message.toObjectList(msg.getLinesList(),
    proto.api.v1.photos.Line.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Lines}
 */
proto.api.v1.photos.Lines.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Lines;
  return proto.api.v1.photos.Lines.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Lines} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Lines}
 */
proto.api.v1.photos.Lines.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.Line;
      reader.readMessage(value,proto.api.v1.photos.Line.deserializeBinaryFromReader);
      msg.addLines(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Lines.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Lines.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Lines} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Lines.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.Line.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Line lines = 1;
 * @return {!Array<!proto.api.v1.photos.Line>}
 */
proto.api.v1.photos.Lines.prototype.getLinesList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Line>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Line, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.Line>} value
 * @return {!proto.api.v1.photos.Lines} returns this
*/
proto.api.v1.photos.Lines.prototype.setLinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.Line=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Line}
 */
proto.api.v1.photos.Lines.prototype.addLines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.Line, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Lines} returns this
 */
proto.api.v1.photos.Lines.prototype.clearLinesList = function() {
  return this.setLinesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Toponims.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Toponims.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Toponims.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Toponims} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Toponims.toObject = function(includeInstance, msg) {
  var f, obj = {
    toponimsList: jspb.Message.toObjectList(msg.getToponimsList(),
    proto.api.v1.photos.Toponim.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Toponims}
 */
proto.api.v1.photos.Toponims.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Toponims;
  return proto.api.v1.photos.Toponims.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Toponims} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Toponims}
 */
proto.api.v1.photos.Toponims.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.Toponim;
      reader.readMessage(value,proto.api.v1.photos.Toponim.deserializeBinaryFromReader);
      msg.addToponims(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Toponims.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Toponims.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Toponims} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Toponims.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToponimsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.Toponim.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Toponim toponims = 1;
 * @return {!Array<!proto.api.v1.photos.Toponim>}
 */
proto.api.v1.photos.Toponims.prototype.getToponimsList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Toponim>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Toponim, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.Toponim>} value
 * @return {!proto.api.v1.photos.Toponims} returns this
*/
proto.api.v1.photos.Toponims.prototype.setToponimsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.Toponim=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Toponim}
 */
proto.api.v1.photos.Toponims.prototype.addToponims = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.Toponim, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Toponims} returns this
 */
proto.api.v1.photos.Toponims.prototype.clearToponimsList = function() {
  return this.setToponimsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.ToponimRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.ToponimRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.ToponimRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.ToponimRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    minFiasLevel: jspb.Message.getFieldWithDefault(msg, 2, 0),
    maxFiasLevel: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.ToponimRequest}
 */
proto.api.v1.photos.ToponimRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.ToponimRequest;
  return proto.api.v1.photos.ToponimRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.ToponimRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.ToponimRequest}
 */
proto.api.v1.photos.ToponimRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinFiasLevel(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxFiasLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.ToponimRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.ToponimRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.ToponimRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.ToponimRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMinFiasLevel();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMaxFiasLevel();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.api.v1.photos.ToponimRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.ToponimRequest} returns this
 */
proto.api.v1.photos.ToponimRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 min_fias_level = 2;
 * @return {number}
 */
proto.api.v1.photos.ToponimRequest.prototype.getMinFiasLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.ToponimRequest} returns this
 */
proto.api.v1.photos.ToponimRequest.prototype.setMinFiasLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 max_fias_level = 3;
 * @return {number}
 */
proto.api.v1.photos.ToponimRequest.prototype.getMaxFiasLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.ToponimRequest} returns this
 */
proto.api.v1.photos.ToponimRequest.prototype.setMaxFiasLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Toponim.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Toponim.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Toponim} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Toponim.toObject = function(includeInstance, msg) {
  var f, obj = {
    country: jspb.Message.getFieldWithDefault(msg, 1, ""),
    countryIsoCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    region: jspb.Message.getFieldWithDefault(msg, 3, ""),
    regionFiasId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    regionWithType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    area: jspb.Message.getFieldWithDefault(msg, 6, ""),
    areaFiasId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    areaWithType: jspb.Message.getFieldWithDefault(msg, 8, ""),
    cityFiasId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    cityTypeFull: jspb.Message.getFieldWithDefault(msg, 10, ""),
    cityDistrictFiasId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    cityDistrict: jspb.Message.getFieldWithDefault(msg, 12, ""),
    settlementFiasId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    settlementWithType: jspb.Message.getFieldWithDefault(msg, 14, ""),
    streetFiasId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    street: jspb.Message.getFieldWithDefault(msg, 16, ""),
    houseFiasId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    house: jspb.Message.getFieldWithDefault(msg, 18, ""),
    block: jspb.Message.getFieldWithDefault(msg, 19, ""),
    flat: jspb.Message.getFieldWithDefault(msg, 20, ""),
    fiasId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    geoLat: jspb.Message.getFieldWithDefault(msg, 22, ""),
    geoLon: jspb.Message.getFieldWithDefault(msg, 23, ""),
    fiasLevel: jspb.Message.getFieldWithDefault(msg, 24, ""),
    humanName: jspb.Message.getFieldWithDefault(msg, 25, ""),
    metro: jspb.Message.getFieldWithDefault(msg, 26, ""),
    id: jspb.Message.getFieldWithDefault(msg, 27, ""),
    cityHumanName: jspb.Message.getFieldWithDefault(msg, 28, ""),
    radius: jspb.Message.getFieldWithDefault(msg, 29, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Toponim}
 */
proto.api.v1.photos.Toponim.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Toponim;
  return proto.api.v1.photos.Toponim.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Toponim} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Toponim}
 */
proto.api.v1.photos.Toponim.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryIsoCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionFiasId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionWithType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setArea(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAreaFiasId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAreaWithType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityFiasId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityTypeFull(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityDistrictFiasId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityDistrict(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementFiasId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementWithType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreetFiasId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setHouseFiasId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setHouse(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlock(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlat(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setFiasId(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setGeoLat(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setGeoLon(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setFiasLevel(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setHumanName(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetro(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityHumanName(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRadius(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Toponim.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Toponim.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Toponim} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Toponim.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountryIsoCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRegionFiasId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRegionWithType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getArea();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAreaFiasId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAreaWithType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCityFiasId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCityTypeFull();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCityDistrictFiasId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCityDistrict();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSettlementFiasId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSettlementWithType();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getStreetFiasId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getHouseFiasId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getHouse();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getBlock();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getFlat();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getFiasId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getGeoLat();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getGeoLon();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getFiasLevel();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getHumanName();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getMetro();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getCityHumanName();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0) {
    writer.writeInt64(
      29,
      f
    );
  }
};


/**
 * optional string country = 1;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country_iso_code = 2;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getCountryIsoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setCountryIsoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string region = 3;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string region_fias_id = 4;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getRegionFiasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setRegionFiasId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string region_with_type = 5;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getRegionWithType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setRegionWithType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string area = 6;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getArea = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setArea = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string area_fias_id = 7;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getAreaFiasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setAreaFiasId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string area_with_type = 8;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getAreaWithType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setAreaWithType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string city_fias_id = 9;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getCityFiasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setCityFiasId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string city_type_full = 10;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getCityTypeFull = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setCityTypeFull = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string city_district_fias_id = 11;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getCityDistrictFiasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setCityDistrictFiasId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string city_district = 12;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getCityDistrict = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setCityDistrict = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string settlement_fias_id = 13;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getSettlementFiasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setSettlementFiasId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string settlement_with_type = 14;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getSettlementWithType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setSettlementWithType = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string street_fias_id = 15;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getStreetFiasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setStreetFiasId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string street = 16;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string house_fias_id = 17;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getHouseFiasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setHouseFiasId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string house = 18;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getHouse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setHouse = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string block = 19;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getBlock = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setBlock = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string flat = 20;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getFlat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setFlat = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string fias_id = 21;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getFiasId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setFiasId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string geo_lat = 22;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getGeoLat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setGeoLat = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string geo_lon = 23;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getGeoLon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setGeoLon = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string fias_level = 24;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getFiasLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setFiasLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string human_name = 25;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getHumanName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setHumanName = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string metro = 26;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getMetro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setMetro = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string id = 27;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string city_human_name = 28;
 * @return {string}
 */
proto.api.v1.photos.Toponim.prototype.getCityHumanName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setCityHumanName = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional int64 radius = 29;
 * @return {number}
 */
proto.api.v1.photos.Toponim.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.Toponim} returns this
 */
proto.api.v1.photos.Toponim.prototype.setRadius = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    lat: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lon: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Location}
 */
proto.api.v1.photos.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Location;
  return proto.api.v1.photos.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Location}
 */
proto.api.v1.photos.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLat(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLat();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLon();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string lat = 1;
 * @return {string}
 */
proto.api.v1.photos.Location.prototype.getLat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Location} returns this
 */
proto.api.v1.photos.Location.prototype.setLat = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string lon = 2;
 * @return {string}
 */
proto.api.v1.photos.Location.prototype.getLon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Location} returns this
 */
proto.api.v1.photos.Location.prototype.setLon = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.City.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.City.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.City} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.City.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: (f = msg.getLocation()) && proto.api.v1.photos.Location.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.City}
 */
proto.api.v1.photos.City.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.City;
  return proto.api.v1.photos.City.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.City} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.City}
 */
proto.api.v1.photos.City.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.api.v1.photos.Location;
      reader.readMessage(value,proto.api.v1.photos.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.City.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.City.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.City} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.City.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.v1.photos.Location.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.v1.photos.City.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.City} returns this
 */
proto.api.v1.photos.City.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.v1.photos.City.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.City} returns this
 */
proto.api.v1.photos.City.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Location location = 3;
 * @return {?proto.api.v1.photos.Location}
 */
proto.api.v1.photos.City.prototype.getLocation = function() {
  return /** @type{?proto.api.v1.photos.Location} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.photos.Location, 3));
};


/**
 * @param {?proto.api.v1.photos.Location|undefined} value
 * @return {!proto.api.v1.photos.City} returns this
*/
proto.api.v1.photos.City.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.photos.City} returns this
 */
proto.api.v1.photos.City.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.photos.City.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Line.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Line.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Line.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Line} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Line.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    color: jspb.Message.getFieldWithDefault(msg, 3, ""),
    stationsList: jspb.Message.toObjectList(msg.getStationsList(),
    proto.api.v1.photos.Station.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Line}
 */
proto.api.v1.photos.Line.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Line;
  return proto.api.v1.photos.Line.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Line} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Line}
 */
proto.api.v1.photos.Line.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 4:
      var value = new proto.api.v1.photos.Station;
      reader.readMessage(value,proto.api.v1.photos.Station.deserializeBinaryFromReader);
      msg.addStations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Line.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Line.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Line} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Line.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.api.v1.photos.Station.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.v1.photos.Line.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Line} returns this
 */
proto.api.v1.photos.Line.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.v1.photos.Line.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Line} returns this
 */
proto.api.v1.photos.Line.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string color = 3;
 * @return {string}
 */
proto.api.v1.photos.Line.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Line} returns this
 */
proto.api.v1.photos.Line.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Station stations = 4;
 * @return {!Array<!proto.api.v1.photos.Station>}
 */
proto.api.v1.photos.Line.prototype.getStationsList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Station>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Station, 4));
};


/**
 * @param {!Array<!proto.api.v1.photos.Station>} value
 * @return {!proto.api.v1.photos.Line} returns this
*/
proto.api.v1.photos.Line.prototype.setStationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.api.v1.photos.Station=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Station}
 */
proto.api.v1.photos.Line.prototype.addStations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.api.v1.photos.Station, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Line} returns this
 */
proto.api.v1.photos.Line.prototype.clearStationsList = function() {
  return this.setStationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Station.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Station.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Station} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Station.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: (f = msg.getLocation()) && proto.api.v1.photos.Location.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Station}
 */
proto.api.v1.photos.Station.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Station;
  return proto.api.v1.photos.Station.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Station} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Station}
 */
proto.api.v1.photos.Station.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.api.v1.photos.Location;
      reader.readMessage(value,proto.api.v1.photos.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Station.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Station.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Station} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Station.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.v1.photos.Location.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.v1.photos.Station.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Station} returns this
 */
proto.api.v1.photos.Station.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.v1.photos.Station.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Station} returns this
 */
proto.api.v1.photos.Station.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Location location = 3;
 * @return {?proto.api.v1.photos.Location}
 */
proto.api.v1.photos.Station.prototype.getLocation = function() {
  return /** @type{?proto.api.v1.photos.Location} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.photos.Location, 3));
};


/**
 * @param {?proto.api.v1.photos.Location|undefined} value
 * @return {!proto.api.v1.photos.Station} returns this
*/
proto.api.v1.photos.Station.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.photos.Station} returns this
 */
proto.api.v1.photos.Station.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.photos.Station.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.FavouriteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.FavouriteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.FavouriteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.FavouriteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.FavouriteRequest}
 */
proto.api.v1.photos.FavouriteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.FavouriteRequest;
  return proto.api.v1.photos.FavouriteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.FavouriteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.FavouriteRequest}
 */
proto.api.v1.photos.FavouriteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.FavouriteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.FavouriteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.FavouriteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.FavouriteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.api.v1.photos.FavouriteRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.FavouriteRequest} returns this
 */
proto.api.v1.photos.FavouriteRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ad_id = 2;
 * @return {string}
 */
proto.api.v1.photos.FavouriteRequest.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.FavouriteRequest} returns this
 */
proto.api.v1.photos.FavouriteRequest.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.ViewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.ViewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.ViewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.ViewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.ViewRequest}
 */
proto.api.v1.photos.ViewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.ViewRequest;
  return proto.api.v1.photos.ViewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.ViewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.ViewRequest}
 */
proto.api.v1.photos.ViewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.ViewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.ViewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.ViewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.ViewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.api.v1.photos.ViewRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.ViewRequest} returns this
 */
proto.api.v1.photos.ViewRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ad_id = 2;
 * @return {string}
 */
proto.api.v1.photos.ViewRequest.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.ViewRequest} returns this
 */
proto.api.v1.photos.ViewRequest.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.AdId.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.AdId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.AdId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.AdId.toObject = function(includeInstance, msg) {
  var f, obj = {
    adId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.AdId}
 */
proto.api.v1.photos.AdId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.AdId;
  return proto.api.v1.photos.AdId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.AdId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.AdId}
 */
proto.api.v1.photos.AdId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.AdId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.AdId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.AdId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.AdId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ad_id = 1;
 * @return {string}
 */
proto.api.v1.photos.AdId.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.AdId} returns this
 */
proto.api.v1.photos.AdId.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.AdIds.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.AdIds.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.AdIds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.AdIds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.AdIds.toObject = function(includeInstance, msg) {
  var f, obj = {
    adIdsList: jspb.Message.toObjectList(msg.getAdIdsList(),
    proto.api.v1.photos.AdId.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.AdIds}
 */
proto.api.v1.photos.AdIds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.AdIds;
  return proto.api.v1.photos.AdIds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.AdIds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.AdIds}
 */
proto.api.v1.photos.AdIds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.AdId;
      reader.readMessage(value,proto.api.v1.photos.AdId.deserializeBinaryFromReader);
      msg.addAdIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.AdIds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.AdIds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.AdIds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.AdIds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.AdId.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AdId ad_ids = 1;
 * @return {!Array<!proto.api.v1.photos.AdId>}
 */
proto.api.v1.photos.AdIds.prototype.getAdIdsList = function() {
  return /** @type{!Array<!proto.api.v1.photos.AdId>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.AdId, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.AdId>} value
 * @return {!proto.api.v1.photos.AdIds} returns this
*/
proto.api.v1.photos.AdIds.prototype.setAdIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.AdId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.AdId}
 */
proto.api.v1.photos.AdIds.prototype.addAdIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.AdId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.AdIds} returns this
 */
proto.api.v1.photos.AdIds.prototype.clearAdIdsList = function() {
  return this.setAdIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.BlackListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.BlackListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.BlackListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.BlackListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    blackedUserId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.BlackListRequest}
 */
proto.api.v1.photos.BlackListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.BlackListRequest;
  return proto.api.v1.photos.BlackListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.BlackListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.BlackListRequest}
 */
proto.api.v1.photos.BlackListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlackedUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.BlackListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.BlackListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.BlackListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.BlackListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBlackedUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.api.v1.photos.BlackListRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.BlackListRequest} returns this
 */
proto.api.v1.photos.BlackListRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string blacked_user_id = 2;
 * @return {string}
 */
proto.api.v1.photos.BlackListRequest.prototype.getBlackedUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.BlackListRequest} returns this
 */
proto.api.v1.photos.BlackListRequest.prototype.setBlackedUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.TinyUser.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.TinyUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.TinyUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.TinyUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    thumbUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.TinyUser}
 */
proto.api.v1.photos.TinyUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.TinyUser;
  return proto.api.v1.photos.TinyUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.TinyUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.TinyUser}
 */
proto.api.v1.photos.TinyUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.TinyUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.TinyUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.TinyUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.TinyUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getThumbUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.api.v1.photos.TinyUser.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.TinyUser} returns this
 */
proto.api.v1.photos.TinyUser.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string thumb_url = 2;
 * @return {string}
 */
proto.api.v1.photos.TinyUser.prototype.getThumbUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.TinyUser} returns this
 */
proto.api.v1.photos.TinyUser.prototype.setThumbUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.api.v1.photos.TinyUser.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.TinyUser} returns this
 */
proto.api.v1.photos.TinyUser.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.TinyUsers.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.TinyUsers.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.TinyUsers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.TinyUsers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.TinyUsers.toObject = function(includeInstance, msg) {
  var f, obj = {
    tinyusersList: jspb.Message.toObjectList(msg.getTinyusersList(),
    proto.api.v1.photos.TinyUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.TinyUsers}
 */
proto.api.v1.photos.TinyUsers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.TinyUsers;
  return proto.api.v1.photos.TinyUsers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.TinyUsers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.TinyUsers}
 */
proto.api.v1.photos.TinyUsers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.TinyUser;
      reader.readMessage(value,proto.api.v1.photos.TinyUser.deserializeBinaryFromReader);
      msg.addTinyusers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.TinyUsers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.TinyUsers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.TinyUsers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.TinyUsers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTinyusersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.TinyUser.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TinyUser tinyusers = 1;
 * @return {!Array<!proto.api.v1.photos.TinyUser>}
 */
proto.api.v1.photos.TinyUsers.prototype.getTinyusersList = function() {
  return /** @type{!Array<!proto.api.v1.photos.TinyUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.TinyUser, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.TinyUser>} value
 * @return {!proto.api.v1.photos.TinyUsers} returns this
*/
proto.api.v1.photos.TinyUsers.prototype.setTinyusersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.TinyUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.TinyUser}
 */
proto.api.v1.photos.TinyUsers.prototype.addTinyusers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.TinyUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.TinyUsers} returns this
 */
proto.api.v1.photos.TinyUsers.prototype.clearTinyusersList = function() {
  return this.setTinyusersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.RemoveParticipationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.RemoveParticipationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.RemoveParticipationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.RemoveParticipationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dialogId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.RemoveParticipationRequest}
 */
proto.api.v1.photos.RemoveParticipationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.RemoveParticipationRequest;
  return proto.api.v1.photos.RemoveParticipationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.RemoveParticipationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.RemoveParticipationRequest}
 */
proto.api.v1.photos.RemoveParticipationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDialogId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.RemoveParticipationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.RemoveParticipationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.RemoveParticipationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.RemoveParticipationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDialogId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string dialog_id = 1;
 * @return {string}
 */
proto.api.v1.photos.RemoveParticipationRequest.prototype.getDialogId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.RemoveParticipationRequest} returns this
 */
proto.api.v1.photos.RemoveParticipationRequest.prototype.setDialogId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.api.v1.photos.RemoveParticipationRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.RemoveParticipationRequest} returns this
 */
proto.api.v1.photos.RemoveParticipationRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.RemoveParticipationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.RemoveParticipationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.RemoveParticipationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.RemoveParticipationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.RemoveParticipationResponse}
 */
proto.api.v1.photos.RemoveParticipationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.RemoveParticipationResponse;
  return proto.api.v1.photos.RemoveParticipationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.RemoveParticipationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.RemoveParticipationResponse}
 */
proto.api.v1.photos.RemoveParticipationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.RemoveParticipationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.RemoveParticipationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.RemoveParticipationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.RemoveParticipationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.api.v1.photos.RemoveParticipationResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.photos.RemoveParticipationResponse} returns this
 */
proto.api.v1.photos.RemoveParticipationResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.RemoveMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.RemoveMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.RemoveMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.RemoveMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.RemoveMessageRequest}
 */
proto.api.v1.photos.RemoveMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.RemoveMessageRequest;
  return proto.api.v1.photos.RemoveMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.RemoveMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.RemoveMessageRequest}
 */
proto.api.v1.photos.RemoveMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.RemoveMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.RemoveMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.RemoveMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.RemoveMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.api.v1.photos.RemoveMessageRequest.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.RemoveMessageRequest} returns this
 */
proto.api.v1.photos.RemoveMessageRequest.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.api.v1.photos.RemoveMessageRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.RemoveMessageRequest} returns this
 */
proto.api.v1.photos.RemoveMessageRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.RemoveMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.RemoveMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.RemoveMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.RemoveMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.RemoveMessageResponse}
 */
proto.api.v1.photos.RemoveMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.RemoveMessageResponse;
  return proto.api.v1.photos.RemoveMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.RemoveMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.RemoveMessageResponse}
 */
proto.api.v1.photos.RemoveMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.RemoveMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.RemoveMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.RemoveMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.RemoveMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.api.v1.photos.RemoveMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.photos.RemoveMessageResponse} returns this
 */
proto.api.v1.photos.RemoveMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.MessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.MessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.MessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.MessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    adId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: (f = msg.getMessage()) && proto.api.v1.photos.Message.toObject(includeInstance, f),
    dialogId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.MessageRequest}
 */
proto.api.v1.photos.MessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.MessageRequest;
  return proto.api.v1.photos.MessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.MessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.MessageRequest}
 */
proto.api.v1.photos.MessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    case 2:
      var value = new proto.api.v1.photos.Message;
      reader.readMessage(value,proto.api.v1.photos.Message.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDialogId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.MessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.MessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.MessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.MessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.v1.photos.Message.serializeBinaryToWriter
    );
  }
  f = message.getDialogId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string ad_id = 1;
 * @return {string}
 */
proto.api.v1.photos.MessageRequest.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.MessageRequest} returns this
 */
proto.api.v1.photos.MessageRequest.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Message message = 2;
 * @return {?proto.api.v1.photos.Message}
 */
proto.api.v1.photos.MessageRequest.prototype.getMessage = function() {
  return /** @type{?proto.api.v1.photos.Message} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.photos.Message, 2));
};


/**
 * @param {?proto.api.v1.photos.Message|undefined} value
 * @return {!proto.api.v1.photos.MessageRequest} returns this
*/
proto.api.v1.photos.MessageRequest.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.photos.MessageRequest} returns this
 */
proto.api.v1.photos.MessageRequest.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.photos.MessageRequest.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string dialog_id = 3;
 * @return {string}
 */
proto.api.v1.photos.MessageRequest.prototype.getDialogId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.MessageRequest} returns this
 */
proto.api.v1.photos.MessageRequest.prototype.setDialogId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Dialogs.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Dialogs.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Dialogs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Dialogs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Dialogs.toObject = function(includeInstance, msg) {
  var f, obj = {
    dialogsList: jspb.Message.toObjectList(msg.getDialogsList(),
    proto.api.v1.photos.Dialog.toObject, includeInstance),
    cursor: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Dialogs}
 */
proto.api.v1.photos.Dialogs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Dialogs;
  return proto.api.v1.photos.Dialogs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Dialogs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Dialogs}
 */
proto.api.v1.photos.Dialogs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.Dialog;
      reader.readMessage(value,proto.api.v1.photos.Dialog.deserializeBinaryFromReader);
      msg.addDialogs(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Dialogs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Dialogs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Dialogs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Dialogs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDialogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.Dialog.serializeBinaryToWriter
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Dialog dialogs = 1;
 * @return {!Array<!proto.api.v1.photos.Dialog>}
 */
proto.api.v1.photos.Dialogs.prototype.getDialogsList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Dialog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Dialog, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.Dialog>} value
 * @return {!proto.api.v1.photos.Dialogs} returns this
*/
proto.api.v1.photos.Dialogs.prototype.setDialogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.Dialog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Dialog}
 */
proto.api.v1.photos.Dialogs.prototype.addDialogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.Dialog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Dialogs} returns this
 */
proto.api.v1.photos.Dialogs.prototype.clearDialogsList = function() {
  return this.setDialogsList([]);
};


/**
 * optional string cursor = 2;
 * @return {string}
 */
proto.api.v1.photos.Dialogs.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Dialogs} returns this
 */
proto.api.v1.photos.Dialogs.prototype.setCursor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.UserDialogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.UserDialogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.UserDialogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.UserDialogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cursor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    perPage: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.UserDialogRequest}
 */
proto.api.v1.photos.UserDialogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.UserDialogRequest;
  return proto.api.v1.photos.UserDialogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.UserDialogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.UserDialogRequest}
 */
proto.api.v1.photos.UserDialogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.UserDialogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.UserDialogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.UserDialogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.UserDialogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.api.v1.photos.UserDialogRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.UserDialogRequest} returns this
 */
proto.api.v1.photos.UserDialogRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cursor = 2;
 * @return {string}
 */
proto.api.v1.photos.UserDialogRequest.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.UserDialogRequest} returns this
 */
proto.api.v1.photos.UserDialogRequest.prototype.setCursor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 per_page = 3;
 * @return {number}
 */
proto.api.v1.photos.UserDialogRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.UserDialogRequest} returns this
 */
proto.api.v1.photos.UserDialogRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.DialogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.DialogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.DialogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.DialogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dialogId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cursor: jspb.Message.getFieldWithDefault(msg, 3, ""),
    perPage: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.DialogRequest}
 */
proto.api.v1.photos.DialogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.DialogRequest;
  return proto.api.v1.photos.DialogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.DialogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.DialogRequest}
 */
proto.api.v1.photos.DialogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDialogId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.DialogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.DialogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.DialogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.DialogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDialogId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string dialog_id = 1;
 * @return {string}
 */
proto.api.v1.photos.DialogRequest.prototype.getDialogId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.DialogRequest} returns this
 */
proto.api.v1.photos.DialogRequest.prototype.setDialogId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.api.v1.photos.DialogRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.DialogRequest} returns this
 */
proto.api.v1.photos.DialogRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string cursor = 3;
 * @return {string}
 */
proto.api.v1.photos.DialogRequest.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.DialogRequest} returns this
 */
proto.api.v1.photos.DialogRequest.prototype.setCursor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 per_page = 4;
 * @return {number}
 */
proto.api.v1.photos.DialogRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.DialogRequest} returns this
 */
proto.api.v1.photos.DialogRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Dialog.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Dialog.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Dialog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Dialog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Dialog.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ad: (f = msg.getAd()) && proto.api.v1.photos.Ad.toObject(includeInstance, f),
    unreadMessages: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    messagesList: jspb.Message.toObjectList(msg.getMessagesList(),
    proto.api.v1.photos.Message.toObject, includeInstance),
    cursor: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Dialog}
 */
proto.api.v1.photos.Dialog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Dialog;
  return proto.api.v1.photos.Dialog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Dialog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Dialog}
 */
proto.api.v1.photos.Dialog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = new proto.api.v1.photos.Ad;
      reader.readMessage(value,proto.api.v1.photos.Ad.deserializeBinaryFromReader);
      msg.setAd(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnreadMessages(value);
      break;
    case 4:
      var value = new proto.api.v1.photos.Message;
      reader.readMessage(value,proto.api.v1.photos.Message.deserializeBinaryFromReader);
      msg.addMessages(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Dialog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Dialog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Dialog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Dialog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.v1.photos.Ad.serializeBinaryToWriter
    );
  }
  f = message.getUnreadMessages();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.api.v1.photos.Message.serializeBinaryToWriter
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.api.v1.photos.Dialog.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Dialog} returns this
 */
proto.api.v1.photos.Dialog.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Ad ad = 2;
 * @return {?proto.api.v1.photos.Ad}
 */
proto.api.v1.photos.Dialog.prototype.getAd = function() {
  return /** @type{?proto.api.v1.photos.Ad} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.photos.Ad, 2));
};


/**
 * @param {?proto.api.v1.photos.Ad|undefined} value
 * @return {!proto.api.v1.photos.Dialog} returns this
*/
proto.api.v1.photos.Dialog.prototype.setAd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.photos.Dialog} returns this
 */
proto.api.v1.photos.Dialog.prototype.clearAd = function() {
  return this.setAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.photos.Dialog.prototype.hasAd = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool unread_messages = 3;
 * @return {boolean}
 */
proto.api.v1.photos.Dialog.prototype.getUnreadMessages = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.photos.Dialog} returns this
 */
proto.api.v1.photos.Dialog.prototype.setUnreadMessages = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated Message messages = 4;
 * @return {!Array<!proto.api.v1.photos.Message>}
 */
proto.api.v1.photos.Dialog.prototype.getMessagesList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Message>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Message, 4));
};


/**
 * @param {!Array<!proto.api.v1.photos.Message>} value
 * @return {!proto.api.v1.photos.Dialog} returns this
*/
proto.api.v1.photos.Dialog.prototype.setMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.api.v1.photos.Message=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Message}
 */
proto.api.v1.photos.Dialog.prototype.addMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.api.v1.photos.Message, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Dialog} returns this
 */
proto.api.v1.photos.Dialog.prototype.clearMessagesList = function() {
  return this.setMessagesList([]);
};


/**
 * optional string cursor = 5;
 * @return {string}
 */
proto.api.v1.photos.Dialog.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Dialog} returns this
 */
proto.api.v1.photos.Dialog.prototype.setCursor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Messages.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Messages.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Messages.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Messages} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Messages.toObject = function(includeInstance, msg) {
  var f, obj = {
    messagesList: jspb.Message.toObjectList(msg.getMessagesList(),
    proto.api.v1.photos.Message.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Messages}
 */
proto.api.v1.photos.Messages.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Messages;
  return proto.api.v1.photos.Messages.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Messages} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Messages}
 */
proto.api.v1.photos.Messages.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.Message;
      reader.readMessage(value,proto.api.v1.photos.Message.deserializeBinaryFromReader);
      msg.addMessages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Messages.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Messages.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Messages} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Messages.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.Message.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Message messages = 1;
 * @return {!Array<!proto.api.v1.photos.Message>}
 */
proto.api.v1.photos.Messages.prototype.getMessagesList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Message>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Message, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.Message>} value
 * @return {!proto.api.v1.photos.Messages} returns this
*/
proto.api.v1.photos.Messages.prototype.setMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.Message=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Message}
 */
proto.api.v1.photos.Messages.prototype.addMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.Message, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Messages} returns this
 */
proto.api.v1.photos.Messages.prototype.clearMessagesList = function() {
  return this.setMessagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Message.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Message.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Message} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Message.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    payload: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    viewed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    uuid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    user: (f = msg.getUser()) && proto.api.v1.photos.TinyUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Message}
 */
proto.api.v1.photos.Message.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Message;
  return proto.api.v1.photos.Message.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Message}
 */
proto.api.v1.photos.Message.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewed(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 6:
      var value = new proto.api.v1.photos.TinyUser;
      reader.readMessage(value,proto.api.v1.photos.TinyUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Message.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Message.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getViewed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.api.v1.photos.TinyUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.api.v1.photos.Message.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Message} returns this
 */
proto.api.v1.photos.Message.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string payload = 2;
 * @return {string}
 */
proto.api.v1.photos.Message.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Message} returns this
 */
proto.api.v1.photos.Message.prototype.setPayload = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 created_at = 3;
 * @return {number}
 */
proto.api.v1.photos.Message.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.Message} returns this
 */
proto.api.v1.photos.Message.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool viewed = 4;
 * @return {boolean}
 */
proto.api.v1.photos.Message.prototype.getViewed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.photos.Message} returns this
 */
proto.api.v1.photos.Message.prototype.setViewed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string uuid = 5;
 * @return {string}
 */
proto.api.v1.photos.Message.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Message} returns this
 */
proto.api.v1.photos.Message.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional TinyUser user = 6;
 * @return {?proto.api.v1.photos.TinyUser}
 */
proto.api.v1.photos.Message.prototype.getUser = function() {
  return /** @type{?proto.api.v1.photos.TinyUser} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.photos.TinyUser, 6));
};


/**
 * @param {?proto.api.v1.photos.TinyUser|undefined} value
 * @return {!proto.api.v1.photos.Message} returns this
*/
proto.api.v1.photos.Message.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.photos.Message} returns this
 */
proto.api.v1.photos.Message.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.photos.Message.prototype.hasUser = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.LatLng.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.LatLng.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.LatLng} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.LatLng.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.LatLng}
 */
proto.api.v1.photos.LatLng.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.LatLng;
  return proto.api.v1.photos.LatLng.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.LatLng} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.LatLng}
 */
proto.api.v1.photos.LatLng.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.LatLng.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.LatLng.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.LatLng} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.LatLng.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double latitude = 1;
 * @return {number}
 */
proto.api.v1.photos.LatLng.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.LatLng} returns this
 */
proto.api.v1.photos.LatLng.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double longitude = 2;
 * @return {number}
 */
proto.api.v1.photos.LatLng.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.LatLng} returns this
 */
proto.api.v1.photos.LatLng.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Ads.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Ads.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Ads.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Ads} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Ads.toObject = function(includeInstance, msg) {
  var f, obj = {
    adsList: jspb.Message.toObjectList(msg.getAdsList(),
    proto.api.v1.photos.Ad.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Ads}
 */
proto.api.v1.photos.Ads.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Ads;
  return proto.api.v1.photos.Ads.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Ads} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Ads}
 */
proto.api.v1.photos.Ads.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.Ad;
      reader.readMessage(value,proto.api.v1.photos.Ad.deserializeBinaryFromReader);
      msg.addAds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Ads.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Ads.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Ads} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Ads.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.Ad.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Ad ads = 1;
 * @return {!Array<!proto.api.v1.photos.Ad>}
 */
proto.api.v1.photos.Ads.prototype.getAdsList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Ad>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Ad, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.Ad>} value
 * @return {!proto.api.v1.photos.Ads} returns this
*/
proto.api.v1.photos.Ads.prototype.setAdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.Ad=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Ad}
 */
proto.api.v1.photos.Ads.prototype.addAds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.Ad, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Ads} returns this
 */
proto.api.v1.photos.Ads.prototype.clearAdsList = function() {
  return this.setAdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Ad.repeatedFields_ = [15,16,18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Ad.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Ad.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Ad} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Ad.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, 0),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    flag: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    price: jspb.Message.getFieldWithDefault(msg, 6, 0),
    location: (f = msg.getLocation()) && proto.api.v1.photos.LatLng.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    address: jspb.Message.getFieldWithDefault(msg, 10, ""),
    userLocation: (f = msg.getUserLocation()) && proto.api.v1.photos.LatLng.toObject(includeInstance, f),
    phone: jspb.Message.getFieldWithDefault(msg, 12, ""),
    lang: jspb.Message.getFieldWithDefault(msg, 13, ""),
    deal: jspb.Message.getFieldWithDefault(msg, 14, ""),
    photosList: jspb.Message.toObjectList(msg.getPhotosList(),
    proto.api.v1.photos.Photo.toObject, includeInstance),
    parametersList: jspb.Message.toObjectList(msg.getParametersList(),
    proto.api.v1.photos.Param.toObject, includeInstance),
    user: (f = msg.getUser()) && proto.api.v1.photos.TinyUser.toObject(includeInstance, f),
    humanParametersList: jspb.Message.toObjectList(msg.getHumanParametersList(),
    proto.api.v1.photos.Param.toObject, includeInstance),
    oldPrice: jspb.Message.getFieldWithDefault(msg, 19, 0),
    createdAt: (f = msg.getCreatedAt()) && proto.api.v1.photos.Date.toObject(includeInstance, f),
    favourite: jspb.Message.getBooleanFieldWithDefault(msg, 21, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Ad}
 */
proto.api.v1.photos.Ad.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Ad;
  return proto.api.v1.photos.Ad.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Ad} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Ad}
 */
proto.api.v1.photos.Ad.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFlag(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 7:
      var value = new proto.api.v1.photos.LatLng;
      reader.readMessage(value,proto.api.v1.photos.LatLng.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 11:
      var value = new proto.api.v1.photos.LatLng;
      reader.readMessage(value,proto.api.v1.photos.LatLng.deserializeBinaryFromReader);
      msg.setUserLocation(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLang(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeal(value);
      break;
    case 15:
      var value = new proto.api.v1.photos.Photo;
      reader.readMessage(value,proto.api.v1.photos.Photo.deserializeBinaryFromReader);
      msg.addPhotos(value);
      break;
    case 16:
      var value = new proto.api.v1.photos.Param;
      reader.readMessage(value,proto.api.v1.photos.Param.deserializeBinaryFromReader);
      msg.addParameters(value);
      break;
    case 17:
      var value = new proto.api.v1.photos.TinyUser;
      reader.readMessage(value,proto.api.v1.photos.TinyUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 18:
      var value = new proto.api.v1.photos.Param;
      reader.readMessage(value,proto.api.v1.photos.Param.deserializeBinaryFromReader);
      msg.addHumanParameters(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOldPrice(value);
      break;
    case 20:
      var value = new proto.api.v1.photos.Date;
      reader.readMessage(value,proto.api.v1.photos.Date.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavourite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Ad.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Ad.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Ad} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Ad.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFlag();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.api.v1.photos.LatLng.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUserLocation();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.api.v1.photos.LatLng.serializeBinaryToWriter
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLang();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDeal();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPhotosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.api.v1.photos.Photo.serializeBinaryToWriter
    );
  }
  f = message.getParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.api.v1.photos.Param.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.api.v1.photos.TinyUser.serializeBinaryToWriter
    );
  }
  f = message.getHumanParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.api.v1.photos.Param.serializeBinaryToWriter
    );
  }
  f = message.getOldPrice();
  if (f !== 0) {
    writer.writeUint64(
      19,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.api.v1.photos.Date.serializeBinaryToWriter
    );
  }
  f = message.getFavourite();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.v1.photos.Ad.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.v1.photos.Ad.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 state = 3;
 * @return {number}
 */
proto.api.v1.photos.Ad.prototype.getState = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.setState = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.api.v1.photos.Ad.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool flag = 5;
 * @return {boolean}
 */
proto.api.v1.photos.Ad.prototype.getFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.setFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int64 price = 6;
 * @return {number}
 */
proto.api.v1.photos.Ad.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional LatLng location = 7;
 * @return {?proto.api.v1.photos.LatLng}
 */
proto.api.v1.photos.Ad.prototype.getLocation = function() {
  return /** @type{?proto.api.v1.photos.LatLng} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.photos.LatLng, 7));
};


/**
 * @param {?proto.api.v1.photos.LatLng|undefined} value
 * @return {!proto.api.v1.photos.Ad} returns this
*/
proto.api.v1.photos.Ad.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.photos.Ad.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string user_id = 8;
 * @return {string}
 */
proto.api.v1.photos.Ad.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string category_id = 9;
 * @return {string}
 */
proto.api.v1.photos.Ad.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string address = 10;
 * @return {string}
 */
proto.api.v1.photos.Ad.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional LatLng user_location = 11;
 * @return {?proto.api.v1.photos.LatLng}
 */
proto.api.v1.photos.Ad.prototype.getUserLocation = function() {
  return /** @type{?proto.api.v1.photos.LatLng} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.photos.LatLng, 11));
};


/**
 * @param {?proto.api.v1.photos.LatLng|undefined} value
 * @return {!proto.api.v1.photos.Ad} returns this
*/
proto.api.v1.photos.Ad.prototype.setUserLocation = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.clearUserLocation = function() {
  return this.setUserLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.photos.Ad.prototype.hasUserLocation = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string phone = 12;
 * @return {string}
 */
proto.api.v1.photos.Ad.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string lang = 13;
 * @return {string}
 */
proto.api.v1.photos.Ad.prototype.getLang = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.setLang = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string deal = 14;
 * @return {string}
 */
proto.api.v1.photos.Ad.prototype.getDeal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.setDeal = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated Photo photos = 15;
 * @return {!Array<!proto.api.v1.photos.Photo>}
 */
proto.api.v1.photos.Ad.prototype.getPhotosList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Photo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Photo, 15));
};


/**
 * @param {!Array<!proto.api.v1.photos.Photo>} value
 * @return {!proto.api.v1.photos.Ad} returns this
*/
proto.api.v1.photos.Ad.prototype.setPhotosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.api.v1.photos.Photo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Photo}
 */
proto.api.v1.photos.Ad.prototype.addPhotos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.api.v1.photos.Photo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.clearPhotosList = function() {
  return this.setPhotosList([]);
};


/**
 * repeated Param parameters = 16;
 * @return {!Array<!proto.api.v1.photos.Param>}
 */
proto.api.v1.photos.Ad.prototype.getParametersList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Param>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Param, 16));
};


/**
 * @param {!Array<!proto.api.v1.photos.Param>} value
 * @return {!proto.api.v1.photos.Ad} returns this
*/
proto.api.v1.photos.Ad.prototype.setParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.api.v1.photos.Param=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Param}
 */
proto.api.v1.photos.Ad.prototype.addParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.api.v1.photos.Param, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.clearParametersList = function() {
  return this.setParametersList([]);
};


/**
 * optional TinyUser user = 17;
 * @return {?proto.api.v1.photos.TinyUser}
 */
proto.api.v1.photos.Ad.prototype.getUser = function() {
  return /** @type{?proto.api.v1.photos.TinyUser} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.photos.TinyUser, 17));
};


/**
 * @param {?proto.api.v1.photos.TinyUser|undefined} value
 * @return {!proto.api.v1.photos.Ad} returns this
*/
proto.api.v1.photos.Ad.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.photos.Ad.prototype.hasUser = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * repeated Param human_parameters = 18;
 * @return {!Array<!proto.api.v1.photos.Param>}
 */
proto.api.v1.photos.Ad.prototype.getHumanParametersList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Param>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Param, 18));
};


/**
 * @param {!Array<!proto.api.v1.photos.Param>} value
 * @return {!proto.api.v1.photos.Ad} returns this
*/
proto.api.v1.photos.Ad.prototype.setHumanParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.api.v1.photos.Param=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Param}
 */
proto.api.v1.photos.Ad.prototype.addHumanParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.api.v1.photos.Param, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.clearHumanParametersList = function() {
  return this.setHumanParametersList([]);
};


/**
 * optional uint64 old_price = 19;
 * @return {number}
 */
proto.api.v1.photos.Ad.prototype.getOldPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.setOldPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional Date created_at = 20;
 * @return {?proto.api.v1.photos.Date}
 */
proto.api.v1.photos.Ad.prototype.getCreatedAt = function() {
  return /** @type{?proto.api.v1.photos.Date} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.photos.Date, 20));
};


/**
 * @param {?proto.api.v1.photos.Date|undefined} value
 * @return {!proto.api.v1.photos.Ad} returns this
*/
proto.api.v1.photos.Ad.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.photos.Ad.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional bool favourite = 21;
 * @return {boolean}
 */
proto.api.v1.photos.Ad.prototype.getFavourite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.photos.Ad} returns this
 */
proto.api.v1.photos.Ad.prototype.setFavourite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.AdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.AdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.AdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.AdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    adId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.AdRequest}
 */
proto.api.v1.photos.AdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.AdRequest;
  return proto.api.v1.photos.AdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.AdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.AdRequest}
 */
proto.api.v1.photos.AdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.AdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.AdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.AdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.AdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ad_id = 1;
 * @return {string}
 */
proto.api.v1.photos.AdRequest.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.AdRequest} returns this
 */
proto.api.v1.photos.AdRequest.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.AdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.AdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.AdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.AdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cursor: jspb.Message.getFieldWithDefault(msg, 3, ""),
    perPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    adId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.AdsRequest}
 */
proto.api.v1.photos.AdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.AdsRequest;
  return proto.api.v1.photos.AdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.AdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.AdsRequest}
 */
proto.api.v1.photos.AdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilter(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPerPage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.AdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.AdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.AdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.AdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.api.v1.photos.AdsRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.AdsRequest} returns this
 */
proto.api.v1.photos.AdsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string filter = 2;
 * @return {string}
 */
proto.api.v1.photos.AdsRequest.prototype.getFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.AdsRequest} returns this
 */
proto.api.v1.photos.AdsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string cursor = 3;
 * @return {string}
 */
proto.api.v1.photos.AdsRequest.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.AdsRequest} returns this
 */
proto.api.v1.photos.AdsRequest.prototype.setCursor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 per_page = 4;
 * @return {number}
 */
proto.api.v1.photos.AdsRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.AdsRequest} returns this
 */
proto.api.v1.photos.AdsRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string ad_id = 5;
 * @return {string}
 */
proto.api.v1.photos.AdsRequest.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.AdsRequest} returns this
 */
proto.api.v1.photos.AdsRequest.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Element.repeatedFields_ = [19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Element.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Element.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Element} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Element.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    caption: jspb.Message.getFieldWithDefault(msg, 2, ""),
    captionHint: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    paramName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    value: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isSkippable: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isVisible: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isRequired: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    format: jspb.Message.getFieldWithDefault(msg, 10, ""),
    inputType: jspb.Message.getFieldWithDefault(msg, 11, ""),
    hint: jspb.Message.getFieldWithDefault(msg, 12, ""),
    minLength: jspb.Message.getFieldWithDefault(msg, 13, 0),
    maxLength: jspb.Message.getFieldWithDefault(msg, 14, 0),
    minValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    maxValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    postfix: jspb.Message.getFieldWithDefault(msg, 17, ""),
    mistakes: jspb.Message.getFieldWithDefault(msg, 18, ""),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.api.v1.photos.Value.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Element}
 */
proto.api.v1.photos.Element.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Element;
  return proto.api.v1.photos.Element.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Element} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Element}
 */
proto.api.v1.photos.Element.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaptionHint(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSkippable(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsVisible(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRequired(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setInputType(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setHint(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinLength(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxLength(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinValue(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxValue(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostfix(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setMistakes(value);
      break;
    case 19:
      var value = new proto.api.v1.photos.Value;
      reader.readMessage(value,proto.api.v1.photos.Value.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Element.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Element.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Element} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Element.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCaptionHint();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getParamName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsSkippable();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsVisible();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsRequired();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getInputType();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getHint();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMinLength();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getMaxLength();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getMinValue();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getMaxValue();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getPostfix();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getMistakes();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.api.v1.photos.Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 code = 1;
 * @return {number}
 */
proto.api.v1.photos.Element.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.api.v1.photos.Element.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string caption_hint = 3;
 * @return {string}
 */
proto.api.v1.photos.Element.prototype.getCaptionHint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.setCaptionHint = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.api.v1.photos.Element.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string param_name = 5;
 * @return {string}
 */
proto.api.v1.photos.Element.prototype.getParamName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.setParamName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string value = 6;
 * @return {string}
 */
proto.api.v1.photos.Element.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_skippable = 7;
 * @return {boolean}
 */
proto.api.v1.photos.Element.prototype.getIsSkippable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.setIsSkippable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_visible = 8;
 * @return {boolean}
 */
proto.api.v1.photos.Element.prototype.getIsVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.setIsVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool is_required = 9;
 * @return {boolean}
 */
proto.api.v1.photos.Element.prototype.getIsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.setIsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string format = 10;
 * @return {string}
 */
proto.api.v1.photos.Element.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string input_type = 11;
 * @return {string}
 */
proto.api.v1.photos.Element.prototype.getInputType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.setInputType = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string hint = 12;
 * @return {string}
 */
proto.api.v1.photos.Element.prototype.getHint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.setHint = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int64 min_length = 13;
 * @return {number}
 */
proto.api.v1.photos.Element.prototype.getMinLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.setMinLength = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 max_length = 14;
 * @return {number}
 */
proto.api.v1.photos.Element.prototype.getMaxLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.setMaxLength = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional float min_value = 15;
 * @return {number}
 */
proto.api.v1.photos.Element.prototype.getMinValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.setMinValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float max_value = 16;
 * @return {number}
 */
proto.api.v1.photos.Element.prototype.getMaxValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.setMaxValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional string postfix = 17;
 * @return {string}
 */
proto.api.v1.photos.Element.prototype.getPostfix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.setPostfix = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string mistakes = 18;
 * @return {string}
 */
proto.api.v1.photos.Element.prototype.getMistakes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.setMistakes = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * repeated Value values = 19;
 * @return {!Array<!proto.api.v1.photos.Value>}
 */
proto.api.v1.photos.Element.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Value>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Value, 19));
};


/**
 * @param {!Array<!proto.api.v1.photos.Value>} value
 * @return {!proto.api.v1.photos.Element} returns this
*/
proto.api.v1.photos.Element.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.api.v1.photos.Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Value}
 */
proto.api.v1.photos.Element.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.api.v1.photos.Value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Element} returns this
 */
proto.api.v1.photos.Element.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Value.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    payload: jspb.Message.getFieldWithDefault(msg, 2, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 3, ""),
    captionHint: jspb.Message.getFieldWithDefault(msg, 4, ""),
    selected: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    hasChildren: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Value}
 */
proto.api.v1.photos.Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Value;
  return proto.api.v1.photos.Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Value}
 */
proto.api.v1.photos.Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaptionHint(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelected(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasChildren(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCaptionHint();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSelected();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getHasChildren();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.api.v1.photos.Value.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Value} returns this
 */
proto.api.v1.photos.Value.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string payload = 2;
 * @return {string}
 */
proto.api.v1.photos.Value.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Value} returns this
 */
proto.api.v1.photos.Value.prototype.setPayload = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string caption = 3;
 * @return {string}
 */
proto.api.v1.photos.Value.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Value} returns this
 */
proto.api.v1.photos.Value.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string caption_hint = 4;
 * @return {string}
 */
proto.api.v1.photos.Value.prototype.getCaptionHint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Value} returns this
 */
proto.api.v1.photos.Value.prototype.setCaptionHint = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool selected = 5;
 * @return {boolean}
 */
proto.api.v1.photos.Value.prototype.getSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.photos.Value} returns this
 */
proto.api.v1.photos.Value.prototype.setSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool has_children = 6;
 * @return {boolean}
 */
proto.api.v1.photos.Value.prototype.getHasChildren = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.photos.Value} returns this
 */
proto.api.v1.photos.Value.prototype.setHasChildren = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Values.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Values.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Values.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Values} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Values.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.api.v1.photos.Value.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Values}
 */
proto.api.v1.photos.Values.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Values;
  return proto.api.v1.photos.Values.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Values} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Values}
 */
proto.api.v1.photos.Values.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.Value;
      reader.readMessage(value,proto.api.v1.photos.Value.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Values.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Values.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Values} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Values.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.Value.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Value values = 1;
 * @return {!Array<!proto.api.v1.photos.Value>}
 */
proto.api.v1.photos.Values.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Value>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Value, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.Value>} value
 * @return {!proto.api.v1.photos.Values} returns this
*/
proto.api.v1.photos.Values.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Value}
 */
proto.api.v1.photos.Values.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.Value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Values} returns this
 */
proto.api.v1.photos.Values.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Draft.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Draft.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Draft.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Draft} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Draft.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    payload: jspb.Message.getFieldWithDefault(msg, 2, ""),
    saved: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    adUuid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    elementsList: jspb.Message.toObjectList(msg.getElementsList(),
    proto.api.v1.photos.Element.toObject, includeInstance),
    parametersList: jspb.Message.toObjectList(msg.getParametersList(),
    proto.api.v1.photos.Param.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Draft}
 */
proto.api.v1.photos.Draft.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Draft;
  return proto.api.v1.photos.Draft.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Draft} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Draft}
 */
proto.api.v1.photos.Draft.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSaved(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdUuid(value);
      break;
    case 5:
      var value = new proto.api.v1.photos.Element;
      reader.readMessage(value,proto.api.v1.photos.Element.deserializeBinaryFromReader);
      msg.addElements(value);
      break;
    case 6:
      var value = new proto.api.v1.photos.Param;
      reader.readMessage(value,proto.api.v1.photos.Param.deserializeBinaryFromReader);
      msg.addParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Draft.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Draft.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Draft} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Draft.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSaved();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAdUuid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.api.v1.photos.Element.serializeBinaryToWriter
    );
  }
  f = message.getParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.api.v1.photos.Param.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.api.v1.photos.Draft.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Draft} returns this
 */
proto.api.v1.photos.Draft.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string payload = 2;
 * @return {string}
 */
proto.api.v1.photos.Draft.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Draft} returns this
 */
proto.api.v1.photos.Draft.prototype.setPayload = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool saved = 3;
 * @return {boolean}
 */
proto.api.v1.photos.Draft.prototype.getSaved = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.photos.Draft} returns this
 */
proto.api.v1.photos.Draft.prototype.setSaved = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string ad_uuid = 4;
 * @return {string}
 */
proto.api.v1.photos.Draft.prototype.getAdUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Draft} returns this
 */
proto.api.v1.photos.Draft.prototype.setAdUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Element elements = 5;
 * @return {!Array<!proto.api.v1.photos.Element>}
 */
proto.api.v1.photos.Draft.prototype.getElementsList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Element>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Element, 5));
};


/**
 * @param {!Array<!proto.api.v1.photos.Element>} value
 * @return {!proto.api.v1.photos.Draft} returns this
*/
proto.api.v1.photos.Draft.prototype.setElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.api.v1.photos.Element=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Element}
 */
proto.api.v1.photos.Draft.prototype.addElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.api.v1.photos.Element, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Draft} returns this
 */
proto.api.v1.photos.Draft.prototype.clearElementsList = function() {
  return this.setElementsList([]);
};


/**
 * repeated Param parameters = 6;
 * @return {!Array<!proto.api.v1.photos.Param>}
 */
proto.api.v1.photos.Draft.prototype.getParametersList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Param>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Param, 6));
};


/**
 * @param {!Array<!proto.api.v1.photos.Param>} value
 * @return {!proto.api.v1.photos.Draft} returns this
*/
proto.api.v1.photos.Draft.prototype.setParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.api.v1.photos.Param=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Param}
 */
proto.api.v1.photos.Draft.prototype.addParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.api.v1.photos.Param, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Draft} returns this
 */
proto.api.v1.photos.Draft.prototype.clearParametersList = function() {
  return this.setParametersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Param.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Param.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Param} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Param.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Param}
 */
proto.api.v1.photos.Param.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Param;
  return proto.api.v1.photos.Param.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Param} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Param}
 */
proto.api.v1.photos.Param.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Param.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Param.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Param} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Param.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.api.v1.photos.Param.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Param} returns this
 */
proto.api.v1.photos.Param.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.api.v1.photos.Param.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Param} returns this
 */
proto.api.v1.photos.Param.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Params.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Params.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Params.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Params} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Params.toObject = function(includeInstance, msg) {
  var f, obj = {
    parametersList: jspb.Message.toObjectList(msg.getParametersList(),
    proto.api.v1.photos.Param.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Params}
 */
proto.api.v1.photos.Params.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Params;
  return proto.api.v1.photos.Params.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Params} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Params}
 */
proto.api.v1.photos.Params.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.Param;
      reader.readMessage(value,proto.api.v1.photos.Param.deserializeBinaryFromReader);
      msg.addParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Params.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Params.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Params} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Params.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.Param.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Param parameters = 1;
 * @return {!Array<!proto.api.v1.photos.Param>}
 */
proto.api.v1.photos.Params.prototype.getParametersList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Param>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Param, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.Param>} value
 * @return {!proto.api.v1.photos.Params} returns this
*/
proto.api.v1.photos.Params.prototype.setParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.Param=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Param}
 */
proto.api.v1.photos.Params.prototype.addParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.Param, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Params} returns this
 */
proto.api.v1.photos.Params.prototype.clearParametersList = function() {
  return this.setParametersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Elements.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Elements.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Elements.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Elements} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Elements.toObject = function(includeInstance, msg) {
  var f, obj = {
    elementsList: jspb.Message.toObjectList(msg.getElementsList(),
    proto.api.v1.photos.Element.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Elements}
 */
proto.api.v1.photos.Elements.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Elements;
  return proto.api.v1.photos.Elements.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Elements} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Elements}
 */
proto.api.v1.photos.Elements.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.Element;
      reader.readMessage(value,proto.api.v1.photos.Element.deserializeBinaryFromReader);
      msg.addElements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Elements.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Elements.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Elements} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Elements.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.Element.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Element elements = 1;
 * @return {!Array<!proto.api.v1.photos.Element>}
 */
proto.api.v1.photos.Elements.prototype.getElementsList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Element>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Element, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.Element>} value
 * @return {!proto.api.v1.photos.Elements} returns this
*/
proto.api.v1.photos.Elements.prototype.setElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.Element=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Element}
 */
proto.api.v1.photos.Elements.prototype.addElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.Element, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Elements} returns this
 */
proto.api.v1.photos.Elements.prototype.clearElementsList = function() {
  return this.setElementsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.CitizenshipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.CitizenshipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.CitizenshipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.CitizenshipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.CitizenshipRequest}
 */
proto.api.v1.photos.CitizenshipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.CitizenshipRequest;
  return proto.api.v1.photos.CitizenshipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.CitizenshipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.CitizenshipRequest}
 */
proto.api.v1.photos.CitizenshipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.CitizenshipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.CitizenshipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.CitizenshipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.CitizenshipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.api.v1.photos.CitizenshipRequest.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.CitizenshipRequest} returns this
 */
proto.api.v1.photos.CitizenshipRequest.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.api.v1.photos.CitizenshipRequest.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.CitizenshipRequest} returns this
 */
proto.api.v1.photos.CitizenshipRequest.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Citizenship.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Citizenship.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Citizenship} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Citizenship.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Citizenship}
 */
proto.api.v1.photos.Citizenship.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Citizenship;
  return proto.api.v1.photos.Citizenship.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Citizenship} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Citizenship}
 */
proto.api.v1.photos.Citizenship.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Citizenship.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Citizenship.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Citizenship} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Citizenship.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.api.v1.photos.Citizenship.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Citizenship} returns this
 */
proto.api.v1.photos.Citizenship.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.api.v1.photos.Citizenship.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Citizenship} returns this
 */
proto.api.v1.photos.Citizenship.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Citizenships.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Citizenships.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Citizenships.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Citizenships} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Citizenships.toObject = function(includeInstance, msg) {
  var f, obj = {
    citizenshipsList: jspb.Message.toObjectList(msg.getCitizenshipsList(),
    proto.api.v1.photos.Citizenship.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Citizenships}
 */
proto.api.v1.photos.Citizenships.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Citizenships;
  return proto.api.v1.photos.Citizenships.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Citizenships} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Citizenships}
 */
proto.api.v1.photos.Citizenships.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.Citizenship;
      reader.readMessage(value,proto.api.v1.photos.Citizenship.deserializeBinaryFromReader);
      msg.addCitizenships(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Citizenships.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Citizenships.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Citizenships} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Citizenships.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCitizenshipsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.Citizenship.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Citizenship citizenships = 1;
 * @return {!Array<!proto.api.v1.photos.Citizenship>}
 */
proto.api.v1.photos.Citizenships.prototype.getCitizenshipsList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Citizenship>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Citizenship, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.Citizenship>} value
 * @return {!proto.api.v1.photos.Citizenships} returns this
*/
proto.api.v1.photos.Citizenships.prototype.setCitizenshipsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.Citizenship=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Citizenship}
 */
proto.api.v1.photos.Citizenships.prototype.addCitizenships = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.Citizenship, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Citizenships} returns this
 */
proto.api.v1.photos.Citizenships.prototype.clearCitizenshipsList = function() {
  return this.setCitizenshipsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.LanguageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.LanguageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.LanguageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.LanguageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.LanguageRequest}
 */
proto.api.v1.photos.LanguageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.LanguageRequest;
  return proto.api.v1.photos.LanguageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.LanguageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.LanguageRequest}
 */
proto.api.v1.photos.LanguageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.LanguageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.LanguageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.LanguageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.LanguageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.api.v1.photos.LanguageRequest.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.LanguageRequest} returns this
 */
proto.api.v1.photos.LanguageRequest.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.api.v1.photos.LanguageRequest.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.LanguageRequest} returns this
 */
proto.api.v1.photos.LanguageRequest.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.CountryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.CountryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.CountryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.CountryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.CountryRequest}
 */
proto.api.v1.photos.CountryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.CountryRequest;
  return proto.api.v1.photos.CountryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.CountryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.CountryRequest}
 */
proto.api.v1.photos.CountryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.CountryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.CountryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.CountryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.CountryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.api.v1.photos.CountryRequest.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.CountryRequest} returns this
 */
proto.api.v1.photos.CountryRequest.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.api.v1.photos.CountryRequest.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.CountryRequest} returns this
 */
proto.api.v1.photos.CountryRequest.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Countries.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Countries.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Countries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Countries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Countries.toObject = function(includeInstance, msg) {
  var f, obj = {
    countriesList: jspb.Message.toObjectList(msg.getCountriesList(),
    proto.api.v1.photos.Country.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Countries}
 */
proto.api.v1.photos.Countries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Countries;
  return proto.api.v1.photos.Countries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Countries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Countries}
 */
proto.api.v1.photos.Countries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.Country;
      reader.readMessage(value,proto.api.v1.photos.Country.deserializeBinaryFromReader);
      msg.addCountries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Countries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Countries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Countries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Countries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.Country.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Country countries = 1;
 * @return {!Array<!proto.api.v1.photos.Country>}
 */
proto.api.v1.photos.Countries.prototype.getCountriesList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Country>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Country, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.Country>} value
 * @return {!proto.api.v1.photos.Countries} returns this
*/
proto.api.v1.photos.Countries.prototype.setCountriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.Country=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Country}
 */
proto.api.v1.photos.Countries.prototype.addCountries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.Country, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Countries} returns this
 */
proto.api.v1.photos.Countries.prototype.clearCountriesList = function() {
  return this.setCountriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Country.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Country.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Country} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Country.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Country}
 */
proto.api.v1.photos.Country.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Country;
  return proto.api.v1.photos.Country.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Country} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Country}
 */
proto.api.v1.photos.Country.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Country.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Country.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Country} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Country.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.api.v1.photos.Country.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Country} returns this
 */
proto.api.v1.photos.Country.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.api.v1.photos.Country.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Country} returns this
 */
proto.api.v1.photos.Country.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Languages.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Languages.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Languages.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Languages} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Languages.toObject = function(includeInstance, msg) {
  var f, obj = {
    languagesList: jspb.Message.toObjectList(msg.getLanguagesList(),
    proto.api.v1.photos.Language.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Languages}
 */
proto.api.v1.photos.Languages.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Languages;
  return proto.api.v1.photos.Languages.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Languages} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Languages}
 */
proto.api.v1.photos.Languages.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.Language;
      reader.readMessage(value,proto.api.v1.photos.Language.deserializeBinaryFromReader);
      msg.addLanguages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Languages.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Languages.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Languages} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Languages.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLanguagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.Language.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Language languages = 1;
 * @return {!Array<!proto.api.v1.photos.Language>}
 */
proto.api.v1.photos.Languages.prototype.getLanguagesList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Language>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Language, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.Language>} value
 * @return {!proto.api.v1.photos.Languages} returns this
*/
proto.api.v1.photos.Languages.prototype.setLanguagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.Language=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Language}
 */
proto.api.v1.photos.Languages.prototype.addLanguages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.Language, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Languages} returns this
 */
proto.api.v1.photos.Languages.prototype.clearLanguagesList = function() {
  return this.setLanguagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Language.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Language.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Language} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Language.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Language}
 */
proto.api.v1.photos.Language.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Language;
  return proto.api.v1.photos.Language.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Language} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Language}
 */
proto.api.v1.photos.Language.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Language.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Language.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Language} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Language.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.api.v1.photos.Language.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Language} returns this
 */
proto.api.v1.photos.Language.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.api.v1.photos.Language.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Language} returns this
 */
proto.api.v1.photos.Language.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.PositionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.PositionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.PositionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.PositionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    position: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.PositionRequest}
 */
proto.api.v1.photos.PositionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.PositionRequest;
  return proto.api.v1.photos.PositionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.PositionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.PositionRequest}
 */
proto.api.v1.photos.PositionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.PositionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.PositionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.PositionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.PositionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.api.v1.photos.PositionRequest.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.PositionRequest} returns this
 */
proto.api.v1.photos.PositionRequest.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 position = 2;
 * @return {number}
 */
proto.api.v1.photos.PositionRequest.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.PositionRequest} returns this
 */
proto.api.v1.photos.PositionRequest.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.PositionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.PositionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.PositionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.PositionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.PositionResponse}
 */
proto.api.v1.photos.PositionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.PositionResponse;
  return proto.api.v1.photos.PositionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.PositionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.PositionResponse}
 */
proto.api.v1.photos.PositionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.PositionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.PositionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.PositionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.PositionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.api.v1.photos.PositionResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.photos.PositionResponse} returns this
 */
proto.api.v1.photos.PositionResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.DeleteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.DeleteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.DeleteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.DeleteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.DeleteResponse}
 */
proto.api.v1.photos.DeleteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.DeleteResponse;
  return proto.api.v1.photos.DeleteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.DeleteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.DeleteResponse}
 */
proto.api.v1.photos.DeleteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.DeleteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.DeleteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.DeleteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.DeleteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.api.v1.photos.DeleteResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.photos.DeleteResponse} returns this
 */
proto.api.v1.photos.DeleteResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.PhotoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.PhotoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.PhotoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.PhotoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.PhotoRequest}
 */
proto.api.v1.photos.PhotoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.PhotoRequest;
  return proto.api.v1.photos.PhotoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.PhotoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.PhotoRequest}
 */
proto.api.v1.photos.PhotoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.PhotoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.PhotoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.PhotoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.PhotoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.api.v1.photos.PhotoRequest.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.PhotoRequest} returns this
 */
proto.api.v1.photos.PhotoRequest.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.api.v1.photos.PhotoRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.PhotoRequest} returns this
 */
proto.api.v1.photos.PhotoRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ad_id = 3;
 * @return {string}
 */
proto.api.v1.photos.PhotoRequest.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.PhotoRequest} returns this
 */
proto.api.v1.photos.PhotoRequest.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Photo.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Photo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Photo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Photo.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    position: jspb.Message.getFieldWithDefault(msg, 4, 0),
    original: jspb.Message.getFieldWithDefault(msg, 5, ""),
    thumb: jspb.Message.getFieldWithDefault(msg, 6, ""),
    big: jspb.Message.getFieldWithDefault(msg, 7, ""),
    small: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Photo}
 */
proto.api.v1.photos.Photo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Photo;
  return proto.api.v1.photos.Photo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Photo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Photo}
 */
proto.api.v1.photos.Photo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPosition(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginal(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumb(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBig(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmall(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Photo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Photo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Photo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Photo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOriginal();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getThumb();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBig();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSmall();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.api.v1.photos.Photo.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Photo} returns this
 */
proto.api.v1.photos.Photo.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.api.v1.photos.Photo.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Photo} returns this
 */
proto.api.v1.photos.Photo.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ad_id = 3;
 * @return {string}
 */
proto.api.v1.photos.Photo.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Photo} returns this
 */
proto.api.v1.photos.Photo.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 position = 4;
 * @return {number}
 */
proto.api.v1.photos.Photo.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.photos.Photo} returns this
 */
proto.api.v1.photos.Photo.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string original = 5;
 * @return {string}
 */
proto.api.v1.photos.Photo.prototype.getOriginal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Photo} returns this
 */
proto.api.v1.photos.Photo.prototype.setOriginal = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string thumb = 6;
 * @return {string}
 */
proto.api.v1.photos.Photo.prototype.getThumb = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Photo} returns this
 */
proto.api.v1.photos.Photo.prototype.setThumb = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string big = 7;
 * @return {string}
 */
proto.api.v1.photos.Photo.prototype.getBig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Photo} returns this
 */
proto.api.v1.photos.Photo.prototype.setBig = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string small = 8;
 * @return {string}
 */
proto.api.v1.photos.Photo.prototype.getSmall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.Photo} returns this
 */
proto.api.v1.photos.Photo.prototype.setSmall = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.photos.Photos.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.Photos.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.Photos.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.Photos} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Photos.toObject = function(includeInstance, msg) {
  var f, obj = {
    photosList: jspb.Message.toObjectList(msg.getPhotosList(),
    proto.api.v1.photos.Photo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.Photos}
 */
proto.api.v1.photos.Photos.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.Photos;
  return proto.api.v1.photos.Photos.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.Photos} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.Photos}
 */
proto.api.v1.photos.Photos.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.photos.Photo;
      reader.readMessage(value,proto.api.v1.photos.Photo.deserializeBinaryFromReader);
      msg.addPhotos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.Photos.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.Photos.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.Photos} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.Photos.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhotosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.photos.Photo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Photo photos = 1;
 * @return {!Array<!proto.api.v1.photos.Photo>}
 */
proto.api.v1.photos.Photos.prototype.getPhotosList = function() {
  return /** @type{!Array<!proto.api.v1.photos.Photo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.photos.Photo, 1));
};


/**
 * @param {!Array<!proto.api.v1.photos.Photo>} value
 * @return {!proto.api.v1.photos.Photos} returns this
*/
proto.api.v1.photos.Photos.prototype.setPhotosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.photos.Photo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.photos.Photo}
 */
proto.api.v1.photos.Photos.prototype.addPhotos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.photos.Photo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.photos.Photos} returns this
 */
proto.api.v1.photos.Photos.prototype.clearPhotosList = function() {
  return this.setPhotosList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.photos.SearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.photos.SearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.photos.SearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.SearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.photos.SearchRequest}
 */
proto.api.v1.photos.SearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.photos.SearchRequest;
  return proto.api.v1.photos.SearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.photos.SearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.photos.SearchRequest}
 */
proto.api.v1.photos.SearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.photos.SearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.photos.SearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.photos.SearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.photos.SearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.api.v1.photos.SearchRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.photos.SearchRequest} returns this
 */
proto.api.v1.photos.SearchRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.api.v1.photos);
