<template>
  <div v-if="isSSR">
    <teleport to="#ssr-head">
      <title v-if="meta.title">{{ meta.title }} | {{ $t("sitename") }}</title>
      <title v-else>
        {{ $t("sitename") }}
      </title>

      <meta
        v-if="meta.description"
        name="description"
        :content="meta.description"
      />

      <meta
        v-if="meta.og && meta.og.url"
        property="og:url"
        :content="meta.og.url"
      />
      <meta
        v-if="meta.og && meta.og.title"
        property="og:title"
        :content="meta.og.title"
      />
      <meta
        v-if="meta.og && meta.og.description"
        property="og:description"
        :content="meta.og.description"
      />
      <meta
        v-if="meta.og && meta.og.type"
        property="og:type"
        :content="meta.og.type"
      />
      <meta
        v-if="meta.og && meta.og.image"
        property="og:image"
        :content="meta.og.image"
      />

      <meta v-if="robotsTag" name="robots" :content="robotsTag" />
      <meta v-else name="robots" content="index, follow" />
    </teleport>
  </div>
  <div v-else></div>
</template>

<script>
/*
 * Была проблема, что на сервере не устанавливались теги head.
 * SsrHead - как `vue-meta`, только для передачи метаданных в head в SSR.
 * Заменяет все метаданные из `vue-meta`.
 */

export default {
  props: {
    /**
     * Объект с метаданными, которые нужно отрисовать
     */
    meta: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isSSR: this.isSSR,
    };
  },
  computed: {
    robotsTag() {
      if (this.meta.robots) {
        return this.meta.robots;
      }

      if (this.meta.meta) {
        const robots = this.meta.meta.find((v) => {
          if (!v.name) {
            return false;
          }
          return v.name === "robots";
        });
        if (robots && robots.content) {
          return robots.content;
        }
      }

      return null;
    },
  },
};
</script>
