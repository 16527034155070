import { login as loginApi, LoginStatus } from "api/login.js";

import {
  getUserSelf as getUserSelfApi,
  deletePhone as deletePhoneApi,
  changeUsername as changeUsernameApi,
  deleteAccount as deleteAccountApi,
  uploadAvatar as uploadAvatarApi,
  logout as logoutApi,
  changePassword as changePasswordApi,
} from "api/profile/user.js";

import DefaultAvatar from "assets/img/default_avatar.png";

const state = () => ({
  authToken: null,
  userId: null,
  username: null,
  rating: null,
  activeAds: null,
  finishedAds: null,
  reviews: null,
  phones: [],
  avatar: {
    original: null,
    thumb: null,
  },
});

const getters = {
  /**
   * Проверить вошел ли пользователь в системы
   * @returns {Boolean} true, если пользователь вошел
   */
  loggedIn(state) {
    return Boolean(state.authToken);
  },
  /**
   * Thumbnail аватара. Если нет аватара, вернет DefaultAvatar
   * @returns {String} Аватар пользователя
   */
  avatarThumb(state) {
    if (state.avatar && state.avatar.thumb) {
      return state.avatar.thumb;
    }

    return String(DefaultAvatar);
  },
};

const mutations = {
  setAuthToken(state, newToken) {
    state.authToken = newToken;
  },

  /**
   * Заменить данные пользователя новыми данными
   */
  setUserData(
    state,
    {
      userId,
      username,
      rating,
      activeAds,
      finishedAds,
      reviews,
      phones,
      avatar,
    }
  ) {
    state.userId = userId;
    state.username = username;
    state.rating = rating;
    state.activeAds = activeAds;
    state.finishedAds = finishedAds;
    state.reviews = reviews;
    state.phones = phones;
    state.avatar = {
      original: avatar.original,
      thumb: avatar.thumb,
    };
  },

  deletePhoneById(state, { id }) {
    if (!id) {
      return;
    }

    var idx = state.phones.findIndex((p) => p.id == id);
    if (idx == -1) {
      return;
    }

    state.phones.splice(idx, 1);
  },

  setUsername(state, { username }) {
    state.username = username;
  },

  setAvatar(state, { avatar }) {
    state.avatar = {
      original: avatar.original,
      thumb: avatar.thumb,
    };
  },

  /**
   * Удалить auth token пользователя и обнулить все поля с данными
   */
  deleteUserData(state) {
    for (var k of Object.keys(state)) {
      state[k] = null;
    }

    state.authToken = null;
    state.avatar = {
      original: null,
      thumb: null,
    };
  },
};

const actions = {
  /**
   * Войти по номеру телефона и паролю, затем обновить информацию пользователя
   * @param {String} {phoneNumber - номер телефона пользователя
   * @param {String} password} - пароль пользователя
   * @returns {Object} - статус входа
   */
  async loginUser({ commit, dispatch }, { phoneNumber, password }) {
    var resp = await loginApi({ login: phoneNumber, password });

    switch (resp.status) {
      case LoginStatus.OK: {
        if (!resp.authToken) {
          console.error(
            "Vuex loginUser got response ok, auth token is undefined"
          );
          return { status: "UNKNOWN" };
        }
        commit("setAuthToken", resp.authToken);
        dispatch("updateUserInfo");
        return {
          status: "OK",
        };
      }
      case LoginStatus.BLOCKED: {
        return { status: "BLOCKED" };
      }
      case LoginStatus.DELETED: {
        return { status: "DELETED" };
      }
      case LoginStatus.INVALID_CREDENTIALS: {
        return { status: "INVALID_CREDENTIALS" };
      }
      default: {
        return { status: "UNKNOWN" };
      }
    }
  },

  /**
   * Обновить информацию пользователя в store (аватар, имя пользователя, ...)
   * @returns {any} Ничего не возвращает
   */
  async updateUserInfo({ commit }) {
    var userSelf = await getUserSelfApi();

    commit("setUserData", userSelf);
  },

  /**
   * Отвязать номер телефона от пользователя и обновить данные в store
   * @param {any} {commit}
   * @param {any} {id} - id номера телефона, который нужно отвязать
   * @returns {Object} - статус отвязки номера телефона
   */
  async deletePhone({ commit }, { id }) {
    0;
    if (!id) {
      return { status: "INVALID" };
    }

    await deletePhoneApi(id);
    commit("deletePhoneById", { id });

    return { status: "SUCCESS" };
  },

  /**
   * Заменить имя пользователя в store и вызвать api для обновления имени пользователя на сервере
   * @param {any} {commit}
   * @param {String} {username} - новое имя пользователя
   * @returns {Object} - статус изменения имени пользователя (может быть слишком длинное/короткое)
   */
  async changeUsername({ commit }, { username }) {
    var r = await changeUsernameApi(username);

    switch (r.status) {
      case "SUCCESS": {
        break;
      }
      case "TOO_LONG": {
        return { status: "TOO_LONG" };
      }
      case "TOO_SHORT": {
        return { status: "TOO_SHORT" };
      }
      case "UNKNOWN": {
        return { status: "UNKNOWN" };
      }
    }

    commit("setUsername", { username });
    return { status: "SUCCESS" };
  },

  /**
   * Удалить аккаунт пользователя и все данные из store
   * @param {any} {commit}
   * @param {any} {reason}
   * @returns {any}
   */
  async deleteAccount({ commit }, { reason }) {
    await deleteAccountApi(reason);
    commit("deleteUserData");
  },

  /**
   * Изменить аватар пользователя и обновить url аватара
   * @param {any} state
   * @param {any} {fileInput} - [File], который нужно загрузить на сервер
   * @returns {Object} Вернет Object с полями:
   *  - avatar (может быть undefined)
   *  - error (может быть undefined)
   *
   * [File]: https://developer.mozilla.org/en-US/docs/Web/API/File
   */
  async changeAvatar({ commit }, { fileInput }) {
    if (!fileInput) {
      return {
        error: "NO_FILE",
      };
    }

    var resp = await uploadAvatarApi({ fileInput });

    if (resp.error) {
      return {
        error: resp.error,
      };
    }

    if (resp.avatar) {
      commit("setAvatar", { avatar: resp.avatar });
      return {
        avatar: resp.avatar,
      };
    }

    return {
      error: "UNKNOWN",
    };
  },

  /** Удалить auth token пользователя и сделать запрос logout */
  async logout({ commit }) {
    await logoutApi();

    commit("deleteUserData");
  },

  /**
   * Изменить пароль и обновить auth token пользователя
   * @param {any} {commit}
   * @param {String} {oldPassword - старый пароль
   * @param {String} newPassword} - новый пароль
   * @returns {Object} Вернет объект с полем status
   */
  async changePassword({ commit }, { oldPassword, newPassword }) {
    var r = await changePasswordApi({
      oldPassword,
      newPassword,
    });

    if (r.status == "SUCCESS" && r.authToken) {
      commit("setAuthToken", r.authToken);
    }

    return {
      status: r.status,
    };
  },
};

const user = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export { user };
