import { createSSRApp, createApp } from "vue";

import { grpc } from "@improbable-eng/grpc-web";
import { NodeHttpTransport } from "@improbable-eng/grpc-web-node-http-transport";

import App from "./App.vue";

import i18n from "./i18n";
import store from "./store";
import routerInit from "./router";

export default async function({ isSSR }) {
  if (isSSR) {
    grpc.setDefaultTransport(NodeHttpTransport());
  }

  const app = (() => {
    if (isSSR) {
      return createSSRApp(App);
    } else {
      return createApp(App);
    }
  })();

  app.config.globalProperties.isSSR = isSSR;

  const { router, metaManager } = routerInit(isSSR);

  app
    .use(router)
    .use(metaManager)
    .use(i18n)
    .use(store);

  if (!isSSR) {
    const VueResizeObserver = await import("vue-resize-observer");
    app.use(VueResizeObserver);

    const LoadScript = (await import("vue-plugin-load-script")).default;
    app.use(LoadScript);

    const { options, Vue3Progress } = await import("./progress_bar");
    app.use(Vue3Progress, options);
  }

  if (process.env.NODE_ENV != "development") {
    app.config.errorHandler = (err, vm, info) => {
      // TODO: use newrelic
      console.error("Error occured", err, vm, info);
    };

    app.config.warnHandler = function(msg, vm, trace) {
      // TODO: use newrelic
      console.error("Warn occured", msg, vm, trace);
    };
  }

  return {
    app,
    router,
  };
}
