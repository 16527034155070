<template>
  <router-link
    :to="{ name: 'AdDetails', params: { id: ad.uuid } }"
    tabindex="-1"
  >
    <img
      :src="adImage"
      class="attachment-thumbnail size-thumbnail wp-post-image"
    />
  </router-link>
  <div class="flex-right">
    <h5 class="adv-title">
      <router-link
        :to="{ name: 'AdDetails', params: { id: ad.uuid } }"
        class="ad-title"
        :title="
          t('ads.ad_similar.link_title', { name: ad.name, price: ad.price })
        "
        :aria-label="
          t('ads.ad_list.link_title', { name: ad.name, price: ad.price })
        "
      >
        {{ ad.name }}
      </router-link>
    </h5>
    <div class="bottom-advert-meta flex-wrap">
      <div class="price">
        <span class="text-price">{{
          Number(ad.price).toLocaleString() + " " + t("$")
        }}</span>
      </div>
      <div class="flex-right">
        <!-- TODO: в избранное? -->
        <!-- <a :title="t('ads.ad_similar.favorite')" href="" class="af-favs " data-toggle="modal" data-target="#login">
                <i class="aficon-heart-o"></i>
                <span>{{ t('ads.ad_similar.favorite') }}</span>
                <span class="small-icon">{{ t('ads.ad_similar.favorite') }}</span>
            </a> -->
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

import EmptyImg from "../../assets/img/empty-image.svg";

export default {
  name: "AdSimilar",
  props: {
    // Should have:
    //  uuid
    //  name
    //  price
    //  mainPhoto - обложка
    //    - thumb
    //    - small
    ad: Object,
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  computed: {
    adImage() {
      var ad = this.$props.ad;

      if (ad.mainPhoto == undefined) {
        return EmptyImg;
      }

      if (ad.mainPhoto.thumb == undefined) {
        return EmptyImg;
      }

      return ad.mainPhoto.thumb.length == 0 ? EmptyImg : ad.mainPhoto.thumb;
    },
  },
};
</script>

<style scoped>
.ad-title {
  text-align: start;
}
</style>
