<template>
  <wrapper-collapsible
    ref="wrapper"
    :filterNameText="name"
    :filterValueText="appliedToponimName"
    :canBeApplied="false"
    @on-close-clicked="clearQuery"
  >
    <TextboxSuggestions
      :label="t('filters.filter_toponim_select.input_alt')"
      :placeholder="
        t('filters.filter_toponim_select.default_search_placeholder')
      "
      icon="<i class='fas fa-search'></i>"
      :suggestions="suggestedToponims"
      @on-input-changed="
        (q) => {
          query = q;
        }
      "
      @on-search-clicked="toponimSelected"
    />
  </wrapper-collapsible>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { v4 as uuidv4 } from "uuid";

import WrapperCollapsible from "./WrapperCollapsible.vue";
import TextboxSuggestions from "modules/ui/TextboxSuggestions.vue";

import GenericFilterMixin from "../mixins/GenericFilterMixin";

import { getCitiesToponims as getCitiesToponimsApi } from "api/get_toponims.js";

export default {
  name: "FilterToponimSelect",
  components: { WrapperCollapsible, TextboxSuggestions },
  mixins: [GenericFilterMixin],
  props: {
    name: {
      type: String,
      required: false,
      default() {
        const { t } = useI18n();
        return t("filters.filter_toponim_select.select_location");
      },
    },
    toponim: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      inputId: uuidv4(),
      query: ref(""),
      suggestedToponims: ref([]),
      appliedToponim: ref(undefined),
    };
  },
  async mounted() {
    if (this.toponim != undefined) {
      this.appliedToponim = this.toponim;
    }

    await this.getToponims();
  },
  watch: {
    async query() {
      this.suggestedToponims = [];
      await this.getToponims();
    },
    toponim: {
      handler() {
        this.appliedToponim = this.toponim;
      },
      deep: true,
    },
  },
  computed: {
    appliedToponimName() {
      if (this.appliedToponim == undefined || this.appliedToponim == null) {
        return "";
      }
      return this.appliedToponim.humanName;
    },
  },
  methods: {
    async getToponims() {
      if (this.query.length < 3) {
        return;
      }

      var toponims = await getCitiesToponimsApi(this.query);

      var suggestedToponims = [];

      for (var t of toponims) {
        suggestedToponims.push({
          title: t.humanName,
          valueObj: t,
        });
      }

      this.suggestedToponims = suggestedToponims;
    },
    toponimSelected(name, toponim) {
      if (toponim == undefined) {
        return;
      }

      this.appliedToponim = toponim;
      this.$emit("on-toponim-selected", this.filterId, toponim);
      this.clearQuery();
      this.$refs.wrapper.setOpen(false);
    },
    clearQuery() {
      this.query = "";
      this.suggestedToponims = [];
    },
  },
};
</script>

<style scoped></style>
