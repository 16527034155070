import { grpc } from "@improbable-eng/grpc-web";

import { SearchAdsRequest } from "./proto/ad_pb.js";
import { PublicAdService } from "./proto/ad_pb_service.js";
import { getMeta } from "./utils/meta.js";

// TODO: добавить pagination при получении объявлений
/**
 * Получить список объявлений для главной страницы
 *
 * @param {number} limit Количество объявлений, которое нужно получить
 * @return {Object[]} Вернет набор объектов с полями:
 *                    - uuid
 *                    - name
 *                    - price
 *                    - date
 *                    - thumb - обложка объявления
 */
export function getAllAds(limit) {
  let request = new SearchAdsRequest();
  request.setLimit(limit);

  return new Promise((resolve, reject) => {
    grpc.unary(PublicAdService.SearchAds, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error PublicAdService.SearchAds",
            status,
            statusMessage,
            message,
          });
        }

        var res_obj = message.toObject();

        var arr = [];

        for (let ad of res_obj.adsList) {
          var thumb = "";
          var small = "";
          var big = "";
          if (typeof ad.mainPhoto !== "undefined") {
            thumb = ad.mainPhoto.thumb;
            small = ad.mainPhoto.small;
            big = ad.mainPhoto.big;
          }

          arr.push({
            uuid: ad.adId,
            name: ad.name,
            price: ad.price,
            date: new Date(ad.createdAt.date),
            mainPhoto: {
              thumb,
              small,
              big,
            },
          });
        }

        return resolve(arr);
      },
    });
  });
}
