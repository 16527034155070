<template>
  <metainfo>
    <template v-slot:title="{ content }">
      {{ content ? `${content} | ${t("sitename")}` : t("sitename") }}
    </template>
  </metainfo>

  <div v-if="showProgress">
    <vue3-progress />
  </div>

  <component :is="layoutComponent">
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </component>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useMeta } from "vue-meta";

import DefaultLayout from "./layouts/DefaultLayout/DefaultLayout.vue";
import ProfileLayout from "./layouts/ProfileLayout/ProfileLayout.vue";

export default {
  name: "App",
  components: {
    DefaultLayout,
    ProfileLayout,
  },
  setup() {
    const { t } = useI18n();

    useMeta({
      title: "",
      description: t("site_description"),
      og: {
        url: typeof window !== "undefined" ? window.location.href : "",
        title: t("sitename"),
        description: t("site_description"),
        type: "website",
        image: process.env.BASE_URL + "favicons/og.jpeg",
      },
      meta: [{ name: "robots", content: "index, follow" }],
    });

    return {
      t,
      showProgress: ref(false),
    };
  },
  created() {
    this.showProgress = !this.isSSR;
    this.$router.beforeEach((to, from, next) => {
      if (this.$progress) {
        this.$progress.start();
      }
      next();
    });

    this.$router.afterEach(() => {
      if (this.$progress) {
        this.$progress.finish();
      }
    });
  },
  computed: {
    layoutComponent() {
      switch (this.$router.currentRoute.value.meta.layout) {
        case "profile-layout": {
          return ProfileLayout;
        }
        case "default-layout":
        default: {
          return DefaultLayout;
        }
      }
    },
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* TODO: not copy from vue3-progress */

.vue3-progress-custom {
  background: rgb(255, 143, 0);
  background: -moz-linear-gradient(
    90deg,
    rgba(255, 144, 0, 1) 0%,
    rgba(22, 197, 255, 1) 25%,
    rgba(0, 169, 255, 1) 50%,
    rgba(255, 169, 0, 1) 65%,
    rgba(255, 144, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 144, 0, 1) 0%,
    rgba(22, 197, 255, 1) 25%,
    rgba(0, 169, 255, 1) 50%,
    rgba(255, 169, 0, 1) 65%,
    rgba(255, 144, 0, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 144, 0, 1) 0%,
    rgba(22, 197, 255, 1) 25%,
    rgba(0, 169, 255, 1) 50%,
    rgba(255, 169, 0, 1) 65%,
    rgba(255, 144, 0, 1) 100%
  );

  -webkit-animation: 2s linear infinite rainbow, 0.5s ease-out enter;
  animation: 2s linear infinite rainbow, 0.5s ease-out enter;
  transition: opacity 0.2s ease-in-out;
  transform-origin: left;
  background-size: 500%;
  z-index: 999999;
  position: fixed;
  display: block;
  width: 100%;
  opacity: 1;
  left: 0;
  top: 0;
}

@-webkit-keyframes rainbow {
  0% {
    background-position: 125% 0;
  }
  to {
    background-position: 0% 0;
  }
}
@keyframes rainbow {
  0% {
    background-position: 125% 0;
  }
  to {
    background-position: 0% 0;
  }
}
@-webkit-keyframes enter {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
@keyframes enter {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
</style>
