<template>
  <wrapper-collapsible
    :filterNameText="name"
    :filterValueText="selectedText"
    :canBeApplied="showApplyBtn"
    @on-apply-clicked="applySelected"
    @on-close-clicked="clearSelected"
  >
    <div class="scrollable">
      <ul class="list-unstyled">
        <li :key="o.value" v-for="o in objects">
          <div
            class="styled-checkbox"
            tabindex="0"
            @keydown.enter="clickRef(String(inputId + '_' + o.value))"
          >
            <input
              type="radio"
              :name="inputId"
              :value="o.value"
              :id="inputId + '_' + o.value"
              :ref="String(inputId + '_' + o.value)"
              v-model="toBeSelected"
            />
            <label :for="inputId + '_' + o.value">{{ o.caption }}</label>
          </div>
        </li>
      </ul>
    </div>
  </wrapper-collapsible>
</template>

<script>
// TODO: dry с FilterCheckboxSingleInline
import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";

import GenericFilterMixin from "../mixins/GenericFilterMixin";
import ClickRefMixin from "../mixins/ClickRefMixin";

import WrapperCollapsible from "./WrapperCollapsible.vue";

export default {
  name: "FilterCheckboxSingleCollapsible",
  components: {
    WrapperCollapsible,
  },
  props: {
    /** Название списка */
    name: {
      type: String,
      required: true,
    },

    /** Набор объектов, которые нужно отрисовать.
     * Должны содержать поля:
     * - value
     * - caption
     * - checked
     */
    objects: {
      type: Array,
      required: true,
    },
  },
  mixins: [GenericFilterMixin, ClickRefMixin],
  setup() {
    return {
      inputId: uuidv4(),
      selectedValue: ref(undefined),
      toBeSelected: ref(""),
    };
  },
  mounted() {
    this.updateSelectedValue();
  },
  watch: {
    objects() {
      this.updateSelectedValue();
    },
  },
  computed: {
    selectedText() {
      if (this.selectedValue == undefined || this.selectedValue == "") {
        return "";
      }

      var selected = this.selectedValue;
      var object = this.objects.find((obj) => obj.value == selected);

      if (object != undefined && object != null) {
        return object.caption;
      }

      return "";
    },
    showApplyBtn() {
      return (
        this.toBeSelected != null &&
        this.toBeSelected != undefined &&
        this.toBeSelected != ""
      );
    },
  },
  methods: {
    updateSelectedValue() {
      var selected_obj = this.objects.find((obj) => obj.checked);

      if (selected_obj == undefined) {
        return;
      }

      this.selectedValue = selected_obj.value;
    },
    applySelected() {
      this.selectedValue = this.toBeSelected;
      this.$emit("on-value-selected", this.filterId, this.selectedValue);
    },
    clearSelected() {
      this.toBeSelected = this.selectedValue;
    },
  },
};
</script>

<style scoped>
.scrollable {
  max-height: 300px;
  overflow: auto;
}
</style>
