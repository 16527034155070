<template>
  <div class="clearfix">
    <div class="kc_clfw"></div>
    <section class="kc-elm kc-css-242291 kc_row row-slider-section">
      <SearchBig @start-search="startSearch" />
    </section>
    <section class="kc-elm kc-css-632086 kc_row">
      <GridCategories />
    </section>
    <section class="kc-elm kc-css-382650 kc_row">
      <div class="kc_wrapper ui-tabs kc_clearfix">
        <!-- TODO: показывать рекомендованные объявления -->
        <!-- <ul class="kc_tabs_nav ui-tabs-nav kc_clearfix">
            <li class="ui-tabs-active">
              <a href="#latest-ads" data-prevent="scroll">Latest Ads</a>
            </li>
            <li>
              <a href="#ending-soon" data-prevent="scroll">Ending Soon</a>
            </li>
          </ul> -->

        <div class="kc-css-382650-child">
          <div class="search-map-results">
            <div
              class="scroll-wrapper search-map-results-content"
              style="position: relative;"
            >
              <div
                class="search-map-results-content scroll-content scroll-scrolly_visible"
                style="height: auto; margin-bottom: 0px;"
              >
                <div class="ajax-search">
                  <div v-if="adsLoading">
                    <TheLoader />
                  </div>
                  <div v-else>
                    <GridAds :ads="ads" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import SearchBig from "../components/SearchBig.vue";
import GridCategories from "../components/GridCategories.vue";
import GridAds from "../components/GridAds.vue";
import TheLoader from "modules/ui/TheLoader";

import { encodeBase64 } from "./SearchPage/base64.js";
import { getAllAds } from "../api/homepage";

export default {
  name: "Home",
  components: {
    SearchBig,
    GridCategories,
    GridAds,
    TheLoader,
  },
  data() {
    return {
      ads: [],
    };
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      adsLoading: ref(false),
    };
  },
  async mounted() {
    this.adsLoading = true;
    this.ads = await getAllAds(24);

    this.adsLoading = false;
  },
  methods: {
    startSearch(q, obj) {
      var query = {};

      if (q != undefined && q != "") {
        query["q"] = q;
      }

      if (obj != undefined) {
        if (obj.query != undefined && String(obj.query) != String(q)) {
          query["q"] = obj.query;
        }

        if (obj.filters != undefined) {
          query["f"] = encodeBase64(obj.filters);
        }

        if (obj.toponim != undefined) {
          query["t"] = encodeBase64(obj.toponim);
        }
      }

      this.$router.push({ name: "SearchPage", query });
    },
  },
};
</script>

<style scoped>
.kc-css-242291 {
  background: #ffa93b;
  padding-top: 50px;
  padding-bottom: 25px;
  overflow: visible;
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 1024px) {
  .kc-css-242291 {
    background: #ffa93b;
    padding-top: 50px;
    padding-bottom: 25px;
  }
}

.kc-css-632086 {
  background: #f1f1f1;
  padding-top: 25px;
  padding-bottom: 25px;
}

.kc-css-382650 {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.kc-css-382650-child {
  max-width: 1500px;
  margin: auto;
  padding: 10px;
}

.kc-css-197220 {
  background: #ffffff;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 75px;
}

.scroll-wrapper {
  overflow: hidden;
}
</style>
