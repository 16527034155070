<template>
  <div class="page-title quick-search" style="padding-bottom: 25px;">
    <div class="container">
      <div class="flex-wrap">
        <div class="flex-left">
          <h1 class="h4-size">{{ title }}</h1>

          <BreadcrumbsCategories
            :categories="categories"
            class="categories-list"
          />

          <div v-if="userDeleted" role="alert" class="alert alert-info">
            <h1 class="h4-size">{{ t("title_ad_wide.is_deleted") }}</h1>
            <br />
            <div class="wrap-list-similar-ads">
              {{ t("title_ad_wide.checkout_similar") }}
              <div v-if="similarAds && similarAds.length !== 0">
                <ul class="list-unstyled random-author-ads list-similar-ads">
                  <li :key="ad.uuid" v-for="ad in similarAds">
                    <AdSimilar :ad="ad" />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div v-else-if="isFinished" role="alert" class="alert alert-info">
            {{ t("title_ad_wide.is_finished") }}
          </div>
        </div>
        <div v-if="canShowMap" class="flex-right map-icon">
          <a
            :href="mapUrl"
            target="_blank"
            rel="noopener"
            :title="t('title_ad_wide.show_map')"
          >
            <i class="fa fa-map" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

import BreadcrumbsCategories from "./BreadcrumbsCategories.vue";
import AdSimilar from "./ad/AdSimilar.vue";

export default {
  name: "TitleAdWide",
  components: {
    BreadcrumbsCategories,
    AdSimilar,
  },
  props: {
    /** Заголовок, который нужно отобразить */
    title: String,
    /** Координаты для открытия карты */
    coords: {
      type: Object,
      required: false,
      default() {
        return {
          lat: 0,
          lon: 0,
        };
      },
    },
    /** Список категорий, который нужно показать
     * Должен быть Array, содержащий Object.
     * У каждого Object должны быть поля:
     *  - id - id категории
     *  - name - название категории
     */
    categories: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    isFinished: {
      type: Boolean,
      required: false,
      default: false,
    },
    userDeleted: {
      type: Boolean,
      required: false,
      default: false,
    },
    similarAds: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  computed: {
    mapUrl() {
      return process.env.VUE_APP_MAP_URL.replaceAll(
        "{lat}",
        this.$props.coords.lat
      ).replaceAll("{lon}", this.$props.coords.lon);
    },
    canShowMap() {
      if (this.$props.isFinished) {
        return false;
      }

      var c = this.$props.coords;
      if (
        c.lat == undefined ||
        c.lon == undefined ||
        c.lat == 0 ||
        c.lon == 0
      ) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>
.page-title {
  padding: 15px 0px;
  margin-bottom: 40px;
}

.map-icon {
  margin-top: 17px;
}

.alert-info {
  background: #ff8f00 !important;
  margin-top: 10px;
  margin-bottom: 0;
}

.categories-list {
  color: #fff;
}

@media (max-width: 576px) {
  .page-title.quick-search .flex-left {
    width: 100%;
  }
}

.wrap-list-similar-ads {
  background: white;
  color: black;
  padding: 20px;
  border-radius: 10px;
  font-size: 15px;
}

.list-similar-ads {
  display: flex;
  flex-flow: row wrap;
  opacity: 1;
}

.list-similar-ads > li {
  padding: 10px;
  width: min-content;
  margin: 10px;
}
</style>

<style>
.list-similar-ads .ad-title {
  color: black;
}
</style>
