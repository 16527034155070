<template>
  <div
    class="white-block hover-shadow advert-item advert-list"
    @keydown.enter="
      $router.push({ name: 'AdDetails', params: { id: ad.uuid } })
    "
  >
    <div class="flex-wrap flex-start-h">
      <div class="flex-left">
        <router-link
          :to="{ name: 'AdDetails', params: { id: ad.uuid } }"
          class="advert-media"
          tabindex="-1"
        >
          <div
            :style="thumbnailStyle(ad.mainPhoto)"
            class="attachment-adifier-list size-adifier-list wp-post-image ad-image"
            width="355"
            height="400"
          />
        </router-link>
      </div>
      <div class="flex-right">
        <div class="white-block-content">
          <!-- TODO: показывать категорию -->
          <!-- <div class="top-advert-meta flex-wrap">
                    <div class="advert-cat text-overflow">
                        <i class="aficon-dot-circle-o"></i>
                        <a href="http://127.0.0.1:8080/advert-category/vehicles/">Vehicles</a>
                    </div>
                    <div class="advert-city text-overflow">
                        <i class="aficon-map-marker-alt-o"></i>
                        <a href="http://127.0.0.1:8080/advert-location/amsterdam/">Amsterdam</a>
                    </div>
                </div> -->
          <h5 class="adv-title">
            <router-link
              :to="{ name: 'AdDetails', params: { id: ad.uuid } }"
              custom
              v-slot="{ href, navigate }"
            >
              <a
                :href="href"
                :aria-label="
                  t('ads.ad_list.link_title', {
                    name: ad.name,
                    price: ad.price,
                  })
                "
                :title="
                  t('ads.ad_list.link_title', {
                    name: ad.name,
                    price: ad.price,
                  })
                "
                @click="navigate"
                ref="focusableLink"
              >
                {{ ad.name }}
              </a>
            </router-link>
          </h5>

          <div class="bottom-advert-meta flex-wrap">
            <div class="price">
              {{ Number(ad.price).toLocaleString() }}
              <span class="price-symbol">{{ t("$") }}</span>
            </div>
            <div class="flex-right">
              <!-- TODO: добавить избранное -->
              <!-- <a href=""
                        :title="t('ads.ad_list.favorite')"						
                        class="af-favs"
                      >
                          <i class="aficon-heart-o"></i>
                          <span>{{ t('ads.ad_list.favorite') }}</span>
                          <span class="small-icon">t('ads.ad_list.favorite')</span>
                      </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

import ThumbnailStyle from "mixins/ThumbnailStyle.js";

export default {
  name: "AdList",
  props: {
    /** Объявление.
     * Должно иметь поля:
     * - uuid
     * - name
     * - price
     * - date
     * - mainPhoto - обложка объявления
     *   - thumb
     *   - small
     *   - big
     */
    ad: Object,
  },
  mixins: [ThumbnailStyle],
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  methods: {
    /** Сфокусироваться на ссылке. Вызывается родителем */
    focus() {
      if (this.$refs.focusableLink) {
        this.$refs.focusableLink.focus();
      }
    },
  },
};
</script>

<style scoped>
.ad-image {
  width: 100px;
  height: 110px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.adv-title {
  text-align: start;
}

.adv-title > a {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
</style>
