import { grpc } from "@improbable-eng/grpc-web";

import { ComplainRequest } from "./proto/photos_pb.js";
import { ApiService } from "./proto/photos_pb_service.js";

import store from "store";
import { getMeta } from "./utils/meta.js";

/**
 * Оставить жалобу на объявление
 * @param {any} {ad_id - id объявления, на которое нужно оставить жалобу
 * @param {any} user_id - id пользователя, который оставил жалобу
 * @param {any} code - код причины жалобы
 * @param {any} text} - текст жалобы. может быть пустой
 */
export function leaveComplaint({ ad_id, code, text }) {
  let request = new ComplainRequest();
  request.setAdId(ad_id);

  if (store.getters["user/loggedIn"]) {
    request.setUserId(store.state.user.userId);
  } else {
    request.setUserId(process.env.VUE_APP_PUBLIC_USER_ID);
  }

  request.setCode(code);
  request.setText(text);

  return new Promise((resolve, reject) => {
    grpc.unary(ApiService.AddComplain, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error ApiService.AddComplain",
            status,
            statusMessage,
            message,
          });
        }

        return resolve({
          status: "OK",
        });
      },
    });
  });
}
