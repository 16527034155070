<template>
  <div>
    <h2 class="sr-only">{{ t("grid_ads.ads_list") }}</h2>
    <div :class="gridClass">
      <div v-if="ads.length == 0" style="padding: 20px;margin: auto;">
        <h3>{{ t("grid_ads.no_ads") }}</h3>
      </div>

      <div
        :class="isGrid ? 'af-item-wrap' : 'af-item-wrap af-list-item'"
        :key="ad.uuid"
        v-for="(ad, index) in ads"
      >
        <AdGrid v-if="isGrid" :ad="ad" :ref="(el) => setLastAdRef(el, index)" />
        <AdList
          v-else
          :class="listAdClass"
          :ad="ad"
          :ref="(el) => setLastAdRef(el, index)"
        />
      </div>
    </div>

    <button v-if="hasMore" @click="clickMoreAds" class="af-button">
      {{ t("grid_ads.more_ads") }}
    </button>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useGrid } from "vue-screen";

import AdGrid from "./ad/AdGrid.vue";
import AdList from "./ad/AdList.vue";

export default {
  name: "GridAds",
  components: {
    AdGrid,
    AdList,
  },
  props: {
    /** Список объявлений, которые нужно отрисовать.
     * Каждое из объявлений должно иметь поля:
     * - uuid
     * - name
     * - price
     * - date
     * - mainPhoto - обложка объявления
     *   - thumb
     *   - small
     *   - big
     */

    ads: {
      type: Array,
      default() {
        return [];
      },
    },
    /** Максимальное количество элементов в ряду.
     * Может быть 2, 4 или 6.
     */
    maxItems: {
      type: Number,
      required: false,
      default() {
        return 6;
      },
    },
    hasMore: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  emits: [
    /** Нажали на кнопку "Показать еще" */
    "has-more-clicked",
  ],
  setup() {
    const { t } = useI18n();
    const grid = useGrid("bootstrap");

    return {
      t,
      grid,
      lastAdRef: ref(""),
    };
  },
  computed: {
    gridClass() {
      var bp = this.grid.breakpoint;
      if (bp == "sm" || bp == "xs") {
        // this is a list
        return "af-items-1 af-listing-list grid-ads-4783290";
      }

      if (this.grid.xxl) {
        if (this.maxItems >= 6) {
          return "af-items-6 af-listing-grid grid-ads-4783290";
        }
      }

      if (this.grid.lg) {
        if (this.maxItems >= 4) {
          return "af-items-4 af-listing-grid grid-ads-4783290";
        }
      }

      return "af-items-2 af-listing-grid grid-ads-4783290";
    },
    listAdClass() {
      var bp = this.grid.breakpoint;
      if (bp == "sm") {
        return "list-ad-sm";
      }

      if (bp == "xs") {
        return "list-ad-xs";
      }

      return "";
    },
    isGrid() {
      var bp = this.grid.breakpoint;
      if (bp == "sm" || bp == "xs") {
        return false;
      }

      return true;
    },
  },
  methods: {
    setLastAdRef(el, index) {
      if (index != this.ads.length - 1) {
        return;
      }

      if (el) {
        this.lastAdRef = el;
      }
    },
    clickMoreAds() {
      if (this.lastAdRef) {
        try {
          this.lastAdRef.focus();
        } catch (e) {
          console.error(
            "Cannot focus on last ad in GridAds. isGrid:",
            this.isGrid,
            ", error : ",
            e
          );
        }
      }
      this.$emit("has-more-clicked");
    },
  },
};
</script>

<style scoped>
.grid-ads-4783290 {
  max-width: 100vw;
}

.af-list-item {
  width: calc(100% - 60px);
  margin: auto;
}

.af-listing-list {
  margin: auto;
  flex-flow: column;
}

.list-ad-sm {
  max-width: 95vw;
  margin: auto;
  margin-bottom: 15px;
}

.list-ad-xs {
  max-width: 95vw;
  margin: auto;
  margin-bottom: 15px;
}
</style>
