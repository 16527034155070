// source: ad.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var toponims_pb = require('./toponims_pb.js');
goog.object.extend(proto, toponims_pb);
goog.exportSymbol('proto.api.v1.ad.AddWebviewRequest', null, global);
goog.exportSymbol('proto.api.v1.ad.AddWebviewResponse', null, global);
goog.exportSymbol('proto.api.v1.ad.CountSearchResultsRequest', null, global);
goog.exportSymbol('proto.api.v1.ad.CountSearchResultsResponse', null, global);
goog.exportSymbol('proto.api.v1.ad.DeleteSavedSearchRequest', null, global);
goog.exportSymbol('proto.api.v1.ad.DeleteSavedSearchResponse', null, global);
goog.exportSymbol('proto.api.v1.ad.DeleteSavedSearchResponse.Status', null, global);
goog.exportSymbol('proto.api.v1.ad.FavouriteRequest', null, global);
goog.exportSymbol('proto.api.v1.ad.FavouriteResponse', null, global);
goog.exportSymbol('proto.api.v1.ad.GetLastSearchQueriesRequest', null, global);
goog.exportSymbol('proto.api.v1.ad.GetLastSearchQueriesResponse', null, global);
goog.exportSymbol('proto.api.v1.ad.GetRecommendedAdsRequest', null, global);
goog.exportSymbol('proto.api.v1.ad.GetRecommendedAdsResponse', null, global);
goog.exportSymbol('proto.api.v1.ad.GetSavedSearchesRequest', null, global);
goog.exportSymbol('proto.api.v1.ad.GetSavedSearchesResponse', null, global);
goog.exportSymbol('proto.api.v1.ad.GetSearchHintsRequest', null, global);
goog.exportSymbol('proto.api.v1.ad.GetSearchHintsResponse', null, global);
goog.exportSymbol('proto.api.v1.ad.HistoryQuery', null, global);
goog.exportSymbol('proto.api.v1.ad.Photo', null, global);
goog.exportSymbol('proto.api.v1.ad.PromoFeatures', null, global);
goog.exportSymbol('proto.api.v1.ad.SavedSearch', null, global);
goog.exportSymbol('proto.api.v1.ad.SearchAdsRequest', null, global);
goog.exportSymbol('proto.api.v1.ad.SearchAdsResponse', null, global);
goog.exportSymbol('proto.api.v1.ad.SearchHint', null, global);
goog.exportSymbol('proto.api.v1.ad.TinyAd', null, global);
goog.exportSymbol('proto.api.v1.ad.UpdateSavedSearchRequest', null, global);
goog.exportSymbol('proto.api.v1.ad.UpdateSavedSearchResponse', null, global);
goog.exportSymbol('proto.api.v1.ad.UpdateSavedSearchResponse.Status', null, global);
goog.exportSymbol('proto.api.v1.ad.ViewRequest', null, global);
goog.exportSymbol('proto.api.v1.ad.ViewResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.TinyAd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.TinyAd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.TinyAd.displayName = 'proto.api.v1.ad.TinyAd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.SavedSearch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.SavedSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.SavedSearch.displayName = 'proto.api.v1.ad.SavedSearch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.PromoFeatures = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.PromoFeatures, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.PromoFeatures.displayName = 'proto.api.v1.ad.PromoFeatures';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.Photo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.Photo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.Photo.displayName = 'proto.api.v1.ad.Photo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.SearchAdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.SearchAdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.SearchAdsRequest.displayName = 'proto.api.v1.ad.SearchAdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.SearchAdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.ad.SearchAdsResponse.repeatedFields_, null);
};
goog.inherits(proto.api.v1.ad.SearchAdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.SearchAdsResponse.displayName = 'proto.api.v1.ad.SearchAdsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.CountSearchResultsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.CountSearchResultsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.CountSearchResultsRequest.displayName = 'proto.api.v1.ad.CountSearchResultsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.CountSearchResultsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.CountSearchResultsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.CountSearchResultsResponse.displayName = 'proto.api.v1.ad.CountSearchResultsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.GetSearchHintsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.GetSearchHintsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.GetSearchHintsRequest.displayName = 'proto.api.v1.ad.GetSearchHintsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.GetSearchHintsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.ad.GetSearchHintsResponse.repeatedFields_, null);
};
goog.inherits(proto.api.v1.ad.GetSearchHintsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.GetSearchHintsResponse.displayName = 'proto.api.v1.ad.GetSearchHintsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.SearchHint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.SearchHint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.SearchHint.displayName = 'proto.api.v1.ad.SearchHint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.GetRecommendedAdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.GetRecommendedAdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.GetRecommendedAdsRequest.displayName = 'proto.api.v1.ad.GetRecommendedAdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.GetRecommendedAdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.ad.GetRecommendedAdsResponse.repeatedFields_, null);
};
goog.inherits(proto.api.v1.ad.GetRecommendedAdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.GetRecommendedAdsResponse.displayName = 'proto.api.v1.ad.GetRecommendedAdsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.ViewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.ViewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.ViewRequest.displayName = 'proto.api.v1.ad.ViewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.ViewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.ad.ViewResponse.repeatedFields_, null);
};
goog.inherits(proto.api.v1.ad.ViewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.ViewResponse.displayName = 'proto.api.v1.ad.ViewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.AddWebviewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.AddWebviewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.AddWebviewRequest.displayName = 'proto.api.v1.ad.AddWebviewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.AddWebviewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.AddWebviewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.AddWebviewResponse.displayName = 'proto.api.v1.ad.AddWebviewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.FavouriteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.FavouriteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.FavouriteRequest.displayName = 'proto.api.v1.ad.FavouriteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.FavouriteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.ad.FavouriteResponse.repeatedFields_, null);
};
goog.inherits(proto.api.v1.ad.FavouriteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.FavouriteResponse.displayName = 'proto.api.v1.ad.FavouriteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.GetSavedSearchesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.GetSavedSearchesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.GetSavedSearchesRequest.displayName = 'proto.api.v1.ad.GetSavedSearchesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.GetSavedSearchesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.ad.GetSavedSearchesResponse.repeatedFields_, null);
};
goog.inherits(proto.api.v1.ad.GetSavedSearchesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.GetSavedSearchesResponse.displayName = 'proto.api.v1.ad.GetSavedSearchesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.UpdateSavedSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.UpdateSavedSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.UpdateSavedSearchRequest.displayName = 'proto.api.v1.ad.UpdateSavedSearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.UpdateSavedSearchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.UpdateSavedSearchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.UpdateSavedSearchResponse.displayName = 'proto.api.v1.ad.UpdateSavedSearchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.DeleteSavedSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.DeleteSavedSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.DeleteSavedSearchRequest.displayName = 'proto.api.v1.ad.DeleteSavedSearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.DeleteSavedSearchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.DeleteSavedSearchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.DeleteSavedSearchResponse.displayName = 'proto.api.v1.ad.DeleteSavedSearchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.HistoryQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.HistoryQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.HistoryQuery.displayName = 'proto.api.v1.ad.HistoryQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.GetLastSearchQueriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.v1.ad.GetLastSearchQueriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.GetLastSearchQueriesRequest.displayName = 'proto.api.v1.ad.GetLastSearchQueriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.v1.ad.GetLastSearchQueriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.v1.ad.GetLastSearchQueriesResponse.repeatedFields_, null);
};
goog.inherits(proto.api.v1.ad.GetLastSearchQueriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.v1.ad.GetLastSearchQueriesResponse.displayName = 'proto.api.v1.ad.GetLastSearchQueriesResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.TinyAd.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.TinyAd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.TinyAd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.TinyAd.toObject = function(includeInstance, msg) {
  var f, obj = {
    adId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    price: jspb.Message.getFieldWithDefault(msg, 4, 0),
    oldPrice: jspb.Message.getFieldWithDefault(msg, 5, 0),
    mainPhoto: (f = msg.getMainPhoto()) && proto.api.v1.ad.Photo.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && common_pb.Date.toObject(includeInstance, f),
    promoFeatures: (f = msg.getPromoFeatures()) && proto.api.v1.ad.PromoFeatures.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && common_pb.Location.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.TinyAd}
 */
proto.api.v1.ad.TinyAd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.TinyAd;
  return proto.api.v1.ad.TinyAd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.TinyAd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.TinyAd}
 */
proto.api.v1.ad.TinyAd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOldPrice(value);
      break;
    case 6:
      var value = new proto.api.v1.ad.Photo;
      reader.readMessage(value,proto.api.v1.ad.Photo.deserializeBinaryFromReader);
      msg.setMainPhoto(value);
      break;
    case 7:
      var value = new common_pb.Date;
      reader.readMessage(value,common_pb.Date.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 16:
      var value = new proto.api.v1.ad.PromoFeatures;
      reader.readMessage(value,proto.api.v1.ad.PromoFeatures.deserializeBinaryFromReader);
      msg.setPromoFeatures(value);
      break;
    case 17:
      var value = new common_pb.Location;
      reader.readMessage(value,common_pb.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.TinyAd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.TinyAd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.TinyAd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.TinyAd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getOldPrice();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getMainPhoto();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.api.v1.ad.Photo.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      common_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPromoFeatures();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.api.v1.ad.PromoFeatures.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      common_pb.Location.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ad_id = 1;
 * @return {string}
 */
proto.api.v1.ad.TinyAd.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.TinyAd} returns this
 */
proto.api.v1.ad.TinyAd.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.api.v1.ad.TinyAd.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.TinyAd} returns this
 */
proto.api.v1.ad.TinyAd.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.api.v1.ad.TinyAd.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.TinyAd} returns this
 */
proto.api.v1.ad.TinyAd.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 price = 4;
 * @return {number}
 */
proto.api.v1.ad.TinyAd.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.ad.TinyAd} returns this
 */
proto.api.v1.ad.TinyAd.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 old_price = 5;
 * @return {number}
 */
proto.api.v1.ad.TinyAd.prototype.getOldPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.ad.TinyAd} returns this
 */
proto.api.v1.ad.TinyAd.prototype.setOldPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional Photo main_photo = 6;
 * @return {?proto.api.v1.ad.Photo}
 */
proto.api.v1.ad.TinyAd.prototype.getMainPhoto = function() {
  return /** @type{?proto.api.v1.ad.Photo} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.ad.Photo, 6));
};


/**
 * @param {?proto.api.v1.ad.Photo|undefined} value
 * @return {!proto.api.v1.ad.TinyAd} returns this
*/
proto.api.v1.ad.TinyAd.prototype.setMainPhoto = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.ad.TinyAd} returns this
 */
proto.api.v1.ad.TinyAd.prototype.clearMainPhoto = function() {
  return this.setMainPhoto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.ad.TinyAd.prototype.hasMainPhoto = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional api.v1.common.Date created_at = 7;
 * @return {?proto.api.v1.common.Date}
 */
proto.api.v1.ad.TinyAd.prototype.getCreatedAt = function() {
  return /** @type{?proto.api.v1.common.Date} */ (
    jspb.Message.getWrapperField(this, common_pb.Date, 7));
};


/**
 * @param {?proto.api.v1.common.Date|undefined} value
 * @return {!proto.api.v1.ad.TinyAd} returns this
*/
proto.api.v1.ad.TinyAd.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.ad.TinyAd} returns this
 */
proto.api.v1.ad.TinyAd.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.ad.TinyAd.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PromoFeatures promo_features = 16;
 * @return {?proto.api.v1.ad.PromoFeatures}
 */
proto.api.v1.ad.TinyAd.prototype.getPromoFeatures = function() {
  return /** @type{?proto.api.v1.ad.PromoFeatures} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.ad.PromoFeatures, 16));
};


/**
 * @param {?proto.api.v1.ad.PromoFeatures|undefined} value
 * @return {!proto.api.v1.ad.TinyAd} returns this
*/
proto.api.v1.ad.TinyAd.prototype.setPromoFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.ad.TinyAd} returns this
 */
proto.api.v1.ad.TinyAd.prototype.clearPromoFeatures = function() {
  return this.setPromoFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.ad.TinyAd.prototype.hasPromoFeatures = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional api.v1.common.Location location = 17;
 * @return {?proto.api.v1.common.Location}
 */
proto.api.v1.ad.TinyAd.prototype.getLocation = function() {
  return /** @type{?proto.api.v1.common.Location} */ (
    jspb.Message.getWrapperField(this, common_pb.Location, 17));
};


/**
 * @param {?proto.api.v1.common.Location|undefined} value
 * @return {!proto.api.v1.ad.TinyAd} returns this
*/
proto.api.v1.ad.TinyAd.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.ad.TinyAd} returns this
 */
proto.api.v1.ad.TinyAd.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.ad.TinyAd.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.SavedSearch.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.SavedSearch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.SavedSearch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.SavedSearch.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    search: (f = msg.getSearch()) && proto.api.v1.ad.SearchAdsRequest.toObject(includeInstance, f),
    savedAt: (f = msg.getSavedAt()) && common_pb.Date.toObject(includeInstance, f),
    lastSearchAt: (f = msg.getLastSearchAt()) && common_pb.Date.toObject(includeInstance, f),
    lastViewAt: (f = msg.getLastViewAt()) && common_pb.Date.toObject(includeInstance, f),
    newAds: jspb.Message.getFieldWithDefault(msg, 6, 0),
    expired: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    hint: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.SavedSearch}
 */
proto.api.v1.ad.SavedSearch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.SavedSearch;
  return proto.api.v1.ad.SavedSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.SavedSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.SavedSearch}
 */
proto.api.v1.ad.SavedSearch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.v1.ad.SearchAdsRequest;
      reader.readMessage(value,proto.api.v1.ad.SearchAdsRequest.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 3:
      var value = new common_pb.Date;
      reader.readMessage(value,common_pb.Date.deserializeBinaryFromReader);
      msg.setSavedAt(value);
      break;
    case 4:
      var value = new common_pb.Date;
      reader.readMessage(value,common_pb.Date.deserializeBinaryFromReader);
      msg.setLastSearchAt(value);
      break;
    case 5:
      var value = new common_pb.Date;
      reader.readMessage(value,common_pb.Date.deserializeBinaryFromReader);
      msg.setLastViewAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNewAds(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExpired(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.SavedSearch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.SavedSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.SavedSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.SavedSearch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.v1.ad.SearchAdsRequest.serializeBinaryToWriter
    );
  }
  f = message.getSavedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getLastSearchAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getLastViewAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getNewAds();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getExpired();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getHint();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.v1.ad.SavedSearch.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.SavedSearch} returns this
 */
proto.api.v1.ad.SavedSearch.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SearchAdsRequest search = 2;
 * @return {?proto.api.v1.ad.SearchAdsRequest}
 */
proto.api.v1.ad.SavedSearch.prototype.getSearch = function() {
  return /** @type{?proto.api.v1.ad.SearchAdsRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.ad.SearchAdsRequest, 2));
};


/**
 * @param {?proto.api.v1.ad.SearchAdsRequest|undefined} value
 * @return {!proto.api.v1.ad.SavedSearch} returns this
*/
proto.api.v1.ad.SavedSearch.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.ad.SavedSearch} returns this
 */
proto.api.v1.ad.SavedSearch.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.ad.SavedSearch.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional api.v1.common.Date saved_at = 3;
 * @return {?proto.api.v1.common.Date}
 */
proto.api.v1.ad.SavedSearch.prototype.getSavedAt = function() {
  return /** @type{?proto.api.v1.common.Date} */ (
    jspb.Message.getWrapperField(this, common_pb.Date, 3));
};


/**
 * @param {?proto.api.v1.common.Date|undefined} value
 * @return {!proto.api.v1.ad.SavedSearch} returns this
*/
proto.api.v1.ad.SavedSearch.prototype.setSavedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.ad.SavedSearch} returns this
 */
proto.api.v1.ad.SavedSearch.prototype.clearSavedAt = function() {
  return this.setSavedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.ad.SavedSearch.prototype.hasSavedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional api.v1.common.Date last_search_at = 4;
 * @return {?proto.api.v1.common.Date}
 */
proto.api.v1.ad.SavedSearch.prototype.getLastSearchAt = function() {
  return /** @type{?proto.api.v1.common.Date} */ (
    jspb.Message.getWrapperField(this, common_pb.Date, 4));
};


/**
 * @param {?proto.api.v1.common.Date|undefined} value
 * @return {!proto.api.v1.ad.SavedSearch} returns this
*/
proto.api.v1.ad.SavedSearch.prototype.setLastSearchAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.ad.SavedSearch} returns this
 */
proto.api.v1.ad.SavedSearch.prototype.clearLastSearchAt = function() {
  return this.setLastSearchAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.ad.SavedSearch.prototype.hasLastSearchAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional api.v1.common.Date last_view_at = 5;
 * @return {?proto.api.v1.common.Date}
 */
proto.api.v1.ad.SavedSearch.prototype.getLastViewAt = function() {
  return /** @type{?proto.api.v1.common.Date} */ (
    jspb.Message.getWrapperField(this, common_pb.Date, 5));
};


/**
 * @param {?proto.api.v1.common.Date|undefined} value
 * @return {!proto.api.v1.ad.SavedSearch} returns this
*/
proto.api.v1.ad.SavedSearch.prototype.setLastViewAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.ad.SavedSearch} returns this
 */
proto.api.v1.ad.SavedSearch.prototype.clearLastViewAt = function() {
  return this.setLastViewAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.ad.SavedSearch.prototype.hasLastViewAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 new_ads = 6;
 * @return {number}
 */
proto.api.v1.ad.SavedSearch.prototype.getNewAds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.ad.SavedSearch} returns this
 */
proto.api.v1.ad.SavedSearch.prototype.setNewAds = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool expired = 7;
 * @return {boolean}
 */
proto.api.v1.ad.SavedSearch.prototype.getExpired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.ad.SavedSearch} returns this
 */
proto.api.v1.ad.SavedSearch.prototype.setExpired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string hint = 8;
 * @return {string}
 */
proto.api.v1.ad.SavedSearch.prototype.getHint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.SavedSearch} returns this
 */
proto.api.v1.ad.SavedSearch.prototype.setHint = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.PromoFeatures.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.PromoFeatures.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.PromoFeatures} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.PromoFeatures.toObject = function(includeInstance, msg) {
  var f, obj = {
    highlight: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.PromoFeatures}
 */
proto.api.v1.ad.PromoFeatures.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.PromoFeatures;
  return proto.api.v1.ad.PromoFeatures.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.PromoFeatures} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.PromoFeatures}
 */
proto.api.v1.ad.PromoFeatures.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHighlight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.PromoFeatures.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.PromoFeatures.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.PromoFeatures} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.PromoFeatures.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHighlight();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool highlight = 1;
 * @return {boolean}
 */
proto.api.v1.ad.PromoFeatures.prototype.getHighlight = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.ad.PromoFeatures} returns this
 */
proto.api.v1.ad.PromoFeatures.prototype.setHighlight = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.Photo.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.Photo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.Photo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.Photo.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    position: jspb.Message.getFieldWithDefault(msg, 4, 0),
    original: jspb.Message.getFieldWithDefault(msg, 5, ""),
    thumb: jspb.Message.getFieldWithDefault(msg, 6, ""),
    big: jspb.Message.getFieldWithDefault(msg, 7, ""),
    small: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.Photo}
 */
proto.api.v1.ad.Photo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.Photo;
  return proto.api.v1.ad.Photo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.Photo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.Photo}
 */
proto.api.v1.ad.Photo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPosition(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginal(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumb(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBig(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmall(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.Photo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.Photo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.Photo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.Photo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOriginal();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getThumb();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBig();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSmall();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.api.v1.ad.Photo.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.Photo} returns this
 */
proto.api.v1.ad.Photo.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.api.v1.ad.Photo.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.Photo} returns this
 */
proto.api.v1.ad.Photo.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ad_id = 3;
 * @return {string}
 */
proto.api.v1.ad.Photo.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.Photo} returns this
 */
proto.api.v1.ad.Photo.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 position = 4;
 * @return {number}
 */
proto.api.v1.ad.Photo.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.ad.Photo} returns this
 */
proto.api.v1.ad.Photo.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string original = 5;
 * @return {string}
 */
proto.api.v1.ad.Photo.prototype.getOriginal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.Photo} returns this
 */
proto.api.v1.ad.Photo.prototype.setOriginal = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string thumb = 6;
 * @return {string}
 */
proto.api.v1.ad.Photo.prototype.getThumb = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.Photo} returns this
 */
proto.api.v1.ad.Photo.prototype.setThumb = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string big = 7;
 * @return {string}
 */
proto.api.v1.ad.Photo.prototype.getBig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.Photo} returns this
 */
proto.api.v1.ad.Photo.prototype.setBig = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string small = 8;
 * @return {string}
 */
proto.api.v1.ad.Photo.prototype.getSmall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.Photo} returns this
 */
proto.api.v1.ad.Photo.prototype.setSmall = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.SearchAdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.SearchAdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.SearchAdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.SearchAdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filtersMap: (f = msg.getFiltersMap()) ? f.toObject(includeInstance, undefined) : [],
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    cursor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    save: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    toponim: (f = msg.getToponim()) && toponims_pb.Toponim.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.SearchAdsRequest}
 */
proto.api.v1.ad.SearchAdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.SearchAdsRequest;
  return proto.api.v1.ad.SearchAdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.SearchAdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.SearchAdsRequest}
 */
proto.api.v1.ad.SearchAdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = msg.getFiltersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSave(value);
      break;
    case 7:
      var value = new toponims_pb.Toponim;
      reader.readMessage(value,toponims_pb.Toponim.deserializeBinaryFromReader);
      msg.setToponim(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.SearchAdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.SearchAdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.SearchAdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.SearchAdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFiltersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSave();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getToponim();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      toponims_pb.Toponim.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.api.v1.ad.SearchAdsRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.SearchAdsRequest} returns this
 */
proto.api.v1.ad.SearchAdsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> filters = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.api.v1.ad.SearchAdsRequest.prototype.getFiltersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.api.v1.ad.SearchAdsRequest} returns this
 */
proto.api.v1.ad.SearchAdsRequest.prototype.clearFiltersMap = function() {
  this.getFiltersMap().clear();
  return this;};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.api.v1.ad.SearchAdsRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.SearchAdsRequest} returns this
 */
proto.api.v1.ad.SearchAdsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 limit = 4;
 * @return {number}
 */
proto.api.v1.ad.SearchAdsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.ad.SearchAdsRequest} returns this
 */
proto.api.v1.ad.SearchAdsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string cursor = 5;
 * @return {string}
 */
proto.api.v1.ad.SearchAdsRequest.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.SearchAdsRequest} returns this
 */
proto.api.v1.ad.SearchAdsRequest.prototype.setCursor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool save = 6;
 * @return {boolean}
 */
proto.api.v1.ad.SearchAdsRequest.prototype.getSave = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.ad.SearchAdsRequest} returns this
 */
proto.api.v1.ad.SearchAdsRequest.prototype.setSave = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional api.v1.toponims.Toponim toponim = 7;
 * @return {?proto.api.v1.toponims.Toponim}
 */
proto.api.v1.ad.SearchAdsRequest.prototype.getToponim = function() {
  return /** @type{?proto.api.v1.toponims.Toponim} */ (
    jspb.Message.getWrapperField(this, toponims_pb.Toponim, 7));
};


/**
 * @param {?proto.api.v1.toponims.Toponim|undefined} value
 * @return {!proto.api.v1.ad.SearchAdsRequest} returns this
*/
proto.api.v1.ad.SearchAdsRequest.prototype.setToponim = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.ad.SearchAdsRequest} returns this
 */
proto.api.v1.ad.SearchAdsRequest.prototype.clearToponim = function() {
  return this.setToponim(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.ad.SearchAdsRequest.prototype.hasToponim = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.ad.SearchAdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.SearchAdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.SearchAdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.SearchAdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.SearchAdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    adsList: jspb.Message.toObjectList(msg.getAdsList(),
    proto.api.v1.ad.TinyAd.toObject, includeInstance),
    cursor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    saved: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    filtersMap: (f = msg.getFiltersMap()) ? f.toObject(includeInstance, undefined) : [],
    toponim: (f = msg.getToponim()) && toponims_pb.Toponim.toObject(includeInstance, f),
    totalResults: jspb.Message.getFieldWithDefault(msg, 6, 0),
    savedId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.SearchAdsResponse}
 */
proto.api.v1.ad.SearchAdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.SearchAdsResponse;
  return proto.api.v1.ad.SearchAdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.SearchAdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.SearchAdsResponse}
 */
proto.api.v1.ad.SearchAdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.ad.TinyAd;
      reader.readMessage(value,proto.api.v1.ad.TinyAd.deserializeBinaryFromReader);
      msg.addAds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSaved(value);
      break;
    case 4:
      var value = msg.getFiltersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 5:
      var value = new toponims_pb.Toponim;
      reader.readMessage(value,toponims_pb.Toponim.deserializeBinaryFromReader);
      msg.setToponim(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalResults(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSavedId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.SearchAdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.SearchAdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.SearchAdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.SearchAdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.ad.TinyAd.serializeBinaryToWriter
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSaved();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getFiltersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getToponim();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      toponims_pb.Toponim.serializeBinaryToWriter
    );
  }
  f = message.getTotalResults();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getSavedId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * repeated TinyAd ads = 1;
 * @return {!Array<!proto.api.v1.ad.TinyAd>}
 */
proto.api.v1.ad.SearchAdsResponse.prototype.getAdsList = function() {
  return /** @type{!Array<!proto.api.v1.ad.TinyAd>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.ad.TinyAd, 1));
};


/**
 * @param {!Array<!proto.api.v1.ad.TinyAd>} value
 * @return {!proto.api.v1.ad.SearchAdsResponse} returns this
*/
proto.api.v1.ad.SearchAdsResponse.prototype.setAdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.ad.TinyAd=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.ad.TinyAd}
 */
proto.api.v1.ad.SearchAdsResponse.prototype.addAds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.ad.TinyAd, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.ad.SearchAdsResponse} returns this
 */
proto.api.v1.ad.SearchAdsResponse.prototype.clearAdsList = function() {
  return this.setAdsList([]);
};


/**
 * optional string cursor = 2;
 * @return {string}
 */
proto.api.v1.ad.SearchAdsResponse.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.SearchAdsResponse} returns this
 */
proto.api.v1.ad.SearchAdsResponse.prototype.setCursor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool saved = 3;
 * @return {boolean}
 */
proto.api.v1.ad.SearchAdsResponse.prototype.getSaved = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.ad.SearchAdsResponse} returns this
 */
proto.api.v1.ad.SearchAdsResponse.prototype.setSaved = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * map<string, string> filters = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.api.v1.ad.SearchAdsResponse.prototype.getFiltersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.api.v1.ad.SearchAdsResponse} returns this
 */
proto.api.v1.ad.SearchAdsResponse.prototype.clearFiltersMap = function() {
  this.getFiltersMap().clear();
  return this;};


/**
 * optional api.v1.toponims.Toponim toponim = 5;
 * @return {?proto.api.v1.toponims.Toponim}
 */
proto.api.v1.ad.SearchAdsResponse.prototype.getToponim = function() {
  return /** @type{?proto.api.v1.toponims.Toponim} */ (
    jspb.Message.getWrapperField(this, toponims_pb.Toponim, 5));
};


/**
 * @param {?proto.api.v1.toponims.Toponim|undefined} value
 * @return {!proto.api.v1.ad.SearchAdsResponse} returns this
*/
proto.api.v1.ad.SearchAdsResponse.prototype.setToponim = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.ad.SearchAdsResponse} returns this
 */
proto.api.v1.ad.SearchAdsResponse.prototype.clearToponim = function() {
  return this.setToponim(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.ad.SearchAdsResponse.prototype.hasToponim = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint64 total_results = 6;
 * @return {number}
 */
proto.api.v1.ad.SearchAdsResponse.prototype.getTotalResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.ad.SearchAdsResponse} returns this
 */
proto.api.v1.ad.SearchAdsResponse.prototype.setTotalResults = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string saved_id = 7;
 * @return {string}
 */
proto.api.v1.ad.SearchAdsResponse.prototype.getSavedId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.SearchAdsResponse} returns this
 */
proto.api.v1.ad.SearchAdsResponse.prototype.setSavedId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.CountSearchResultsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.CountSearchResultsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.CountSearchResultsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.CountSearchResultsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchRequest: (f = msg.getSearchRequest()) && proto.api.v1.ad.SearchAdsRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.CountSearchResultsRequest}
 */
proto.api.v1.ad.CountSearchResultsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.CountSearchResultsRequest;
  return proto.api.v1.ad.CountSearchResultsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.CountSearchResultsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.CountSearchResultsRequest}
 */
proto.api.v1.ad.CountSearchResultsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.ad.SearchAdsRequest;
      reader.readMessage(value,proto.api.v1.ad.SearchAdsRequest.deserializeBinaryFromReader);
      msg.setSearchRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.CountSearchResultsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.CountSearchResultsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.CountSearchResultsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.CountSearchResultsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.v1.ad.SearchAdsRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional SearchAdsRequest search_request = 1;
 * @return {?proto.api.v1.ad.SearchAdsRequest}
 */
proto.api.v1.ad.CountSearchResultsRequest.prototype.getSearchRequest = function() {
  return /** @type{?proto.api.v1.ad.SearchAdsRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.ad.SearchAdsRequest, 1));
};


/**
 * @param {?proto.api.v1.ad.SearchAdsRequest|undefined} value
 * @return {!proto.api.v1.ad.CountSearchResultsRequest} returns this
*/
proto.api.v1.ad.CountSearchResultsRequest.prototype.setSearchRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.ad.CountSearchResultsRequest} returns this
 */
proto.api.v1.ad.CountSearchResultsRequest.prototype.clearSearchRequest = function() {
  return this.setSearchRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.ad.CountSearchResultsRequest.prototype.hasSearchRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.CountSearchResultsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.CountSearchResultsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.CountSearchResultsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.CountSearchResultsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalResults: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.CountSearchResultsResponse}
 */
proto.api.v1.ad.CountSearchResultsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.CountSearchResultsResponse;
  return proto.api.v1.ad.CountSearchResultsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.CountSearchResultsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.CountSearchResultsResponse}
 */
proto.api.v1.ad.CountSearchResultsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.CountSearchResultsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.CountSearchResultsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.CountSearchResultsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.CountSearchResultsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalResults();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 total_results = 1;
 * @return {number}
 */
proto.api.v1.ad.CountSearchResultsResponse.prototype.getTotalResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.ad.CountSearchResultsResponse} returns this
 */
proto.api.v1.ad.CountSearchResultsResponse.prototype.setTotalResults = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.GetSearchHintsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.GetSearchHintsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.GetSearchHintsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.GetSearchHintsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.GetSearchHintsRequest}
 */
proto.api.v1.ad.GetSearchHintsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.GetSearchHintsRequest;
  return proto.api.v1.ad.GetSearchHintsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.GetSearchHintsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.GetSearchHintsRequest}
 */
proto.api.v1.ad.GetSearchHintsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.GetSearchHintsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.GetSearchHintsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.GetSearchHintsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.GetSearchHintsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.api.v1.ad.GetSearchHintsRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.GetSearchHintsRequest} returns this
 */
proto.api.v1.ad.GetSearchHintsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.api.v1.ad.GetSearchHintsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.ad.GetSearchHintsRequest} returns this
 */
proto.api.v1.ad.GetSearchHintsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.ad.GetSearchHintsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.GetSearchHintsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.GetSearchHintsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.GetSearchHintsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.GetSearchHintsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hintsList: jspb.Message.toObjectList(msg.getHintsList(),
    proto.api.v1.ad.SearchHint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.GetSearchHintsResponse}
 */
proto.api.v1.ad.GetSearchHintsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.GetSearchHintsResponse;
  return proto.api.v1.ad.GetSearchHintsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.GetSearchHintsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.GetSearchHintsResponse}
 */
proto.api.v1.ad.GetSearchHintsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.ad.SearchHint;
      reader.readMessage(value,proto.api.v1.ad.SearchHint.deserializeBinaryFromReader);
      msg.addHints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.GetSearchHintsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.GetSearchHintsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.GetSearchHintsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.GetSearchHintsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHintsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.ad.SearchHint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SearchHint hints = 1;
 * @return {!Array<!proto.api.v1.ad.SearchHint>}
 */
proto.api.v1.ad.GetSearchHintsResponse.prototype.getHintsList = function() {
  return /** @type{!Array<!proto.api.v1.ad.SearchHint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.ad.SearchHint, 1));
};


/**
 * @param {!Array<!proto.api.v1.ad.SearchHint>} value
 * @return {!proto.api.v1.ad.GetSearchHintsResponse} returns this
*/
proto.api.v1.ad.GetSearchHintsResponse.prototype.setHintsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.ad.SearchHint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.ad.SearchHint}
 */
proto.api.v1.ad.GetSearchHintsResponse.prototype.addHints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.ad.SearchHint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.ad.GetSearchHintsResponse} returns this
 */
proto.api.v1.ad.GetSearchHintsResponse.prototype.clearHintsList = function() {
  return this.setHintsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.SearchHint.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.SearchHint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.SearchHint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.SearchHint.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hint: jspb.Message.getFieldWithDefault(msg, 2, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 3, ""),
    request: (f = msg.getRequest()) && proto.api.v1.ad.SearchAdsRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.SearchHint}
 */
proto.api.v1.ad.SearchHint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.SearchHint;
  return proto.api.v1.ad.SearchHint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.SearchHint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.SearchHint}
 */
proto.api.v1.ad.SearchHint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHint(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 4:
      var value = new proto.api.v1.ad.SearchAdsRequest;
      reader.readMessage(value,proto.api.v1.ad.SearchAdsRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.SearchHint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.SearchHint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.SearchHint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.SearchHint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHint();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.api.v1.ad.SearchAdsRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.api.v1.ad.SearchHint.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.SearchHint} returns this
 */
proto.api.v1.ad.SearchHint.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hint = 2;
 * @return {string}
 */
proto.api.v1.ad.SearchHint.prototype.getHint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.SearchHint} returns this
 */
proto.api.v1.ad.SearchHint.prototype.setHint = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string icon = 3;
 * @return {string}
 */
proto.api.v1.ad.SearchHint.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.SearchHint} returns this
 */
proto.api.v1.ad.SearchHint.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SearchAdsRequest request = 4;
 * @return {?proto.api.v1.ad.SearchAdsRequest}
 */
proto.api.v1.ad.SearchHint.prototype.getRequest = function() {
  return /** @type{?proto.api.v1.ad.SearchAdsRequest} */ (
    jspb.Message.getWrapperField(this, proto.api.v1.ad.SearchAdsRequest, 4));
};


/**
 * @param {?proto.api.v1.ad.SearchAdsRequest|undefined} value
 * @return {!proto.api.v1.ad.SearchHint} returns this
*/
proto.api.v1.ad.SearchHint.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.v1.ad.SearchHint} returns this
 */
proto.api.v1.ad.SearchHint.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.v1.ad.SearchHint.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.GetRecommendedAdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.GetRecommendedAdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.GetRecommendedAdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.GetRecommendedAdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cursor: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.GetRecommendedAdsRequest}
 */
proto.api.v1.ad.GetRecommendedAdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.GetRecommendedAdsRequest;
  return proto.api.v1.ad.GetRecommendedAdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.GetRecommendedAdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.GetRecommendedAdsRequest}
 */
proto.api.v1.ad.GetRecommendedAdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.GetRecommendedAdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.GetRecommendedAdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.GetRecommendedAdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.GetRecommendedAdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint64 limit = 1;
 * @return {number}
 */
proto.api.v1.ad.GetRecommendedAdsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.ad.GetRecommendedAdsRequest} returns this
 */
proto.api.v1.ad.GetRecommendedAdsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string cursor = 2;
 * @return {string}
 */
proto.api.v1.ad.GetRecommendedAdsRequest.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.GetRecommendedAdsRequest} returns this
 */
proto.api.v1.ad.GetRecommendedAdsRequest.prototype.setCursor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.ad.GetRecommendedAdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.GetRecommendedAdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.GetRecommendedAdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.GetRecommendedAdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.GetRecommendedAdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    adsList: jspb.Message.toObjectList(msg.getAdsList(),
    proto.api.v1.ad.TinyAd.toObject, includeInstance),
    cursor: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.GetRecommendedAdsResponse}
 */
proto.api.v1.ad.GetRecommendedAdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.GetRecommendedAdsResponse;
  return proto.api.v1.ad.GetRecommendedAdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.GetRecommendedAdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.GetRecommendedAdsResponse}
 */
proto.api.v1.ad.GetRecommendedAdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.ad.TinyAd;
      reader.readMessage(value,proto.api.v1.ad.TinyAd.deserializeBinaryFromReader);
      msg.addAds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.GetRecommendedAdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.GetRecommendedAdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.GetRecommendedAdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.GetRecommendedAdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.ad.TinyAd.serializeBinaryToWriter
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated TinyAd ads = 1;
 * @return {!Array<!proto.api.v1.ad.TinyAd>}
 */
proto.api.v1.ad.GetRecommendedAdsResponse.prototype.getAdsList = function() {
  return /** @type{!Array<!proto.api.v1.ad.TinyAd>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.ad.TinyAd, 1));
};


/**
 * @param {!Array<!proto.api.v1.ad.TinyAd>} value
 * @return {!proto.api.v1.ad.GetRecommendedAdsResponse} returns this
*/
proto.api.v1.ad.GetRecommendedAdsResponse.prototype.setAdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.ad.TinyAd=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.ad.TinyAd}
 */
proto.api.v1.ad.GetRecommendedAdsResponse.prototype.addAds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.ad.TinyAd, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.ad.GetRecommendedAdsResponse} returns this
 */
proto.api.v1.ad.GetRecommendedAdsResponse.prototype.clearAdsList = function() {
  return this.setAdsList([]);
};


/**
 * optional string cursor = 2;
 * @return {string}
 */
proto.api.v1.ad.GetRecommendedAdsResponse.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.GetRecommendedAdsResponse} returns this
 */
proto.api.v1.ad.GetRecommendedAdsResponse.prototype.setCursor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.ViewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.ViewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.ViewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.ViewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    adId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.ViewRequest}
 */
proto.api.v1.ad.ViewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.ViewRequest;
  return proto.api.v1.ad.ViewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.ViewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.ViewRequest}
 */
proto.api.v1.ad.ViewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.ViewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.ViewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.ViewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.ViewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ad_id = 1;
 * @return {string}
 */
proto.api.v1.ad.ViewRequest.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.ViewRequest} returns this
 */
proto.api.v1.ad.ViewRequest.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.ad.ViewResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.ViewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.ViewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.ViewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.ViewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    adIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.ViewResponse}
 */
proto.api.v1.ad.ViewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.ViewResponse;
  return proto.api.v1.ad.ViewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.ViewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.ViewResponse}
 */
proto.api.v1.ad.ViewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.ViewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.ViewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.ViewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.ViewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ad_ids = 1;
 * @return {!Array<string>}
 */
proto.api.v1.ad.ViewResponse.prototype.getAdIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.api.v1.ad.ViewResponse} returns this
 */
proto.api.v1.ad.ViewResponse.prototype.setAdIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.api.v1.ad.ViewResponse} returns this
 */
proto.api.v1.ad.ViewResponse.prototype.addAdIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.ad.ViewResponse} returns this
 */
proto.api.v1.ad.ViewResponse.prototype.clearAdIdsList = function() {
  return this.setAdIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.AddWebviewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.AddWebviewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.AddWebviewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.AddWebviewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    adId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.AddWebviewRequest}
 */
proto.api.v1.ad.AddWebviewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.AddWebviewRequest;
  return proto.api.v1.ad.AddWebviewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.AddWebviewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.AddWebviewRequest}
 */
proto.api.v1.ad.AddWebviewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.AddWebviewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.AddWebviewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.AddWebviewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.AddWebviewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ad_id = 1;
 * @return {string}
 */
proto.api.v1.ad.AddWebviewRequest.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.AddWebviewRequest} returns this
 */
proto.api.v1.ad.AddWebviewRequest.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.AddWebviewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.AddWebviewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.AddWebviewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.AddWebviewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.AddWebviewResponse}
 */
proto.api.v1.ad.AddWebviewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.AddWebviewResponse;
  return proto.api.v1.ad.AddWebviewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.AddWebviewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.AddWebviewResponse}
 */
proto.api.v1.ad.AddWebviewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.AddWebviewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.AddWebviewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.AddWebviewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.AddWebviewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.FavouriteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.FavouriteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.FavouriteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.FavouriteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    adId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.FavouriteRequest}
 */
proto.api.v1.ad.FavouriteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.FavouriteRequest;
  return proto.api.v1.ad.FavouriteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.FavouriteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.FavouriteRequest}
 */
proto.api.v1.ad.FavouriteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.FavouriteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.FavouriteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.FavouriteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.FavouriteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ad_id = 1;
 * @return {string}
 */
proto.api.v1.ad.FavouriteRequest.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.FavouriteRequest} returns this
 */
proto.api.v1.ad.FavouriteRequest.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.ad.FavouriteResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.FavouriteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.FavouriteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.FavouriteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.FavouriteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    adIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.FavouriteResponse}
 */
proto.api.v1.ad.FavouriteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.FavouriteResponse;
  return proto.api.v1.ad.FavouriteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.FavouriteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.FavouriteResponse}
 */
proto.api.v1.ad.FavouriteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.FavouriteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.FavouriteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.FavouriteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.FavouriteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ad_ids = 1;
 * @return {!Array<string>}
 */
proto.api.v1.ad.FavouriteResponse.prototype.getAdIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.api.v1.ad.FavouriteResponse} returns this
 */
proto.api.v1.ad.FavouriteResponse.prototype.setAdIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.api.v1.ad.FavouriteResponse} returns this
 */
proto.api.v1.ad.FavouriteResponse.prototype.addAdIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.ad.FavouriteResponse} returns this
 */
proto.api.v1.ad.FavouriteResponse.prototype.clearAdIdsList = function() {
  return this.setAdIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.GetSavedSearchesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.GetSavedSearchesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.GetSavedSearchesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.GetSavedSearchesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.GetSavedSearchesRequest}
 */
proto.api.v1.ad.GetSavedSearchesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.GetSavedSearchesRequest;
  return proto.api.v1.ad.GetSavedSearchesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.GetSavedSearchesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.GetSavedSearchesRequest}
 */
proto.api.v1.ad.GetSavedSearchesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.GetSavedSearchesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.GetSavedSearchesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.GetSavedSearchesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.GetSavedSearchesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.ad.GetSavedSearchesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.GetSavedSearchesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.GetSavedSearchesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.GetSavedSearchesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.GetSavedSearchesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchesList: jspb.Message.toObjectList(msg.getSearchesList(),
    proto.api.v1.ad.SavedSearch.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.GetSavedSearchesResponse}
 */
proto.api.v1.ad.GetSavedSearchesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.GetSavedSearchesResponse;
  return proto.api.v1.ad.GetSavedSearchesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.GetSavedSearchesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.GetSavedSearchesResponse}
 */
proto.api.v1.ad.GetSavedSearchesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.ad.SavedSearch;
      reader.readMessage(value,proto.api.v1.ad.SavedSearch.deserializeBinaryFromReader);
      msg.addSearches(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.GetSavedSearchesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.GetSavedSearchesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.GetSavedSearchesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.GetSavedSearchesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.ad.SavedSearch.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SavedSearch searches = 1;
 * @return {!Array<!proto.api.v1.ad.SavedSearch>}
 */
proto.api.v1.ad.GetSavedSearchesResponse.prototype.getSearchesList = function() {
  return /** @type{!Array<!proto.api.v1.ad.SavedSearch>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.ad.SavedSearch, 1));
};


/**
 * @param {!Array<!proto.api.v1.ad.SavedSearch>} value
 * @return {!proto.api.v1.ad.GetSavedSearchesResponse} returns this
*/
proto.api.v1.ad.GetSavedSearchesResponse.prototype.setSearchesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.ad.SavedSearch=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.ad.SavedSearch}
 */
proto.api.v1.ad.GetSavedSearchesResponse.prototype.addSearches = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.ad.SavedSearch, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.ad.GetSavedSearchesResponse} returns this
 */
proto.api.v1.ad.GetSavedSearchesResponse.prototype.clearSearchesList = function() {
  return this.setSearchesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.UpdateSavedSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.UpdateSavedSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.UpdateSavedSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.UpdateSavedSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expired: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.UpdateSavedSearchRequest}
 */
proto.api.v1.ad.UpdateSavedSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.UpdateSavedSearchRequest;
  return proto.api.v1.ad.UpdateSavedSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.UpdateSavedSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.UpdateSavedSearchRequest}
 */
proto.api.v1.ad.UpdateSavedSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExpired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.UpdateSavedSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.UpdateSavedSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.UpdateSavedSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.UpdateSavedSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpired();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.v1.ad.UpdateSavedSearchRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.UpdateSavedSearchRequest} returns this
 */
proto.api.v1.ad.UpdateSavedSearchRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool expired = 2;
 * @return {boolean}
 */
proto.api.v1.ad.UpdateSavedSearchRequest.prototype.getExpired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.api.v1.ad.UpdateSavedSearchRequest} returns this
 */
proto.api.v1.ad.UpdateSavedSearchRequest.prototype.setExpired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.UpdateSavedSearchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.UpdateSavedSearchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.UpdateSavedSearchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.UpdateSavedSearchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.UpdateSavedSearchResponse}
 */
proto.api.v1.ad.UpdateSavedSearchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.UpdateSavedSearchResponse;
  return proto.api.v1.ad.UpdateSavedSearchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.UpdateSavedSearchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.UpdateSavedSearchResponse}
 */
proto.api.v1.ad.UpdateSavedSearchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.api.v1.ad.UpdateSavedSearchResponse.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.UpdateSavedSearchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.UpdateSavedSearchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.UpdateSavedSearchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.UpdateSavedSearchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.api.v1.ad.UpdateSavedSearchResponse.Status = {
  UNKNOWN: 0,
  SUCCESS: 1,
  SEARCH_NOT_FOUND: 2
};

/**
 * optional Status status = 1;
 * @return {!proto.api.v1.ad.UpdateSavedSearchResponse.Status}
 */
proto.api.v1.ad.UpdateSavedSearchResponse.prototype.getStatus = function() {
  return /** @type {!proto.api.v1.ad.UpdateSavedSearchResponse.Status} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.api.v1.ad.UpdateSavedSearchResponse.Status} value
 * @return {!proto.api.v1.ad.UpdateSavedSearchResponse} returns this
 */
proto.api.v1.ad.UpdateSavedSearchResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.DeleteSavedSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.DeleteSavedSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.DeleteSavedSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.DeleteSavedSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.DeleteSavedSearchRequest}
 */
proto.api.v1.ad.DeleteSavedSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.DeleteSavedSearchRequest;
  return proto.api.v1.ad.DeleteSavedSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.DeleteSavedSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.DeleteSavedSearchRequest}
 */
proto.api.v1.ad.DeleteSavedSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.DeleteSavedSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.DeleteSavedSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.DeleteSavedSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.DeleteSavedSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string search_id = 1;
 * @return {string}
 */
proto.api.v1.ad.DeleteSavedSearchRequest.prototype.getSearchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.DeleteSavedSearchRequest} returns this
 */
proto.api.v1.ad.DeleteSavedSearchRequest.prototype.setSearchId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.DeleteSavedSearchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.DeleteSavedSearchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.DeleteSavedSearchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.DeleteSavedSearchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.DeleteSavedSearchResponse}
 */
proto.api.v1.ad.DeleteSavedSearchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.DeleteSavedSearchResponse;
  return proto.api.v1.ad.DeleteSavedSearchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.DeleteSavedSearchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.DeleteSavedSearchResponse}
 */
proto.api.v1.ad.DeleteSavedSearchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.api.v1.ad.DeleteSavedSearchResponse.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.DeleteSavedSearchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.DeleteSavedSearchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.DeleteSavedSearchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.DeleteSavedSearchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.api.v1.ad.DeleteSavedSearchResponse.Status = {
  UNKNOWN: 0,
  SUCCESS: 1,
  SEARCH_NOT_FOUND: 2
};

/**
 * optional Status status = 1;
 * @return {!proto.api.v1.ad.DeleteSavedSearchResponse.Status}
 */
proto.api.v1.ad.DeleteSavedSearchResponse.prototype.getStatus = function() {
  return /** @type {!proto.api.v1.ad.DeleteSavedSearchResponse.Status} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.api.v1.ad.DeleteSavedSearchResponse.Status} value
 * @return {!proto.api.v1.ad.DeleteSavedSearchResponse} returns this
 */
proto.api.v1.ad.DeleteSavedSearchResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.HistoryQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.HistoryQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.HistoryQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.HistoryQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.HistoryQuery}
 */
proto.api.v1.ad.HistoryQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.HistoryQuery;
  return proto.api.v1.ad.HistoryQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.HistoryQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.HistoryQuery}
 */
proto.api.v1.ad.HistoryQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.HistoryQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.HistoryQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.HistoryQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.HistoryQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.api.v1.ad.HistoryQuery.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.HistoryQuery} returns this
 */
proto.api.v1.ad.HistoryQuery.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_id = 2;
 * @return {string}
 */
proto.api.v1.ad.HistoryQuery.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.v1.ad.HistoryQuery} returns this
 */
proto.api.v1.ad.HistoryQuery.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.GetLastSearchQueriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.GetLastSearchQueriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.GetLastSearchQueriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.GetLastSearchQueriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.GetLastSearchQueriesRequest}
 */
proto.api.v1.ad.GetLastSearchQueriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.GetLastSearchQueriesRequest;
  return proto.api.v1.ad.GetLastSearchQueriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.GetLastSearchQueriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.GetLastSearchQueriesRequest}
 */
proto.api.v1.ad.GetLastSearchQueriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.GetLastSearchQueriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.GetLastSearchQueriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.GetLastSearchQueriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.GetLastSearchQueriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 limit = 1;
 * @return {number}
 */
proto.api.v1.ad.GetLastSearchQueriesRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.v1.ad.GetLastSearchQueriesRequest} returns this
 */
proto.api.v1.ad.GetLastSearchQueriesRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.v1.ad.GetLastSearchQueriesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.v1.ad.GetLastSearchQueriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.v1.ad.GetLastSearchQueriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.v1.ad.GetLastSearchQueriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.GetLastSearchQueriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    queriesList: jspb.Message.toObjectList(msg.getQueriesList(),
    proto.api.v1.ad.HistoryQuery.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.v1.ad.GetLastSearchQueriesResponse}
 */
proto.api.v1.ad.GetLastSearchQueriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.v1.ad.GetLastSearchQueriesResponse;
  return proto.api.v1.ad.GetLastSearchQueriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.v1.ad.GetLastSearchQueriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.v1.ad.GetLastSearchQueriesResponse}
 */
proto.api.v1.ad.GetLastSearchQueriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.v1.ad.HistoryQuery;
      reader.readMessage(value,proto.api.v1.ad.HistoryQuery.deserializeBinaryFromReader);
      msg.addQueries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.v1.ad.GetLastSearchQueriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.v1.ad.GetLastSearchQueriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.v1.ad.GetLastSearchQueriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.v1.ad.GetLastSearchQueriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.v1.ad.HistoryQuery.serializeBinaryToWriter
    );
  }
};


/**
 * repeated HistoryQuery queries = 1;
 * @return {!Array<!proto.api.v1.ad.HistoryQuery>}
 */
proto.api.v1.ad.GetLastSearchQueriesResponse.prototype.getQueriesList = function() {
  return /** @type{!Array<!proto.api.v1.ad.HistoryQuery>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.v1.ad.HistoryQuery, 1));
};


/**
 * @param {!Array<!proto.api.v1.ad.HistoryQuery>} value
 * @return {!proto.api.v1.ad.GetLastSearchQueriesResponse} returns this
*/
proto.api.v1.ad.GetLastSearchQueriesResponse.prototype.setQueriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.v1.ad.HistoryQuery=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.v1.ad.HistoryQuery}
 */
proto.api.v1.ad.GetLastSearchQueriesResponse.prototype.addQueries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.v1.ad.HistoryQuery, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.v1.ad.GetLastSearchQueriesResponse} returns this
 */
proto.api.v1.ad.GetLastSearchQueriesResponse.prototype.clearQueriesList = function() {
  return this.setQueriesList([]);
};


goog.object.extend(exports, proto.api.v1.ad);
