<template>
  <ProfileHeader
    :sidebarVisible="sidebarVisible"
    @set-sidebar-visible="(n) => (sidebarVisible = n)"
    ref="header"
  />

  <div class="flex-wrap">
    <ProfileSidebar
      :sidebarVisible="sidebarVisible"
      @close-clicked="() => (sidebarVisible = false)"
    />

    <main>
      <the-error-handler :showErr="showErr">
        <slot></slot>
      </the-error-handler>
    </main>
  </div>
</template>

<script>
import { ref } from "vue";
import { useMeta } from "vue-meta";

import ProfileSidebar from "./ProfileSidebar.vue";
import ProfileHeader from "./ProfileHeader.vue";
import TheErrorHandler from "../../components/TheErrorHandler.vue";

export default {
  name: "ProfileLayout",
  components: {
    ProfileHeader,
    ProfileSidebar,
    TheErrorHandler,
  },
  setup() {
    useMeta({
      bodyAttrs: {
        class:
          "archive author author-oiufih8034 author-3 logged-in kc-css-system author-dashboard",
      },
    });

    return {
      showErr: ref(false),
      sidebarVisible: ref(false),
    };
  },
  errorCaptured(err, instance, info) {
    console.error("Error: ", err, instance, info);
    if (process.env.NODE_ENV != "development") {
      this.showErr = true;
    }
  },
};
</script>
