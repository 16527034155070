<template>
  <div class="filters-list">
    <div v-if="showLoader" :style="loaderStyle" class="loader-anim">
      <TheLoader />
    </div>

    <div :style="resultsStyle">
      <div class="clear-button-wrapper">
        <button class="af-button clear-button noselect" @click="clearFilters">
          {{ t("filters.filters_list.clear_filters") }}
          <a
            class="reset-search"
            :title="t('filters.filters_list.clear_filters')"
            ><i class="aficon-undo"></i>
          </a>
        </button>
      </div>

      <div :key="f.idx" v-for="f in filters" class="form-group">
        <component :is="f.component" :="f.props"
        @on-value-selected="selectFilterValue"
        @on-toponim-selected="selectToponim" />
      </div>

      <div
        :class="
          stickyBtnApply
            ? 'submit-search-form submit-sticky'
            : 'submit-search-form'
        "
      >
        <button
          @click="applyClicked"
          @keydown.enter="applyClicked"
          class="af-button filter-adverts"
        >
          {{ t("filters.filters_list.apply", { x: resultsCount }) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, shallowRef } from "vue";
import { useI18n } from "vue-i18n";
import _ from "lodash";

import TheLoader from "modules/ui/TheLoader";

import { getFilters as getFiltersApi } from "api/filters.js";
import { fromFilter } from "./from_api.js";
import { toUrlEncoded } from "./to_api.js";
import { getSearchResultsCount as getSearchResultsCountApi } from "api/search.js";

export default {
  name: "FiltersSearch",
  components: { TheLoader },
  props: {
    /** Показывать анимацию загрузки при обновлении фильтров */
    showLoader: {
      type: Boolean,
      required: false,
      default: true,
    },
    /** Если true, держать кнопку применить фильтры внизу экрана */
    stickyBtnApply: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Фильтры, которые уже были выбраны.
     * Должен иметь ключ filter_id и значение filter_value
     */
    alreadySelectedFilters: {
      type: Object,
      required: false,
      default: undefined,
    },
    /** Топоним, который уже был выбран */
    alreadySelectedToponim: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  emits: [
    /**
     * Была нажата кнопка применить фильтры.
     * Параметры:
     *  1. filters {Object} - список фильтров
     *  2. toponim {Object} - топоним
     */
    "on-filters-applied",
  ],
  setup() {
    const { t } = useI18n();

    return {
      t,
      /** Список фильтров, полученных с сервера */
      filters: shallowRef([]),
      selected: {
        filters: {},
        toponim: {},
      },
      resultsCount: ref(0),
      isLoading: ref(false),
    };
  },
  async mounted() {
    var applied = await this.applyFiltersToponimFromProps();
    if (!applied) {
      await this.updateFilters();
    }
  },
  computed: {
    loaderStyle() {
      if (this.isLoading) {
        return {
          visibility: "visible",
        };
      }

      return {
        visibility: "hidden",
      };
    },
    resultsStyle() {
      if (this.isLoading) {
        return {
          "pointer-events": "none",
          opacity: "0.4",
        };
      }

      return {};
    },
  },
  watch: {
    // TODO: обновлять фильтры и топоним одним запросом
    async alreadySelectedFilters() {
      if (this.alreadySelectedFilters == undefined) {
        return;
      }
      await this.applyFiltersToponimFromProps();
    },
    async alreadySelectedToponim() {
      if (this.alreadySelectedToponim == undefined) {
        return;
      }

      await this.applyFiltersToponimFromProps();
    },
  },
  methods: {
    async selectFilterValue(id, value) {
      if (String(value).length == 0) {
        delete this.selected.filters[id];
        await this.updateFilters();
        return;
      }

      var encoded = toUrlEncoded(value.constructor.name, value);
      this.selected.filters[id] = encoded;
      await this.updateFilters();
    },
    async selectToponim(id, toponim) {
      this.selected.toponim = toponim;
      await this.updateFilters();
    },
    /** Применить alreaySelectedFilters и alreadySelectedToponim.
     * @returns {Boolean} Были ли обновлены фильтры
     */
    async applyFiltersToponimFromProps() {
      var shouldUpdate = false;

      if (this.alreadySelectedFilters != undefined) {
        if (!_.isEqual(this.alreadySelectedFilters, this.selected.filters)) {
          this.selected.filters = this.alreadySelectedFilters;
          shouldUpdate = true;
        }
      }

      if (this.alreadySelectedToponim != undefined) {
        if (!_.isEqual(this.alreadySelectedToponim, this.selected.toponim)) {
          this.selected.toponim = this.alreadySelectedToponim;
          shouldUpdate = true;
        }
      }

      if (shouldUpdate) {
        await this.updateFilters();
      }

      return shouldUpdate;
    },
    async updateFilters() {
      this.isLoading = true;
      var filters = await getFiltersApi(
        this.selected.filters,
        this.selected.toponim,
        fromFilter
      );

      this.filters = filters.filters;
      this.selected.filters = filters.selectedFilters;
      this.selected.toponim = filters.selectedToponim;

      var resultsCount = await getSearchResultsCountApi(
        {
          filters: filters.selectedFilters,
        },
        filters.selectedToponim
      );
      this.resultsCount = resultsCount;

      this.isLoading = false;
    },
    clearFilters() {
      this.selected.filters = {};
      this.selected.toponim = {};
      // TODO: пока устанавливает this.filters как [].
      //       Лучше давать фильтрам знать что значение изменилось на
      //       undefined: если сервер изменит какие-либо значения в процессе выбора фильтров,
      //       изменения не будут видны пользователю. Пока такое не происходит, можно установить фильтры как [].
      this.filters = [];
      this.applyClicked();
      this.updateFilters();
    },
    applyClicked() {
      this.$emit(
        "on-filters-applied",
        this.selected.filters,
        this.selected.toponim
      );
    },
  },
};
</script>

<style scoped>
.filters-list {
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
}

.loader-anim {
  position: sticky;
  top: 200px;
  z-index: 99;
  height: 0;
  margin: 0;
}

.clear-button-wrapper {
  width: 100%;
  display: flex;
}

.clear-button {
  margin-left: auto;
  margin-bottom: 15px;
}

.clear-button a {
  margin: 0;
}

.obstacle-wrap {
  position: relative;
}

.obstacle {
  background: red;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 99;
}

.submit-search-form {
  padding: 10px;
  background: #f9f9f9;
  width: 100%;
}

.submit-search-form::before,
.submit-search-form::after {
  z-index: 99;
}

.submit-sticky {
  position: sticky;
  bottom: 0;
  z-index: 99;
}

.af-button {
  user-select: none;
}
</style>
