<template>
  <div class="textbox-suggestions">
    <div class="suggestion-wrap">
      <span v-if="icon" v-html="icon" class="suggestion-icon"></span>
      <input
        ref="textInput"
        :id="id"
        :placeholder="placeholder"
        :style="icon ? '' : 'padding-left: 10px;'"
        :type="inputType"
        @keydown.down="selectSuggestion('next')"
        @keydown.up="selectSuggestion('prev')"
        @keydown.enter="inputEnterClicked()"
        @input="inputKeydown"
        @focus="inputFocus"
        @blur="inputBlur"
        :value="inputText"
        class="suggestion-input"
        role="combobox"
        :aria-label="label"
        aria-autocomplete="list"
        aria-expanded="false"
        autocomplete="off"
      />

      <a class="clear-btn" @click="clearInputText">
        <i class="aficon-times-circle"></i>
      </a>
    </div>

    <div class="autocomplete-suggestions-wrap">
      <div
        v-show="showSuggestions"
        class="autocomplete-suggestions"
        role="listbox"
        :aria-expanded="suggestionsList.length != 0"
        @mouseleave="selectedSuggestionIdx = -1"
      >
        <div
          role="option"
          v-for="(suggestion, index) in suggestionsList"
          :key="suggestion.idx"
          :class="getSuggestionClass(index)"
          @mouseover="selectedSuggestionIdx = index"
          @click="suggestionClicked(suggestion)"
          @keydown.up="selectSuggestion('prev')"
          @keydown.down="selectSuggestion('next')"
          @keydown.enter="suggestionClicked(suggestion)"
        >
          <img
            v-if="suggestion.iconUrl"
            :src="suggestion.iconUrl"
            width="20"
            height="20"
            aria-hidden="true"
          />
          <div>
            <div class="title">{{ suggestion.title }}</div>
            <div v-if="suggestion.description" class="description">
              {{ suggestion.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "TextboxSuggestions",
  props: {
    /** Id поля ввода. */
    id: {
      type: String,
      required: false,
      default() {
        return "input_" + uuidv4();
      },
    },
    /** Описание поля ввода */
    label: {
      type: String,
      required: true,
    },
    /** Подсказки, которые показаны в поле ввода.
     * Должен быть или:
     *  - список string, ['first', 'second', 'third']
     *  - список object с полями:
     *    - title { String } - текст подсказки. обязательно
     *    - description { String } - описание подсказки. может быть undefined
     *    - iconUrl { String } - url к иконке (может быть undefined)
     *    - valueObj { Object } - объект, который нужно выбрать если выбрали эту
     *                            подсказку. может быть undefined
     */
    suggestions: {
      type: Array,
      required: false,
      default: undefined,
    },
    /** HTML иконки, которую нужно показать перед поиском */
    icon: {
      type: String,
      required: false,
      default: "",
    },
    /** Placeholder для пустого значения input */
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    /** Показывать кнопку очистить */
    canClear: {
      type: Boolean,
      required: false,
      default: true,
    },
    /** Текст, который должен быть введен в поле ввода */
    defaultText: {
      type: String,
      required: false,
      default: undefined,
    },
    /** Тип, который нужно применить к input */
    inputType: {
      type: String,
      required: false,
      default: "text",
    },
  },
  emits: [
    /**
     * Была нажата клавиша ввод.
     * Параметры:
     *   1. val - текст ввода
     *   2. valueObj - объект, указанный в suggestion (если была нажата suggestion).
     *                 Может быть undefined.
     */
    "on-search-clicked",
    /**
     * Изменился текст в поле ввода.
     * Параметры:
     *   1. text - новый текст в поле ввода
     */
    "on-input-changed",
    /**
     * Фокус был перемещен в окно ввода.
     * Можно использовать для получения подсказок в первый раз, например.
     */
    "on-input-focused",
  ],
  setup(props) {
    return {
      inputText: ref(props.defaultText == undefined ? "" : props.defaultText),
      suggestionsList: ref([]),
      showSuggestions: ref(false),
      selectedSuggestionIdx: ref(-1),
    };
  },
  mounted() {
    this.updateSuggestionsList();
  },
  watch: {
    suggestions: {
      handler() {
        this.updateSuggestionsList();
      },
      deep: true,
    },
    defaultText(t) {
      this.inputText = t;
    },
  },
  methods: {
    getSuggestionClass(idx) {
      if (this.selectedSuggestionIdx != -1) {
        if (idx == this.selectedSuggestionIdx) {
          return "autocomplete-suggestion selected";
        }
      }

      return "autocomplete-suggestion";
    },
    selectSuggestion(action) {
      if (
        this.suggestionsList == undefined ||
        this.suggestionsList.length == 0
      ) {
        return;
      }

      var count;
      if (action == "next") {
        count = 1;
      } else if (action == "prev") {
        count = -1;
      } else {
        return;
      }

      if (this.selectedSuggestionIdx == -1) {
        if (count == 1) {
          this.selectedSuggestionIdx = 0;
        } else {
          this.selectedSuggestionIdx = this.suggestionsList.length - 1;
        }

        this.inputText = this.suggestionsList[this.selectedSuggestionIdx].title;
        return;
      }

      if (
        this.selectedSuggestionIdx >= this.suggestionsList.length - 1 &&
        count == 1
      ) {
        this.selectedSuggestionIdx = 0;
        this.inputText = this.suggestionsList[0].title;
        return;
      }

      if (this.selectedSuggestionIdx <= 0 && count == -1) {
        this.selectedSuggestionIdx = this.suggestionsList.length - 1;
        this.inputText = this.suggestionsList[
          this.suggestionsList.length - 1
        ].title;

        return;
      }

      this.selectedSuggestionIdx += count;
      this.inputText = this.suggestionsList[this.selectedSuggestionIdx].title;
    },
    suggestionClicked(sugg) {
      this.$emit("on-search-clicked", sugg.title, sugg.valueObj);
    },
    inputEnterClicked() {
      if (this.suggestionsList != undefined) {
        if (this.selectedSuggestionIdx != -1) {
          var sugg = this.suggestionsList[this.selectedSuggestionIdx];
          this.$emit("on-search-clicked", sugg.title, sugg.valueObj);
          return;
        }
      }

      this.$emit("on-search-clicked", this.inputText, undefined);
    },
    inputKeydown(ev) {
      this.selectedSuggestionIdx = -1;
      this.inputText = ev.target.value;
      this.$emit("on-input-changed", this.inputText);
    },
    updateSuggestionsList() {
      if (this.suggestions == undefined) {
        return;
      }

      var list = [];
      var idx = 0;

      for (var s of this.suggestions) {
        if (typeof s == "string") {
          list.push({
            idx,
            title: s,
            description: undefined,
            iconUrl: undefined,
          });
          idx += 1;
        } else if (typeof s == "object") {
          var title = s.title;
          if (title != undefined)
            list.push({
              idx,
              title,
              description: s.description,
              iconUrl: s.iconUrl,
              valueObj: s.valueObj,
            });
          idx += 1;
        }
      }

      this.suggestionsList = list;
    },
    clearInputText() {
      this.inputText = "";
      this.$emit("on-input-changed", this.inputText);
    },
    inputFocus() {
      this.$emit("on-input-focused");
      this.showSuggestions = true;
    },
    inputBlur() {
      // не ловит click если установить showSuggestions = true сразу
      setTimeout(() => {
        this.showSuggestions = false;
      }, 200);
    },
    /** Спрятать подсказки из вида.
     * Может быть вызвано родительскими компонентами
     */
    hideHints() {
      this.showSuggestions = false;
      this.$refs.textInput.blur();
    },
  },
};
</script>

<style scoped>
.textbox-suggestions {
  display: block;
  width: 100%;
}

.suggestion-wrap {
  display: flex;
  background-color: white;
  width: 100%;
}

.suggestion-wrap > input {
  width: 100%;
}

.suggestion-icon {
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  opacity: 0.4;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  width: 20px;
  height: 20px;
  position: relative;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #000;
}

.suggestion-input {
  box-sizing: border-box;
  background: none;
  padding: 5px;
  position: relative;
  overflow: hidden;
  width: calc(100% - 25px);
  border: none !important;
  margin: 0;
}

.clear-btn {
  margin: auto 10px auto auto;
  visibility: hidden;
}

.suggestion-wrap:hover > .clear-btn {
  visibility: visible;
}

.autocomplete-suggestions-wrap {
  position: relative;
}

.autocomplete-suggestions {
  max-height: 300px;
  overflow: auto;
  position: absolute;
  width: 100%;
  z-index: 999;
  cursor: pointer;
}

.autocomplete-suggestion {
  display: flex;
}

.autocomplete-suggestion img {
  width: 20px;
  height: 20px;
  margin: auto 5px auto 0;
}

.autocomplete-suggestion .title {
  font-size: 15px;
}

.autocomplete-suggestion .description {
  font-size: 12px;
}
</style>
