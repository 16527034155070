<template>
  <div class="empty-div">
    <wrapper-static v-if="showName" :filterNameText="name"> </wrapper-static>
  </div>
</template>

<script>
import GenericFilterMixin from "../mixins/GenericFilterMixin";

import WrapperStatic from "./WrapperStatic.vue";

export default {
  name: "FilterEmptySpace",
  components: {
    WrapperStatic,
  },
  props: {
    name: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  mixins: [GenericFilterMixin],
  computed: {
    showName() {
      return (
        this.name != undefined && this.name != null && this.name.length != 0
      );
    },
  },
};
</script>

<style scoped>
.empty-div {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
