<template>
  <header class="sticky-header author-header">
    <div class="flex-wrap">
      <button
        class="small-sidebar-open"
        :title="t('profile_header.sidebar')"
        @click="() => toggleSidebar()"
      >
        <i class="aficon-align-left"></i>
        <span class="small-icon">{{ t("profile_header.sidebar") }}</span>
      </button>
      <!-- <a class="small-sidebar-open" data-target=".navigation-wrap">
        <i class="aficon-align-justify"></i>
        <span class="small-icon">Menu</span>
      </a> -->
      <!-- <div class="show-on-414">
        <a class="submit-btn-wrap submit-btn ">
          <i class="aficon-add-ad"></i>
          <span>Submit Ad</span>
          <span class="small-icon">Submit Ad</span>
        </a>
      </div> -->
      <div class="navigation-wrap">
        <ul class="navigation list-inline list-unstyled">
          <li
            id="menu-item-1710"
            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-has-children menu-item-1710 dropdown"
          >
            <router-link :to="{ name: 'Home' }">
              {{ t("profile_header.home") }}
            </router-link>
          </li>
          <li
            id="menu-item-1712"
            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-1712 dropdown "
          >
            <router-link
              :to="{ name: 'GetApp' }"
              :title="$t('profile_header.download_app')"
              class="dropdown-toggle"
            >
              <i class="fas fa-mobile-alt"></i>
              {{ $t("profile_header.download_app") }}
            </router-link>
          </li>
          <!-- 
          <li
            id="menu-item-1713"
            class="menu-item menu-item-type-post_type menu-item-object-page current_page_parent menu-item-has-children menu-item-1713 dropdown "
          >
            <a
              title="News"
              href="http://127.0.0.1:8080/news/"
              class="dropdown-toggle"
              data-hover="dropdown"
              aria-haspopup="true"
              >News <i class="aficon-angle-down"></i
            ></a>
            <ul role="menu" class=" dropdown-menu">
              <li
                id="menu-item-1689"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1689"
              >
                <a
                  title="News Style 2"
                  href="http://demo.spoonthemes.net/themes/adifier/news/?listing_type=2"
                  >News Style 2</a
                >
              </li>
              <li
                id="menu-item-1690"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1690"
              >
                <a
                  title="News Style 3"
                  href="http://demo.spoonthemes.net/themes/adifier/news/?listing_type=3"
                  >News Style 3</a
                >
              </li>
              <li
                id="menu-item-1691"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1691"
              >
                <a
                  title="News Style 4"
                  href="http://demo.spoonthemes.net/themes/adifier/news/?listing_type=4"
                  >News Style 4</a
                >
              </li>
              <li
                id="menu-item-1692"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1692"
              >
                <a
                  title="News Style 5"
                  href="http://demo.spoonthemes.net/themes/adifier/news/?listing_type=5"
                  >News Style 5</a
                >
              </li>
            </ul>
          </li>
          <li
            id="menu-item-1662"
            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1662 dropdown"
          >
            <a
              title="Pages"
              href="#"
              class="dropdown-toggle"
              data-hover="dropdown"
              aria-haspopup="true"
              >Pages <i class="aficon-angle-down"></i
            ></a>
            <ul role="menu" class=" dropdown-menu" style="display: none;">
              <li
                id="menu-item-1722"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1722"
              >
                <a
                  title="All Categories"
                  href="http://127.0.0.1:8080/all-categories/"
                  >All Categories</a
                >
              </li>
              <li
                id="menu-item-1714"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1714"
              >
                <a title="FAQ" href="http://127.0.0.1:8080/faq/">FAQ</a>
              </li>
              <li
                id="menu-item-1715"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1715"
              >
                <a
                  title="How It Works"
                  href="http://127.0.0.1:8080/how-it-works/"
                  >How It Works</a
                >
              </li>
              <li
                id="menu-item-1725"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1725"
              >
                <a title="Our Sellers" href="http://127.0.0.1:8080/our-sellers/"
                  >Our Sellers</a
                >
              </li>
              <li
                id="menu-item-1693"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1693 dropdown "
              >
                <a
                  title="Ad Types"
                  href="#"
                  class="dropdown-toggle"
                  data-hover="dropdown"
                  aria-haspopup="true"
                  >Ad Types <i class="aficon-angle-right"></i
                ></a>
                <ul role="menu" class=" dropdown-menu">
                  <li
                    id="menu-item-1694"
                    class="menu-item menu-item-type-post_type menu-item-object-advert menu-item-1694"
                  >
                    <a
                      title="Sell"
                      href="http://127.0.0.1:8080/advert/scania-r-srs-l-class-r450-la-streamline-highline-diesel/"
                      >Sell</a
                    >
                  </li>
                  <li
                    id="menu-item-1695"
                    class="menu-item menu-item-type-post_type menu-item-object-advert menu-item-1695"
                  >
                    <a
                      title="Auction"
                      href="http://127.0.0.1:8080/advert/move-quickly-with-high-load-trailer/"
                      >Auction</a
                    >
                  </li>
                  <li
                    id="menu-item-1707"
                    class="menu-item menu-item-type-post_type menu-item-object-advert menu-item-1707"
                  >
                    <a
                      title="Buy"
                      href="http://127.0.0.1:8080/advert/construction-company-with-20-years-of-experience/"
                      >Buy</a
                    >
                  </li>
                  <li
                    id="menu-item-1696"
                    class="menu-item menu-item-type-post_type menu-item-object-advert menu-item-1696"
                  >
                    <a
                      title="Exchange"
                      href="http://127.0.0.1:8080/advert/trained-ballet-dancer-is-required-for-the-show/"
                      >Exchange</a
                    >
                  </li>
                  <li
                    id="menu-item-1697"
                    class="menu-item menu-item-type-post_type menu-item-object-advert menu-item-1697"
                  >
                    <a
                      title="Gift"
                      href="http://127.0.0.1:8080/advert/polaris-600-assault-144-snowmobile-2017-with-warranty/"
                      >Gift</a
                    >
                  </li>
                  <li
                    id="menu-item-1706"
                    class="menu-item menu-item-type-post_type menu-item-object-advert menu-item-1706"
                  >
                    <a
                      title="Rent"
                      href="http://127.0.0.1:8080/advert/indoor-furniture-pe-wicker-sofa-lounge-couch/"
                      >Rent</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li
            id="menu-item-1709"
            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1709"
          >
            <a title="Contact" href="http://127.0.0.1:8080/contact/">Contact</a>
          </li> -->
        </ul>
      </div>
      <!-- <div class="special-nav flex-wrap">
        <a
          href="javascript:void(0);"
          class="account-btn small-sidebar-open search-trigger show-on-414"
          data-target=".search-sidebar"
        >
          <i class="aficon-search"></i>
          <span class="small-icon">Search</span>
        </a>
        <a
          href="http://127.0.0.1:8080/author/oiufih8034/"
          class="account-btn header-user-icon"
          title="Visit Account"
        >
          <i class="aficon-user"></i>
          <span class="small-icon">Account</span>
        </a>
        <a
          href="http://127.0.0.1:8080/author/oiufih8034/?screen=messages"
          class="account-btn header-messages-icon"
          title="Messages"
        >
          <i class="aficon-messages"></i>
          <div class="messages-unread-count"></div>
          <span class="small-icon">Messages</span>
        </a>

        <a
          href="javascript:void(0);"
          class="account-btn compare-open"
          title="Compare"
        >
          <i class="aficon-repeat"></i>
          <div class="compare-count"></div>
          <span class="small-icon">Compare</span>
        </a>
        <a
          href="http://127.0.0.1:8080/author/oiufih8034/?screen=new"
          class="submit-btn-wrap submit-btn "
        >
          <i class="aficon-add-ad"></i>
          <span>Submit Ad</span>
          <span class="small-icon">Submit Ad</span>
        </a>
        <a
          href="javascript:void(0);"
          class="small-sidebar-open navigation-trigger"
          data-target=".navigation-wrap"
        >
          <i class="aficon-align-justify"></i>
          <span class="small-icon">Menu</span>
        </a>
      </div> -->
    </div>
  </header>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "ProfileHeader",
  props: {
    sidebarVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    /**
     * Сделать sidebar visible.
     * Параметры:
     * 1. Boolean должен ли sidebar быть видимым
     */
    "set-sidebar-visible",
  ],
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  methods: {
    /**
     * Установить sidebarVisible = `value`, toggle если value = undefined.
     * Может быть вызвана родителем.
     * @param {Boolean} value значение, которое нужно установить
     */
    toggleSidebar(value) {
      var sidebarVisible = Boolean(value);
      if (value == null || value == undefined) {
        sidebarVisible = !this.sidebarVisible;
      }

      this.$emit("set-sidebar-visible", sidebarVisible);
    },
  },
};
</script>

<style scoped>
button {
  border: none;
  background: none;
}
</style>
