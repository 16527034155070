import {
  createRouter,
  createWebHistory,
  createMemoryHistory,
} from "vue-router";
import { createMetaManager, defaultConfig } from "vue-meta";
import store from "../store";

import routes from "./routes.js";

export default (isSSR) => {
  const metaManager = createMetaManager(isSSR, {
    ...defaultConfig,
    meta: { tag: "meta", nameless: true },
  });

  if (isSSR) {
    const router = createRouter({
      history: createMemoryHistory(),
      routes,
    });

    router.beforeEach((to, from, next) => {
      if (to.meta.authRequired && !store.getters["user/loggedIn"]) {
        next({ name: "LoginPage" });
      } else {
        next();
      }
    });

    router.onError((error, to, from) => {
      console.error("Router error: ", error, " to: ", to, " from: ", from);
      if (typeof window !== "undefined") {
        window.location.href = "/internal-error";
      }
    });

    return { router, metaManager };
  }

  const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return {
          el: to.hash,
          behavior: "smooth",
        };
      }

      if (savedPosition) {
        return savedPosition;
      } else {
        return { left: 0, top: 0 };
      }
    },
  });

  router.beforeEach((to, from, next) => {
    if (to.meta.authRequired && !store.getters["user/loggedIn"]) {
      next({ name: "LoginPage" });
    } else {
      next();
    }
  });

  router.onError((error, to, from) => {
    console.error("Router error: ", error, " to: ", to, " from: ", from);

    if (typeof window !== "undefined") {
      window.location.href = "/internal-error";
    }
  });

  return { router, metaManager };
};
