<template>
  <div class="element-wrapper">
    <div class="static-wrapper">
      <div class="labels">
        <label v-if="filterNameText != ''" :style="nameTextStyle">
          {{ filterNameText }}
        </label>
        <transition name="text-slide-up">
          <label v-if="filterValueText.length != 0" class="value-text">
            {{ filterValueText }}
          </label>
        </transition>
      </div>

      <div class="buttons">
        <div class="buttons-inner">
          <button
            @click="$emit('on-clear-selected')"
            v-if="canBeCleared"
            class="button-clear"
            :aria-label="t('filters.wrapper_static.clear')"
          >
            <i class="sl-close"></i>
          </button>

          <button
            @click="$emit('on-apply-clicked')"
            v-if="canBeApplied"
            class="button-apply"
            :aria-label="t('filters.wrapper_static.apply')"
          >
            <i class="sl-check"></i>
          </button>
        </div>
      </div>
    </div>

    <slot></slot>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "WrapperStatic",
  props: {
    /** Заголовок названия фильтра */
    filterNameText: {
      type: String,
      required: false,
      default: "",
    },
    /** Текст значения, установленного в фильтре */
    filterValueText: {
      type: String,
      required: false,
      default: "",
    },
    canBeApplied: {
      type: Boolean,
      required: false,
      default: false,
    },
    canBeCleared: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    /** Была нажата кнопка очистить выбранное */
    "on-clear-selected",

    /** Была нажата кнопка применить */
    "on-apply-clicked",
  ],
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  computed: {
    nameTextStyle() {
      if (this.filterValueText.length == 0) {
        return {
          fontSize: "15px",
        };
      }

      return {
        fontSize: "12px",
        color: "#616161",
      };
    },
  },
};
</script>

<style scoped>
button {
  font-size: 15px;
  transition: all 0.2s ease;
  padding-left: 10px;
}

label {
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.1s ease-in-out;
}

.static-wrapper {
  display: grid;
  margin-bottom: 10px;
}

.labels {
  grid-column: 1;
}

.labels label {
  padding: 0;
  margin: auto 0;
  width: 100%;
  text-align: start;
}

.buttons {
  grid-column: 2;
}

.buttons-inner {
  float: right;
}

.button-clear {
  width: auto;
  border-style: none;
  background: none;
}

.button-apply {
  width: auto;
  border-style: none;
  background: none;
  color: #4eac00;
}

.value-text {
  font-size: 15px;
  color: black;
}

.text-slide-up-enter-from {
  transform: translateY(10px);
}
</style>
