<template>
  <footer class="footer">
    <!-- TODO: email рассылка -->
    <!-- <div class="subscription-footer">
            <div class="container">
                <div class="flex-wrap">
                    <div class="flex-left flex-wrap">
                        <i class="aficon-paper-plane"></i>
                        <div class="subscribe-title">
                            <h4>Subscribe To Newsletter</h4>
                            <p>and receive new ads in inbox</p>
                        </div>
                    </div>
                    <div class="flex-right">
                        <form class="ajax-form" autocomplete="off"><input type="hidden" value="1" name="aff-cpt">
                            <div class="adifier-form">
                                <input type="text" name="email" placeholder="Input your email address">
                                <a href="javascript:void(0)" class="submit-ajax-form">Subscribe</a>
                            </div>
                            <input type="hidden" name="action" value="subscribe">
                            <input type="hidden" id="adifier_nonce" name="adifier_nonce" value="a04068290e"><input type="hidden" name="_wp_http_referer" value="/"> </form>
                    </div>
                </div>
            </div>
        </div> -->

    <div class="bottom-sidebar-wrap">
      <div class="container">
        <div class="row">
          <div class="col-sm-4">
            <div class="widget white-block clearfix widget_archive">
              <div class="white-block-title">
                <h5>{{ t("the_footer.useful_links") }}</h5>
              </div>
              <ul>
                <li>
                  <router-link :to="{ name: 'ScammersWarning' }">
                    {{ t("the_footer.scammers_link_text") }}
                  </router-link>
                </li>
                <li>
                  <a :href="t('the_footer.terms_link')">
                    {{ t("the_footer.terms_link_text") }}
                  </a>
                </li>
              </ul>
            </div>
            <!-- TODO: больше ссылок -->
            <!-- <div class="widget white-block clearfix widget_categories">
                            <div class="white-block-title">
                                <h5>Categories</h5>
                            </div>
                            <ul>
                                <li class="cat-item cat-item-2"><a href="http://127.0.0.1:8080/category/services/">Services</a>
                                </li>
                                <li class="cat-item cat-item-3"><a href="http://127.0.0.1:8080/category/tips-tricks/">Tips &amp; Tricks</a>
                                </li>
                                <li class="cat-item cat-item-4"><a href="http://127.0.0.1:8080/category/tutorials/">Tutorials</a>
                                </li>
                                <li class="cat-item cat-item-1"><a href="http://127.0.0.1:8080/category/%d0%b1%d0%b5%d0%b7-%d1%80%d1%83%d0%b1%d1%80%d0%b8%d0%ba%d0%b8/">Без рубрики</a>
                                </li>
                            </ul>

                        </div>
                        <div class="widget white-block clearfix widget_meta">
                            <div class="white-block-title">
                                <h5>Meta</h5>
                            </div>
                            <ul>
                                <li><a href="http://127.0.0.1:8080/wp-login.php">Log in</a></li>
                                <li><a href="http://127.0.0.1:8080/feed/">Entries feed</a></li>
                                <li><a href="http://127.0.0.1:8080/comments/feed/">Comments feed</a></li>

                                <li><a href="https://wordpress.org/">WordPress.org</a></li>
                            </ul>

                        </div> -->
            <!-- TODO: логотип? -->
            <!-- <div class="widget white-block clearfix widget_media_image"><img src="http://127.0.0.1:8080/wp-content/uploads/2018/03/logo-footer.png" class="image wp-image-544  attachment-full size-full" alt="" style="max-width: 100%; height: auto;" width="170" height="50"></div> -->
            <div class="widget white-block clearfix widget_text">
              <div class="textwidget">
                <p>{{ t("the_footer.before_contact_text") }}</p>
                <p>{{ t("the_footer.if_you_wish_to_contact_us") }}</p>
                <ul>
                  <li>
                    <i
                      class="fa fa-envelope"
                      aria-hidden="true"
                      style="margin-right: 10px;"
                    ></i>
                    <span style="color: #fff;">
                      {{ t("the_footer.email_us") }}
                      <a :href="'mailto:' + t('the_footer.email')">{{
                        t("the_footer.email")
                      }}</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <!-- TODO: вернуть строку поиска -->
            <!-- <div class="widget white-block clearfix widget_search">
                            <form method="get" class="searchform" action="http://127.0.0.1:8080/"><input type="hidden" value="1" name="aff-cpt">
                                <div class="adifier-form">
                                    <input type="text" value="" name="s" placeholder="Search for...">
                                    <input type="hidden" value="post" name="post_type">
                                    <a href="javascript:void(0);" class="submit-form"><i class="aficon-search"></i></a>
                                </div>
                            </form>
                        </div> -->
          </div>
          <div class="col-sm-4">
            <div class="widget white-block clearfix widget_adifier_posts_list">
              <div class="white-block-title">
                <h5>{{ t("the_footer.download_our_app") }}</h5>
              </div>
              <ul class="list-unstyled">
                <li>
                  <a
                    :href="t('the_footer.google_play_url')"
                    :title="t('the_footer.get_on_google_play')"
                  >
                    <i class="fab fa-google-play"></i>
                    {{ t("the_footer.get_on_google_play") }}
                  </a>
                </li>
                <li>
                  <a
                    :href="t('the_footer.app_store_url')"
                    :title="t('the_footer.get_in_app_store')"
                  >
                    <i class="fab fa-app-store-ios"></i>
                    {{ t("the_footer.get_in_app_store") }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="copyrights">
      <div class="container">
        <div class="flex-wrap">
          <div class="flex-left">© {{ t("the_footer.copyright") }}</div>
          <div class="flex-center">
            <ul class="list-unstyled list-inline social-links">
              <!-- TODO: social media pages -->
              <!-- <li>
                                <a href="#" target="_blank">
                                    <i class="aficon-facebook"></i>
                                </a>
                            </li>

                            <li>
                                <a href="#" target="_blank">
                                    <i class="aficon-twitter"></i>
                                </a>
                            </li>

                            <li>
                                <a href="#" target="_blank">
                                    <i class="aficon-instagram"></i>
                                </a>
                            </li> -->

              <li>
                <a
                  :href="t('the_footer.google_play_url')"
                  :title="t('the_footer.get_on_google_play')"
                >
                  <i class="fab fa-google-play"></i>
                </a>
              </li>
              <li>
                <a
                  :href="t('the_footer.app_store_url')"
                  :title="t('the_footer.get_in_app_store')"
                >
                  <i class="fab fa-app-store-ios"></i>
                </a>
              </li>
              <li>
                <a
                  :href="t('the_footer.youtube_link')"
                  target="_blank"
                  :title="t('the_footer.our_youtube')"
                >
                  <i class="aficon-youtube"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "TheFooter",
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
};
</script>

<style scoped>
.footer {
  margin-top: 20px;
}
</style>
