<template>
  <div class="alert" role="alert" v-if="showErr">
    <button
      class="closebtn"
      onclick="window.location.reload();"
      :title="t('the_error_handler.refresh')"
    >
      <i class="fas fa-redo" aria-hidden="true"></i>
    </button>
    <h1>{{ t("the_error_handler.error") }}</h1>
    <p>{{ t("the_error_handler.unknown_error_occurred") }}</p>
  </div>

  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useMeta } from "vue-meta";

export default {
  name: "TheErrorHandler",
  props: {
    showErr: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();

    const computedMeta = computed(() => {
      // TODO: Яндексу что-то не нравится
      // if (props.showErr) {
      // return {
      //   meta: [{ name: "robots", content: "noindex,nofollow" }],
      // };
      // }

      return {};
    });
    useMeta(computedMeta);

    return {
      t,
    };
  },
};
</script>

<style scoped>
button {
  background: none;
  border: none;
}

.alert {
  padding: 20px;
  background-color: #f44336;
  color: white;
  text-align: start;
  margin: 40vh 50px;
}

.alert > h1 {
  color: white;
}

.alert > p {
  color: white;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.closebtn:hover {
  text-decoration: underline;
}
</style>
