/** API для получения списка диалогов/сообщений, отправки сообщений */

import { grpc } from "@improbable-eng/grpc-web";

import {
  DialogRequest,
  Message,
  MessageRequest,
  MessagesCountRequest,
  UserDialogRequest,
} from "../proto/photos_pb.js";
import { ApiService } from "../proto/photos_pb_service.js";
import { getMeta } from "../utils/meta.js";
import { tsToDate } from "../utils/common.js";

import { AdState } from "../ad_details.js";

import DefaultAvatar from "assets/img/default_avatar.png";

import store from "store";

/**
 * Получить список диалогов
 * @param {String} {cursor - курсор поиска
 * @param {Number} limit} - максимальное количество результатов на странице
 * @returns {Object} Вернет объект с полями:
 *   - dialogs - список диалогов
 *   - cursor - курсор поиска
 */
export function getDialogs({ cursor, limit }) {
  var request = new UserDialogRequest();

  var myId = store.state.user.userId;

  request.setUserId(myId);

  if (cursor) {
    request.setCursor(cursor);
  }

  if (limit) {
    request.setPerPage(limit);
  } else {
    request.setPerPage(10);
  }

  return new Promise((resolve) => {
    grpc.unary(ApiService.GetUserDialogs, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          var e = new Error("error ApiService.GetUserDialogs");
          e.statusMessage = statusMessage;
          e.message = message;
          throw e;
        }

        var obj = message.toObject();

        var dialogs = [];

        for (var d of obj.dialogsList) {
          var lastMessage = messageFromList(d.messagesList[0]);

          var ad = adFromObj(d);

          dialogs.push({
            uuid: d.uuid,
            unreadMessages: d.unreadMessages,
            lastMessage,
            ad,
          });
        }

        return resolve({
          dialogs,
          cursor: obj.cursor,
        });
      },
    });
  });
}

/**
 * Получить список сообщений в диалоге
 * @param {String} {dialogId id диалога, который нужно получить
 * @param {Number} limit максимальное количество результатов
 * @param {String} cursor} курсор результатов поиска
 * @returns {Object} Вернет объект с полями
 *   - ad
 *   - cursor
 *   - messages
 */
export function getMessages({ dialogId, limit, cursor }) {
  if (!dialogId) {
    throw "No dialog id in getMessages";
  }

  var request = new DialogRequest();

  request.setUserId(store.state.user.userId);
  request.setDialogId(dialogId);

  if (cursor) {
    request.setCursor(cursor);
  }

  if (limit) {
    request.setPerPage(limit);
  } else {
    request.setPerPage(10);
  }

  return new Promise((resolve, reject) => {
    grpc.unary(ApiService.GetDialog, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error ApiService.GetDialog",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        var ad = adFromObj(obj);

        var messages = [];

        if (obj.messagesList) {
          for (var msg of obj.messagesList) {
            var m = messageFromList(msg);
            if (m) {
              messages.push(m);
            }
          }
        }

        return resolve({
          ad,
          cursor: obj.cursor,
          messages,
        });
      },
    });
  });
}

/**
 * Проверить есть ли новые сообщения после сообщения с lastMessageId
 * @param {String} {dialogId id диалога, для которого нужно получить новые сообщения
 * @param {String} lastMessageId} id последнего сообщения, после которого нужно проверить
 * @returns {Boolean} true если есть новые сообщения
 */
export async function hasNewMessages({ dialogId, lastMessageId }) {
  var newMessages = await getMessages({
    dialogId,
    limit: 1,
    cursor: "",
  });

  var firstMessage = newMessages.messages[0];
  if (!firstMessage || !firstMessage.uuid) {
    return false;
  }

  return firstMessage.uuid != lastMessageId;
}

/**
 * Пометить диалог как прочитанный
 * @param {String} {dialogId} - id диалога, который нужно пометить как прочитанный
 */
export function setViewedDialog({ dialogId }) {
  var request = new DialogRequest();

  request.setUserId(store.state.user.userId);
  request.setDialogId(dialogId);

  return new Promise((resolve, reject) => {
    grpc.unary(ApiService.SetViewedDialog, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error ApiService.SetViewedDialog",
            status,
            statusMessage,
            message,
          });
        }

        return resolve();
      },
    });
  });
}

/**
 * Отправить сообщение
 * @param {String} {adId id объявления, которое нужно получить
 * @param {String} dialogId id диалога, в который нужно отправить сообщение
 * @param {String} messageText} сообщение, которое нужно отправить
 * @returns {any}
 */
export function sendMessage({ adId, dialogId, messageText }) {
  var request = new MessageRequest();

  var msg = new Message();
  msg.setUserId(store.state.user.userId);
  msg.setPayload(messageText);

  request.setMessage(msg);
  request.setDialogId(dialogId);
  request.setAdId(adId);

  return new Promise((resolve) => {
    grpc.unary(ApiService.WriteMessage, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          var e = new Error("error ApiService.WriteMessage");
          e.statusMessage = statusMessage;
          e.message = message;
          throw e;
        }

        return resolve();
      },
    });
  });
}

export async function getNewMessagesCount() {
  if (!store.state.user.userId) {
    return 0;
  }

  var request = new MessagesCountRequest();

  request.setUserId(store.state.user.userId);

  return new Promise((resolve, reject) => {
    grpc.unary(ApiService.GetNewMessagesCount, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error ApiService.GetNewMessagesCount",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        return resolve(obj.counter ? obj.counter : 0);
      },
    });
  });
}

/**
 * Получить объявление для объекта из grpc
 * @param {Object} obj объект диалога, из которого нужно получить объект
 * @returns {Object}
 */
function adFromObj(obj) {
  var ad = null;

  if (obj.ad) {
    var mainPhoto = null;

    var p = obj.ad.photosList[0];
    if (p) {
      mainPhoto = {
        thumb: p.thumb,
        small: p.small,
      };
    }

    ad = {
      uuid: obj.ad.id,
      price: obj.ad.price,
      name: obj.ad.name,
      mainPhoto,
      state: Object.keys(AdState).find((key) => AdState[key] == obj.ad.state),
    };
  }

  return ad;
}

/**
 * Получить сообщение из списка диалога
 * @param {Object} m
 * @returns {Object}
 */
function messageFromList(m) {
  var msg = null;

  if (m) {
    var myId = store.state.user.userId;
    var isMe = m.userId ? m.userId == myId : false;

    var user = null;

    if (m.user) {
      user = {
        thumbUrl: m.user.thumbUrl ? m.user.thumbUrl : DefaultAvatar,
        userId: m.user.userId,
        username: m.user.username,
      };
    }

    msg = {
      createdAt: tsToDate(m.createdAt),
      text: m.payload,
      isMe,
      user,
      viewed: isMe ? true : m.viewed,
      uuid: m.uuid,
    };
  }

  return msg;
}
