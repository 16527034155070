<template>
  <div>
    <h2 class="sr-only">{{ t("grid_categories.categories_list") }}</h2>
    <div class="kc-row-container  kc-container">
      <div class="kc-wrap-columns">
        <div class="kc-elm kc-css-398759 kc_col-sm-10 kc_column kc_col-sm-10">
          <div
            class="element-categories-table element-categories-table-5 flex-wrap flex-center"
          >
            <router-link
              :to="{
                name: 'SearchPage',
                query: { f: createCategoryFilters(category.id) },
              }"
              :key="category.id"
              v-for="category in categories"
              class="hover-shadow"
            >
              <div class="category-item" style="padding: 15px">
                <img
                  :src="category.icon"
                  aria-hidden="true"
                  width="55"
                  height="55"
                />

                <h6 class="animation">{{ category.name }}</h6>

                <div
                  v-if="categoryStats[category.id]"
                  class="category-item-count"
                >
                  {{
                    $t("grid_categories.x_ads", {
                      x: categoryStats[category.id].toLocaleString(),
                    })
                  }}
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import TransportIcon from "../assets/category-icons/transport.svg";
import RealtyIcon from "../assets/category-icons/realty.svg";
import JobsIcon from "../assets/category-icons/jobs.svg";
import ServicesIcon from "../assets/category-icons/services.svg";
import ElectronicsIcon from "../assets/category-icons/electronics.svg";
import GoodsIcon from "/src/assets/category-icons/goods.svg";
import HomeIcon from "/src/assets/category-icons/home.svg";
import HobbiesIcon from "/src/assets/category-icons/hobby.svg";
import AnimalsIcon from "/src/assets/category-icons/animals.svg";
import BusinessIcon from "/src/assets/category-icons/business.svg";

import { createCategoryFilters } from "../views/SearchPage/search.js";

import { getCategoryStats as getCategoryStatsApi } from "api/categories.js";

export default {
  name: "GridCategories",
  setup() {
    const { t } = useI18n();
    const categories = [
      {
        id: "ba9e5194-cacf-4d6c-a9ec-cf4c959cdbf0",
        name: "Транспорт",
        icon: TransportIcon,
      },
      {
        id: "6b132d5a-52b3-40c4-b479-75a02952d761",
        name: "Дома и квартиры",
        icon: RealtyIcon,
      },
      {
        id: "4ebee241-1678-458c-b222-04b460272730",
        name: "Работа",
        icon: JobsIcon,
      },
      {
        id: "d16cfad7-62d6-4a8d-9dfa-6e226e10d31d",
        name: "Услуги",
        icon: ServicesIcon,
      },
      {
        id: "2455f80f-135a-47de-b2ff-4c7723c902c5",
        name: "Личные вещи",
        icon: GoodsIcon,
      },
      {
        id: "653542d7-e2f2-4995-a51a-0a5026812f9c",
        name: "Для дома и дачи",
        icon: HomeIcon,
      },
      {
        id: "39202bdc-2009-4554-9ac3-32527199b511",
        name: "Бытовая электроника",
        icon: ElectronicsIcon,
      },
      {
        id: "4c931989-a644-4eef-be61-826c08c1248e",
        name: "Хобби и отдых",
        icon: HobbiesIcon,
      },
      {
        id: "93fa481b-e257-4fe3-accc-29f5a9bbe6c9",
        name: "Животные",
        icon: AnimalsIcon,
      },
      {
        id: "a71074f0-2e51-4327-a118-9a06d08be533",
        name: "Для бизнеса",
        icon: BusinessIcon,
      },
    ];

    return {
      t,
      categories,
      createCategoryFilters,
      categoryStats: ref([]),
    };
  },
  async mounted() {
    this.categoryStats = await getCategoryStatsApi(
      this.categories.map((c) => c.id)
    );
  },
};
</script>

<style scoped>
.category-item-count {
  margin-top: 10px;
}

.element-categories-list {
  justify-content: center;
}

.element-categories-table {
  justify-content: center;
}

.kc-css-398759 {
  margin: auto;
  width: 100%;
}
</style>
