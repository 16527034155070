<template>
  <div>
    <span
      :class="starOne"
      :aria-label="
        t('rating_star.rating_stars', { x: Number(rating).toPrecision(1) })
      "
    ></span>
    <span :class="starTwo"></span>
    <span :class="starThree"></span>
    <span :class="starFour"></span>
    <span :class="starFive"></span>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "RatingStar",
  props: {
    /** Рейтинг, от 0 до 5 */
    rating: Number,
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  computed: {
    starOne() {
      if (this.$props.rating >= 1) {
        return "aficon-star";
      }
      return "aficon-star-o";
    },
    starTwo() {
      if (this.$props.rating >= 2) {
        return "aficon-star";
      }
      return "aficon-star-o";
    },
    starThree() {
      if (this.$props.rating >= 3) {
        return "aficon-star";
      }
      return "aficon-star-o";
    },
    starFour() {
      if (this.$props.rating >= 4) {
        return "aficon-star";
      }
      return "aficon-star-o";
    },
    starFive() {
      if (this.$props.rating >= 5) {
        return "aficon-star";
      }
      return "aficon-star-o";
    },
  },
};
</script>

<style scoped></style>
