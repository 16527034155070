<template>
  <div class="white-block filters-toggle">
    <div class="white-block-content">
      <h6>
        <a @click="toggleFilters">{{ t("search_page.toggle_filters") }}</a>
      </h6>
    </div>
  </div>

  <div class="search-form" :class="searchFormClass">
    <FiltersList
      :stickyBtnApply="!showFilters"
      :alreadySelectedFilters="alreadySelectedFilters"
      :alreadySelectedToponim="alreadySelectedToponim"
      @on-filters-applied="filtersApplied"
    />
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useToggle } from "@vueuse/core";

import FiltersList from "modules/filters/FiltersList.vue";

export default {
  name: "FiltersSearch",
  components: {
    FiltersList,
  },
  props: {
    /**
     * Фильтры, которые уже были выбраны.
     * Должен иметь ключ filter_id и значение filter_value
     */
    alreadySelectedFilters: {
      type: Object,
      required: false,
      default: undefined,
    },
    /** Топоним, который уже был выбран */
    alreadySelectedToponim: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  emits: [
    /**
     * Была нажата кнопка применить.
     * Параметры:
     *  1. filters {Object} - список фильтров
     *  2. toponim {Object} - топоним
     */
    "on-filters-applied",
  ],
  setup() {
    const { t } = useI18n();
    const [showFilters, toggleFilters] = useToggle();

    return {
      t,
      showFilters,
      toggleFilters,
    };
  },
  computed: {
    searchFormClass() {
      if (this.showFilters) {
        return "search-form-active";
      }

      return "";
    },
  },
  methods: {
    filtersApplied(filters, toponim) {
      this.$emit("on-filters-applied", filters, toponim);
      this.toggleFilters();
    },
  },
};
</script>

<style scoped>
.filters-toggle {
  width: 100%;
  margin-bottom: 20px;
  user-select: none;
  margin: "auto 15px auto 0";
}

.search-form {
  transition: max-height 0.4s linear;
  overflow: initial !important;
}

.search-form.search-form-active {
  display: block;
  visibility: visible;
  margin-bottom: 50px;
}

@media (max-width: 740px) {
  .search-form {
    margin-bottom: 0;
    visibility: hidden;
    overflow: hidden;
    display: none;
  }
}
</style>
